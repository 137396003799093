import {useMutation, useQueryClient } from "@tanstack/react-query";
import endpoint from "../../Helpers/endpoint";
import Http from "../../http";

const addNormalization = ({data, token}) => {
    const URL = `${endpoint.normalization.addNormalization}`;
    const headers  = {
        "Authorization": `Bearer ${token}` 
    }
    return Http().post(URL, data, headers);
}

const deleteNormalization = ({questionid, token}) => {
  const URL = `${endpoint.normalization.addNormalization}/${questionid}`;
  const headers  = {
      "Authorization": `Bearer ${token}` 
  }
  return Http().delete(URL, '', headers);
}


export const useNormalization = () => {
  const queryClient = useQueryClient();
  return useMutation({
      mutationFn:addNormalization,
      onSuccess: (data, variables, context) => {
        // I will fire first
        queryClient.refetchQueries(['getNormalization', data]);
      },
      onError: (error, variables, context) => {
        // I will fire first
      },
      onSettled: (data, error, variables, context) => {
        // I will fire first
      },
  });
}


export const useNormalizationDelete = () => {
  const queryClient = useQueryClient();
  return useMutation({
      mutationFn:deleteNormalization,
      onSuccess: (data, variables, context) => {
        // I will fire first
        queryClient.refetchQueries(['getNormalization', data]);
      },
      onError: (error, variables, context) => {
        // I will fire first
      },
      onSettled: (data, error, variables, context) => {
        // I will fire first
      },
  });
}



