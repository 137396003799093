import Http from "../../../http";
import endpoint from "../../../Helpers/endpoint";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { Spinner } from "../../Loader/Spinner";
import AdminLayouts from "../../../Layouts/Admin/AdminLayouts";

export function AdminJobDetail() {
    const {jobuid} = useParams();
    function getJobs() {
        let endpoints = `${endpoint.job.jobdetail}/${jobuid}`;
        return Http().get(endpoints);
    }
    const { data, isLoading } = useQuery({ queryKey: ["job-detail", jobuid], queryFn: () => getJobs() });

    return(
        <AdminLayouts>
        <div className="w-full h-[100vh] bg-green-100">
          <div className="w-[95%] m-[auto] gap-3 flex items-center justify-start flex-col mt-10">
            <div className="w-[95%] border border-gray-200 bg-white p-8 rounded-md gap-3 flex items-start justify-center flex-col">
            {isLoading ? <Spinner /> : <>
                <p className="font-medium text-[16px]">
                    Job Title:{" "}
                    <span className="font-normal text-[14px]">
                    {data?.data?.jobtitle}
                    </span>
                </p>
                <div className="font-medium text-[16px]">
                    {" "}
                    Job Overview:{" "}
                    <div
                    className="text-[14px] font-normal mt-3"
                    dangerouslySetInnerHTML={{
                        __html: data?.data?.description,
                    }}
                    />{" "}
                </div>
                <p className="font-medium text-[16px]">
                    Mandatory Skills:{" "}
                    <span className="font-normal text-[14px]">
                    {data?.data?.mandatoryskills}
                    </span>
                </p>
                <p className="font-medium text-[16px]">
                    Good ToHave Skills:{" "}
                    <span className="font-normal text-[14px]">
                        {data?.data?.additionalskills}
                    </span>
                </p>
                <p className="font-medium text-[16px]">Screening Questions: </p>
                {data?.screening?.map((data, index) => {
                    return (
                    <p className="font-normal text-[14px]" key={data}>
                        {index + 1}. {data?.question}
                    </p>
                    );
                })}
            </>
            }
            </div>
          </div>
        </div>
        </AdminLayouts>
    );
}