import React from "react";
import AdminLayouts from "../../../Layouts/Admin/AdminLayouts";
import LineChart from "../../Chart/LineChart";
import DoughnutChart from "../../Chart/Doughnut";
import BarlineChart from "../../Chart/BarlineChart";
import endpoint from "../../../Helpers/endpoint";
import Http from "../../../http";
import Token from "../../../Helpers/Token";
import { useQuery } from "@tanstack/react-query";
import { JobsReport } from "./JobsReport";

export default function AdminReport(){
    const  getReportCandWeek = () => {
        const URL = `${endpoint.report.candidate}?typeof=Week`;
        return Http().get(URL, {
            'Authorization' : `Bearer ${Token.getAccessToken()}`,
        });
    }

    const  getReportCandYear = () => {
        const URL = `${endpoint.report.candidate}?typeof=Year`;
        return Http().get(URL, {
            'Authorization' : `Bearer ${Token.getAccessToken()}`,
        });
    }

    const  mulipleSource = () => {
        const URL = `${endpoint.report.candidate}?typeof=mulipleSource`;
        return Http().get(URL, {
            'Authorization' : `Bearer ${Token.getAccessToken()}`,
        });
    }

    const {data:candReportWeek} = useQuery({queryKey: ['getcandreportweek'], queryFn: getReportCandWeek});
    const {data:candReportYear} = useQuery({queryKey: ['getcandreportyear'], queryFn: getReportCandYear});
    const {data:candReportmultipleSource} = useQuery({queryKey: ['getcandreportSource'], queryFn: mulipleSource});


    return (
        <AdminLayouts>
            <div className="px-10 py-5 w-full bg-green-50">
                <div className="text-center py-2"><h1 className="text-[20px]">Candidate Analytics</h1></div>
                <div className="flex flex-wrap  justify-between">
                    <div className="w-[49%] mb-3 mr-2 px-3 py-3 bg-white rounded border">
                        <LineChart Report={candReportWeek} chartType="Weekly Candidate" />
                    </div>
                    <div className="w-[49%] mb-3 mr-2 px-3 py-3 bg-white rounded border">
                        <DoughnutChart Report={candReportmultipleSource} datatype={"cvsource"} />
                    </div>
                    {/* <div className="w-[49%] mr-2 mb-3 px-3 py-3 bg-white rounded border">
                        <RadarChart />
                    </div> */}
                    <div className="w-[49%] mr-2 mb-3 px-3 py-3 bg-white rounded border">
                       <BarlineChart  Report={candReportYear} chartLabel="Montly Candidate"  />
                    </div>
                </div>
                <div className="text-center py-2"><h1 className="text-[20px]">Job Analytics</h1></div>
               <JobsReport />
            </div>
        </AdminLayouts>
    );
}