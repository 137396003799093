import React, { useState } from "react";
import SideBar from "./SideBar";
import endpoint from "../../Helpers/endpoint";
import Http from "../../http";
import { useQuery } from "@tanstack/react-query";
import ProfileUserStatus from "../../Popups/ProfileUserStatus";

export default function UserLayout({ children }) {
  const [open, setOpen] = useState(false);
  const getHeader = () => {
    let endpoints = endpoint.user.header;
    return Http().get(endpoints);

  };

  const { data } = useQuery({queryKey : ['Header'], queryFn : getHeader});

  return (
    <div className="w-full flex items-start justify-center relative bg-lightbrown bg-opacity-5">
      <ProfileUserStatus data={data?.profile} />
      <SideBar header={data} open={open} setOpen={setOpen} />
      <div
        className={`${
          open ? "w-full" : ""
        } w-[85%] flex items-center justify-center`}
      >
      {typeof children === 'function' ? children({ open, setOpen, header : data }) : children}
      </div>
    </div>
  );
}
