import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaTimesCircle } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import endpoint from "../../../Helpers/endpoint";
import Token from "../../../Helpers/Token";
import { useProductAdd, useProductUpdate } from "../../../hooks/apihooks/useProduct";
import Http from "../../../http";
import AdminLayouts from "../../../Layouts/Admin/AdminLayouts";
import { Spinner } from "../../Loader/Spinner";

export default function AddEditProduct({title}) {
  const {productuid} = useParams();
  const [countcurrency, setCountmultcurrency] = useState([{original_price : "", sale_price : "", currency : ""}]);
  const {register, handleSubmit, formState: {errors}, reset} = useForm();
  const {mutateAsync : addProduct, isPending} = useProductAdd();
  const {mutateAsync : updateProduct, isPending : isUpdatePending} = useProductUpdate();
  // Function to handle removing an input field
  const removeInputField = (index) => {
    const newInputs = countcurrency.filter((_, i) => i !== index);
    setCountmultcurrency(newInputs);
  }

  const getsingleProduct = () => {
    const URL = `${process.env.REACT_APP_APIENDPOINT}${endpoint.product.product}/${productuid}`;
    return Http().get(URL, {
      "Authorization": `Bearer ${Token.getAccessToken()}` 
    });
  }
  const {data, isLoading} = useQuery({queryKey: ['getsingleproduct', productuid], queryFn : getsingleProduct, enabled: Boolean(productuid)});

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    setCountmultcurrency(prevState => {
      const updatedInputs = [...prevState];
      updatedInputs[index] = { ...updatedInputs[index], [name]: value };
      return updatedInputs;
    });
  };
  
  const addCurrency = () => {
    if(countcurrency.length < 3){
      setCountmultcurrency([...countcurrency, {original_price : "", sale_price : "", currency : ""}]);
    }else{
      toast.error('Only 3 country currency is added.');
    }
  };

  const onSubmitProduct = (data) => {
    let newdata = {...data, currency : countcurrency};
    if(productuid){
      updateProduct({newdata, productuid, token : Token.getAccessToken()}).then((resp) => {
        if(resp?.status===true){
          toast.success(resp?.message);
        }
        if(resp?.status===false){
          toast.error(resp?.message);
        }
      })
    }else{
        addProduct({newdata, token : Token.getAccessToken()}).then((resp) => {
          if(resp?.status===true){
            reset();
            toast.success(resp?.message);
          }
          if(resp?.status===false){
            toast.error(resp?.message);
          }
        })
    }
  }
  
  useEffect(() => {
    if(data){
      reset(data?.data);
      setCountmultcurrency(JSON.parse(data?.data?.currency))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <AdminLayouts>
      {isPending || isUpdatePending || isLoading  ? <Spinner size="medium"/> : ""}
      <div className="w-full px-10 py-5 h-[100vh] bg-green-50">
        <div className="p-[30px] bg-[white] max-sm:p-[15px]">
          <div className="flex justify-between">
            <h3>{title}</h3>
            <Link to={'/admin/product'} className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-green-400 to-blue-600 group-hover:from-green-400 group-hover:to-blue-600 hover:text-white  focus:ring-4 focus:outline-none focus:ring-green-200 dark:focus:ring-green-800">
              <span className="relative px-5 py-2.5 transition-all ease-in duration-75 dark:text-white bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
              Products
              </span>
            </Link>
          </div>
          <form onSubmit={handleSubmit(onSubmitProduct)} className="flex flex-wrap justify-between">
              <div className="relative z-0 w-[49%] mb-5 group">
                  <input type="text" name="productname" {...register('productname', {required : true})} id="productname" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                  <label htmlFor="productname" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Product Name</label>
                  {errors?.productname ? <p className="text-[red]">Product is required.</p>:""}
              </div>
              <div className="relative z-0 w-[49%] mb-5 group">
                  <input type="date" {...register('duration')} name="duration" id="duration" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                  <label htmlFor="duration" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Duration</label>
              </div>
              <div className="flex w-full justify-between">
                <div className="w-[100%] flex flex-wrap justify-between">
                  {countcurrency?.map((input, index) => (
                    <div key={`product${index}`} className=" w-full flex justify-between">
                      <div className="flex flex-wrap w-[80%] justify-between" >
                        <div className="relative z-0 w-[32%] mb-5 group">
                          <input type="text" name="original_price" value={input.original_price} onChange={(event) => handleInputChange(index, event)} id="original_price" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                          <label htmlFor="original_price" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Original Price</label>
                        </div>
                        <div className="relative z-0 w-[32%] mb-5 group">
                            <input type="text" name="sale_price" value={input.sale_price} onChange={(event) => handleInputChange(index, event)} id="sale_price" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                            <label htmlFor="sale_price" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Sale Price</label>
                        </div>
                        <div className="relative z-0 w-[32%] mb-5 group" >
                            <select name="currency" value={input.currency}  onChange={(event) => handleInputChange(index, event)} className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer">
                              <option value="">Select Currency</option>
                              <option value="INR">INR ₹</option>
                              <option value="USD">US $</option>
                              <option value="CAD">CAD $</option>
                            </select>
                            <label htmlFor="category" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Currency</label>
                        </div>
                      </div>
                      {index > 0 ?<div className="w-[10%]"> <FaTimesCircle className="text-[red] cursor-pointer" onClick={() => removeInputField(index)} /></div> : "" }
                    </div>
                  ))}
                </div>
                {countcurrency.length < 3 ?
                <div className="w-[20%] text-right">
                  <Link to={''} onClick={addCurrency} className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">+ADD</Link>
                </div>
                  : "" }
              </div>
              <div className="relative z-0 w-[49%] mb-5 group">
                  <select name="category" {...register('category', {required:true})} id="category" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer">
                    <option value="Monthly">Monthly</option>
                    <option value="Yearly">Yearly</option>
                  </select>
                  <label htmlFor="category" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Select category</label>
                  {errors?.category ? <p className="text-[red]">Category is required.</p>:""}
              </div>
              <div className="relative z-0 w-[49%] mb-5 group">
                  <input type="text" {...register('note')} name="note" id="note" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                  <label htmlFor="note" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Note</label>
              </div>
              <div className="relative z-0 w-full mb-5 group">
                  <textarea type="text" {...register('description')} name="description" id="description" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                  <label htmlFor="description" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Description</label>
              </div>
              <div className="relative z-0 w-full mb-5 group">
                <button className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-green-400 to-blue-600 group-hover:from-green-400 group-hover:to-blue-600 hover:text-white  focus:ring-4 focus:outline-none focus:ring-green-200 dark:focus:ring-green-800">
                  <span className="relative px-5 py-2.5 transition-all ease-in duration-75 dark:text-white bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                  Save
                  </span>
                </button>
              </div>
          </form>
        </div>
      </div>
    </AdminLayouts>
  );
}


