import { useMutation } from "@tanstack/react-query";
import endpoint from "../../Helpers/endpoint";
import Http from "../../http";

const checkout = ({data, token}) => {
    const URL = `${endpoint.checkout.checkout}`;
    const headers  = {
        "Authorization": `Bearer ${token}` 
      }
    return Http().post(URL, data, headers);
}

const checkoutHelcim = ({data, token}) => {
  const URL = `${endpoint.checkout.checkoutHelcim}`;
  const headers  = {
      "Authorization": `Bearer ${token}` 
  }
  return Http().post(URL, data, headers);
}

const updatecheckoutHelcim = ({data, token}) => {
  const URL = `${endpoint.checkout.updateCheckoutHelcim}`;
  const headers  = {
      "Authorization": `Bearer ${token}` 
    }
  return Http().post(URL, data, headers);
}


const paymentCofirm = ({data, token}) => {
  const URL = `${endpoint.checkout.paymentConfirm}`;
  const headers  = {
      "Authorization": `Bearer ${token}` 
    }
  return Http().post(URL, data, headers);
}

export const useCheckout = () => {
  return useMutation({
      mutationFn:checkout,
      onSuccess: (data, variables, context) => {
        // I will fire first
      },
      onError: (error, variables, context) => {
        // I will fire first
      },
      onSettled: (data, error, variables, context) => {
        // I will fire first
      },
  });
}

export const useCheckoutHelcim = () => {
  return useMutation({
      mutationFn:checkoutHelcim,
      onSuccess: (data, variables, context) => {
        // I will fire first
      },
      onError: (error, variables, context) => {
        // I will fire first
      },
      onSettled: (data, error, variables, context) => {
        // I will fire first
      },
  });
}

export const useUpdateCheckoutHelcim = () => {
  return useMutation({
      mutationFn:updatecheckoutHelcim,
      onSuccess: (data, variables, context) => {
        // I will fire first
      },
      onError: (error, variables, context) => {
        // I will fire first
      },
      onSettled: (data, error, variables, context) => {
        // I will fire first
      },
  });
}


export const usePaymentConfirm = () => {
  return useMutation({
      mutationFn:paymentCofirm,
      onSuccess: (data, variables, context) => {
        // I will fire first
      },
      onError: (error, variables, context) => {
        // I will fire first
      },
      onSettled: (data, error, variables, context) => {
        // I will fire first
      },
  });
}


