import AdminLayouts from "../../../Layouts/Admin/AdminLayouts";
import React from "react";
import DataTable from 'react-data-table-component';
import { useQuery } from "@tanstack/react-query";
import endpoint from "../../../Helpers/endpoint";
import Http from "../../../http";
import { Link } from "react-router-dom";
import Token from "../../../Helpers/Token";
import { FaEdit, FaToggleOff, FaToggleOn } from "react-icons/fa";
import { useSendMail, useUserUpdate } from "../../../hooks/apihooks/useUser";
import { Spinner } from "../../Loader/Spinner";
import { toast } from "react-toastify";
import { RiMailSendLine } from "react-icons/ri";

export default function AdminUsers(){

  const getalluserlist = () => {
    const URL = `${process.env.REACT_APP_APIENDPOINT}${endpoint.team.team}`;
    return Http().get(URL,{
        "Content-type": "application/json",
        "Authorization": `Bearer ${Token.getAccessToken()}`,
    });
  }

  const {mutateAsync : sendVerficationMail, isPending : isLoad} =  useSendMail();
  const {mutateAsync : updateStatus , isPending} =  useUserUpdate();
  const {isLoading, data: usedata, refetch} = useQuery({queryKey: ['getuseralllist'], queryFn: getalluserlist, enabled : Boolean(Token.getAccessToken())});

  const userStatus = (userid, status) => {
    if(window.confirm(`Are you sure you want to ${status}`)){
        let reqbody = {
            userid, status
        }
        updateStatus({reqbody, token : Token.getAccessToken()}).then((resp) => {
            if(resp?.status===true){
                refetch();
                toast.success(resp?.message)
            }
        })
    }
  }

  const resendMail = (userid, status) => {
    let reqbody = {
        userid, action : status
    }
    sendVerficationMail({reqbody, token : Token.getAccessToken()}).then((resp) => {
        if(resp?.status===true){
            refetch();
            toast.success(resp?.message);
        }
    })
  }


  const columns = [
    {
      name: 'Name',
      selector: row => row.fname,
    },
    {
      name: 'Email',
      selector: row => row.email,
    },
    {
      name: 'Role',
      selector: row => row.role,
    },
    {
      name: 'Status',
      selector: row => row.status,
    },
    {
      name: 'Type',
      selector: row => row.signtype,
    },
    {
      name: 'Action',
      selector: (row) => {
        return <div className="flex items-center">
        <Link to={`/admin/users/edit/${row?.user_uid}`} className="text-[white] px-2 py-1 rounded-[5px] inline-block"><FaEdit className="text-[green]" size={25}  title="Edit"/></Link> &nbsp;
        {row?.status==='active'
         ? <FaToggleOn size={30} title="Deactive" className="text-[green] cursor-pointer" onClick={() => userStatus(row?.user_uid, 'deactive')} /> : <FaToggleOff size={30} title="Active" onClick={() => userStatus(row?.user_uid, 'active')} className="text-[red] cursor-pointer" />
        }
        {row?.status==='pending' ?  <RiMailSendLine className="ml-2 cursor-pointer" onClick={() => resendMail(row?.user_uid, 'resentmailactivation')} title="Resend Mail" size={25} /> : ""}  
        </div>
      }
    },
  ];

    return (
        <AdminLayouts>
            {isLoading || isPending || isLoad  ? <Spinner size="medium" /> : ""}
            <div className="w-full bg-green-50 h-[100vh] px-10 py-5">
              <div className=" mt-[30px] mb-[30px] w-full">
                  <div className="p-[30px] bg-white max-sm:p-[15px]">
                    <h3>User List</h3>
                    <DataTable
                      pagination
                      columns={columns}
                      progressPending={isLoading}
                      data={usedata?.data}
                    />
                  </div>
              </div>
            </div>
        </AdminLayouts>
    );
}


