import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { TbFileExport } from "react-icons/tb";
import endpoint from "../../../Helpers/endpoint";
import Tooltip from "../../../Helpers/Tooltip";
import useDebounce from "../../../Helpers/useDebounce";
import Http from "../../../http";
import { Spinner } from "../../Loader/Spinner";
import JobCard from "../../Users/Jobs/Listing/JobCard";

export function JobList() {
    const [ query, setQuery ] = useState({
        keyword : ''
      });
    
      let debouncesearch = useDebounce(query.keyword,500)
      function getJobs(keyword) {
        let endpoints = `${endpoint.job.listing}?keyword=${keyword}`;
        return Http().get(endpoints);
      }
    
      const { data, isLoading } = useQuery({ queryKey: ["jobslisting", debouncesearch], queryFn: () => getJobs(debouncesearch) });
    
      const queryHandler = (key) => (value) => {
       setQuery((prevState) => ({
         ...prevState,
         [key] : value
       }))
      };
    return(
        <>
        <div className="w-full flex items-center justify-center bg-green-100 ">
          <div className="w-[95%] gap-3 flex items-center justify-start flex-col min-h-[100vh] mt-10">
            <p className="bg-white w-full text-[18px] p-3 border border-gray-200">
              Jobs
            </p>
            <div className="w-full flex items-center justify-between p-3 bg-white border border-gray-200">
              <div className="flex items-center justify-center gap-2">
                <input
                  placeholder="Search"
                  type="search"
                  value={query.keyword}
                  onChange={(event) => queryHandler('keyword')(event.target.value)}
                  className="min-w-[300px] px-3 text-[14px] h-8 border border-gray-200"
                />
              </div>
              <div className="flex items-center justify-center gap-2 ">
                <Tooltip message="Download">
                  <div className="h-8 w-8 border border-gray-200 p-2 rounded-md">
                    <TbFileExport />
                  </div>
                </Tooltip> 
              </div>
            </div>

            <div className="w-full flex items-center justify-center flex-col gap-2">
              {data?.data?.map((data, index) => (
                <JobCard data={data} key={index} />
              ))}
              {isLoading ? <Spinner /> : ""}
            </div>
          </div>
        </div>
        </>
    );
}