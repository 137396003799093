import { getHeader } from "./header";

export const getQueryType = async (query, userId = "F7u4YBzv9E8LrdjX") => {
  const url =
    "https://prod-sutlej.kreativit.in:443/kitmyclients-apis/v1/gai_manager/HIPL/anthropic/query_type";
  let headers = getHeader("genai");
  const payload = {
    prompt: query,
    user_id: userId,
  };

  try {
    const response = await fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }

    const data = await response.json();

    if (data.success === "true") {
      return data.data.type;
    } else {
      throw new Error("Error: Success flag is not true.");
    }
  } catch (error) {
    console.error("API Error:", error);
    return `Error: ${error.message}`;
  }
};
