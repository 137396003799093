import React, { useState } from "react";
import ActivityControl from "./SettingsComponents/ActivityControl";
import WorkingHours from "./SettingsComponents/WorkingHours";
import Subscriptions from "./SettingsComponents/Subscriptions";

export default function Settings() {
  const [value, setValue] = useState(10);
  const [step, setStep] = useState(1);
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <div className="w-full flex items-center justify-center flex-col gap-2">
      <div className="w-[95%] flex items-center justify-start gap-2  bg-white p-2 border border-gray-200">
        <p
          onClick={() => setStep(1)}
          className={`text-[14px] py-1 px-2 cursor-pointer ${
            step === 1 ? "font-semibold" : ""
          }`}
        >
          Limits & Activity control
        </p>
        <p
          onClick={() => setStep(2)}
          className={`text-[14px] py-1 px-2 cursor-pointer  ${
            step === 2 ? "font-semibold" : ""
          }`}
        >
          Working hours
        </p>
        <p
          onClick={() => setStep(3)}
          className={`text-[14px] py-1 px-2 cursor-pointer  ${
            step === 3 ? "font-semibold" : ""
          }`}
        >
          Subscriptions & Billing
        </p>
        <p
          onClick={() => setStep(4)}
          className={`text-[14px] py-1 px-2 cursor-pointer  ${
            step === 4 ? "font-semibold" : ""
          }`}
        >
          Transactions
        </p>
      </div>

      {step === 1 ? (
        <ActivityControl value={value} handleChange={handleChange} />
      ) : step === 2 ? (
        <WorkingHours />
      ) : step === 3 ? (
        <Subscriptions />
      ) : (
        <></>
      )}
    </div>
  );
}

// <p></p>
// <p>Messages</p>
