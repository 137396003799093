import React, { useState } from 'react';
import UserLayout from '../../../Layouts/Users/UserLayout';
import { useQuery } from '@tanstack/react-query';
import endpoint from '../../../Helpers/endpoint';
import Http from '../../../http';
import Token from '../../../Helpers/Token';
import moment from 'moment';
import { Spinner } from '../../Loader/Spinner';
import { Link } from 'react-router-dom';
import PopupDetail from './PopupDetail';
import { FaDownload } from 'react-icons/fa6';

const TransactionHistory = () => {
  const [popup, setPopup] = useState(false);
  const [orderDetail, setOrderDetail] = useState({});

  const getPaymentHistroy = () => {
      const URL = `${endpoint.transection.transection}`;
      return Http().get(URL, {
        'Authorization' : `Bearer ${Token.getAccessToken()}`,
      });
  }

  const {data, isLoading} = useQuery({queryKey: ['paymentHistory'], queryFn: getPaymentHistroy});

  return (
    <UserLayout>
      <PopupDetail popup={popup} setPopup={setPopup} data={orderDetail} />
    <div className="w-[95%] mx-auto my-10 p-6 bg-white shadow-lg rounded-lg">
      <h1 className="text-3xl font-bold text-gray-800 mb-6">Billing & Invoice</h1>

      <table className="min-w-full bg-gray-100 border-collapse">
        <thead>
          <tr>
            <th className="py-3 px-6 text-left text-sm font-medium text-gray-700">Order ID</th>
            <th className="py-3 px-6 text-left text-sm font-medium text-gray-700">Date</th>
            <th className="py-3 px-6 text-left text-sm font-medium text-gray-700">Amount</th>
            <th className="py-3 px-6 text-left text-sm font-medium text-gray-700">Status</th>
            <th className="py-3 px-6 text-left text-sm font-medium text-gray-700">Payment Method</th>
            <th className="py-3 px-6 text-left text-sm font-medium text-gray-700">Invoice</th>
          </tr>
        </thead>

        <tbody>
          {data?.data?.map((row, index) => (
            <tr key={row.id} className="border-t border-gray-200">
              {/* // <td className="py-3 px-6 text-sm text-gray-700"><Link className='text-blue-600 font-semibold' onClick={() => {setPopup(true); setOrderDetail(row)}}>{`${row?.transaction_id || 'Not Available'}`}</Link></td> */}
              <td className="py-3 px-6 text-sm text-gray-700"><Link className='text-blue-600 font-semibold' onClick={() => {setPopup(true); setOrderDetail(row)}}>{`#${row.order_id}`}</Link></td>
              <td className="py-3 px-6 text-sm text-gray-700">{moment(row.created_at).format("LLL")}</td>
              <td className="py-3 px-6 text-sm text-gray-700">{row?.currency} {row.amount}</td>
              <td className={`py-3 px-6 text-sm text-gray-700 uppercase font-semibold ${getStatusClass(row?.ostatus)}`}>
                {row.ostatus}
              </td>
              <td className="py-3 px-6 text-sm text-gray-700">{row.payment_method}</td>
              <td className="py-3 px-6 text-sm text-gray-700 flex">
                {row?.ostatus=='success'? <Link to={`/download/invoice/${row?.order_id}`} target='_blank' className='mr-2 text-blue-500'>Detail</Link> : ""}
                {row?.ostatus=='success'? <Link to={`/download/invoice/${row?.order_id}`} target='_blank' className='text-green-500'><FaDownload /></Link> : ""}
              </td>
              
            </tr>
          ))}
          {
            isLoading ? <tr>
              <td colSpan={6}> <Spinner  size='small' /></td>
            </tr> 
            :""
          }
        </tbody>
      </table>
    </div>
    </UserLayout>
  );
};

// Helper function to style the status column
const getStatusClass = (status) => {
  switch (status) {
    case 'Completed':
      return 'text-green-500 font-bold';
    case 'success':
      return 'text-green-500 font-bold';
    case 'ACTIVE':
        return 'text-green-500 font-bold';
    case 'pending':
      return 'text-yellow-500';
    case 'Failed':
      return 'text-red-500';
    default:
      return '';
  }
};

export default TransactionHistory;
