import React from "react";

export default function ActivityControl({ value, handleChange }) {
  return (
    <div className="w-[95%] flex items-start justify-center  rounded-lg shadow flex-col gap-3  bg-white p-4 border border-gray-200">
      <p className="text-md  font-medium">
        {" "}
        Maximum number of actions per day:
      </p>
      <div className="w-full flex items-center justify-center flex-col gap-5">
        <div className="flex items-center justify-between gap-2 w-full">
          <p className="whitespace-nowrap text-[14px] text-blue-950 font-medium">
            Connection requests by Linkedin :
          </p>
          <div className="flex w-[60%] flex-col items-center space-y-4 mt-2">
            <div className="flex items-center space-x-2 w-full">
              <span className="text-[12px]">0</span>
              <div className="relative w-full">
                <input
                  type="range"
                  min="0"
                  max="100"
                  value={value}
                  onChange={handleChange}
                  className="w-full h-2 appearance-none rounded-full cursor-pointer"
                  style={{
                    background: `linear-gradient(to right, #3b82f6 ${value}%, #d1d5db ${value}%)`,
                  }}
                />
                <div
                  className="absolute  -top-1 left-0 transform -translate-x-1/2 -translate-y-1/2 text-black  text-[10px]"
                  style={{
                    left: `${value}%`,
                  }}
                >
                  {value}
                </div>
              </div>
              <span className="text-[12px]">100</span>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between gap-2 w-full">
          <p className="whitespace-nowrap text-[14px] text-blue-950 font-medium">
            Connection requests by email :
          </p>
          <div className="flex w-[60%] flex-col items-center space-y-4 mt-2">
            <div className="flex items-center space-x-2 w-full">
              <span className="text-[12px]">0</span>
              <div className="relative w-full">
                <input
                  type="range"
                  min="0"
                  max="100"
                  value={value}
                  onChange={handleChange}
                  className="w-full h-2 appearance-none rounded-full cursor-pointer"
                  style={{
                    background: `linear-gradient(to right, #3b82f6 ${value}%, #d1d5db ${value}%)`,
                  }}
                />
                <div
                  className="absolute  -top-1 left-0 transform -translate-x-1/2 -translate-y-1/2 text-black  text-[10px]"
                  style={{
                    left: `${value}%`,
                  }}
                >
                  {value}
                </div>
              </div>
              <span className="text-[12px]">100</span>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between gap-2 w-full">
          <p className="whitespace-nowrap text-[14px] font-medium text-blue-950">
            Messages :
          </p>
          <div className="flex w-[60%] flex-col items-center space-y-4 mt-2">
            <div className="flex items-center space-x-2 w-full">
              <span className="text-[12px]">0</span>
              <div className="relative w-full">
                <input
                  type="range"
                  min="0"
                  max="100"
                  value={value}
                  onChange={handleChange}
                  className="w-full h-2 appearance-none rounded-full cursor-pointer"
                  style={{
                    background: `linear-gradient(to right, #3b82f6 ${value}%, #d1d5db ${value}%)`,
                  }}
                />
                <div
                  className="absolute  -top-1 left-0 transform -translate-x-1/2 -translate-y-1/2 text-black  text-[10px]"
                  style={{
                    left: `${value}%`,
                  }}
                >
                  {value}
                </div>
              </div>
              <span className="text-[12px]">100</span>
            </div>
          </div>
        </div>
        <div className="flex w-full items-center justify-end gap-2">
          <button className="px-4 py-1 hover:bg-green-600 border text-green-600 border-green-600 text-[14px] hover:text-white font-medium rounded-full text white">
            Cancel
          </button>
          <button className="px-4 py-1 bg-green-600 border border-green-600 text-[14px] text-white font-medium rounded-full text white">
            Save
          </button>
        </div>
      </div>
    </div>
  );
}
