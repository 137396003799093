import { useQuery } from "@tanstack/react-query";
import Token from "../../../Helpers/Token";
import endpoint from "../../../Helpers/endpoint";
import Http from "../../../http";
import { Spinner } from "../../Loader/Spinner";

export function Mappers({dataFilter, setDataFilter, setopenModalCheck}){
    const getallquerylist = () => {
        const URL = `${endpoint.mapper.mapperSingleList}`;
        return Http().get(URL, {
            'Authorization' : `Bearer ${Token.getAccessToken()}`,
        });
    }
    const {data:mapperList, isLoading} = useQuery({queryKey: ['mapperlist'], queryFn : getallquerylist});

    return(
       <>
            <h3 className="py-2">Mapper List</h3>
            <div className="flex justify-between">
                {
                    mapperList?.data?.map((row) => {
                    return  <div className="border w-[25%] px-3 py-3 shadow rounded cursor-pointer hover:bg-gray-400 hover:text-white font-semibold" onClick={() => {setopenModalCheck(true); setDataFilter({...dataFilter, data: row?.request, atsType: row?.type, call_entity: 'mapper'})}}>
                                {row?.type}
                            </div>
                    })
                }
                {isLoading? <Spinner />: ""}
            </div>
       </>
    )
}