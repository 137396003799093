import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Link } from "react-router-dom";
import endpoint from "../../../Helpers/endpoint";
import Http from "../../../http";
import Token from "../../../Helpers/Token";
import moment from "moment";
import { FaEye } from "react-icons/fa6";
import PopupSubscription from "./PopupSubscription";
import { Spinner } from "../../Loader/Spinner";

export function Page() {
  // const [search, setSearch] = useState("");
  const [popup, setPopup] = useState(false);
  const [subDetail, setsubDetail] = useState({});
  // Sample data for subscription history
  const getsubscription = () => {
    const URL = `${endpoint.transection.subscription}`;
    return Http().get(URL, {
      Authorization: `Bearer ${Token.getAccessToken()}`,
    });
  };

  const { data, isLoading } = useQuery({
    queryKey: ["getsubscription"],
    queryFn: getsubscription,
  });

  return (
    <>
      <PopupSubscription popup={popup} setPopup={setPopup} data={subDetail} />
      <div className="h-[auto] w-full">
        <div className="px-5 py-5">
          <div className="rounded font-semibold text-gray-800 mb-4 bg-white border w-full px-3 py-2 flex justify-between items-center">
            <h3>Manage your Subscription</h3>{" "}
            <div>
              <input
                type="search"
                // onChange={(e) => setSearch(e.target.value)}
                className="border py-2 text-[13px] px-2 rounded w-[250px]"
                placeholder="Search Subscription"
              />
            </div>
          </div>
        </div>
        <div className="min-h-screen px-5 w-full flex justify-between">
          <div className="w-[70%]">
            <div className="overflow-x-auto bg-white rounded-lg shadow-md">
              <table className="min-w-full table-auto">
                <thead className="bg-gray-100 border-b">
                  <tr>
                    <th className="px-4 py-2 text-left text-gray-600">Plan</th>
                    <th className="px-4 py-2 text-left text-gray-600">
                      Start Date
                    </th>
                    <th className="px-4 py-2 text-left text-gray-600">
                      End Date
                    </th>
                    <th className="px-4 py-2 text-left text-gray-600">
                      Status
                    </th>
                    <th className="px-4 py-2 text-left text-gray-600">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data?.data?.map((subscription) => {
                    // console.log("Subscription", subscription);
                    const metaProduct = subscription?.meta_info?.find(
                      (metavalue) => metavalue?.meta_key === "product"
                    );
                    const productname = metaProduct?.meta_value?.productname || "";
                  
                    return (
                      <tr
                        key={subscription.id}
                        className="border-b hover:bg-gray-50"
                      >
                        <td className="px-4 py-2 text-gray-800">
                          {productname}
                        </td>
                        <td className="px-4 py-2 text-gray-600">
                          {moment(subscription?.start_date).format("ll")}
                        </td>
                        <td className="px-4 py-2 text-gray-600">
                          {moment(subscription?.end_date).format("ll")}
                        </td>
                        <td
                          className={`px-4 py-2 text-sm font-semibold uppercase ${
                            subscription.status === "active"
                              ? "text-green-600"
                              : "text-red-600"
                          }`}
                        >
                          {subscription.status}
                        </td>
                        <td className="px-4 py-2">
                          <Link
                            className="text-blue-500 hover:text-blue-700 mr-2"
                            onClick={() => {
                              setsubDetail(subscription);
                              setPopup(true);
                            }}
                          >
                            <FaEye />
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                  {isLoading ? (
                    <tr>
                      <td colSpan={5}>
                        <Spinner />
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="w-[28%]">
            <div className="bg-white p-4 border rounded shadow">
              <p>Yogesh, unklock you full potential with coffee crew premium</p>
              <div className="text-center py-10">
                <span className="text-[20px] font-bold">Coffee Crew</span>
              </div>
              <p>Unlimeted plan for all autonomus functionality </p>
              <div className="text-center mt-3">
                <button className="border border-green-600 rounded-full px-3 ">
                  Try for free
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
