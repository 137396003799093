import React from "react";
import { CiStar } from "react-icons/ci";
import { FaStar } from "react-icons/fa6";
import { FaRegStarHalfStroke } from "react-icons/fa6";

function getStars(count) {
  let blankStars = 0;
  let fullStars = Math.floor(count);
  let remainingStars = 5 - count;

  if (remainingStars < 1) {
    blankStars = 0;
  } else if (remainingStars >= 1) {
    blankStars = Math.floor(remainingStars);
  }

  let halfStars = 5 - (fullStars + blankStars);

  return {
    fullStars,
    blankStars,
    halfStars,
  };
}

const RatingSystem = ({ Ratper, className = "test", plugpoints = 0 }) => {
  let Rating = Number(Ratper) > 10 ? Number(Ratper) / 10 : Number(Ratper);
  const RatingCount = 100 - (Rating * 100) / (Rating > 5 ? 10 : 5);

  const starCounts = getStars(Rating);
  const starsArray = [
    ...Array(starCounts.fullStars).fill("FaStar"),
    ...Array(starCounts.halfStars).fill("FaRegStarHalfStroke"),
    ...Array(starCounts.blankStars).fill("CiStar"),
  ];


  return (
    <div
      className={` ${
        className ? className : "m-0"
      } group relative cursor-pointer`}
    >
      <div
        className={`${
          plugpoints ? "w-[180px]" : "w-[80px]"
        }  absolute text-center group-hover:visible invisible top-6 -z-10 group-hover:z-10 left-11  text-white group-hover:opacity-[1] transition-all duration-300 group-hover:top-[42px] opacity-0 bg-gray-600 py-1`}
      >
        <span className="text-[14px]">
          {plugpoints ? `Plugpoints = ${Rating} / 5 match` : Rating}
        </span>
      </div>
      <div className="flex items-center justify-center ">
        {starsArray.map((data, index) => (
          <div key={index}>
            {data === "FaStar" ? (
              <FaStar className="text-lightbrown" key={`full-${index}`} size={16} />
            ) : data === "CiStar" ? (
              <FaStar className="text-gray-300" key={`full-${index}`} size={18} />
            ): data === "FaRegStarHalfStroke" ? (
              <FaRegStarHalfStroke className="text-lightbrown"  key={`full-${index}`} size={17} />
            ) : (
              ""
            )}
          </div>
        ))}
        {/* FaRegStarHalfStroke */}
        {/* {[...Array(fullStars)].map((_, index) => (
          <FaStar key={`full-${index}`} size={16} />
        ))}

        {partialStar > 0 && <CiStar size={18} />}

        {[...Array(remainingStars)].map((_, index) => (
          <CiStar key={`empty-${index}`} size={18} />
        ))} */}
      </div>
    </div>
  );
};

export default RatingSystem;
