import { useQuery } from "@tanstack/react-query";
import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  FaCamera,
  FaDownload,
  FaPlus,
  FaTimes
} from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select, { createFilter } from "react-select";
import CreatableSelect from "react-select/creatable";
import { toast } from "react-toastify";
import endpoint from "../../../Helpers/endpoint";
import Token from "../../../Helpers/Token";
import useDebounce from "../../../Helpers/useDebounce";
import { ErrorStyles } from "../../../Helpers/Utils";
import { useCandidateUpdate } from "../../../hooks/apihooks/useCandidate";
import CountryCodes, {
  CountryOptions,
  currencyCode,
} from "../../../hooks/CountryCodes";
import { CVSource, Genders, NoticePeriod } from "../../../hooks/useGlobalHooks";
import Http, { AMAZONS3 } from "../../../http";
import { CertificationCand } from "../../../Popups/Candidate/CertificationCand";
import { EducationCand } from "../../../Popups/Candidate/EducationCand";
import { ExperienceCand } from "../../../Popups/Candidate/ExperienceCand";
import { ProjectCand } from "../../../Popups/Candidate/ProjectCand";
import { Spinner } from "../../Loader/Spinner";

export default function CandidateEdit() {
  const {
    register,
    reset,
    control,
    setValue,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      active: 0, // Default to 0 (unchecked)
    },
  });
  const { candidateuid } = useParams();
  const [diversity, setDiverstiy] = useState(false);
  const getcandidatedetail = () => {
    const URL = `${process.env.REACT_APP_APIENDPOINT}${endpoint.candidate.candidate}/${candidateuid}`;
    return Http().get(URL, {
      "Content-type": "application/json",
      Authorization: `Bearer ${Token.getAccessToken()}`,
    });
  };
  const { isLoading: candload, data: canddata, refetch } = useQuery({
    queryKey: ["getcandidatedetail", candidateuid],
    queryFn: () => getcandidatedetail(candidateuid),
    enabled: Boolean(Token.getAccessToken()),
  });

  
  const [profile, setProfile] = useState(null);
  const [images, setImages] = useState([]);
  const [file, setFile] = useState(null);
  const [imageSelected, setImageSelected] = useState([]);
  const [AddInput, setAddInput] = useState("");
  const [enabled, setEnabled] = useState({
    department: false,
    city: false,
    skills: false,
  });
  const navigate = useNavigate();
  const [countrySearch, setCountrySearch] = useState("");
  const debounceSkillsSearch = useDebounce(AddInput, 500);
  const { isPending, mutateAsync } = useCandidateUpdate();

  const getskills = (skill) => {
    let url = `http://hiringplug.local/manage-helpdesk-service/v1/skills?keyword=${skill}`;
    return Http().get(url);
  };

  const getCity = (city) => {
    let url = `http://hiringplug.local/manage-helpdesk-service/v1/city?keyword=${city}`;
    return Http().get(url);
  };

  const { data: SkillsData, isLoading: SkillsLoading } = useQuery({
    queryKey: ["getallskilss", debounceSkillsSearch],
    queryFn: () => getskills(debounceSkillsSearch),
  });

  const { data: dataArr, isLoading } = useQuery({
    queryKey: ["getallcity", countrySearch],
    queryFn: () => getCity(countrySearch),
    enabled : Boolean(countrySearch)
  });

  const SkillsOptions = SkillsData?.data.map((skill) => ({
    value: skill.skillid,
    label: skill.skill,
  }));

  const CurrencyOptions = currencyCode?.map((lists) => ({
    value: lists.value,
    label: `${lists.label}-${lists.symbol}`,
  }));

  const handleAdditionalSkillsInput = (e) => {
    setAddInput(e);
  };

  const LocationOptions = dataArr?.data.map((city) => ({
    value: city.cityid,
    label: city.city,
  }));

  const onSubmit = (data) => {
    let newdata = {
      ...data,
      fresher : data?.fresher===false?0:1,
      resume: file,
      profile: images,
    };
    mutateAsync({ newdata, token: Token?.getAccessToken() }).then((resp) => {
      if (resp?.status === true) {
        refetch();
        toast.success(resp?.message);
      }
    });
  };

  useEffect(() => {
    if (canddata) {
      reset(canddata?.data);
      let setskills = canddata?.data?.skills?.split(",");
      let skills = setskills?.map((skills) => ({
        value: 123,
        label: skills,
      }));
      setCountrySearch(canddata?.data?.city)
      setValue("skills", skills);
      setValue("synopsis", canddata?.data?.synopsis);
      setValue('active', canddata?.data?.status==='active'?true:false);
      setValue("domain", { value: 1222, label: canddata?.data?.domain });
      setValue("location", {value : canddata?.data?.location_id, label : canddata?.data?.location});
      setValue("gender", { value: 1222, label: canddata?.data?.gender });
      setValue("countryid",  {value : canddata?.data?.countryid, label : canddata?.data?.country});
      setValue("currencyid",  {label : canddata?.data?.currency, value : canddata?.data?.currencyid});
      setValue("currencyid2", {label : canddata?.data?.currency2, value : canddata?.data?.currencyid2});
      setValue("noticeperiod", {
        value: 1222,
        label: canddata?.data?.noticeperiod,
      });
      setValue("cvsource", { value: 1222, label: canddata?.data?.cvsource });
      setProfile(canddata?.data?.profile);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canddata]);

  const handleOnImageClick = () => {
    document.getElementById("image-input-box").click();
  };

  const handleImageChange = useCallback((e) => {
    const file = e.target.files[0];
    setImages(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfile(reader.result);
      };
      reader.readAsDataURL(file);
    }
  }, []);

  const FormAssets = [
    { head: "Age diversity", img_src: `${AMAZONS3}/form-assets/div-age.jpeg` },
    {
      head: "Career break",
      img_src: `${AMAZONS3}/form-assets/div-career-break.jpeg`,
    },
    {
      head: "Cultural diversity",
      img_src: `${AMAZONS3}/form-assets/div-cultural.jpeg`,
    },
    {
      head: "LGBTQI diversity",
      img_src: `${AMAZONS3}/form-assets/div-LGBTQI.jpeg`,
    },
    {
      head: "Differently-abled",
      img_src: `${AMAZONS3}/form-assets/div-diffable.jpeg`,
    },
    {
      head: "Gender diversity",
      img_src: `${AMAZONS3}/form-assets/div-gender.jpeg`,
    },
    {
      head: "International",
      img_src: `${AMAZONS3}/form-assets/div-international.jpeg`,
    },
    {
      head: "Religious diversity",
      img_src: `${AMAZONS3}/form-assets/div-religion.jpeg`,
    },
  ];

  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: 40,
    }),
  };

  function GET_All_DEPARTMENT() {
    const newEndpoints =
      "http://hiringplug.local/manage-helpdesk-service/v1/department";
    return Http().get(newEndpoints);
  }

  const { data, isLoading: isFetchingDepartment } = useQuery({
    queryKey: ["Departments"],
    queryFn: () => GET_All_DEPARTMENT(),
    enabled: Boolean(enabled.department),
  });
  const DepartmentOptions = data?.data?.map((list) => ({
    value: list.departmentid,
    label: list.title,
  }));

  const handleImageSelect = (head) => {
    if (imageSelected?.includes(head)) {
      let list = [...imageSelected];
      let val = list?.findIndex((v) => v === head);
      list?.splice(val, 1);
      setImageSelected(list);
    } else {
      setImageSelected([...imageSelected, head]);
    }
  };


  const handleOnChange = useCallback((e) => {
    setFile(e.target.files[0]);
  },[]);

  return (
    <>
      {isPending || candload ? <Spinner size="medium" /> : ""}
      <div className="w-full pb-5 bg-green-50 py-3 flex items-center gap-3 justify-start flex-col relative ">
        <div className=" w-11/12 relative bg-[white]">
          <div className="w-full p-1 h-[250px] object-cover">
            <img src={`/images/profileimage.png`} className="" alt="" />
          </div>
          <div className="flex p-4 font-primary items-center flex-col my-10 justify-center">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="w-[95%] gap-10 flex-col md:flex-row flex  my-10 items-start justify-center"
            >
              <div className="md:w-11/12 w-full gap-3 flex items-center flex-col justify-center">
                <div className="w-full flex items-start justify-center gap-4 md:gap-8 flex-col">
                  <div className="absolute my-auto top-[100px] left-[50px] ">
                    <div className="w-[200px] flex-col flex items-center rounded-[50%]  top-0 justify-center relative bg-[#fcfcfc] border-[#e0e0e0]  border h-[200px] shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)]">
                      <FaCamera
                        className="absolute cursor-pointer right-[5px] bottom-[30px] text-[25px] text-[gray] z-[9]"
                        onClick={() => handleOnImageClick()}
                      />
                      <p className="relative text-center top-[45%]">
                        {" "}
                        Profile Image
                      </p>
                      <input
                        type="file"
                        name="image"
                        id="image-input-box"
                        onChange={handleImageChange}
                        className="w-[200px] h-[200px] opacity-0"
                        accept=".png,.jpg,.jpeg,.webp,.svg"
                      />

                      <div className="w-[200px] rounded-full absolute flex items-center justify-center  h-[200px]">
                        <img
                          type="url"
                          src={profile}
                          alt="a"
                          onClick={() => handleOnImageClick()}
                          className="w-[200px] h-[200px] object-cover rounded-full"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex items-center justify-center flex-col  gap-2">
                    <label className="w-full flex items-start justify-center flex-col">
                      <span className="text-[14px]">
                        First Name <i className="text-[red]">*</i>
                      </span>
                      <input
                        type="text"
                        name="firstname"
                        {...register("firstname", { required: true })}
                        placeholder="Name"
                        className={`w-full px-3 text-sm h-10 border rounded-[4px] ${
                          errors.firstname ? "border-red" : "border-border-col "
                        }`}
                      />
                    </label>

                    <div className="w-full flex items-center justify-center gap-5 my-2 flex-col md:flex-row">
                      <label className="w-full">
                        <span className="text-[14px]">
                          Middle Name 
                        </span>
                        <input
                          type="text"
                          name="middlename"
                          {...register("middlename")}
                          placeholder="Middle Name"
                          className="w-full px-3 text-sm h-10 border border-border-col rounded-[4px] "
                        />
                      </label>
                      <label className="w-full">
                        <span className="text-[14px]">
                          Last Name
                        </span>
                        <input
                          type="text"
                          name="lastname"
                          {...register("lastname")}
                          placeholder="Last Name"
                          className="w-full px-3 text-sm h-10 border border-border-col rounded-[4px] "
                        />
                      </label>
                    </div>
                    {/*  */}
                    <div className="w-full flex items-center justify-center gap-5 mb-2 flex-col md:flex-row">
                      <label className="w-full relative flex items-start justify-center flex-col">
                        <span className="text-[14px]">
                          Email address <i className="text-[red]">*</i>
                        </span>
                        <input
                          type="email"
                          name="email"
                          {...register("email", {
                            required: "Email is required !",
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: "invalid email address",
                            },
                          })}
                          placeholder="john@example.com"
                          required
                          className="w-full px-3 text-sm h-10 border border-border-col rounded-[4px] "
                        />
                        {errors.email && (
                          <span className="absolute font-gothic bottom-[-14px] text-[12px] text-red left-0">
                            {errors.email.message}
                          </span>
                        )}
                      </label>

                      <label className="w-full relative flex items-start justify-center flex-col">
                        <span className="text-[14px]">
                          Date of birth
                        </span>
                        <input
                          type="date"
                          name="dateofbirth"
                          {...register("dateofbirth")}
                          placeholder="Date of Birth"
                          className="w-full px-3 text-sm h-10 border border-border-col rounded-[4px] "
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div className="w-full flex items-center justify-center gap-3 flex-col">
                  <div className="w-full  flex items-center justify-center gap-5 flex-col md:flex-row">
                    <label className="w-full border-border-col flex items-start justify-center flex-col">
                      <span className="text-[14px]">
                        Gender
                      </span>
                      <Controller
                        name="gender"
                        control={control}
                        // rules={{ required: true }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={Genders}
                            className=" w-full font-primary text-black text-sm h-10 rounded-sm"
                            styles={
                              errors.gender && errors.gender.type === "required"
                                ? ErrorStyles
                                : customStyles
                            }
                            cacheOptions
                            placeholder="Select Gender"
                            Searchable
                          />
                        )}
                      />
                    </label>
                    <label className="w-full flex items-start  justify-center flex-col">
                      <span className="text-[14px]">
                        Domain
                      </span>
                      <Controller
                        name="domain"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            onFocus={() =>
                              setEnabled((prev) => {
                                return {
                                  department: true,
                                  city: prev.city,
                                  skills: prev.skills,
                                };
                              })
                            }
                            isLoading={isFetchingDepartment}
                            options={DepartmentOptions}
                            id="departmentField"
                            placeholder="Select Domain"
                            className={` w-full font-primary text-sm h-10 rounded-sm`}
                            styles={
                              errors.domain && errors.domain.type === "required"
                                ? ErrorStyles
                                : customStyles
                            }
                          />
                        )}
                      />
                    </label>
                  </div>
                  {/*  */}
                  {/*  */}
                  <div className="w-full  flex items-center justify-center gap-3 flex-col md:flex-row">
                    <label className="w-full md:w-6/12 flex items-start justify-center flex-col mt-1">
                      <span className="text-[14px]">
                        Country
                      </span>
                      <Controller
                        name="countryid"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            onFocus={() =>
                              setEnabled((prev) => {
                                return {
                                  department: prev.department,
                                  city: true,
                                  skills: prev.skills,
                                };
                              })
                            }
                            Searchable
                            className="w-full font-primary text-sm h-10 rounded-sm"
                            options={CountryOptions}
                            placeholder="--Select Country--"
                            styles={
                              errors.countryid &&
                              errors.countryid.type === "required"
                                ? ErrorStyles
                                : customStyles
                            }
                          />
                        )}
                      />
                    </label>
                    <label className="w-full md:w-6/12 flex items-start justify-center flex-col mt-1">
                      <span className="text-[14px]">
                        Location <i className="text-[red]">*</i>
                      </span>
                      <Controller
                        name="location"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            onFocus={() =>
                              setEnabled((prev) => {
                                return {
                                  department: prev.department,
                                  city: true,
                                  skills: prev.skills,
                                };
                              })
                            }
                            Searchable
                            onInputChange={(e) => setCountrySearch(e)}
                            id="locationField"
                            isLoading={isLoading}
                            className=" w-full  font-primary text-sm h-10 rounded-sm"
                            options={LocationOptions}
                            placeholder="--Select City--"
                            styles={
                              errors.location &&
                              errors.location.type === "required"
                                ? ErrorStyles
                                : customStyles
                            }
                          />
                        )}
                      />
                    </label>
                  </div>
                  {/*  */}
                  <div className="w-full flex items-center justify-center gap-3 flex-col md:flex-row mt-1">
                    <label className="w-full md:w-6/12 flex items-start justify-center flex-col mt-1">
                      <span className="text-[14px]">
                        Phone Code <i className="text-[red]">*</i>
                      </span>
                      <select
                        name="countrycode"
                        {...register("countrycode")}
                        className="w-full  px-3 text-sm h-10 border border-border-col rounded-[4px] bg-white"
                      >
                        <CountryCodes />
                      </select>
                    </label>
                    <label className="w-full md:w-6/12 relative flex items-start justify-center flex-col">
                      <span className="text-[14px]">
                        Contact No. <i className="text-[red]">*</i>
                      </span>
                      <input
                        type="text"
                        name="phone"
                        {...register("phone", {
                          required: "Phone number is required !",
                          minLength: {
                            value: 10,
                            message:
                              "Phone number must be at least 10 numbers !",
                          },
                          maxLength: {
                            value: 15,
                            message: "This input exceed maxLength.",
                          },
                          pattern: {
                            value: /^[0-9 +-]+$/,
                            message: "Please enter a number",
                          },
                        })}
                        placeholder="98765XXXXX"
                        className={`w-full px-3 text-sm h-10 border  rounded-[4px] ${
                          errors.phone ? "border-red" : "border-border-col "
                        }`}
                      />
                      {errors.phone ? (
                        <span className="absolute font-gothic bottom-[-14px] text-[12px] text-red left-0">
                          {errors.phone.message}
                        </span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>

                  <div className="w-full flex items-center justify-start gap-3 flex-col md:flex-row mt-1">
                    <label className="w-full md:w-6/12 relative flex items-start justify-center flex-col">
                      <span className="text-[14px]">
                        Alternate Contact No.{" "}
                      </span>
                      <input
                        name="alternatecontact"
                        type="text"
                        {...register("alternatecontact", {
                          minLength: {
                            value: 10,
                            message:
                              "Phone number must be at least 10 numbers !",
                          },
                          maxLength: {
                            value: 15,
                            message: "This input exceed maxLength.",
                          },
                          pattern: {
                            value: /^[0-9 +-]+$/,
                            message: "Please enter a number",
                          },
                        })}
                        placeholder="98765XXXXX"
                        className="w-full px-3 text-sm h-10 border border-border-col rounded-[4px] "
                      />
                      {errors.alternatecontact ? (
                        <span className="absolute font-gothic bottom-[-14px] text-[12px] text-red left-0">
                          {errors.alternatecontact.message}
                        </span>
                      ) : (
                        ""
                      )}
                    </label>
                  </div>

                  <label className="w-full flex flex-col items-start">
                    <span className="text-[14px]">
                      Qualification
                    </span>
                    <textarea
                      type="text"
                      rows="4"
                      name="qualification"
                      {...register("qualification")}
                      placeholder="Mention the Qualifications/ Education/Academics"
                      className="w-full h-full p-3 text-sm  border border-border-col rounded-[4px] "
                    />
                  </label>

                  <label className="w-full flex flex-col items-start">
                    <span className="text-[14px]">
                      Synopsis
                    </span>
                    <textarea
                      type="text"
                      rows="4"
                      name="synopsis"
                      {...register("synopsis")}
                      placeholder="Synopsis"
                      className="w-full h-full p-3 text-sm  border border-border-col rounded-[4px] "
                    />
                  </label>
                  {/*  */}
                  <label className="w-full flex flex-col items-start">
                    <span className="text-[14px]">
                      Add Skills <i className="text-[red]">*</i>
                    </span>
                    <Controller
                      name="skills"
                      control={control}
                      rules={{ required: true, minLength: 3 }}
                      render={({ field }) => (
                        <CreatableSelect
                          {...field}
                          isLoading={SkillsLoading}
                          onFocus={() =>
                            setEnabled((prev) => {
                              return {
                                department: prev.department,
                                city: prev.city,
                                skills: true,
                              };
                            })
                          }
                          options={SkillsOptions}
                          onInputChange={handleAdditionalSkillsInput}
                          className="outline-none table  w-full font-primary text-sm h-10 rounded-sm"
                          styles={
                            errors.skills && errors.skills.type === "required"
                              ? ErrorStyles
                              : customStyles
                          }
                          Searchable
                          id="mandatoryskills"
                          isMulti
                          placeholder="Search... Skills"
                        />
                      )}
                    />
                  </label>
                  
                  
                  <ExperienceCand experience={canddata?.data?.experience} setValue={setValue}/>
                  <EducationCand education={canddata?.data?.education}  setValue={setValue} />
                  <ProjectCand project={canddata?.data?.project}  setValue={setValue} />
                  <CertificationCand  certification={canddata?.data?.certification}  setValue={setValue} />

                  <div className="w-full my-10 flex items-center justify-center gap-3 flex-col md:flex-row">
                    <label className="md:w-3/12 w-full flex items-start justify-center flex-col">
                      <span className="text-[14px]">
                        Currency
                      </span>
                      <Controller
                        name="currencyid"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            filterOption={createFilter({
                              ignoreAccents: false,
                            })}
                            options={CurrencyOptions}
                            className="w-full font-primary text-sm h-10 rounded-sm"
                            styles={customStyles}
                            Searchable
                            placeholder="Search... Currency"
                          />
                        )}
                      />
                    </label>
                    <label className="md:w-6/12 relative w-full flex items-start justify-center flex-col">
                      <span className="text-[14px]">
                        Current Salary
                      </span>
                      <input
                        type="text"
                        name="currentsalary"
                        {...register("currentsalary")}
                        placeholder="Current Salary"
                        className="w-full px-3 text-sm h-10 border border-border-col rounded-[4px] "
                      />
                      {errors.currentsalary ? (
                        <span className="absolute font-gothic bottom-[-14px] text-[12px] text-red left-0">
                          {errors.currentsalary.message}
                        </span>
                      ) : (
                        ""
                      )}
                    </label>
                    <label className="md:w-3/12 w-full flex items-start justify-center flex-col">
                      <span className="text-[14px]">Salary Tenure</span>
                      <select
                        name="salary_tenure"
                        {...register("salary_tenure")}
                        className="w-full px-3 text-sm h-10 border border-border-col rounded-[4px] bg-white"
                      >
                        <option value="1">Yearly</option>
                        <option value="2">Monthly</option>
                        <option value="3">Hourly</option>
                      </select>
                    </label>
                  </div>
                  {/*  */}
                  {/*  */}
                  <div className="w-full my- flex items-center justify-center gap-3 flex-col md:flex-row">
                    <label className="md:w-3/12 w-full flex items-start justify-center flex-col">
                      <span className="text-[14px]">
                        Currency
                      </span>
                      <Controller
                        name="currencyid2"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            filterOption={createFilter({
                              ignoreAccents: false,
                            })}
                            options={CurrencyOptions}
                            className="w-full font-primary text-sm h-10 rounded-sm"
                            styles={customStyles}
                            Searchable
                            placeholder="Search... Currency"
                          />
                        )}
                      />
                    </label>
                    <label className="md:w-6/12 w-full relative flex items-start justify-center flex-col">
                      <span className="text-[14px]">
                        Expected Salary
                      </span>
                      <input
                        type="text"
                        name="expectedsalary"
                        {...register("expectedsalary")}
                        placeholder="Expected Salary"
                        className="w-full px-3 text-sm h-10 border border-border-col rounded-[4px] "
                      />
                      {errors.expectedsalary ? (
                        <span className="absolute font-gothic bottom-[-14px] text-[12px] text-red left-0">
                          {errors.expectedsalary.message}
                        </span>
                      ) : (
                        ""
                      )}
                    </label>
                    <label className="md:w-3/12 w-full flex items-start justify-center flex-col">
                      <span className="text-[14px]">Salary Tenure</span>
                      <select
                        name="salary_tenure"
                        {...register("salary_tenure")}
                        className="w-full px-3 text-sm h-10 border border-border-col rounded-[4px] bg-white"
                      >
                        <option value="1">Yearly</option>
                        <option value="2">Monthly</option>
                        <option value="3">Hourly</option>
                      </select>
                    </label>
                  </div>
                  {/*  */}
                  <div className="w-full flex items-center justify-start gap-3 flex-col md:flex-row">
                    <label className=" md:w-6/12 w-full flex items-start justify-center flex-col">
                      <span className="text-[14px]">
                        {" "}
                        Notice Period
                      </span>
                      <Controller
                        name="noticeperiod"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            Searchable
                            id="noticeperiod"
                            isLoading={isLoading}
                            className=" w-full font-primary text-sm h-10 rounded-sm"
                            options={NoticePeriod}
                            placeholder="--Select Notice--"
                            styles={
                              errors.noticeperiod &&
                              errors.noticeperiod.type === "required"
                                ? ErrorStyles
                                : customStyles
                            }
                          />
                        )}
                      />
                    </label>
                    {watch("noticeperiod")?.label === "Serving Notice period" && (
                      <label className=" md:w-6/12 w-full flex relative items-start justify-center flex-col">
                        <p className="opacity-0">null</p>
                        <input
                          name="noticeenddate"
                          {...register("noticeenddate", {
                            required: "Required",
                          })}
                          type="date"
                          placeholder="Enter Notice Period in days"
                          className="w-full rounded-[5px] px-2 border border-border-col h-10"
                        />
                        {errors.noticeenddate ? (
                          <span className="absolute font-gothic bottom-[-14px] text-[12px] text-red left-0">
                            {errors.noticeenddate.message}
                          </span>
                        ) : (
                          ""
                        )}
                      </label>
                    )}
                    {watch("noticeperiod")?.value === "Immediate" && (
                      <label className=" md:w-6/12 w-full flex relative items-center justify-start flex-row">
                        {/* <span className={` text-red mr-1 text-[25px]`}>*</span> */}
                        <input
                          name="offerinhand"
                          {...register("offerinhand")}
                          value="1"
                          type="checkbox"
                          className={"mr-1"}
                        />
                        <p className={`${errors.offerinhand && "text-red"}`}>
                          {" "}
                          Offer In Hand
                        </p>
                        {errors.offerinhand ? (
                          <span className="absolute font-gothic bottom-[-14px] text-[12px] text-red left-0">
                            {errors.offerinhand.message}
                          </span>
                        ) : (
                          ""
                        )}
                      </label>
                    )}
                    {watch("noticeperiod")?.value === "Other" && (
                      <label className=" md:w-6/12 w-full flex relative items-start justify-center flex-col">
                        <p className="opacity-0">null</p>
                        <input
                          name="othernoticeperiod"
                          {...register("othernoticeperiod", {
                            required: "Required",
                          })}
                          type="text"
                          placeholder="Enter Notice Period in days"
                          className="w-full rounded-[5px] px-2 border border-border-col h-10"
                        />
                        {errors.noticeperioddays ? (
                          <span className="absolute font-gothic bottom-[-14px] text-[12px] text-red left-0">
                            {errors.noticeperioddays.message}
                          </span>
                        ) : (
                          ""
                        )}
                      </label>
                    )}
                  </div>
                  {/*  */}
                  {/*  */}
                  {/* <label className="w-full flex flex-col items-start">
                    <span className="text-[14px]">Agency's Comment</span>
                    <textarea
                      type="text"
                      rows="1"
                      name="agencycomment"
                      {...register("agencycomment")}
                      placeholder="Mention the Qualifications/ Education/Academics"
                      className="w-full p-3 text-sm  border border-border-col rounded-[4px] "
                    />
                  </label> */}
                  {/*  */}
                  {/*  */}
                  <div className="w-full flex items-center justify-start gap-3 flex-col md:flex-row">
                    <label className=" md:w-6/12 w-full flex items-start justify-center flex-col">
                      <span className="text-[14px]">
                        Source of CV <i className="text-[red]">*</i>
                      </span>

                      <Controller
                        name="cvsource"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            Searchable
                            id="cvsource"
                            className=" w-full font-primary text-sm h-10 rounded-sm"
                            options={CVSource}
                            placeholder="--CV Source--"
                            styles={
                              errors.cvsource &&
                              errors.cvsource.type === "required"
                                ? ErrorStyles
                                : customStyles
                            }
                          />
                        )}
                      />
                    </label>
                  </div>

                  <div className="w-full flex my-4 items-start justify-center flex-col">
                    <p className="text-[14px] my-3 flex items-center">
                      <span className="text-[16px] font-semibold">Please identify the candidate's Diversity parameter</span><span className="ml-2 cursor-pointer" onClick={() => setDiverstiy(!diversity)}>{diversity===false? <FaPlus className="text-green-700" size={20} /> :  <FaTimes className="text-red-600" size={20} />}</span>
                    </p>

                    {diversity ? 
                     <div className="w-full xl:w-12/12 flex items-center justify-between flex-wrap mr-auto gap-3 lg:ml-6">
                     {FormAssets?.map(({ img_src, head }, index) => (
                       <div
                         onClick={() => handleImageSelect(head)}
                         className=" flex items-center justify-center flex-col gap-y-4"
                         key={index}
                       >
                         <p className="font-normal font-primary text-[10px]">
                           {head}
                         </p>
                         <div
                           className={` ${
                             imageSelected?.includes(head)
                               ? " border-border-col "
                               : "border-white"
                           } w-15  p-3 transition-all duration-300 border relative`}
                         >
                           {imageSelected?.includes(head) ? (
                             <div className=" transition-all duration-300 w-5 h-5 bg-green text-white -top-1 -left-1 rounded-full absolute flex items-center justify-center">
                               &#10003;
                             </div>
                           ) : (
                             ""
                           )}
                           <img
                             src={img_src}
                             alt={head}
                             className={` ${
                               imageSelected?.includes(head)
                                 ? "   border-border-col image_box_shadow "
                                 : "border-white"
                             }  z-[1] w-10 border cursor-pointer transition-all duration-300`}
                           />
                         </div>
                       </div>
                     ))}
                      </div>
                      :""
                    }
                  </div>
                  {/*  */}
                  <label className="w-full flex flex-col items-start">
                    <span className="text-[14px]">
                      Please describe about candidate's Diversity parameter
                    </span>
                    <textarea
                      type="text"
                      rows="1"
                      name="diversitycomment"
                      {...register("diversitycomment")}
                      placeholder="Mention specific comments regarding the Diversity of this candidate, if any."
                      className="w-full p-3 text-sm  border border-border-col rounded-[4px] "
                    />
                  </label>
                  {/*  */}
                  <div className="w-full">
                    <h3 className="text-left text-[14px]">CV Document</h3>
                  </div>
                  <div className="w-full flex items-center justify-between">
                    <div className="w-full flex-col  flex gap-5 mb-5 items-start justify-start">
                      {file?.name ? (
                        <div
                          style={{ wordBreak: "break-all" }}
                          className="text-xs flex items-end justify-center"
                        >
                          <p className="flex text-[16px] text-base-col items-center justify-center gap-2">
                            {file?.name}
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                      {canddata?.data?.resume ? (
                        <Link
                          to={canddata?.data?.resume}
                          target="_Blank"
                          className="flex text-white border px-2 py-1 rounded bg-green-700"
                          download
                          rel="noreferrer"
                        >
                          <span>Download</span>{" "}
                          <FaDownload size={20} className="ml-2" />
                        </Link>
                      ) : (
                        ""
                      )}
                      <div className="flex gap-4 items-center justify-center">
                        <label className="w-[50px] h-[53px] bg-white rounded-[4px] border cursor-pointer border-border-col">
                          <img src={`${AMAZONS3}/images/upload.png`} alt="" />
                          <input
                            type="file"
                            name="resume"
                            onChange={(e) => handleOnChange(e)}
                            className=" w-[100px] relative bottom-10 opacity-0"
                            accept=".pdf,.doc,.docx"
                          />
                        </label>{" "}
                        <span className="text-[14px]">Upload Resume</span>
                      </div>
                      <label>
                        <input
                          type="checkbox"
                          {...register("active")}
                          name="active"
                          className="mr-2"
                          value="1"
                        />
                        Active Candidate
                      </label>
                    </div>
                  </div>
                  {/*  */}

                  <div className="flex items-center w-full justify-center gap-3">
                    <button
                      type="button"
                      onClick={() => navigate(-1)}
                      className="px-3 py-1 bg-white border cursor-pointer border-green text-green rounded-full"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="bg-[green] text-white px-3 rounded-full py-1"
                    >
                      {" "}
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
