import { Link, useNavigate } from 'react-router-dom';
import { clear } from '../../Helpers/Storage';
import Token from '../../Helpers/Token';
const Errors = () => {
const queryParams = new URLSearchParams(window.location.search);
const errorParam = queryParams.get('error');
const navigate = useNavigate();
  if(errorParam==='token is expire pls login agin'){
    setTimeout(() => {
        clear();
        Token.clearToken();
        navigate('/signin')
    }, 2000);
  }

  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-gray-100 py-6 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full bg-white shadow-md rounded-lg p-8">
        <div className="flex items-center justify-center mb-4">
          <svg
            className="w-16 h-16 text-red-500"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M11 7h2M11 11h2m-2 4h.01M5.5 4A2.5 2.5 0 0 1 8 1.5h8A2.5 2.5 0 0 1 18.5 4h-13zM5.5 4h13A2.5 2.5 0 0 1 21 6.5V18a2.5 2.5 0 0 1-2.5 2.5H5.5A2.5 2.5 0 0 1 3 18V6.5A2.5 2.5 0 0 1 5.5 4z"
            />
          </svg>
        </div>
        <h1 className="text-4xl font-extrabold text-gray-900 mb-4">Oops!</h1>
        <p className="text-lg text-gray-700 mb-4">
          {errorParam || 'An unknown error occurred. Please try again later.'}
        </p>
        <Link
          to="/"
          className="inline-block px-6 py-3 text-white bg-blue-500 hover:bg-blue-600 rounded-lg font-semibold text-center transition duration-300"
        >
          Go Back Home
        </Link>
      </div>
    </div>
  );
};

export default Errors;
