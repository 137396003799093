
import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import Header from '../../Website/Header';
import Footer from '../../Website/Footer';
import endpoint from '../../../Helpers/endpoint';
import Http from '../../../http';
import { useGlobalContext } from '../../../Context/ContextWrapper';
import { Spinner } from '../../Loader/Spinner';
import { useNavigate, useParams } from 'react-router-dom';
import { get } from '../../../Helpers/Storage';
import { useCheckout, useCheckoutHelcim, useUpdateCheckoutHelcim } from '../../../hooks/apihooks/useCheckout';
import Token from '../../../Helpers/Token';
import { useForm } from 'react-hook-form';
import {load} from '@cashfreepayments/cashfree-js';


export default function CartProduct() {
   const {productuid} = useParams();
   const {register, watch, setValue} = useForm();
   const {mutateAsync, isPending} = useCheckout();
   const {mutateAsync : mutateHelcim, isPending:isLoadHelcim} = useCheckoutHelcim();
   const {mutateAsync : mutateAsyncUpdHel} =  useUpdateCheckoutHelcim();
   const navigate = useNavigate();
  const getsingleplan = () => {
    const URL = `${process.env.REACT_APP_APIENDPOINT}${endpoint.plan.plandetail}/${productuid}`;
    return Http().get(URL);
  }
  const getBillingDetail = () => {
    const URL = `${endpoint.billing.billing}/${get('userAuth')?.user_uid}`;
    return Http().get(URL, {
      'Authorization' : `Bearer ${Token.getAccessToken()}`,
    });
  }

  var cashfree;
  var initializeSDK = async function () {
    cashfree = await load({
      mode: "sandbox",
    });
  };
  initializeSDK();
  
  const [newamountprice,setAmountVar] = useState({})
  const {currency} = useGlobalContext();

  const {data, isLoading} = useQuery({queryKey: ['getsingleplan', productuid], queryFn : getsingleplan});
  const {data: billingData} = useQuery({queryKey: ['getBillingDetail'], queryFn : getBillingDetail, enabled:Boolean(Token.getAccessToken())});

  const handleCheckout = (productuid) => {
    if(get('userAuth')===null){
      navigate(`/signin?redirect=cartproduct/${productuid}`)
      return;
    }

    let data = {
      productuid,
      billing_data: watch(),
      base_url:process.env.REACT_APP_PAYMENT_URL,
      return_url:'subscription-confirmation'
    }

    if(watch('currency')==='USD' || watch('currency')==='CAD'){
      mutateHelcim({data, token: Token.getAccessToken()}).then((resp) => {
        if (resp?.checkoutToken) {
          // console.log("data", data);
          // Check if HelcimPay is loaded and the token is available
          window.appendHelcimPayIframe(resp.checkoutToken); // Append the iframe with the token
          window.addEventListener('message', (event) => {
            const helcimPayJsIdentifierKey = 'helcim-pay-js-' + resp?.checkoutToken;
            if(event.data.eventName === helcimPayJsIdentifierKey){
              if(event.data.eventStatus === 'ABORTED'){
                console.error('Transaction failed!', event.data.eventMessage);
              }

              if(event.data.eventStatus === 'SUCCESS'){
                console.log('Transaction Success!', JSON.parse(event.data?.eventMessage));
                let newdata = JSON.parse(event.data?.eventMessage) || {};
                let data = {
                  ...newdata,
                  checkoutToken : resp?.checkoutToken
                }
                mutateAsyncUpdHel({data, token:Token.getAccessToken()}).then((resp) => {
                  if(resp.status===true){
                    navigate(`/subscription-confirmation?order_id=${resp?.data?.order_id}`)
                  }
                });
              }
            }
          });
        } else {
          console.error('Helcim payment token not found or HelcimPay is not loaded:', resp);
        }
      });
    }else if(watch('currency')==='INR'){
        mutateAsync({data, token: Token.getAccessToken()}).then((resp) => {
          cashfree.checkout({
                paymentSessionId: resp?.payment_session_id,
                redirectTarget: "_self"
            });
        });
    }

  }


  useEffect(() => {
      if(billingData?.data){
        setValue('customer_id', get('userAuth').customer_id);
        setValue('first_name', billingData?.data?.first_name);
        setValue('company', billingData?.data?.company);
        setValue('country_iso3', billingData?.data?.country_iso3);
        setValue('state_iso2', billingData?.data?.state_iso2);
        setValue('last_name', billingData?.data?.last_name);
        setValue('email', billingData?.data?.email);
        setValue('address_line1', billingData?.data?.address_line1);
        setValue('address_line2', billingData?.data?.address_line2);
        setValue('phone', billingData?.data?.phone);
        setValue('city', billingData?.data?.city);
        setValue('state', billingData?.data?.state);
        setValue('zip_code', billingData?.data?.zip_code);
        setValue('country', billingData?.data?.country);
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[billingData?.data])

  useEffect(() => {
    if(data){
      let filterprice = JSON.parse(data?.data.currency);
      const newprice = filterprice.filter((mony) => mony?.currency === currency?.code);
      setAmountVar(newprice[0]);
      setValue('currency', newprice[0]?.currency);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data, currency])
  return (
    <>
    <Header />
      {isLoading || isPending || isLoadHelcim ? <Spinner size='medium'/> :""}
      <div className='patterlayer2 relative max-sm:h-[250px]'>
        <div className="flex items-center justify-center h-16 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <h1 className='text-[white] text-[30px] w-full max-sm:text-[20px]'>Cart Detail</h1>
        </div>
      </div>
      <div className='container m-auto flex justify-between'>
        <div className='w-[65%]'>
          <div className="max-w-5xl mx-auto my-8 p-6 bg-white border shadow rounded-lg">
            <section className="mb-6">
              <h1 className="text-2xl font-bold text-gray-800">Your Cart</h1>
              <h2 className="text-xl font-semibold text-gray-800">{data?.data?.productname} Product - 1 {data?.data?.category} Plan</h2>
              <p className="text-gray-600 mt-2">Price: <span className="font-semibold"><del>{Number(newamountprice?.original_price).toLocaleString()}</del> <span className='text-[18px]'> {currency?.code} {Number(newamountprice?.sale_price).toLocaleString()} </span></span></p>
              <ul className="mt-4 list-disc pl-6 text-gray-600">
                <li>Generate 10k Words</li>
                <li>40+ Use Cases</li>
                <li>20+ Languages</li>
                <li>50+ Templates</li>
                <li>1 Brand Voice</li>
              </ul>
            </section>
            {/* Billing Details Form */}
            <section className="mb-6">
              <h2 className="text-xl font-semibold text-gray-800">Billing Details</h2>
              <form className="mt-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <input
                    type="text"
                    placeholder="First Name"
                    {...register('first_name')}
                    className="p-2 border border-gray-300 rounded-md"
                  />
                  <input
                    type="text"
                    placeholder="Last Name"
                    {...register('last_name')}
                    className="p-2 border border-gray-300 rounded-md"
                  />
                  <input
                    type="email"
                    placeholder="Email Address"
                    {...register('email')}
                    disabled={true}
                    className="p-2 border border-gray-300 rounded-md"
                  />
                  <input
                    type="text"
                    placeholder="Mobile No"
                    {...register('phone')}
                    className="p-2 border border-gray-300 rounded-md"
                  />
                  <textarea
                    placeholder="Address line one"
                    {...register('address_line1')}
                    className="w-full p-2 grid-cols-1 border border-gray-300 rounded-md"
                  />
                  <textarea
                    {...register('address_line2')}
                    placeholder="Address line two"
                    className="w-full p-2 grid-cols-1 border border-gray-300 rounded-md"
                  />
                  <input
                    type="text"
                    placeholder="City"
                    {...register('city')}
                    className="p-2 border border-gray-300 rounded-md"
                  />
                  <input
                    type="text"
                    {...register('state')}
                    placeholder="State"
                    className="p-2 border border-gray-300 rounded-md"
                  />
                  <input
                    type="text"
                    {...register('zip_code')}
                    placeholder="ZIP Code"
                    className="p-2 border border-gray-300 rounded-md"
                  />
                  <input
                    type="text"
                    {...register('country')}
                    placeholder="Country"
                    className="p-2 border border-gray-300 rounded-md"
                  />
                </div>
              </form>
            </section>
            
          </div>
        </div>
        {/* Order Details */}
        <div className='w-[30%] '>
          <section className="max-w-5xl mx-auto my-8 p-6 bg-white border shadow rounded-lg">
            <div className='w-full'>
              <h2 className="text-xl font-semibold text-gray-800">Order Summary</h2>
              <p className="text-gray-600 mt-2">Tax and shipping will be calculated at checkout.</p>
            </div>
            <div className='w-full'>
              <p className="text-lg font-semibold text-gray-700">Subtotal: {currency?.code} {Number(newamountprice?.original_price).toLocaleString()} </p>
              <p className="text-lg font-semibold text-green-700">Discount : - {currency?.code} {Number(newamountprice?.original_price - newamountprice?.sale_price).toLocaleString() } </p>
              <p className="text-xl font-bold text-gray-800 mt-2">Total: {currency?.code} {Number(newamountprice?.sale_price).toLocaleString()} </p>
            </div>
            <div className="mt-6 flex w-full justify-end">
              <button className="bg-blue-500 w-full text-white px-6 py-3 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50" onClick={() => handleCheckout(data?.data?.productuid)}>
                Proceed to Checkout
              </button>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </>
  )
}
