import React from 'react';
import AdminLayouts from '../../../Layouts/Admin/AdminLayouts';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import endpoint from '../../../Helpers/endpoint';
import Http from '../../../http';
import { Spinner } from '../../Loader/Spinner';
import Token from '../../../Helpers/Token';
import moment from 'moment';

const ApiList = () => {

  const getallapislist  = () => {
    const URL  = endpoint.credentials.apis;
    return Http().get(URL, {
      'Authorization' : `Bearer ${Token.getAccessToken()}`,
    });
  }
  const {data :apis, isLoading} = useQuery({queryKey:['getallapislist'], queryFn: getallapislist});
  return (
    <AdminLayouts>
    {isLoading? <Spinner size='medium'/> : ""}
    <div className="px-10 py-5 h-[100vh] w-full bg-green-50">
      <div className='flex justify-between items-center mb-6'>
        <h2 className="text-2xl font-bold">Manage APIs</h2>
        <Link to={'/admin/api-details/add'} className='bg-blue-500 text-white py-2 px-3 rounded-lg hover:bg-blue-600'>+Add</Link>
      </div>
      <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-lg">
        <thead>
          <tr className='text-left'>
            <th className="py-2 px-4 border-b">API Name</th>
            <th className="py-2 border-b">Date</th>
            <th className="py-2 border-b">Status</th>
            <th className="py-2 border-b">Action</th>
          </tr>
        </thead>
        <tbody>
          {apis?.data?.map((api, index) => (
            <tr key={index} className="hover:bg-gray-50 text-left">
              <td className="py-2 px-4 border-b"><Link to={`/admin/api-details/${api?.name}`}>{api.name}</Link></td>
              <td className="py-2 border-b">{moment(api?.created_at).format("ll")}</td>
              <td className="py-2 border-b">{api?.status===0?'deactive':'active'}</td>
              <td className="py-2 border-b">
                <Link className="text-blue-500 hover:text-blue-700" to={`/admin/api-details/edit/${api?.apiuid}`}>Edit</Link>
                {/* <button className="ml-4 text-red-500 hover:text-red-700">Disable</button> */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </AdminLayouts>
  );
};

export default ApiList;
