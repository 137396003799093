import { FaTimes } from "react-icons/fa";
import {load} from '@cashfreepayments/cashfree-js';
import { useNavigate } from "react-router-dom";
import Token from "../../../Helpers/Token";
import { useCheckoutHelcim } from "../../../hooks/apihooks/useCheckout";
export default function PopupDetail ({popup, setPopup, data}){
    const {mutateAsync, isPending} = useCheckoutHelcim();
    let cashfree;
    const navigate = useNavigate();
    var initializeSDK = async function () {
      cashfree = await load({
        mode: "sandbox" //or production
      });
    };
    initializeSDK();

    const makePayment = (data) => {
        console.log(data, "data");
        if(data?.payment_gateway==='helcim'){
            const iframe = window.appendHelcimPayIframe(data.checkout_token); // Append the iframe with the token
            window.addEventListener('message', (event) => {
            const helcimPayJsIdentifierKey = 'helcim-pay-js-' + data?.checkout_token;

            if(event.data.eventName === helcimPayJsIdentifierKey){
              if(event.data.eventStatus === 'ABORTED'){
                console.error('Transaction failed!', event.data.eventMessage);
              }
              if(event.data.eventStatus === 'SUCCESS'){
                console.log('Transaction Success!', JSON.parse(event.data?.eventMessage));
                let newdata = JSON.parse(event.data?.eventMessage) || {};
                let data = {
                  ...newdata,
                  checkoutToken : data?.checkout_token
                }
                mutateAsync({data, token:Token.getAccessToken()}).then((resp) => {
                  if(resp.status===true){
                    navigate('/subscription-confirmation?order_id=')
                  }
                });
              }
            }
          });
        }else{
            cashfree.checkout({
                paymentSessionId: data?.payment_session_id,
                redirectTarget: "_blank"
            });
        }
        
    }

    

    return (
        <>
        <div class={`relative z-10 ${popup?'':'hidden'}`} aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div class="fixed inset-0 bg-gray-500/75 transition-opacity" aria-hidden="true"></div>
            <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <div class="relative transform text-left transition-all sm:my-8 sm:w-full sm:max-w-lg max-w-3xl mx-auto p-8 bg-gradient-to-r from-indigo-500 to-purple-600 text-white shadow-lg rounded-xl mt-10">
                        <div className="absolute top-[-10px] right-[-10px] w-[30px] h-[30px] rounded-full bg-red-600 flex justify-center items-center cursor-pointer" onClick={() => setPopup(false)}><FaTimes /></div>
                        <div class=" px-4 pb-4 pt-5 sm:p-6 sm:pb-4 ">
                            <div class="">
                                <div class=" mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                    <h3 class="text-base font-semibold" id="modal-title">View detail order & product</h3>
                                    <div className="">
                                        <div className="mt-8 space-y-6">
                                            <div className="grid grid-cols-2 gap-4">
                                            <div className="flex flex-col">
                                                <span className=" font-medium text-gray-300">Order ID</span>
                                                <span className=" font-semibold">#{data.order_id}</span>
                                            </div>
                                            <div className="flex flex-col">
                                                <span className=" font-medium text-gray-300">Product ID</span>
                                                <span className=" font-semibold">{data.product_id}</span>
                                            </div>
                                            <div className="flex flex-col">
                                                <span className=" font-medium text-gray-300">Amount</span>
                                                <span className=" font-semibold">{data?.currency} {data.amount}</span>
                                            </div>
                                            <div className="flex flex-col">
                                                <span className=" font-medium text-gray-300">Status</span>
                                                <span className=" font-semibold capitalize">{data.ostatus}</span>
                                            </div>
                                            </div>
                                            <div className="flex flex-col space-y-4">
                                                <div>
                                                    <span className=" font-medium text-gray-300">Order Note : </span>
                                                    <span className=" font-semibold">{data.order_note}</span>
                                                </div>
                                                <div>
                                                    <span className=" font-medium text-gray-300">Date : </span>
                                                    <span className=" font-semibold">{new Date(data.created_at).toLocaleString()}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-8 text-center">
                                            {data?.tstatus==='ACTIVE'? 
                                            <button
                                            onClick={() => makePayment(data)}
                                            className="px-8 py-4 bg-white text-purple-700 font-semibold rounded-full shadow-md hover:bg-purple-200 transition duration-300"
                                            >
                                            Continue Payment
                                            </button>
                                            :""}
                                        </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}