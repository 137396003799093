import { useQuery } from "@tanstack/react-query";
import endpoint from "../../../Helpers/endpoint";
import Token from "../../../Helpers/Token";
import Http from "../../../http";
import { useState } from "react";
import { Spinner } from "../../Loader/Spinner";
import { FaTimes } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useManualSubscriber } from "../../../hooks/apihooks/useTransaction";

export function PopupSubscriptionAction({popup, setPopup}){
    const {register, watch} = useForm();
    const {mutateAsync, isPending}  = useManualSubscriber()
    const [selectedUser, setSelectedUser] = useState("");
    const [selectedProduct, setSelectedProduct] = useState("");
    const [getcurrency, setselectCurrency] = useState({});
    const [getSelectAmount, setSelectAmount] = useState("");
    const [enterManual, setenterManual] = useState(false);
    
    const getUserList = () => {
        const URL = `${endpoint.team.team}?parentuser=1`;
        return Http().get(URL, {
            'Authorization' : `Bearer ${Token.getAccessToken()}`,
        });
    }

    const getUserDetail = () => {
        const URL = `${endpoint.team.team}/${selectedUser}`;
        return  Http().get(URL, {
            'Authorization' : `Bearer ${Token.getAccessToken()}`,
        });
    }

    const productInfo = () => {
        const URL = `${endpoint.product.product}?pagination=no`;
        return  Http().get(URL, {
            'Authorization' : `Bearer ${Token.getAccessToken()}`,
        });
    }

    const {data,} = useQuery({queryKey: ['getUserList'], queryFn : getUserList});
    const {data:billingDetail, isLoading:billLoading} = useQuery({queryKey: ['getBillingDetail', selectedUser], queryFn : getUserDetail, enabled : Boolean(selectedUser)});
    const {data:productList} = useQuery({queryKey: ['productList', selectedUser], queryFn : productInfo, enabled : Boolean(selectedUser)});

    const handleCheckSelectUser = (event) => {
        setSelectedUser(event?.target.value)
    }

    const handleProductList = (productid) => {
        if(productid!==""){
            let selectPro = productList?.data?.filter((data) => data?.productuid===productid);
            setSelectedProduct(selectPro[0]);
            let currency = JSON.parse(selectPro[0]?.currency);
            setselectCurrency(currency);
            return ;
        }
        setSelectedProduct("");
        setselectCurrency({});
    }


    if(popup===false){
        return <div></div>
    }

    const Currency = selectedProduct?.currency? JSON.parse(selectedProduct?.currency)?.map((row) => row?.currency) || [] : [];

    console.log("Currency", Currency);

    const submitSubscription = () => {
        let selectAmount = JSON.parse(selectedProduct?.currency)?.filter((index, row) => row===getSelectAmount)[0] || [];
        let subData = {
            product : selectedProduct,
            amount : enterManual? watch('product'): selectAmount,
            billing : billingDetail?.data?.billing_info,
            data : watch()
        }
        
        mutateAsync(subData).then((resp) => {
            if((resp?.status===true)){
                toast.success(resp?.message);
                setPopup(false);
            }
        });
    }

    return (
        <div>
            {isPending? <Spinner size="medium"/> :""}
            <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                <div class="fixed inset-0 bg-gray-500/75 transition-opacity" aria-hidden="true"></div>
                <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <div class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[800px]">
                    <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                        <div class="flex flex-wrap items-start w-full">
                            <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left flex flex-wrap">
                                <h3 class="text-base font-semibold text-gray-900 w-full" id="modal-title">Add New Subscription</h3>
                                <form>
                                    <div className="w-full">
                                        <label>Select User</label>
                                        <select className="w-full border rounded px-2   mb-2 py-1" onChange={(e) => handleCheckSelectUser(e)}>
                                            {data?.data?.map((row, index) => {
                                                return (<option value={row?.guid}>{row?.fname} {row?.lname}</option>)
                                            })}
                                        </select>
                                    </div>
                                    {billingDetail?.data?.billing_info ? (
                                        <div className="w-full flex flex-wrap justify-between">
                                            <div className="w-[100%] mb-2">
                                                <label>Select Product</label>
                                                <select className="border rounded w-full px-2 py-1" onChange={(e) => handleProductList(e.target.value)}>
                                                    <option value={""}>Select Product</option>
                                                    {productList?.data?.map((row, index) => {
                                                       return  <option value={row?.productuid}>{row?.productname} - {row?.category}</option>
                                                    })}
                                                </select>
                                            </div>
                                            {selectedProduct? 
                                            <>
                                                <div className="w-[100%] mb-2 border rounded">
                                                    <h3 className="bg-gray-100 px-2 py-2">Product : </h3>
                                                    <div className="px-2 py-2">
                                                        <b>Product Name :</b> {selectedProduct?.productname} <br />
                                                        <div className="mb-2 mt-2">    
                                                            <b>Select Product Amount</b> <span className="bg-green-500 cursor-pointer rounded text-[13px] px-2 py-1 text-white" onClick={() => setenterManual(!enterManual)}>Enter Manual</span>
                                                        </div>
                                                        {
                                                            !enterManual ? <div>
                                                            {getcurrency.length>0 && getcurrency?.map((row, index) => {
                                                                return <div key={index} className={`rounded border px-2 py-1 mb-2 cursor-pointer flex items-center justify-between ${index === getSelectAmount? 'bg-green-100':""}`} ><span className="w-[95%] border-r"  onClick={() => setSelectAmount(index)}> <b> Amount :</b> {row?.sale_price} {row?.currency}</span> {index === getSelectAmount? <span className="text-red-600 w-[5%] block text-center"><FaTimes onClick={() => setSelectAmount("")}/></span>:""} </div>
                                                            })}
                                                        </div> : <div className="flex justify-between w-[50%]">
                                                            <div className="w-[50%]">
                                                                <label>
                                                                    Amount
                                                                </label>
                                                                <input type="text" className="border mr-4 px-2 py-1 rounded w-full" name="product.amount" placeholder="Enter amount" {...register('product.sales_price')} />
                                                            </div>
                                                            <div className="w-[48%]">
                                                                <label>
                                                                    Select Currency
                                                                </label>
                                                                <select className="border mr-2 px-2 py-1 rounded w-full"  {...register('product.currency')}>
                                                                    {Currency?.map((row) => {
                                                                        return <option value={row}>{row}</option>
                                                                    })}
                                                                
                                                                </select>
                                                            </div>
                                                        </div>
                                                        }
                                                    </div>
                                                </div>
                                            </>
                                            :""}
                                           
                                            <div className="border w-full  rounded">
                                                <h3 className="bg-gray-100 px-2 py-2">Billing Information : </h3>
                                                <div className="px-2 py-2">
                                                    <div><span>Name : </span> <span className="font-semibold">{billingDetail?.data?.billing_info?.first_name} {billingDetail?.data?.billing_info?.last_name} </span></div>
                                                    <div><span>Mobile : </span> <span className="font-semibold">{billingDetail?.data?.billing_info?.first_name} {billingDetail?.data?.billing_info?.last_name} </span></div>
                                                    <div><span>Eamil-Id : </span> <span className="font-semibold">{billingDetail?.data?.billing_info?.first_name} {billingDetail?.data?.billing_info?.last_name} </span></div>
                                                    <div><span>Country : </span> <span className="font-semibold"> {billingDetail?.data?.billing_info?.country} </span></div>
                                                    <div><span>Satate : </span> <span className="font-semibold"> {billingDetail?.data?.billing_info?.state} </span></div>
                                                    <div><span>City : </span> <span className="font-semibold">{billingDetail?.data?.billing_info?.city}  </span></div>
                                                    <div><span>Address : </span> <span className="font-semibold">{billingDetail?.data?.billing_info?.address1} {billingDetail?.data?.billing_info?.addres2} </span></div>
                                                </div>
                                            </div>
                                            <div className="w-[49%] mb-2">
                                                <label>Transaction ID </label>
                                                <input type="text" name="transaction_id" {...register('transaction_id')} className="border rounded w-full px-2 py-1" placeholder="Enter transection ID"/>
                                            </div>
                                            <div className="w-[49%] mb-2">
                                                <label>Payment Mode</label>
                                                <select name="payment_method" className="border rounded w-full px-2 py-1" {...register('payment_method')} >
                                                    <option>Cash</option>
                                                    <option>Debit Card</option>
                                                    <option>Credit Card</option>
                                                    <option>Bank Transfer</option>
                                                    <option>Online Payments</option>
                                                    <option>Mobile Payments</option>
                                                    <option>Checks</option>
                                                    <option>Cryptocurrency</option>
                                                    <option>Money Orders</option>
                                                    <option>E-wallets</option>
                                                </select>
                                            </div>
                                        </div>
                                    ) : ""}

                                    {billingDetail?.data?.billing_info===null ? <div>User billing information not found, pls update first billing info.</div>:""}
                                    {billLoading? <Spinner size="small" /> :""}
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                        <button type="button" class="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto" onClick={submitSubscription}>Submit</button>
                        <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto" onClick={() =>setPopup(false)}>Cancel</button>
                    </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    )
}
    
    
    