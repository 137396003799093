import { useState } from "react";
import { FaLink, FaSearch, FaUpload } from "react-icons/fa";
import { IoMenu, IoText } from "react-icons/io5";
import { Link } from "react-router-dom";
import { BooleanSearch } from "./Sourcing/BooleanSearch";
import { JobCopyPast } from "./Sourcing/JobCopyPast";
import { JobLink } from "./Sourcing/JobLink";
import { JobMenu } from "./Sourcing/JobMenu";
import { UploadDocument } from "./Sourcing/UploadDocument";

export default function SourcePopup({ open, setOpen, campaign }) {
  const [tabsSourcing, setTabsSourcing] = useState("Boolean-Search");

  return (
    <div
      className={`relative z-10  ${open ? `` : `hidden tansition-all`}`}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>

      <div className="fixed  inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className=" w-[900px] absolute top-20 ">
            <button
              type="button"
              className="bg-[red] absolute z-50 text-white right-[-10px] top-[-10px] rounded-full h-[30px] w-[30px]"
              onClick={() => setOpen(false)}
            >
              X
            </button>
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 w-full rounded transition-all duration-[1000ms] h-auto">
              <h3
                className="text-[18px] font-semibold leading-6 text-gray-900"
                id="modal-title"
              >
                Candidates Sourcing
              </h3>
              <ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
                <li
                  className="me-2"
                  onClick={() => setTabsSourcing("Boolean-Search")}
                >
                  <Link
                    className={`${
                      tabsSourcing === "Boolean-Search"
                        ? "text-bgbrown border-b-2  border-bgbrown"
                        : ""
                    } inline-flex items-center transition-all duration-[500ms]   justify-center p-4  rounded-t-lg  group`}
                  >
                    <FaSearch size={16} /> &nbsp; Boolean Search
                  </Link>
                </li>
                <li
                  className="me-2"
                  onClick={() => setTabsSourcing("Job-Link")}
                >
                  <Link
                    className={`${
                      tabsSourcing === "Job-Link"
                        ? "text-bgbrown border-b-2  border-bgbrown"
                        : ""
                    } inline-flex items-center transition-all duration-[500ms]   justify-center p-4  rounded-t-lg group`}
                  >
                    <FaLink size={16} /> &nbsp; Job Link
                  </Link>
                </li>
                <li className="me-2" onClick={() => setTabsSourcing("Menu")}>
                  <Link
                    className={`${
                      tabsSourcing === "Menu"
                        ? "active text-bgbrown border-b-2  border-bgbrown "
                        : ""
                    } inline-flex items-center transition-all duration-[500ms] justify-center p-4 rounded-t-lg  group`}
                  >
                    <IoMenu size={16} /> &nbsp; Menu
                  </Link>
                </li>
                <li
                  className="me-2"
                  onClick={() => setTabsSourcing("Document")}
                >
                  <Link
                    className={`${
                      tabsSourcing === "Document"
                        ? "text-bgbrown border-b-2  border-bgbrown"
                        : ""
                    } inline-flex items-center transition-all duration-[500ms]   justify-center p-4  rounded-t-lg  group`}
                  >
                    <FaUpload size={16} /> &nbsp; Document Upload
                  </Link>
                </li>
                <li className="me-2" onClick={() => setTabsSourcing("Text")}>
                  <Link
                    className={`${
                      tabsSourcing === "Text"
                        ? "text-bgbrown border-b-2  border-bgbrown"
                        : ""
                    } inline-flex items-center  transition-all duration-[500ms]   justify-center p-4  rounded-t-lg  group`}
                  >
                    <IoText size={20} /> &nbsp; Text
                  </Link>
                </li>
              </ul>
              <div className="bg-white px-3 w-[98%] overflow-x-auto py-3">
                {tabsSourcing === "Boolean-Search" ? (
                  <BooleanSearch  setOpen={setOpen} campaign={campaign} />
                ) : tabsSourcing === "Job-Link" ? (
                  <JobLink campaign={campaign} />
                ) : tabsSourcing === "Document" ? (
                  <UploadDocument  setOpen={setOpen} />
                ) : tabsSourcing === "Menu" ? (
                  <JobMenu setOpen={setOpen}  />
                ) : tabsSourcing === "Text" ? (
                  <JobCopyPast setOpen={setOpen} />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
