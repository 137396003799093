import moment from "moment/moment";
import React, { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { FiFilter } from "react-icons/fi";
import { TbFileExport } from "react-icons/tb";
import { Link } from "react-router-dom";
import Tooltip from "../../../Helpers/Tooltip";
import useDebounce from "../../../Helpers/useDebounce";
import { CVSource } from "../../../hooks/useGlobalHooks";
import UserLayout from "../../../Layouts/Users/UserLayout";
import DuplicateCand from "../../../Popups/Candidate/DuplicateCand";
import PopupAddCand from "../../../Popups/Candidate/PopupAddCand";
import ImportCandidate from "../../../Popups/ImportCandidate";
import useCandidateItem from "../../Admin/InfiniteScroll/InfiniteScrollCan";
import { Spinner } from "../../Loader/Spinner";
import CandidateCard from "./CandidateCard";

export default function Candidates() {
  const [open, setOpen] = useState(false);
  const [popupDuplic, setPopupDupl] = useState(false);
  // const [openSource, setOpenSource] = useState(false);
  const [donwloadData, setdataDownload] = useState([]);
  const [manualCand, setManualCand] = useState(false);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState(false);
  const [toggleCalender, setToggleCalender] = useState(false);
  const [selectSource, setSelecthandle] = useState({
    cvsource : "",
    status : ""
  });

  const specificDate = new Date();
  const previousYearSpecificDate = new Date(specificDate);
  previousYearSpecificDate.setFullYear(specificDate.getFullYear() - 1);

  const [daterange, setDateRange] = useState({
    startDate: previousYearSpecificDate,
    endDate: new Date(),
    key: "selection",
  });

  const searchdebounce = useDebounce(search, 500);
  const { data, fetchNextPage, hasNextPage, isFetching } = useCandidateItem({
    pageParam: 1,
    perPage: 10,
    searchdebounce,
    selectSource : selectSource?.cvsource,
    status : selectSource?.status,
    fromdate: moment(daterange?.startDate.toUTCString()).format("YYYY-MM-DD"),
    todate: moment(daterange?.endDate.toUTCString()).format("YYYY-MM-DD"),
  });
  const loaderRef = useRef(null);

  // Scroll event listener
  useEffect(() => {
    let fetching = false;
    const onScroll = async (event) => {
      const { scrollHeight, scrollTop, clientHeight } =
        event.target.scrollingElement;
      if (!fetching && scrollHeight - scrollTop <= clientHeight * 1.7) {
        fetching = true;
        if (hasNextPage) await fetchNextPage();
        fetching = false;
      }
    };

    document.addEventListener("scroll", onScroll);
    return () => {
      document.removeEventListener("scroll", onScroll);
    };

  }, [hasNextPage, fetchNextPage]);

  useEffect(() => {
    if (window.location.hash === '#openCand') {
      setOpen(true);
    }
  }, []);

  const lengthofpages = data?.pages?.length;

  function handleSelect(date) {
    setDateRange({
      startDate: date?.selection?.startDate,
      endDate: date?.selection?.endDate,
      key: "selection",
    });
  }

  // candidate
  /**
   * Hook that alerts clicks outside of the passed ref
   */
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
      */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          //  alert("You clicked outside of me!");
          setToggleCalender(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  /**
   * Component that alerts if you click outside of it
  */

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const csvDownload = () => {
    setdataDownload([]);
    let store = [];

    data?.pages?.forEach((page) => {
      page?.data?.data?.forEach((item) => {
        store.push(item);
      });
    });
    setdataDownload(store);

  };

  return (
    <UserLayout>
      <div className={`${lengthofpages > 1? "" : "h-[100vh]"} w-full bg-extralightbrown py-3 flex items-center gap-3 justify-start flex-col relative`}>
        <div className="w-[95%] flex items-center justify-center  flex-col gap-3 mt-10">
          <div className="bg-white w-full flex justify-between text-[18px] p-3 border border-gray-200">
            <div>Candidate Bank</div>
            <div className="text-[16px]">
              Displaying page{" "}
              {data?.pages[lengthofpages - 1]?.data?.current_page} Out of{" "}
              {data?.pages[lengthofpages - 1]?.data?.links.length - 2} &nbsp;
              Total Record {data?.pages[lengthofpages - 1]?.data?.total}
            </div>
          </div>
          <div className="w-full sticky z-[9] top-1">
            <div className="sticky top-0 p-3 bg-white border border-gray-200">
              <div className="w-full  sticky top-1">
                  <div className="">
                    <div className="flex justify-between ">
                      <div className="flex items-center justify-center gap-2">
                        <input
                        type="search"
                          placeholder="Search - Name | Email | Mobile"
                          onChange={(e) => setSearch(e.target.value)}
                          className="min-w-[300px] px-3 text-[14px] h-8 border border-gray-200"
                        />
                      </div>
                      <div className="flex items-center justify-center gap-2">
                        <Tooltip content="Filters" >
                          <div className="h-8 w-8 border border-gray-200 p-2 rounded-md" ref={wrapperRef} onClick={() => {setFilter(!filter); setDateRange({
                              startDate: previousYearSpecificDate,
                              endDate: new Date(),
                              key: 'selection',
                            })}} >
                            <FiFilter />
                          </div>
                        </Tooltip>
                        <Tooltip content="Export">
                          <div className="h-8 w-8 border border-gray-200 p-2 rounded-md" onClick={csvDownload}>
                              <CSVLink data={donwloadData} > 
                              <TbFileExport />
                              </CSVLink>
                          </div>
                        </Tooltip>
                        <Link to={'#openCand'} target="_blank" className="text-white bg-bgbrown text-[14px] px-4 py-1 rounded-[5px]">+ Add</Link>
                        <Link
                        to={'/source-public-candidate'}
                          className="text-white bg-bgbrown text-[14px] px-4 py-1 rounded-[5px]"
                        > More
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className={`text-right w-full justify-end flex items-end py-3 ${filter? "" : "hidden"}`}>
                    <div className="text-left mr-2" ref={wrapperRef}>
                      <div>Date Range : </div>
                      <div className="border w-[250px] text-left inline-block px-2 py-1 rounded" onClick={() => setToggleCalender(!toggleCalender)}> {moment(daterange?.startDate.toUTCString()).format("ll")} - {moment(daterange?.endDate.toUTCString()).format("ll")}</div>
                      <div className={`absolute border rounded right-[10px] top-[90px] ${toggleCalender ? '' : 'hidden'}`} >
                        <DateRangePicker 
                          ranges={[daterange]}
                          onChange={handleSelect}
                        />
                      </div>
                    </div>
                    <div className="text-left mr-2">
                      <div>Source:</div>
                      <select className="border rounded w-[250px] py-[5px] px-[5px]" onChange={(e) => setSelecthandle({...selectSource, ...{cvsource : e.target.value}})}>
                        <option>--Select Source--</option>
                        {CVSource.map((row, index) => (
                          <option>{row.label}</option>
                        ))}
                      </select>
                    </div>
                    <div className="text-left mr-2">
                      <div>Status:</div>
                      <select className="border rounded w-[250px] py-[5px] px-[5px]" onChange={(e) => setSelecthandle({...selectSource, ...{status : e.target.value}})}>
                        <option>--Select Status--</option>
                        <option>Active</option>
                        <option>Pending</option>
                        <option>Draft</option>
                        <option>Hold</option>
                      </select>
                    </div>
                  </div>
              </div>
            </div>
          </div>

          <ImportCandidate
            open={open}
            setOpen={setOpen}
            setManualCand={setManualCand}
          />

          <PopupAddCand manualCand={manualCand} setManualCand={setManualCand} />
          
          <DuplicateCand manualCand={popupDuplic} setManualCand={setPopupDupl} />
          
          {/* <div className="w-full flex items-center justify-center flex-col gap-3 "> */}
            <div className="w-full flex flex-wrap items-start sm:flex-row gap-1">
              {data?.pages?.map((data, i) => {
                return data?.data?.data?.map((data, index) => {
                  return (
                    <CandidateCard ref={loaderRef} key={index} data={data} />
                  );
                });
              })}
              {isFetching ? <Spinner  className="text-center"/> : ""}
            </div>
          {/* </div> */}
        </div>
      </div>
    </UserLayout>
  );
}
