import moment from "moment/moment";
import React from "react";
import { Link } from "react-router-dom";
import { FaRegCalendarAlt } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { FaRegEye } from "react-icons/fa";
import { get } from "../../../../Helpers/Storage";

export default function JobCard({ data }) {
  const {
    jobuid,
    jobtitle,
    location,
    experiencefrom,
    experienceto,
    created_at,
  } = data || {};

  return (
    <div className="w-full p-4 bg-white  flex items-center justify-center flex-col gap-2 border border-gray-200 rounded-[5px] shadow-sm">
      <div className="w-full flex items-center justify-between">
        <Link to={`${get('userAuth')?.role==='Admin'? `/admin/job-detail/${jobuid}`:`/jobdetail/${jobuid}`}`} className="w-[70%] text-[16px] font-semibold">
          {jobtitle}
        </Link>
        <p className="w-[15%] text-[15px]">
          <span className="flex items-center"><FaLocationDot className="mr-2" /> {location}</span> 
        </p>
        <p className="w-[15%] text-[15px] text-right">
          <span className="font-semibold">Exp</span> : {experiencefrom}
          to {experienceto} years
        </p>
      </div>
      <div className="w-full flex items-center justify-between">
      <p className="text-[14px]">
        <span className="font-semibold flex items-center "><FaRegCalendarAlt className="mr-2" /> {moment(created_at).format("DD-MMM-YYYY")}</span>
        </p>
        <Link to={`${get('userAuth')?.role==='Admin'? `/admin/job-detail/${jobuid}`:`/jobdetail/${jobuid}`}`} className="text-[15px] border px-1 rounded hover:bg-lightbrown hover:text-white flex items-center text-bgbrown">
         View <FaRegEye className="ml-2" />
        </Link>
      </div>
    </div>
  );
}
