import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { CiSearch } from "react-icons/ci";
import { WiStars } from "react-icons/wi";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Creatable from "react-select/creatable";
import extractJSON from "../../../Helpers/extractJSON";
import { useCreateJD } from "../../../hooks/apihooks/useCreateJD";
// import Loader from "../../Loader/Loader";
import { ExperienceLevel } from "./config";
import { useAddJob } from "../../../hooks/apihooks/jobs/useAddJob";
import { toast } from "react-toastify";
// import { useCreateCampaign } from "../../../hooks/apihooks/Campaigns/useCreateCampaign";
import { useQuery } from "@tanstack/react-query";
import Http from "../../../http";
import axios from "axios";
import useDebounce from "../../../Helpers/useDebounce";
import endpoint from "../../../Helpers/endpoint";
import Loader from "../../Loader/Loader";

export default function JobQuery({ header }) {
  const { handleSubmit, register, getValues, control, watch } = useForm();
  const router = useNavigate();
  const navigate = useNavigate();
  // const { mutateAsync: Mutate, isPending: campaignStatus } =
  //   useCreateCampaign();
  const { isPending, mutateAsync } = useCreateJD();
  const { isPending: status, mutateAsync: createJob } = useAddJob();
  const [countrySearch, setCountrySearch] = useState({
    id: "",
    country_id  : "",
    search : ""
  });

  const [citiesSearch, setCitiesSearch] = useState({
    id: "",
    state_id  : "",
    search : ""
  });
  
  const debcountrysearch = useDebounce(countrySearch, 500);
  const debcitiesSearch = useDebounce(citiesSearch, 500)
  // Countries
  const getcountries = () => {
    let url = endpoint.libraries.counties+'?search='+debcountrysearch?.search;
    return Http().get(url);
  };

  const { data: Counties, isLoading : loadcountries} = useQuery({
      queryKey: ["getcountries", debcountrysearch ,debcountrysearch],
      queryFn: () => getcountries(debcountrysearch),
      enabled:Boolean(debcountrysearch)
  });
  // Cities
  const getcities = (name='') => {
      let url = endpoint.libraries.cities+'?search='+name.search+'&country_id='+debcountrysearch.country_id;
      return Http().get(url);
  };

  const { data: Cities, isLoading : loadcities} = useQuery({
      queryKey: ["getstates", debcitiesSearch,debcountrysearch.country_id],
      queryFn: () => getcities(debcitiesSearch),
      enabled: Boolean(debcountrysearch?.country_id)
  });



  // function createCampaign(query) {
  //   Mutate({}).then((res) => {
  //     if (res?.status) {
  //       router(
  //         `/campaigns/${res?.data?.campaignuid}?query=true&prompt=${btoa(
  //           query
  //         )}`,
  //         { scroll: false }
  //       );
  //     }
  //   });
  // }

  const [json, setJson] = useState("");
  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: 40,
      backgroundColor: "transparent",
      boxShadow: "none",
      border: "0px",
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: "white",
    }),
    placeholder: (base) => ({
      ...base,
      color: "gray",
    }),
    valueContainer: (base) => ({
      ...base,
      fontSize: "13px", // Set text size inside the select
    }),
    singleValue: (base) => ({
      ...base,
      fontSize: "13px", // Set text size for the selected option
    }),
    input: (base) => ({
      ...base,
      fontSize: "13px", // Set text size for the input field
    }),
  };

  function addJob() {
    let payload = {
      jobtitle: json?.job_description?.jobTitle,
      experiencefrom: getValues("experiencefrom")?.value,
      experienceto: getValues("experienceto")?.value,
      location: getValues("location")?.label,
      country: getValues("country")?.label,
      mandatoryskills: json?.job_description?.mandatorySkillsets,
      additionalskills: json?.job_description?.goodToHaveSkillsets,
      description: json?.job_description?.jobDescription,
    };

    if (json?.job_description?.screeningQuestions?.length > 0) {
      payload['screeningquestions'] =  json?.job_description?.screeningQuestions?.map((data) => {
        return {
          question: data,
          desiredanswer: "",
          answertype: "",
        };
      });
    }

    createJob(payload).then((res) => {

      if (res?.status) {
        toast.success(res?.message);
        navigate('/job-listing');
      } else {
        toast.warn("Something went wrong");
      }
    });
  }

  const onSubmit = (data) => {
    let postData = {
      job_role: data.jobtitle,
      skills: data.skills?.map((x) => x.label).toString(),
      experience_range: `${data.experiencefrom.value} to ${data.experienceto.value} years`,
      user_id: "SYSTEM",
      location: data.location.label,
    };

    mutateAsync(postData).then((res) => {
      if (res?.data?.summary) {
        let json = extractJSON(res?.data?.summary);
        let data = json.length ? json[0] : {};
        setJson(data);
      }
    });
  };

  // let navigate =
  function generateCandidates() {
    let jobtitle = watch("jobtitle");
    let skills = watch("skills")
      ?.map((x) => x.label)
      .toString();
    let experience = `${watch("experiencefrom")?.value} to ${
      watch("experienceto")?.value
    }`;
    let location = watch("location")?.value;
    let query = `Share a list of 10 candidates for ${jobtitle} profile with  experience from ${experience} years from location ${location} with a skillset of ${skills}`;

    query &&
      router(
        `/plug/${header?.data?.chats?.chat_uid}?query=true&prompt=${btoa(
          query
        )}`,
        { scroll: false }
      );

    // createCampaign(query); for navigation to main campaign
  }

  const fetchSkills = async (AddInput = "") => {
    const response = await axios.get(
      `https://api-in.hiringplug.com/manage-helpdesk-service/v1/skills?keyword=${AddInput}`
    );
    return response.data;
  };

  const { data } = useQuery({
    queryKey: ["Skills"],
    queryFn: () => fetchSkills(),
  });

  const skillOptions = data?.data?.map((data) => {
    return { label: data.skill, value: data.skillid };
  });

  return (
    <div
      className={`w-full md:[80%] h-[100vh] flex items-center  flex-col gap-4  bg-extralightbrown overflow-y-scroll pb-10 ${
        json ? "justify-start" : "justify-center"
      }`}
    >
      {status  ? <Loader width={100} /> : ""}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className=" w-full  pb-5 flex items-center gap-3 justify-start pt-10 flex-col "
      >
        <div className="w-[95%] flex items-center justify-center">
          <p className="text-lightbrown text-[35px]">
            Automate Job Creation with AI-Plug Solutions
          </p>
        </div>
        <div
          className={`${
            isPending ? "animated-corner-border" : ""
          } w-[95%] bg-white p-2 flex items-center justify-center gap-2 flex-col border border-gray-200 rounded-md shadow-sm`}
        >
          <div className="w-[95%] flex  flex-wrap items-center justify-center gap-4 mt-5">
            <label className="w-[92%] flex items-center justify-center  border-b  border-gray-300 ">
              <CiSearch />
              <input
                placeholder="Job Title"
                name="jobtitle"
                {...register("jobtitle")}
                className="w-full outline-none bg-transparent h-[38px] p-2 text-[13px] placeholder-gray-500"
              />
            </label>
            <label className="w-[45%] flex items-center justify-center  border-b  border-gray-300 ">
              <Controller
                name="country"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    {...field}
                    styles={customStyles}
                    onChange={(e) => 
                      {field.onChange(e); setCountrySearch({...countrySearch, ...{country_id : e.value}})}
                    }
                    onInputChange={(e) => setCountrySearch({...countrySearch, ...{search : e}})}
                    className="w-full bg-transparent"
                    placeholder="Select country"
                    isLoading={loadcountries}
                    options={Counties?.data}
                  />
                )}
              />
            </label>

            <label className="w-[45%] flex items-center justify-center  border-b  border-gray-300 ">
              <Controller
                name="location"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    {...field}
                    styles={customStyles}
                    onChange={(e) => 
                      {
                          field.onChange(e); setCitiesSearch({...citiesSearch, ...{state_id : e.value}})
                      }
                    }
                    onInputChange={(e) => setCitiesSearch({...citiesSearch, ...{search : e}})}
                    className="w-full bg-transparent"
                    placeholder="Select location"
                    isLoading={loadcities}
                    options={Cities?.data}
                  />
                )}
              />
            </label>
          </div>
          <div className="w-[95%] flex items-center justify-center gap-4 mt-5">
            <label className="w-[45%] flex items-center justify-center  border-b  border-gray-300 ">
              <Controller
                name="experiencefrom"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    {...field}
                    styles={customStyles}
                    className="w-full bg-transparent"
                    placeholder="Experience from"
                    Searchable
                    options={ExperienceLevel}
                  />
                )}
              />
            </label>
            <label className="w-[45%] flex items-center justify-center  border-b  border-gray-300 ">
              <Controller
                name="experienceto"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    {...field}
                    styles={customStyles}
                    className="w-full bg-transparent"
                    placeholder="Experience to"
                    Searchable
                    options={ExperienceLevel}
                    isOptionDisabled={(option) =>
                      Number(option.value) <=
                      Number(getValues("experiencefrom.value"))
                        ? true
                        : false
                    }
                  />
                )}
              />
            </label>
          </div>
          <div className="w-[90%] px-[20px] flex items-center justify-center gap-4 mt-5">
            <label className="w-full flex items-center justify-center  border-b  border-gray-300 ">
              <Controller
                name="skills"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Creatable
                    {...field}
                    styles={customStyles}
                    className="w-full bg-transparent"
                    placeholder="Skills"
                    Searchable
                    isMulti
                    options={skillOptions}
                  />
                )}
              />
            </label>
          </div>
          <div className="w-[90%] flex items-center justify-end px-4">
            {watch("jobtitle") &&
            watch("skills") &&
            watch("experienceto") &&
            watch("experiencefrom") ? (
              <div className="flex items-center justify-center gap-2">
                <button
                  type="submit"
                  className="flex items-center justify-center text-[14px] aibutton px-4 py-1 rounded-full text-white hover:scale-90 transition-all"
                >
                  <WiStars size={20} /> {json ? "Re-Generate" : "Generate"}
                </button>
                {json ? (
                  <>
                    <button
                      onClick={generateCandidates}
                      type="button"
                      className="flex items-center justify-center text-[14px] aibutton px-4 py-1 rounded-full text-white hover:scale-90 transition-all"
                    >
                      Search Candidates
                    </button>
                    <button
                      onClick={addJob}
                      type="button"
                      className="flex items-center justify-center text-[14px] bg-lightbrown px-4 py-1 rounded-full text-white hover:scale-90 transition-all"
                    >
                      Save this Job
                    </button>
                  </>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </form>
      {json ? (
        <div className="w-[95%] border border-gray-200 bg-white p-8 rounded-md gap-3 flex items-start justify-center flex-col">
          <p className="font-medium text-[16px]">
            Job Title:{" "}
            <span className="font-normal text-[14px]">
              {json?.job_description?.jobTitle}
            </span>
          </p>
          <div className="font-medium text-[16px]">
            {" "}
            Job Overview:{" "}
            <div
              className="text-[14px] font-normal mt-3"
              dangerouslySetInnerHTML={{
                __html: json?.job_description?.jobDescription,
              }}
            />{" "}
          </div>
          <p className="font-medium text-[16px]">
            Mandatory Skills:{" "}
            <span className="font-normal text-[14px]">
              {json?.job_description?.mandatorySkillsets}
            </span>
          </p>
          <p className="font-medium text-[16px]">
            Good ToHave Skills:{" "}
            <span className="font-normal text-[14px]">
              {json?.job_description?.goodToHaveSkillsets}
            </span>
          </p>

          <p className="font-medium text-[16px]">Screening Questions: </p>

          {json?.job_description?.screeningQuestions?.map((data, index) => {
            return (
              <p className="font-normal text-[14px]" key={data}>
                {index + 1}. {data}
              </p>
            );
          })}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
