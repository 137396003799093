import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { BsRocketTakeoffFill } from "react-icons/bs";
import { FiFilter } from "react-icons/fi";
import { TbFileExport } from "react-icons/tb";
import { useNavigate, useParams } from "react-router-dom";
import endpoint from "../../../../Helpers/endpoint";
import Tooltip from "../../../../Helpers/Tooltip";
import useDebounce from "../../../../Helpers/useDebounce";
import { useCreateCampaign } from "../../../../hooks/apihooks/Campaigns/useCreateCampaign";
import Http from "../../../../http";
import SelectCampaignPopUp from "../../../../Popups/SelectCampaignPopUp";
import { Spinner } from "../../../Loader/Spinner";
import CampaignCard from "./CampaignCard";
import { FaRegPlusSquare } from "react-icons/fa";

export default function CampaignsListing({ header }) {
  // params
  let navigate = useNavigate();
  let {type} = useParams();
 
  // state
  const [query, setQuery] = useState({
    keyword: "",
    status: "",
    type: type ? type : "linkedin",
  });

  const [actions, setActions] = useState({
    filters: false,
  });

  const [open, setOpen] = useState(false);
  // hooks
  const { isPending, mutateAsync } = useCreateCampaign();
  let debouncesearch = useDebounce(query.keyword, 500);

  // functions
  function getCampaign(keyword, status, type) {
    let endpoints = `${endpoint.campaign.getall}?keyword=${keyword}&status=${status}&type=${type}`;
    return Http().get(endpoints);
  }

  // queries
  const { data, isLoading } = useQuery({
    queryKey: ["Campaigns", debouncesearch, query.status, query.type],
    queryFn: () => getCampaign(debouncesearch, query.status, query.type),
  });
  // handlers

  const queryHandler = (key) => (value) => {
    setQuery((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const actionHandler = (key) => (value) => {
    setActions((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const createCampaign = () => {
    if (header?.data?.campaignuid) {
      navigate(`/campaigns/${header?.data?.campaignuid}`);
    } else {
      mutateAsync({}).then((res) => {
        if (res?.status) {
          navigate(`/campaigns/${res?.data?.campaignuid}`);
        } else {
        }
      });
    }
  };

  return (
    <div className="w-full flex items-center bg-[#fffbf8]  min-h-[100vh] justify-start py-10 flex-col">
      <div className="w-[95%] flex  items-start justify-center gap-2 flex-col">
        <div className="bg-white flex flex-col items-start justify-center  bg-gradient-leads w-full text-[18px] p-3 border border-gray-200 gap-1 rounded-md">
          <div className="flex items-center justify-start gap-1">
            <BsRocketTakeoffFill />
            <p className="text-[18px]">Campaigns</p>
          </div>
          <div>
            <p className="text-[13px]">All Campaigns created by you.</p>
          </div>
        </div>

        <SelectCampaignPopUp
          open={open}
          setOpen={setOpen}
          createCampaign={createCampaign}
        />
        <div className="w-full flex items-center justify-between p-3 rounded-md  bg-white border border-gray-200">
          <div className="flex items-center justify-center gap-2">
            <input
              placeholder="Search"
              value={query.keyword}
              onChange={(e) => queryHandler("keyword")(e.target.value)}
              className="min-w-[300px] px-3 text-[14px] h-8 border border-gray-200"
            />
          </div>
          <div className="flex items-center justify-center gap-2 ">
            <button
              onClick={() => queryHandler("type")("normal")}
              className={`${query.type === 'normal' ? 'text-white bg-lightbrown  bg-opacity-80' : 'bg-white text-lightbrown '}  border border-lightbrown  text-[12px] px-4 py-1 rounded-[5px]`}
            >
              Normal Campaigns
            </button>
            <button
              onClick={() => queryHandler("type")("linkedin")}
              className={`${query.type === 'linkedin' ? 'text-white bg-lightbrown  bg-opacity-80' : 'bg-white text-lightbrown '}  border border-lightbrown  text-[12px] px-4 py-1 rounded-[5px]`}
            >
              LinkedIn-Specific Campaigns
            </button>
            <Tooltip content="Filters">
              <div
                onClick={() => actionHandler("filters")(!actions.filters)}
                className="h-8 w-8 border border-gray-200 p-2 rounded-md"
              >
                <FiFilter />
              </div>
            </Tooltip>
            <Tooltip content="Export">
              <div className="h-8 w-8 border border-gray-200 p-2 rounded-md">
                <TbFileExport />
              </div>
            </Tooltip>
            <Tooltip content="Create Campaign">
              <div
                onClick={() => setOpen(true)}
                className="h-8 w-8 border border-gray-200 p-2 rounded-md"
              >
                <FaRegPlusSquare />
              </div>
            </Tooltip>

            {/* <button
              
              className="text-white bg-lightbrown bg-opacity-80 text-[14px] px-4 py-1 rounded-[5px]"
            >
              + Create Campaign
            </button> */}
          </div>
        </div>
        {actions.filters ? (
          <div className="w-full flex items-center justify-between p-3  bg-white border border-gray-200">
            <select
              onChange={(e) => queryHandler("status")(e.target.value)}
              value={query.status}
              className="w-4/12 border border-gray-300 rounded-[4px] p-2"
            >
              <option value="">Status</option>
              <option value="DRAFT">Draft</option>
              <option value="ACTIVE">Active</option>
              <option value="INACTIVE">Inactive</option>
              <option value="DELETED">Deleted</option>
            </select>
          </div>
        ) : (
          ""
        )}

        {isLoading || isPending ? <Spinner size="medium" /> : ""}
        <div className="w-full flex items-center justify-center flex-col gap-3">
          {data?.data?.data?.map((data) => (
            <CampaignCard type={query.type} key={data.campaignid} data={data} />
          ))}
        </div>
      </div>
    </div>
  );
}
