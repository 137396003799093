import React from "react";
import UserLayout from "../../../Layouts/Users/UserLayout";
import { Page } from "./Page";

export default function Notification() {
  return (
    <UserLayout>
      <Page />
    </UserLayout>
  );
}
