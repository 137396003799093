import moment from "moment";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Role from "../../../Helpers/Role";
import { BsStars } from "react-icons/bs";
import Tooltip from "../../../Helpers/Tooltip";
import AiChatCandidate from "../../../Popups/AiChatCandidate";

export default function CandidateCard({ data }) {
  const [openRating, setOpenRating] = useState(false);

  const skills = data?.skills?.split(",");
  return (
    <div className="sm:w-[49%] mb-5 mr-[0.8%] w-full hover:text-white bg-white shadow-lg rounded-lg overflow-hidden hover:bg-darkbrown hover:shadow-lg transition duration-200 cursor-pointer relative">
      <AiChatCandidate open={openRating} setOpen={setOpenRating} Data={data} />
      <div
        onClick={() => setOpenRating(true)}
        className="flex items-center cursor-pointer justify-center flex-col absolute  right-3 bottom-1"
      >
        <Tooltip
          content={`Ask anything about ${data?.fullname}`}
          place="right"
          effect="solid"
        >
          <button className="aibutton rounded-full text-[15px] p-2 hover:scale-90 transition-all">
            <BsStars className="" />
          </button>
        </Tooltip>
      </div>
      <div className="flex items-start p-4">
          <Link  to={`/${
              Role.getAccessRole() === "Admin" ? "admin" : "candidate"
            }/preview/${data?.candidateuid}`}
          >
            <img
              alt="placeholder"
              className={`w-16 h-16 rounded-full border-2 object-cover ${(data?.profile) ? "" : ""}`}
              src={(data?.profile) ? data?.profile : data?.gender==='Male'?'/images/profile/profile_male.webp': data?.gender==='Female'?"/images/profile/profile_female.png" : "/images/profile/profile-unknown.png"}
            />
          </Link>
        <div className="ml-4 flex-grow">
          <Link
            to={`/${
              Role.getAccessRole() === "Admin" ? "admin" : "candidate"
            }/preview/${data?.candidateuid}`}
            className="text-[16px] font-semibold"
          >
            {data?.firstname} {data?.lastname}
          </Link>
          <p className="text-[12px]">{data?.email}</p>
          <p className="text-[12px]">{ data?.phone? `+`+data?.countrycode+`-`+data?.phone:""}</p>
          {
            data?.city ? <p className="text-[12px]">{data?.city} {data?.location}, {data?.countryname}</p> : ""
          }
        </div>
        <div className="ml-auto text-right">
          <span className="px-3 py-1 text-sm text-white font-medium bg-bgbrown rounded-full">
            {data?.status}
          </span>
        </div>
      </div>
      <div className="px-4 pb-4">
        
        <div className="flex flex-wrap gap-2 mt-2 items-start justify-start">
          <h3 className="font-semibold text-[15px] ">Skills:</h3>
          {skills?.slice(0,3)?.map((row, index) => {
            return (
              <p key={`skill${index}`} className="text-sm font-medium text-[12px]">
                {row}
                {skills?.slice(0,3)?.length - 1 > index ? "," : ""}
              </p>
            );
          })}
        </div>
      </div>
      <hr></hr>
      <div className="flex px-4 justify-start">
          <div className="mr-4 py-2">
            <p className="text-[15px]"> <span className="font-semibold">Source:</span> {data?.cvsource}</p>
          </div>
          <div className="mr-4  py-2">
            <p className=" text-[15px]">
              <span className="font-semibold  ">Update On:</span> {moment(data?.updated_at).format("ll")}
            </p>
          </div>
      </div>
    </div>
  );
}
