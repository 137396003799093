import React, { useEffect, useState } from 'react';
import AdminLayouts from '../../../Layouts/Admin/AdminLayouts';
import { useForm } from 'react-hook-form';
import { decryptData, encryptData } from '../../../hooks/EncryptDecrypt';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useApisCredentils, useApisUpdateCredentils } from '../../../hooks/apihooks/useApis';
import { Spinner } from '../../Loader/Spinner';
import Token from '../../../Helpers/Token';
import { useQuery } from '@tanstack/react-query';
import endpoint from '../../../Helpers/endpoint';
import Http from '../../../http';
import ResetPassword from '../../Website/Resetpassword/ResetPassword';
import { toast } from 'react-toastify';

const ApiDetails = () => {
  const navigation = useNavigate();
  const {mutateAsync, isPending} = useApisCredentils();
  const {mutateAsync : updateAsync, isPending: upIspending} = useApisUpdateCredentils();
  const {apiuid} = useParams();
  const {register, watch, setValue, handleSubmit} = useForm();
  const handleSave = (data) => {
    let newdata = {
      name: data?.name,
      type_authrization : data?.type_authrization,
      client_key: encryptData(data?.client_key),
      secret_key : encryptData(data?.secret_key),
      username: encryptData(data?.username),
      password: encryptData(data?.password),
      status: data?.status??0,
      token: encryptData(data?.token)
    }

    if(apiuid){
      updateAsync({data : newdata,apiuid:apiuid, token: Token.getAccessToken()}).then((resp) => {
        if(resp?.status===true){
          toast.success("Update Successfully Credetials");
          navigation('/admin/api-list');
        }
      })
    }else{
      mutateAsync({data : newdata, token: Token.getAccessToken()}).then((resp) => {
      })
    }
   
  };
  
  const getapisdetail = () => {
    const URL = `${endpoint.credentials.apis}/${apiuid}`;
    return Http().get(URL, {
      'Authorization' : `Bearer ${Token.getAccessToken()}`,
    });
  }

  const {data, isLoading} = useQuery({queryKey:['getapisdetail', apiuid], queryFn: getapisdetail, enabled : Boolean(apiuid)});

  useEffect(() => {
    if(data?.data){
      setValue('name', data?.data?.name);
      setValue('type_authrization', data?.data?.type_authrization);
      setValue('client_key', decryptData(data?.data?.client_key));
      setValue('secret_key', decryptData(data?.data?.secret_key));
      setValue('username', decryptData(data?.data?.username));
      setValue('password', decryptData(data?.data?.password));
      setValue('status',data?.data?.status);
      setValue('token', decryptData(data?.data?.token));
    }
  },[data?.data])

  return (
    <AdminLayouts>
      {isPending || isLoading || upIspending? <Spinner size='medium' /> : ""}
      <div className="px-10 py-5 h-[100vh] w-full bg-green-50">
        <div className='flex justify-between'>
          <Link to={'/admin/api-list'}>Back</Link>
          <h2 className="text-2xl font-bold mb-6">API Credential</h2>
        </div>
        <form onSubmit={handleSubmit(handleSave)} className="bg-white p-6 rounded-lg shadow-lg">
          <div className='flex justify-between'>
            <div className="mb-4 w-[49%]">
              <label className="block text-sm font-semibold mb-2">API Name</label>
              <input
                type="text"
                name='name'
                {...register("name")}
                className="w-full p-2 border border-gray-300 rounded-lg"
              />
            </div>
            <div className="mb-4 w-[49%]">
              <label className="block text-sm font-semibold mb-2">Type of Api</label>
              <select {...register('type_of_api', {required:true})} className='w-full p-2 border border-gray-300 rounded-lg'>
                <option value="">--Select Type of Apis</option>
                <option>Profile Link</option>
                <option>Profile Scrap</option>
                <option>Contact Detail</option>
              </select>
            </div>
          </div>
          <div className='flex flex-wrap justify-between'>
            <div className="mb-4 w-[49%]">
              <label className="block text-sm font-semibold mb-2">Secret Key</label>
              <input
                type="text"
                name='secret_key'
                {...register("secret_key")}
                className="w-full p-2 border border-gray-300 rounded-lg"
                placeholder='Enter the secret key'
              />
            </div>
            <div className="mb-4 w-[49%]">
              <label className="block text-sm font-semibold mb-2">Client Key</label>
              <input
                type="text"
                name='client_key'
                {...register("client_key")}
                className="w-full p-2 border border-gray-300 rounded-lg"
                placeholder='Enter the client key'
              />
            </div>
          </div>
          
          <div className='flex flex-wrap justify-between'>
            <div className='w-full'>
              <h2 className='font-bold'>Basic Auth:</h2>
            </div>
            <div className="mb-4 w-[49%]">
              <label className="block text-sm font-semibold mb-2">UserName</label>
              <input
                type="text"
                name='username'
                {...register("username")}
                className="w-full p-2 border border-gray-300 rounded-lg"
                placeholder='Enter the username'
              />
            </div>

            <div className="mb-4 w-[49%]">
              <label className="block text-sm font-semibold mb-2">Password</label>
              <input
                type="text"
                name='password'
                value={watch('password')}
                {...register("password")}
                className="w-full p-2 border border-gray-300 rounded-lg"
                placeholder='Enter the password'
              />
            </div>
          </div>
         
          <div className="mb-4">
            <label className="block text-sm font-semibold mb-2">API URL</label>
            <input
              type="url"
              name='api_url'
              {...register('api_url')}
              placeholder='Enter API url'
              className="w-full p-2 border border-gray-300 rounded-lg"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-semibold mb-2">Rate Limit</label>
            <input
              type="rate_limit"
              {...register('rate_limit')}
              className="w-full p-2 border border-gray-300 rounded-lg"
            />
          </div>
          <div className="block text-sm font-semibold mb-2">
              Status
            </div>
          <div className="mb-4">
            <label>Active</label>
            <input
              type="radio"
              {...register('status')}
              value={1}
              checked={true}
              className="p-2 border border-gray-300 rounded-lg"
            />
             <label>Inactive</label>
             <input
              type="radio"
              value={0}
              {...register('status')}
              className="p-2 border border-gray-300 rounded-lg"
            />
          </div>
          <div className="flex justify-end">
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600">
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </AdminLayouts>
  );
};

export default ApiDetails;
