import React, { useState } from "react";
import { toast } from "react-toastify";
import { Spinner } from "../Loader/Spinner";

const StreamedData = () => {
  const [streamedData, setStreamedData] = useState("");
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");
  const [formatEnableDisable, setFormatEnableDisable] = useState(false);

  const handleStreamRequest = async () => {
    if (text === "" || text === "") {
      toast.warning("Pls write something to ask a query to Ai");
    }
    setLoading(true);
    setStreamedData(""); // Reset the data when new stream starts

    const response = await fetch(
      "http://hiringplugai.local/manage-user-service/v1/conversation-ai",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: text,
        }),
      }
    );

    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    let done = false;
    let data = "";

    // Read stream data and update state as it arrives
    while (!done) {
      const { value, done: doneReading } = await reader.read();
      done = doneReading;
      data += decoder.decode(value, { stream: true });
      // Filter and extract only the text portion from the stream
      const textContent = extractTextFromStream(data);
      if (textContent) {
        setStreamedData(textContent); // Update the state with the streamed text
      }
      // Update the state with the streamed data
      // setStreamedData(data);
    }
    setLoading(false); // Stop loading when the stream is finished
    setText("");
  };

  const extractTextFromStream = (streamData) => {
    let text = "";
    try {
      // Regex to capture everything inside the "text" field including escaped characters
      const regex = /"text"\s*:\s*"([^"]+)"/g;
      // const regex = /"text"\s*:\s*"([^"]*)"/g;
      let match;

      // While the regex finds a match, append the captured text
      while ((match = regex.exec(streamData)) !== null) {
        text += match[1]; // Replace newlines with <br> tags
      }
    } catch (error) {
      console.error("Error processing stream data with regex:", error);
    }

    return text.replace(/\\n/g, "").replace(/'/g, '"').trim(); // Trim any leading/trailing spaces
  };

  // const preWrapStyle = {
  //   whiteSpace: 'pre-wrap',  // Maintains newlines and wraps text
  //   wordWrap: 'break-word',  // Prevents long words from overflowing
  // };

  return (
    <div className="px-4 py-5">
      <h1>Ask to Gen AI For streamed Data</h1>
      <textarea
        onChange={(e) => setText(e.target.value)}
        rows={5}
        className="w-6/12 border-gray-600 border-2 rounded px-3 py-2"
        placeholder="write here"
      >
        {" "}
      </textarea>
      <br></br>
      <button
        onClick={handleStreamRequest}
        disabled={loading}
        className="bg-green-600 text-white rounded px-2 py-1"
      >
        {loading ? "Loading..." : "Start Streaming"}
      </button>
      <div
        style={{
          whiteSpace: "pre-wrap", // Preserve newlines and wrap text
          wordWrap: "break-word", // Prevent word overflow
          background: "#f5f5f5", // Light background for better readability
          padding: "10px",
          borderRadius: "5px",
          fontFamily: "Courier, monospace", // Monospaced font for code appearance
          fontSize: "16px",
          lineHeight: "1.6", // Line spacing
          height: "500px",
          overflow: "scroll",
        }}
      >
        <h2>Response:</h2>
        {loading ? (
          <Spinner />
        ) : streamedData !== "" ? (
          <button
            className=""
            onClick={() => setFormatEnableDisable(!formatEnableDisable)}
          >
            Pretty Format
          </button>
        ) : (
          ""
        )}
        {formatEnableDisable ? (
          <pre>{JSON.stringify(JSON.parse(streamedData), null, 2)}</pre>
        ) : (
          <div dangerouslySetInnerHTML={{ __html: streamedData }}></div>
        )}
      </div>
    </div>
  );
};

export default StreamedData;
