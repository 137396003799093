import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import endpoint from "../../../../Helpers/endpoint";
import Http from "../../../../http";
import { useQuery } from "@tanstack/react-query";
import useDebounce from "../../../../Helpers/useDebounce";
import { FaEdit} from "react-icons/fa";
import { useParseProfile } from "../../../../hooks/apihooks/useParseProfile";
import Token from "../../../../Helpers/Token";
import { toast } from "react-toastify";
export function JobMenu({setOpen}){  
  const [editJobDetail, seteditJobDetail] = useState(false);
  const [totalnorecord, setTotalNoOfRecord] = useState(10);
  const {mutateAsync, isLoading} = useParseProfile();
    const {
      control,
      formState: { errors },
    } = useForm();
    const [ query, setQuery ] = useState({
      keyword : ''
    });

    const [jobselectdata ,setInputJob] = useState({});

    let debouncesearch = useDebounce(query.keyword,500)

    function getJobs(keyword) {
      let endpoints = `${endpoint.job.listing}?keyword=${keyword}`;
      return Http().get(endpoints);
    }

    const { data, isLoading:dedded } = useQuery({ queryKey: ["jobslisting",debouncesearch], queryFn: () => getJobs(debouncesearch) });
    const filteredJobs = data?.data.map(({ jobid, jobtitle, location, mandatoryskills,  description, additionalskills }) => ({ value: jobid, label:jobtitle, location, mandatoryskills, description, additionalskills }));

    let JobData = '';

    const handleSearch = () => {
      const includedKeywords = jobselectdata?.additionalskills.split(',').map(item => `"${item}"`).join(' AND ');
      const query = `"${jobselectdata?.label}" "${jobselectdata?.location}" AND ${includedKeywords} -intitle:"profiles" -inurl:"dir/ "site:in.linkedin.com/in/ OR site:in.linkedin.com/pub/`;
      // Encode the query for the URL
      const encodedQuery = encodeURIComponent(query);
      // Generate the Google search URL
      // setGoogleSearch({query : encodedQuery, totalrecord : 20 });
      // Open the URL in a new tab
      mutateAsync({encodedQuery,totalnorecord, campaign:{campaign : "yes"}, token: Token.getAccessToken()}).then((resp) => {
        if(resp?.status===true){
          toast.success(resp?.message);
          setOpen(false);
        }
      })
    };


    return (
        <form className="w-full">
        <div className="mt-3 text-center sm:text-left w-full">
          <div className="w-full flex items-start justify-center gap-2">
              <label className="w-full flex items-start  justify-center flex-col">
                <span className="text-[14px]">
                  Select Job <i className="text-[red]">*</i>
                </span>
                <Controller
                  name="job"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={filteredJobs}
                      onChange={(e) => setInputJob(e)}
                      onInputChange={(e) => setQuery({keyword : e})}
                      Searchable
                      isLoading={isLoading}
                      className=" w-full font-primary text-black text-sm h-10 rounded-sm"
                      cacheOptions
                      placeholder="Select Job"
                    />
                  )}
                />
              </label>
          </div>
          <div>
          {(Object.keys(jobselectdata).length> 0) && editJobDetail===false ? (
            <div>
              <div className="bg-[#e0e0e0] text-center mt-3 mb-2 py-2 rounded flex items-center justify-between"><span className="px-3"> Job Detail </span> <span className="px-3 text-[green] cursor-pointer"> <FaEdit  onClick={() => seteditJobDetail(!editJobDetail) }/> </span></div>
              <div className="font-bold ">Job Title : </div>
              {jobselectdata?.label}
              <div className="font-bold">Job Location : </div>
              {jobselectdata?.location}
              <div className="font-bold">Additional Skills</div>
              <div>{jobselectdata?.additionalskills}</div>
              <div className="font-bold">Mandatory Skills</div>
              <div>{jobselectdata?.mandatoryskills}</div>
              <div className="font-bold">Job Description</div>
              <div dangerouslySetInnerHTML={{__html : jobselectdata?.description }}></div>
              <div className="font-bold  bg-[#e0e0e0] w-full">Total Record</div>
                    <input type="text" onChange={(e) => setTotalNoOfRecord(e.target.value)} className="w-full px-2 border rounded py-1 my-2"  value={totalnorecord} />
          </div>) : 
          (Object.keys(jobselectdata).length> 0) && editJobDetail===true ? (
            <div>
              <div className="bg-[#e0e0e0] text-center mt-3 mb-2 py-2 rounded flex items-center justify-between"><span className="px-3"> Job Detail </span> <span className="px-3 text-[green] cursor-pointer"> <FaEdit  onClick={() => seteditJobDetail(!editJobDetail) }/> </span></div>
              <div className="font-bold ">Job Title : </div>
              <input type="text" onChange={(e) => setInputJob({...jobselectdata, ...{label : e.target.value} })} className="w-full px-2 border rounded py-1 my-2"  value={jobselectdata?.label} />
              <div className="font-bold">Job Location : </div>
              <input type="text" onChange={(e) => setInputJob({...jobselectdata, ...{location : e.target.value} })} className="w-full px-2 border rounded py-1 my-2"  value={jobselectdata?.location} />
              <div className="font-bold">Additional Skills</div>
              <input type="text" onChange={(e) => setInputJob({...jobselectdata, ...{additionalskills : e.target.value} })} className="w-full px-2 border rounded py-1 my-2"  value={jobselectdata?.additionalskills} />
              <div className="font-bold">Mandatory Skills</div>
              <input type="text" onChange={(e) => setInputJob({...jobselectdata, ...{mandatoryskills : e.target.value} })} className="w-full px-2 border rounded py-1 my-2"  value={jobselectdata?.mandatoryskills} />
              <div className="font-bold">Job Description</div>
              <div dangerouslySetInnerHTML={{__html : jobselectdata?.description }}></div>
              <div className="font-bold  bg-[#e0e0e0] w-full">Total Record</div>
                    <input type="text" onChange={(e) => setTotalNoOfRecord(e.target.value)} className="w-full px-2 border rounded py-1 my-2"  value={totalnorecord} />
          </div>
          ) : ""
        }
          </div>
        </div>
        <div className="py-3 sm:flex sm:flex-row-reverse">
            <button
                type="button"
                onClick={() => handleSearch()}
                className="inline-flex w-full justify-center rounded-md bg-green-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-600 sm:ml-3 sm:w-auto"
            >
                Generate
            </button>
            </div>
        </form>
    )
}