
import React from 'react'
import { MdAddCall,MdOutlineEmail } from "react-icons/md";
import Header from '../Header';
import Footer from '../Footer';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useContact } from '../../../hooks/useContact';

export default function ContactUS() {
  const {handleSubmit, register} = useForm();

  const {mutateAsync, isPending} = useContact();
  
  const onSubmit = (data) => {
    mutateAsync(data).then((resp) => {
      console.log(resp);
    })
  }

  return (
    <>
      <Header />
      <section className=''>
        <div className='patterlayer relative max-sm:h-[250px]'>
          <div className="flex justify-center items-center absolute max-sm:w-[80%] max-sm:left-[30px] max-sm:right-0 top-[50%] right-[35%] transform align-middle">
            <h1 className='text-white text-[30px] w-full max-sm:text-[20px]'>Contact Us</h1>
          </div>
        </div>
      </section>
      <section className='py-[70px]'>
        <div className='container max-sm:px-3 m-[auto]'>
          <div className='text-center'>
            <h1 className='text-[40px] max-sm:text-[25px]'>Get In Touch  <br/> With Us For More Infromation</h1>
            <p>54 Berrick 2nd Street Boston, Massachusetts
            02115,United States.</p>
          </div>
          <div className='flex justify-between items-center py-[30px] flex-wrap'>
            <div className="border w-[48%] max-sm:mb-3 max-sm:w-full p-[20px] rounded flex items-center justify-start">
              <div className='w-[30%]'>
                <div className='w-[80px] h-[80px] bg-green-100 px-5 py-5 rounded-[50%]'>
                  <MdAddCall  className='text-[40px] text-[gray]'/>
                </div>
              </div>
              <div className='w-[70%]'>
                <h3 className='text-[25px]'> Call Us</h3>
                <p className='py-2'>Call us today & Experience exceptional service.</p>
                <Link>(044) 888.12.345 & 67</Link>
              </div>
            </div>
            <div className="border w-[48%] max-sm:w-full p-[20px] rounded flex items-center justify-start">
              <div className='w-[30%]'>
                <div className='w-[80px] h-[80px] bg-green-100 px-5 py-5 rounded-[50%]'>
                  <MdOutlineEmail  className='text-[40px] text-[gray]'/>
                </div>
              </div>
              <div className='w-[70%]'>
                <h3 className='text-[25px]'> Call Us</h3>
                <p className='py-2'>Call us today & Experience exceptional service.</p>
                <Link>(044) 888.12.345 & 67</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="conatactbg py-[100px]">
      <div className="container max-sm:px-3 m-[auto]">
        <div className="flex items-center flex-wrap">
          <div className="w-[40%] max-sm:w-full text-white">
            <h2 className="text-[40px] ">Let's Collaborate & Discuss Your Project</h2>
            <p className="text-[16px]">I want to discuss;</p>
            <ul className="py-[50px]">
              <li className="py-2"><input type="checkbox"/> <span> User Experience</span></li>
              <li className="py-2"><input type="checkbox"/><span> Quality & Reliability</span></li>
              <li className="py-2"><input type="checkbox"/><span> Collaboration</span></li>
              <li className="py-2"><input type="checkbox"/><span> Legal and Copyright</span></li>
            </ul>
          </div>
          <div className="w-[60%] max-sm:w-full">
            <div className="px-[100px] max-sm:px-0">
              <div className="bg-white border py-5 px-5 rounded-[10px]">
                  <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="mb-5">
                        <label className='flex mb-3'>Your Name</label>
                        <input type="text" {...register('full_name', {required:true})} className="border rounded w-full py-3 px-3" placeholder='Enter name'/>
                      </div>
                      <div className="mb-5">
                        <label className='flex mb-3'>Email Address</label>
                        <input type="text" {...register('email', {required:true})}  className="border rounded w-full py-3 px-3" placeholder='Email address'/>
                      </div>
                      <div className="mb-5">
                        <label className='flex mb-3'>Message</label>
                        <textarea type="text" {...register('message')}  rows={5} className="border rounded w-full py-3 px-3" placeholder='Message'/>
                      </div>
                      <div className="mb-5">
                        <button type='submit'  className='bg-[#000] text-white block w-full rounded py-4'>{isPending ? 'Sending...' : 'Send Your Message'}</button>
                      </div>
                  </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
    </>
  )
}
