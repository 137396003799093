import React, { useEffect, useMemo, useState } from "react";
import endpoint from "../Helpers/endpoint";
import Http from "../http";
import { useQuery } from "@tanstack/react-query";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import Select from "react-select";
import { IoTrash } from "react-icons/io5";
import { FaPlusCircle } from "react-icons/fa";
import { useCreateCampaign } from "../hooks/apihooks/Campaigns/useCreateCampaign";
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import Loader from "../Components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function CreateCampaignJob({ setOpen, open, Data, header, selectedCandidates}) {
  const handleClose = () => {
    setOpen(false);
  };
  let navigate = useNavigate();
  const [states, setStates] = useState({
    campaigntitle: { state: false, title: "" },
    job: false,
    questions: false,
  });
  const { isPending, mutateAsync } = useCreateCampaign();
  const { handleSubmit, register, control, setValue, watch } = useForm();
  const { fields, append, remove } = useFieldArray({
    name: "questions",
    control,
  });

  // function getCampaign() {
  //   let endpoints = endpoint.campaign.single.replace(
  //     "{campaignuid}",
  //     header?.data?.campaignuid
  //   );
  //   return Http().get(endpoints);
  // }

  // const { data: Campaign } = useQuery({
  //   queryKey: ["Campaign"],
  //   queryFn: getCampaign,
  //   enabled: Boolean(header?.data?.campaignuid),
  // });

  function getJobs() {
    let endpoints = endpoint.job.listing;
    return Http().get(endpoints);
  }

  const { data: Jobs, isLoading: loading } = useQuery({
    queryKey: ["Jobs"],
    queryFn: getJobs,
  });

  function getQuestions(jobid) {
    let endpoints = endpoint.job.screeningquestions.replace("{jobid}", jobid);
    return Http().get(endpoints);
  }

  const { data: Questions, isLoading } = useQuery({
    queryKey: ["Questions", watch("jobuid")?.value],
    queryFn: () => getQuestions(watch("jobuid")?.value),
    enabled: Boolean(watch("jobuid")?.value && states.job),
  });

  const JobOptions = useMemo(() => {
    return Jobs?.data?.map((data) => {
      return { label: data?.jobtitle, value: data.jobuid };
    });
  }, [Jobs]);

  useEffect(() => {
    setStates({
      ...states,
      campaigntitle: { state: false, title: "Campaign" },
    });

    if (Questions?.data) {
      remove();
      let Ques = Questions?.data?.map((data) => {
        return {
          question: data?.question,
          mandatory: "1",
          answertype: data?.answer_type,
          question_id: data?.question_id,
        };
      });
      setValue(
        "questions",
        Ques?.length > 0 ? Ques : [{ question: "", mandatory: "1" }]
      );
    } else {
      remove();
      setValue("questions", [{ question: "", mandatory: "1" }]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Questions?.data, watch("jobuid")?.value]);

  function onSubmit(data) {

    let postData = {
      campaigntitle: states.campaigntitle.title,
      jobuid: data.jobuid.value,
      questions: data.questions,
      candidates :  selectedCandidates.map(data => data.candidateid),
      message_uid : Data?.message_uid
    };

    mutateAsync(postData).then((res) => {
      if(res?.status){
        navigate('/campaigns-list/normal')
      }else{
        toast.warn('Something went wrong')
      }
    });
  }

  const handleActions = (key) => {
    setStates({ ...states, [key]: !states[key] });
  };

  const updateJob = () => {
    setStates({ ...states, job: true, questions: false });
  };

  return (
    <>
      {open && (
        <React.Fragment>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-gothic bg-pop-up">
            {isLoading ? <Loader /> : ""}
            {isPending ? <Loader /> : ''}
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="relative rounded-lg gap-3 my-6 mx-auto bg-white py-4 px-8 lg:w-8/12 flex items-center flex-col justify-center w-80 -top-28 "
            >
              <p className="font-semibold text-[24px]">Define your campaign</p>
              {!states.campaigntitle.state ? (
                <div className="w-full flex items-start justify-center flex-col">
                  <p className="text-[26px]">{states?.campaigntitle?.title}</p>
                  <button
                    type="button"
                    onClick={() =>
                      setStates({
                        ...states,
                        campaigntitle: { ...states.campaigntitle, state: true },
                      })
                    }
                  >
                    Edit name
                  </button>
                </div>
              ) : (
                <div className="w-full flex items-center justify-center flex-row gap-2">
                  <input
                    placeholder="Enter Campaign Title"
                    className="w-full border border-gray-300 px-2 h-10"
                    name="campaigntitle"
                    {...register("campaigntitle", { required: true })}
                    value={states?.campaigntitle?.title}
                    onChange={(e) =>
                      setStates({
                        ...states,
                        campaigntitle: {
                          ...states.campaigntitle,
                          title: e.target.value,
                        },
                      })
                    }
                  />
                  <button
                    onClick={() =>
                      setStates({
                        ...states,
                        campaigntitle: {
                          ...states.campaigntitle,
                          state: false,
                        },
                      })
                    }
                    type="button"
                    className="px-4 py-1 bg-lightbrown text-white font-medium rounded-full"
                  >
                    Save
                  </button>
                </div>
              )}

              <div className="w-full flex items-center flex-col justify-center  border border-gray-200">
                <div className="border-b w-full flex items-start gap-2 justify-center p-6  flex-col border-gray-200 ">
                  <p className="flex items-center justify-start gap-2">
                    {states?.job ? (
                      <IoCheckmarkCircleSharp
                        size={20}
                        className="text-lightbrown"
                      />
                    ) : (
                      ""
                    )}{" "}
                    Which <b>Job</b> are you hiring for?
                  </p>

                  <div className="w-full flex items-center justify-between gap-2">
                    <Controller
                      name="jobuid"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          className={`${
                            states.job ? "hidden" : ""
                          } w-full bg-transparent`}
                          placeholder="Select..."
                          Searchable
                          options={JobOptions}
                          isLoading={loading}
                        />
                      )}
                    />
                    {states.job ? (
                      <div className=" px-4 py-1 bg-lightbrown bg-opacity-10 text-lightbrown font-semibold rounded-sm ">
                        {watch("jobuid")?.label}
                      </div>
                    ) : (
                      ""
                    )}
                    <button
                      onClick={() => handleActions("job")}
                      type="button"
                      className="px-4 py-1 bg-gray-300 text-gray-800 font-medium rounded-full"
                    >
                      Edit
                    </button>
                  </div>
                  {states.job ? (
                    ""
                  ) : (
                    <div className="w-full flex items-center justify-start gap-2">
                      {/* <button
                        type="button"
                        className="px-4 py-1 bg-gray-300 text-gray-800 font-medium rounded-full"
                      >
                        cancel
                      </button> */}
                      <button
                        type="button"
                        onClick={updateJob}
                        className="px-4 py-1 bg-lightbrown text-white font-medium rounded-full"
                      >
                        Save
                      </button>
                    </div>
                  )}
                </div>
                <div className="border-b w-full flex items-start justify-center p-6 flex-col gap-3 border-gray-200 ">
                  <div className="w-full flex items-center justify-between ">
                    <p className="font-semibold">Screening Questions</p>
                    <button
                      onClick={() => handleActions("questions")}
                      type="button"
                      className="px-4 py-1 bg-gray-300 text-gray-800 font-medium rounded-full"
                    >
                      Edit
                    </button>
                  </div>
                  {states.job && !states.questions ? (
                    <div className="w-full flex items-center justify-center gap-2 flex-col transition-height duration-500 ease">
                      {fields?.map((data, index, array) => (
                        <div
                          key={index}
                          className="w-full flex gap-2 items-start flex-col justify-center"
                        >
                          <div className="w-full flex items-center justify-center gap-3">
                            <textarea
                              type="text"
                              name={`questions[${index}]question`}
                              {...register(`questions.${index}.question`)}
                              placeholder="Enter Your Question"
                              className=" w-full font-primary text-sm py-1  rounded-sm px-4 border border-border-col"
                              // oninput={autoExpand}
                            />
                            <input
                              type="text"
                              name={`questions[${index}]questiontext`}
                              {...register(`questions.${index}.questiontext`)}
                              placeholder="Enter Your Question"
                              className="hidden"
                            />
                            <input
                              name={`questions[${index}]desiredanswer`}
                              {...register(`questions.${index}.desiredanswer`)}
                              className="hidden"
                            />
                            <input
                              name={`questions[${index}]answertype`}
                              {...register(`questions.${index}.answertype`)}
                              className="hidden"
                            />
                            <input
                              name={`questions[${index}]mandatory`}
                              {...register(`questions.${index}.mandatory`)}
                              className="hidden"
                            />

                            <input
                              name={`questions[${index}]question_id`}
                              {...register(`questions.${index}.question_id`)}
                              className="hidden"
                            />

                            <div className=" flex items-center justify-center gap-3">
                              <IoTrash
                                onClick={() => remove(index)}
                                className="text-[28px] text-gray-400 cursor-pointer"
                              />
                            </div>
                          </div>

                          {array.length - 1 === index && array.length < 5 ? (
                            <p
                              onClick={() =>
                                append({
                                  questiontext: "",
                                  mandatory: "1",
                                })
                              }
                              className="w-[96%] bg-white p-3 cursor-pointer flex items-center justify-start gap-2 border border-border-col"
                            >
                              <FaPlusCircle className="text-[28px] text-lightbrown " />
                              {/* <FontAwesomeIcon
                            icon={faPlusCircle}
                            className="text-[28px] text-green "
                          />{" "} */}
                              Add a Question
                            </p>
                          ) : (
                            <></>
                          )}
                        </div>
                      ))}
                      {fields.length === 0 ? (
                        <p
                          onClick={() =>
                            append({
                              questiontext: "",
                              mandatory: "1",
                            })
                          }
                          className="w-[96%] bg-white p-3 cursor-pointer flex items-center justify-start gap-2 border border-border-col"
                        >
                          <FaPlusCircle className="text-[28px] text-lightbrown " />
                          Add a Question
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}

                  {states.job && states.questions ? (
                    <div className="w-full flex items-center justify-start flex-wrap gap-2">
                      {fields?.map((data) => (
                        <div
                          key={data?.question}
                          className=" px-4 py-1 bg-lightbrown bg-opacity-10 text-lightbrown font-semibold rounded-sm "
                        >
                          {data?.question}
                        </div>
                      ))}
                    </div>
                  ) : (
                    ""
                  )}
                  {states.job && !states.questions ? (
                    <div className="w-full flex items-center justify-start gap-2">
                      {/* <button
                        type="button"
                        className="px-4 py-1 bg-gray-300 text-gray-800 font-medium rounded-full"
                      >
                        cancel
                      </button> */}
                      <button
                        type="button"
                        onClick={() =>
                          setStates({ ...states, questions: true })
                        }
                        className="px-4 py-1 bg-lightbrown text-white font-medium rounded-full"
                      >
                        Save
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {states.job && states.questions ? (
                <div className="w-full flex items-center justify-center gap-2">
                  <button
                    onClick={handleClose}
                    type="button"
                    className="px-4 py-1 bg-gray-300 text-gray-800 font-medium rounded-full"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-1 bg-lightbrown text-white font-medium rounded-full"
                  >
                    Save
                  </button>
                </div>
              ) : (
                <></>
              )}
            </form>
          </div>
          {/* <div className="opacity-25 fixed inset-0 z-40 bg-black"></div> */}
        </React.Fragment>
      )}
    </>
  );
}

export default CreateCampaignJob;
