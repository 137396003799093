import endpoint from '../../../Helpers/endpoint';
import Token from '../../../Helpers/Token';
import Http from '../../../http';
import { useInfiniteQuery } from '@tanstack/react-query';

const fetchItems  = async (pageParam, filter) => {
    const URL = `${process.env.REACT_APP_APIENDPOINT}${endpoint.candidate.candidate}?page=${pageParam}&perPage=${filter.perPage}&search=${filter.searchdebounce}&sourcecate=${filter.selectSource}&status=${filter.status}&fromdate=${filter?.fromdate}&todate=${filter?.todate}`;
    return  Http().get(URL, {
      "Content-type": "application/json",
      "Authorization": `Bearer ${Token.getAccessToken()}`
    });
}

const useCandidateItem = (filter) => {

    
      return useInfiniteQuery({
        queryKey: ['candidateitems', filter], // Updated to use array format for query key
        queryFn: ({pageParam = 1}) => fetchItems(pageParam, filter),
        getNextPageParam: (lastPage, allPages) => {
            const maxpages = lastPage.data?.last_page;
            const nextPage = allPages.length + 1;
            return nextPage <= maxpages ? nextPage : undefined;
        },
    });
};

export default useCandidateItem;