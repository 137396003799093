import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Token from "../../../Helpers/Token";
import { useChangePassword } from "../../../hooks/apihooks/useProfile";
import UserLayout from "../../../Layouts/Users/UserLayout";
import { Spinner } from "../../Loader/Spinner";

export default function ChangePassword() {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { mutateAsync, isPending } = useChangePassword();

  const onSubmit = (data) => {
    mutateAsync({ data, token: Token.getAccessToken() }).then((resp) => {
      if (resp?.status === false && resp?.error) {
        toast.error(resp?.error?.password[0]);
      }
      if (resp?.status === false && resp?.message) {
        toast.error(resp?.message);
      }

      if (resp?.status === true) {
        reset();
        toast.success(resp?.message);
      }
    });
  };
  return (
    <UserLayout>
      {isPending ? <Spinner size="medium" /> :""}
      <div className="h-[100vh] w-[50%] sm:w-[80%]pb-5 py-3 flex items-center gap-3 justify-start flex-col relative ">
        <div className="bg-white w-[95%] mt-[30px] mb-[30px] rounded-[10px] border border-[#e0e0e0]">
          <div className="p-[30px] max-sm:p-[15px]">
            <h2 className="text-[20px] text-center">Change Password</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex justify-between flex-wrap">
                <div className="w-full mb-3">
                  <label>Current Password</label>
                  <input
                    type="password"
                    {...register("oldpassword", { required: true })}
                    className="border rounded border-[#e0e0e0] w-full px-3 py-2"
                    placeholder="Current Password"
                  />
                  {errors?.oldpassword ? (
                    <p>{errors?.message?.oldpassword}</p>
                  ) : (
                    ""
                  )}
                </div>
                <div className="w-full mb-3">
                  <label>New Password</label>
                  <input
                    type="password"
                    {...register("password", { required: true })}
                    className="border rounded border-[#e0e0e0] w-full px-3 py-2"
                    placeholder="New Password"
                  />
                  {errors?.password ? <p>{errors?.message?.password}</p> : ""}
                </div>
                <div className="w-full mb-3">
                  <label>Confirm New Password</label>
                  <input
                    type="password"
                    {...register("password_confirmation", { required: true })}
                    className="border rounded border-[#e0e0e0] w-full px-3 py-2"
                    placeholder="Confirm New Password"
                  />
                  {errors?.password_confirmation ? (
                    <p>{errors?.message?.password_confirmation}</p>
                  ) : (
                    ""
                  )}
                </div>
                <div className="w-[100%] text-center">
                  <button
                    type="submit"
                    className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-darkbrown to-lightbrown group-hover:from-green-400 group-hover:to-blue-600 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-darkbrown dark:focus:ring-darkbrown"
                  >
                    <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">Change Password</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </UserLayout>
  );
}
