
import React, { useState } from 'react'
import { useForm } from "react-hook-form"
import { toast } from 'react-toastify';
import { useSignup } from '../../../hooks/apihooks/useSignup';
import { Link } from 'react-router-dom';
import LinkedInOAuth from '../../Auth/LinkedInOAuth';
import { useGoogleLogin } from '@react-oauth/google';
export default function Signup() {
  const [ user, setUser ] = useState(null);
  const {
    register,
    // watch,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const signIn = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log('Login Failed:', error)
  });

  const {mutateAsync : singuppost, isPending} = useSignup();

  const formSubmit = (data) => {
    singuppost(data).then((resp) => {
      if(resp.status===true){
        toast.success(resp.message);
      }
      if(resp.status===false){
        toast.error(resp?.error?.email[0]);
      }
    });
  };


  return (
    <>
      {/* <WebHeader /> */}
      <section className='bg-green-100 h-[100vh]'>
        <div className='m-[auto] max-sm:px-3 relative'>
          <div className='flex items-center flex-wrap max-sm:flex-wrap-reverse'>
            <div className='w-[50%] max-sm:w-full px-10'>
              <div className='py-5 absolute top-0 left-[3%] max-sm:ml-5'><Link to={'/'} className='font-bold'>{process.env.REACT_APP_APP_NAME}</Link></div>
              <h1 className='text-[40px] font-extrabold'>The Hire talent Engagement Platform</h1>
              <img src='/images/loginpage.png'  alt=''/>
              <p>Accelerate hiring, strengthen your brand, and exceed recruiting targets - all while delivering a personalized candidate experience.</p>
            </div>
            <div className='w-[50%] max-sm:w-full bg-[white] h-[100vh]'>
              <div className='px-10 flex flex-wrap items-center h-[100vh] max-sm:p-0'>
                <div className='px-5 py-5 w-[70%] max-sm:w-full m-[auto] max-sm:m-0'>
                  <div className='w-full'>
                    <h2 className='text-[25px] py-5 font-semibold'>Hi, welcome to {process.env.REACT_APP_APP_NAME}!</h2>
                  </div>
                  <form  onSubmit={handleSubmit(formSubmit)}>
                    <div className='flex flex-wrap justify-between mb-3'>
                      <div className='w-[100%] mb-5'>
                        <label className='py-2 block'>Username<sup className='text-[red]'>*</sup></label>
                        <input type='text' name='email'  {...register("email", { required: true })} className='w-full px-2 py-2 border  rounded' placeholder='Username/Email' />
                        {errors.email && <p className="text-[red]">Username is required.</p>}
                      </div>
                    </div>
                    <div className='flex justify-between flex-wrap mb-3'>
                      <div className='w-[100%]'>
                        <button type='submit' disabled={isPending?true:false} className='w-full px-2 py-2 bg-green-700 text-white  rounded'> {isPending? 'Loading...':"Continue"}</button>
                      </div>
                    </div>
                  </form>
                  <div className='py-5 relative'>
                    <hr />
                    <span className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white py-2 w-[40px] h-[40px] text-center middle'>OR</span>
                  </div>
                  <div className='flex flex-wrap justify-between'>
                      <button onClick={() => signIn()} className='w-[49%] max-sm:mb-3 max-sm:w-full border-2 py-3 rounded-[8px] flex justify-center items-center'>
                        <div className='w-[20px] mr-3'>
                          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><g><path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path><path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path><path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path><path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path><path fill="none" d="M0 0h48v48H0z"></path></g></svg>
                         </div>
                        <div className='text-[13px] max-sm:text-[16px]'>Continue with Google</div>
                      </button>
                      <LinkedInOAuth className="w-[49%]" />
                      <p className='py-2 text-center'>Already using {process.env.REACT_APP_APP_NAME}?
                      <Link to="/signin" className='text-green-700'>Signin an account</Link></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <WebFooter /> */}
    </>
  )
}
