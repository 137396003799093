import AdminLayouts from "../../../Layouts/Admin/AdminLayouts";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import endpoint from "../../../Helpers/endpoint";
import Http from "../../../http";
import Token from "../../../Helpers/Token";
import moment from "moment";
import { Link } from "react-router-dom";
import { FaEye } from "react-icons/fa6";
import { Spinner } from "../../Loader/Spinner";

const  AdminBillingAndInvoice = () => {
    const [subscriptionPlan, setsubscriptionPlan] = useState(false);
    
    const getallsubscription = () => {
        const URL = `${endpoint.transection.subscription}`;
        return Http().get(URL, {'Authorization' : `Bearer ${Token.getAccessToken()}`});
    }

    const {data, isLoading} = useQuery({queryKey : ['getallbillinginvoice'], queryFn : getallsubscription});
    
    return (
        <AdminLayouts>
            <div className="px-10 py-5 w-full bg-green-50">
                <div className="w-full pb-5 bg-green-50 py-3 flex items-center justify-start flex-col relative">
                    <div className="bg-white w-full flex justify-between text-[18px] p-3 border border-gray-200 mb-5">
                        <div>Billings & Invoices</div>
                        <div className="text-[16px]">Displaying{" "}</div>
                    </div>
                    <div className="w-full mb-5">
                        <div className="p-3 bg-white border border-gray-200">
                            <div className="w-full">
                                <div className="">
                                    <div className="flex justify-between ">
                                        <div className="flex items-center justify-center gap-2">
                                            <input
                                                type="search"
                                                placeholder="Search - Name | Email | Mobile"
                                                className="min-w-[300px] px-3 text-[14px] h-8 border border-gray-200"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="border mt-2 px-2 py-2">
                                <table className="min-w-full bg-gray-100 border-collapse">
                                    <thead className="">
                                        <tr>
                                            <th className="py-3 px-6 text-left text-sm font-medium text-gray-700">Order ID</th>
                                            <th className="py-3 px-6 text-left text-sm font-medium text-gray-700">Invoice No</th>
                                            <th className="py-3 px-6 text-left text-sm font-medium text-gray-700">Name</th>
                                            <th className="py-3 px-6 text-left text-sm font-medium text-gray-700">Company Name</th>
                                            <th className="py-3 px-6 text-left text-sm font-medium text-gray-700">Email</th>
                                            <th className="py-3 px-6 text-left text-sm font-medium text-gray-700">Date</th>
                                            <th className="py-3 px-6 text-left text-sm font-medium text-gray-700">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.data?.map((row) => (
                                            <tr className="border-t border-gray-200">
                                                <td className="py-3 px-6 text-sm text-gray-700"><Link className="text-blue-500 font-semibold">#{row?.order_id}</Link></td>
                                                <td className="py-3 px-6 text-sm text-gray-700"><Link className="text-blue-500 font-semibold">--</Link></td>
                                                <td className="py-3 px-6 text-sm text-gray-700">{row?.fname}</td>
                                                <td className="py-3 px-6 text-sm text-gray-700">---</td>
                                                <td className="py-3 px-6 text-sm text-gray-700">{row?.email}</td>
                                                <td className="py-3 px-6 text-sm text-gray-700">{moment(row?.created_at).format('ll')}</td>
                                                <td className="py-3 px-6 text-sm text-gray-700"><Link className="text-blue-500 font-semibold"><FaEye /></Link></td>
                                            </tr>
                                        ))}
                                         {isLoading? <tr>
                                            <td colSpan={6}><Spinner /></td>
                                        </tr>:""}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AdminLayouts>
    )
}

export default AdminBillingAndInvoice;