import React from 'react';

const ConfirmationModal = ({ actionType, onCancel, onConfirm }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-md w-96">
        <h2 className="text-xl font-semibold mb-4">Confirmation</h2>
        <p className="mb-4">
          We are about to merge the duplicate candidate records. Please review the following rules:
        </p>
        <ul className="mb-4 list-disc pl-6">
          <li>If the system removes duplicates, we will use the most recent data.</li>
          <li>If you manually remove duplicates, we will keep the selected record(s) and remove the others.</li>
        </ul>

        <div className="flex justify-between">
          <button
            onClick={onCancel}
            className="bg-gray-300 text-black px-4 py-2 rounded-lg"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="bg-blue-500 text-white px-4 py-2 rounded-lg"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
