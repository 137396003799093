import React from 'react';
import UserLayout from '../../../Layouts/Users/UserLayout';
import BarlineChart from '../../Chart/BarlineChart';
import { useQuery } from '@tanstack/react-query';
import endpoint from '../../../Helpers/endpoint';
import Http from '../../../http';
import Token from '../../../Helpers/Token';
import { FaUsersLine } from "react-icons/fa6";
import { RiUser2Line } from "react-icons/ri";
import AdminLayouts from '../../../Layouts/Admin/AdminLayouts';

const AdminDashboard = () => {
  const getAnalyticsReport = () => {
    const URL = `${endpoint.analyticsreport.analytics}`;
    return Http().get(URL, {
      'Authorization' : `Bearer ${Token.getAccessToken()}`,
    });
  }

  const {data} = useQuery({queryKey : ['getAnalyticsreport','admin'], queryFn: getAnalyticsReport});
  return (
    <AdminLayouts>
    <div className="min-h-screen bg-green-100 w-full py-6 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-3xl font-semibold text-gray-900 mb-6">Admin Dashboard</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
          {/* Number of Panels */}
          <div className="bg-white p-6 rounded-lg border border-gray-400 border-l-indigo-600 border-l-8">
            <h3 className="text-lg font-medium text-gray-700">Number of Panels (User)</h3>
            <p className="mt-2 text-2xl font-bold text-indigo-600 flex justify-start"><FaUsersLine size={40} className='mr-10'/> {data?.data?.paneluser}</p>
          </div>

          {/* Linkedin Hr Requiter account */}
          <div className="bg-white p-6 rounded-lg border border-gray-400 border-l-indigo-600 border-l-8">
            <h3 className="text-lg font-medium text-gray-700">Linkedin HR requiter</h3>
            <p className="mt-2 text-2xl font-bold text-indigo-600 flex justify-start"><RiUser2Line size={40} className='mr-10' /> {data?.data?.linkedin_hr_requiter}</p>
          </div>

          {/* Job Count */}
          <div className="bg-white p-6 rounded-lg border border-gray-400 border-l-indigo-600 border-l-8">
            <h3 className="text-lg font-medium text-gray-700">Job Count</h3>
            <p className="mt-2 text-2xl font-bold text-indigo-600">{data?.data?.job}</p>
          </div>
          {/* Out of Source Campaigns */}
          <div className="bg-white p-6 rounded-lg border border-gray-400 border-l-indigo-600 border-l-8">
            <h3 className="text-lg font-medium text-gray-700">Out of Source Campaigns</h3>
            <p className="mt-2 text-2xl font-bold text-red-600">{data?.data?.public_campaign}</p>
          </div>
        </div>
        
        <div className="grid mt-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">

          {/* Credits Remaining */}
          <div className="bg-white p-6 rounded-lg border border-gray-400 border-l-green-600 border-l-8">
            <h3 className="text-lg font-medium text-gray-700">Services Credit</h3>
            <div className="mt-2 space-y-2">
              <div className="flex justify-between text-sm text-gray-600">
                <span>Google</span>
                <span className="font-bold text-green-600">{data?.data?.credits?.google_credit}</span>
              </div>
              <div className="flex justify-between text-sm text-gray-600">
                <span>Linkedin</span>
                <span className="font-bold text-green-600">{data?.data?.credits?.linkedin_credit}</span>
              </div>
            </div>
          </div>

          {/* Credits Remaining */}
          <div className="bg-white p-6 rounded-lg border border-gray-400 border-l-yellow-600 border-l-8">
            <h3 className="text-lg font-medium text-gray-700">Cumminication Credit</h3>
            <div className="mt-2 space-y-2">
              <div className="flex justify-between text-sm text-gray-600">
                <span>What's App</span>
                <span className="font-bold text-green-600">{data?.data?.cummincation?.whats_app}</span>
              </div>
              <div className="flex justify-between text-sm text-gray-600">
                <span>Email</span>
                <span className="font-bold text-green-600">{data?.data?.cummincation?.email}</span>
              </div>
              <div className="flex justify-between text-sm text-gray-600">
                <span>Text Message</span>
                <span className="font-bold text-green-600">{data?.data?.cummincation?.text_message}</span>
              </div>
            </div>
          </div>

          {/* Total Communication Users */}
          <div className="bg-white p-6 rounded-lg border border-gray-400 border-l-blue-600 border-l-8">
            <h3 className="text-lg font-medium text-gray-700">Linkedin Communication</h3>
              <div className="mt-2 space-y-2">
                  {
                    data?.data?.linkedin_cummunication.map((row, index) => (
                      <div className="flex justify-between text-sm text-gray-600">
                        <span className=' capitalize'>{row?.status}</span>
                        <span className="font-bold text-blue-600">{row?.count}</span>
                      </div>
                    ))
                  }
              </div>
          </div>

          {/* Settings Section */}
          {/* <div className="bg-white p-6 rounded-lg border-gray-400">
            <h3 className="text-lg font-medium text-gray-700">Settings</h3>
            <p className="mt-2 text-gray-600">Customize your dashboard settings, preferences, and notifications.</p>
            <button className="mt-4 bg-indigo-600 text-white py-2 px-4 rounded-lg hover:bg-indigo-700">
              Go to Settings
            </button>
          </div> */}
        </div>
        <div className='grid mt-7 grid-cols-1 lg:grid-cols-2'>
            {/* Candidate Pipline */}
            <div className="bg-white w=[50%] p-6 rounded-lg border border-gray-400">
              <h3 className="text-lg font-medium text-gray-700">Hiring Pipline</h3>
              <div className="mt-2 space-y-2">
                <BarlineChart  Report={[
                  {
                      "month": "Sourced",
                      "count": 139
                  },
                  {
                      "month": "Screened",
                      "count": 90
                  },
                  {
                      "month": "Offered",
                      "count": 30
                  },
                  {
                      "month": "Hired",
                      "count": 5
                  }]} chartLabel={['Candidate Pipline']} />
              </div>
            </div>
        </div>
      </div>
    </div>
    </AdminLayouts>
  );
};

export default AdminDashboard;
