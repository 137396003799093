import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { CiSearch } from "react-icons/ci";
import Select from "react-select";
import { ExperienceLevel } from "../config";
import Creatable from "react-select/creatable";
import { WiStars } from "react-icons/wi";
import { useQuery } from "@tanstack/react-query";
import Http from "../../../../http";
import Token from "../../../../Helpers/Token";
import endpoint from "../../../../Helpers/endpoint";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../../Loader/Loader";
import useDebounce from "../../../../Helpers/useDebounce";
import extractJSON from "../../../../Helpers/extractJSON";
import { useCreateJD } from "../../../../hooks/apihooks/useCreateJD";
import { useSaveJob } from "../../../../hooks/apihooks/jobs/useAddJob";
import { toast } from "react-toastify";

export default function JobDetail() {
  const { jobuid } = useParams();
  const navigate = useNavigate();
  const { register, reset, setValue, watch, control, getValues, handleSubmit } = useForm();
  const [json, setJson] = useState();
  const { isPending, mutateAsync } = useCreateJD();
  const { isPending:savePending, mutateAsync:saveMutateAsync } = useSaveJob();
  const [countrySearch, setCountrySearch] = useState({
    id: "",
    country_id  : "",
    search : ""
  });

  const [citiesSearch, setCitiesSearch] = useState({
    id: "",
    state_id  : "",
    search : ""
  });
  
  const debcountrysearch = useDebounce(countrySearch, 500);
  const debcitiesSearch = useDebounce(citiesSearch, 500)
  // Countries
  const getcountries = () => {
    let url = endpoint.libraries.counties+'?search='+debcountrysearch?.search;
    return Http().get(url);
  };

  const { data: Counties, isLoading : loadcountries} = useQuery({
      queryKey: ["getcountries", debcountrysearch ,debcountrysearch],
      queryFn: () => getcountries(debcountrysearch),
      enabled:Boolean(debcountrysearch)
  });
  // Cities
  const getcities = (name='') => {
      let url = endpoint.libraries.cities+'?search='+name.search+'&country_id='+debcountrysearch.country_id;
      return Http().get(url);
  };

  const { data: Cities } = useQuery({
      queryKey: ["getstates", debcitiesSearch],
      queryFn: () => getcities(debcitiesSearch),
      enabled: Boolean(debcountrysearch?.country_id)
  });
 

  const getjobdetail = () => {
    const URL = `${endpoint.job.jobdetail}/${jobuid}`;
    return Http().get(URL, {
      Authorization: `Bearer ${Token.getAccessToken()}`,
    });
  };

  const { data, isLoading } = useQuery({
    queryKey: ["jobdeail"],
    queryFn: getjobdetail,
  });
  
  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: 40,
      backgroundColor: "transparent",
      boxShadow: "none",
      border: "0px",
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: "white",
    }),
    placeholder: (base) => ({
      ...base,
      color: "gray",
    }),
    valueContainer: (base) => ({
      ...base,
      fontSize: "15px", // Set text size inside the select
    }),
    singleValue: (base) => ({
      ...base,
      fontSize: "15px", // Set text size for the selected option
    }),
    input: (base) => ({
      ...base,
      fontSize: "13px", // Set text size for the input field
    }),
  };

  const onSubmit = (data) => {
    let postData = {
      job_role: data.jobtitle,
      skills: data.skills?.map((x) => x.label).toString(),
      experience_range: `${data.experiencefrom.value} to ${data.experienceto.value} years`,
      user_id: "SYSTEM",
      location: data.location.label,
    };

    mutateAsync(postData).then((res) => {
      if (res?.data?.summary) {
        let json = extractJSON(res?.data?.summary);
        let data = json.length ? json[0] : {};
        setJson(data);
      }
    });
  };

  const saveJob = () => {
    let payload = {
      jobtitle: json?.job_description?.jobTitle,
      experiencefrom: getValues("experiencefrom")?.value,
      experienceto: getValues("experienceto")?.value,
      location: getValues("location")?.label,
      country: getValues("country")?.label,
      mandatoryskills: json?.job_description?.mandatorySkillsets,
      additionalskills: json?.job_description?.goodToHaveSkillsets,
      description: json?.job_description?.jobDescription,
    };

    if (json?.job_description?.screeningQuestions?.length > 0) {
      payload['screeningquestions'] =  json?.job_description?.screeningQuestions?.map((data) => {
        return {
          question: data,
          desiredanswer: "",
          answertype: "",
        };
      });
    }


    saveMutateAsync({payload, jobuid}).then((res) => {
      if (res?.status) {
        toast.success(res?.message);
        navigate('/job-listing');
      } else {
        toast.warn("Something went wrong");
      }
    });
  }

  useEffect(() => {
    if (data?.data) {
      reset(data?.data);
      setValue('country', { value: 0, label:  data?.data?.country})
      setValue('location', { value: 0, label:  data?.data?.location})
      setValue('experiencefrom', {value: data?.data?.experiencefrom, label:  data?.data?.experiencefrom+` `+(data?.data?.experiencefrom  > 1? 'Years' : 'Year')} )
      const techString = data?.data?.mandatoryskills;
      const technologies = techString.split(", ");
      const formattedTechnologies = technologies.map((tech, index) => ({
        value: index,  // You can modify the value logic as per your requirement
        label: tech
      }));
      setValue('skills', formattedTechnologies)
      setValue('experienceto', {value: data?.data?.experienceto, label:   data?.data?.experienceto+` `+(data?.data?.experienceto  > 1? 'Years' : 'Year')})
    }
    //  eslint-disable-next-line
  }, [data?.data, reset]);



  return (
    <div className="w-full  px-4 py-4  flex items-center min-h-[100vh] justify-start bg-lightbrown bg-opacity-5 flex-col gap-2 border shadow-sm">
      <div className="bg-white w-full px-4 py-4 rounded">
        <h2 className="text-center font-semibold text-[20px]">Automate Job Creation with AI-Plug Solutions</h2>
        {isLoading ||isPending||savePending ? <Loader /> : ""}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className=" w-full  pb-5 flex items-center gap-3 justify-start pt-10 flex-col"
        >
         <div className="w-[95%] flex items-center justify-center gap-4 mt-5">
            <label className="w-11/12 flex items-center justify-center  border-b  border-gray-300 ">
              <CiSearch />
              <input
                placeholder="Job Title"
                name="jobtitle"
                {...register("jobtitle")}
                className="w-full outline-none bg-transparent h-[38px] p-2 text-[15px] placeholder-gray-500"
              />
            </label>
        
          </div>
          <div className="w-[95%] flex items-center justify-center gap-4 mt-5">
          <label className="w-[45%] flex items-center justify-center  border-b  border-gray-300 ">
              <Controller
                name="country"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    {...field}
                    styles={customStyles}
                    onChange={(e) => 
                      {field.onChange(e); setCountrySearch({...countrySearch, ...{country_id : e.value}})}
                    }
                    onInputChange={(e) => setCountrySearch({...countrySearch, ...{search : e}})}
                    className="w-full bg-transparent"
                    placeholder="Select country"
                    isLoading={loadcountries}
                    options={Counties?.data}
                  />
                )}
              />
            </label>
            <label className="w-[45%] flex items-center justify-center  border-b  border-gray-300 ">
              <Controller
                name="location"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    {...field}
                    styles={customStyles}
                    onChange={(e) => 
                      {
                          field.onChange(e); setCitiesSearch({...citiesSearch, ...{state_id : e.value}})
                      }
                    }
                    onInputChange={(e) => setCitiesSearch({...citiesSearch, ...{search : e}})}
                    className="w-full bg-transparent"
                    placeholder="Select location"
                    Searchable
                    options={Cities?.data}
                  />
                )}
              />
            </label>
          </div>
          <div className="w-[95%] flex items-center justify-center gap-4 mt-5">
            <label className="w-[45%] flex items-center justify-center  border-b  border-gray-300 ">
              <Controller
                name="experiencefrom"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    {...field}
                    styles={customStyles}
                    className="w-full bg-transparent"
                    placeholder="Experience from"
                    Searchable
                    options={ExperienceLevel}
                  />
                )}
              />
            </label>
            <label className="w-[45%] flex items-center justify-center  border-b  border-gray-300 ">
              <Controller
                name="experienceto"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    {...field}
                    styles={customStyles}
                    className="w-full bg-transparent"
                    placeholder="Experience to"
                    Searchable
                    options={ExperienceLevel}
                    isOptionDisabled={(option) =>
                      Number(option.value) <=
                      Number(getValues("experiencefrom.value"))
                        ? true
                        : false
                    }
                  />
                )}
              />
            </label>
          </div>
          <div className="w-[90%] px-[20px] flex items-center justify-center gap-4 mt-5">
            <label className="w-full flex items-center justify-center  border-b  border-gray-300 ">
              <Controller
                name="skills"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Creatable
                    {...field}
                    styles={customStyles}
                    className="w-full bg-transparent"
                    placeholder="Skills"
                    Searchable
                    isMulti
                  />
                )}
              />
            </label>
          </div>
          <div className="w-[87%]">
            <p className="w-full my-5 border-b  font-semibold border-gray-300 ">
              Job Description
            </p>
            <div
              className="text-[15px]"
              dangerouslySetInnerHTML={{ __html: data?.data?.description }}
            />
          </div>
          <div className="w-[87%]">
            <p className="font-medium text-[16px]">Screening Questions: </p>
            {console.log("Screening Question", data?.screening)}
            {data?.screening?.map((data, index) => {
              return (
                <p className="font-normal text-[14px]" key={data}>
                  {index + 1}. {data?.question}
                </p>
              );
            })}
          </div>
          
          <div className="w-[90%] flex items-center justify-end px-4">
            {watch("jobtitle") &&
            watch("skills") &&
            watch("experienceto") &&
            watch("experiencefrom") ? (
              <div className="flex items-center justify-center gap-2">
                <button
                  type="submit"
                  className="flex items-center justify-center text-[14px] aibutton px-4 py-1 rounded-full text-white hover:scale-90 transition-all"
                >
                  <WiStars size={20} /> Re-Gen
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </form>
        {json ? (
        <div className="w-[95%] border border-gray-200 bg-white p-8 rounded-md gap-3 flex items-start justify-center flex-col">
          <p>
                <button
                  type="submit"
                  onClick={saveJob}
                  className="flex items-center justify-center text-[14px] aibutton px-4 py-1 rounded-full text-white hover:scale-90 transition-all"
                >
                   Save Job
                </button>
          </p>
          <p className="font-medium text-[16px]">
            Job Title:{" "}
            <span className="font-normal text-[14px]">
              {json?.job_description?.jobTitle}
            </span>
          </p>
          <div className="font-medium text-[16px]">
            {" "}
            Job Overview:{" "}
            <div
              className="text-[14px] font-normal mt-3"
              dangerouslySetInnerHTML={{
                __html: json?.job_description?.jobDescription,
              }}
            />{" "}
          </div>
          <p className="font-medium text-[16px]">
            Mandatory Skills:{" "}
            <span className="font-normal text-[14px]">
              {json?.job_description?.mandatorySkillsets}
            </span>
          </p>
          <p className="font-medium text-[16px]">
            Good ToHave Skills:{" "}
            <span className="font-normal text-[14px]">
              {json?.job_description?.goodToHaveSkillsets}
            </span>
          </p>

          <p className="font-medium text-[16px]">Screening Questions: </p>

          {json?.job_description?.screeningQuestions?.map((data, index) => {
            return (
              <p className="font-normal text-[14px]" key={data}>
                {index + 1}. {data}
              </p>
            );
          })}
        </div>
      ) : (
        ""
      )}
      </div>
    </div>
  );
}
