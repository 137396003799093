import { useMutation } from "@tanstack/react-query";
import endpoint from "../../Helpers/endpoint";
import Http from "../../http";

const getSingpleList = ({data, token}) => {
    const URL =  `${endpoint.mapper.mapperDetail}`;
  const headers  = {
    "Authorization": `Bearer ${token}`
  }
  return Http().post(URL, data, headers)
}

export const useMapperDetail = () => {
  return useMutation({
      mutationFn:getSingpleList,
      onSuccess: (data, variables, context) => {
        // I will fire first
      },
      onError: (error, variables, context) => {
        // I will fire first
      },
      onSettled: (data, error, variables, context) => {
        // I will fire first
      },
  });
}