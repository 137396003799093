import moment from "moment";
import React from "react";
import { FaCalendar, FaMapMarkerAlt, FaSuitcase, FaUserFriends, FaUserGraduate } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function CandCampCard({ value }) {
  return (
    <div className="w-full mb-5 bg-gradient-to-r from-white to-white border rounded shadow-lg overflow-hidden transform transition-all duration-500 hover:scale-105">
        <h2 className="text-[16px] px-2 py-2  border-b flex items-center justify-between">
          <div className="flex items-center">
            <FaSuitcase size={18} className="mr-2" /><Link to={`/candidate-public/${value?.campaign_uid}`} > {value?.name} </Link>
          </div>
          <div>
            <span className={`${value?.status==='active'? 'bg-green-600':value?.status==='pending'? `bg-orange-500` : ''} px-2 py-1 text-white rounded-full`}>{value?.status}</span>
          </div>
        </h2>
      <div className="px-3 py-3 space-y-4">
        <div className="flex items-center">
          <p className="opacity-80 w-[50%] flex items-start"><FaUserGraduate size={18} className="mr-1" /> <span>{value?.skills}</span></p>
          {/* Status, Total Source and Date Section */}
          <div className="space-y-2 w-[50%] flex justify-between">
            <div className="flex items-center opacity-80">
              {/* <HiOutlineClipboardList className="mr-2 text-green-300" /> */}
              <FaUserFriends size={18} className="mr-1"  /> <span>{value?.campaign_count}</span>
            </div>
            <div className="flex items-center opacity-80">
              {/* <HiOutlineClipboardList className="mr-2 text-green-300" /> */}
              <FaMapMarkerAlt size={18} className="mr-1" /> <span>Country: {value?.country}</span>
            </div>
            <div className="flex items-center  opacity-80">
              <FaCalendar size={18} className="mr-1" /><span>{moment(value?.created_at).format('ll')}</span>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  );
}
