import React from "react";

export default function Subscriptions() {
  return (
    <div className="flex gap-6 rounded-lg w-[95%]">
      <div className="flex-1 bg-white p-6 rounded-lg shadow-md border border-gray-200">
        <h3 className="text-green-700 text-[16px] font-semibold">
          Advanced plan
        </h3>
        <p className="text-sm mt-2">
          <span className="text-gray-500">Status:</span>{" "}
          <span className="text-green-600 font-medium">Active</span>
        </p>
        <p className="text-sm mt-2">
          <span className="text-gray-500">Trial ends:</span> Nov 29, 2024
        </p>
        <button className="mt-4 px-3 py-1 text-green-700 border border-green-700 rounded-lg hover:bg-green-600 hover:text-white text-[14px]">
          Upgrade
        </button>
      </div>

      <div className="flex-1 bg-white p-6 rounded-lg shadow-md border border-gray-200">
        <div className="flex justify-between items-center">
          <h3 className="text-green-700 text-[16px] font-semibold">
            Email finder
          </h3>
          <span className="text-sm text-gray-500">Free trial</span>
        </div>
        <p className="text-sm mt-2">
          <span className="text-gray-500">Status:</span>{" "}
          <span className="text-green-600 font-medium">Active</span>
        </p>
        <p className="text-sm mt-2">
          <span className="text-gray-500">Credits:</span>{" "}
          <span className="font-medium">100/mo</span>
        </p>
        <button
          className="mt-4 px-3 py-1 text-gray-400 border border-gray-300 rounded-lg cursor-not-allowed text-[14px]"
          disabled
        >
          Upgrade
        </button>
      </div>

      <div className="flex-1 bg-white p-6 rounded-lg shadow-md border border-gray-200">
        <h3 className="text-gray-900 text-[16px] font-semibold">
          Payment details
        </h3>
        <div className="mt-4 bg-gray-100 p-4 rounded-lg">
          <p className="text-sm text-gray-500">
            You don’t have an active billing account
          </p>
        </div>
      </div>
    </div>
  );
}
