import React, { useState } from "react";
import Header from "./Header";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Http from "../../http";
import endpoint from "../../Helpers/endpoint";
import { useQuery } from "@tanstack/react-query";
import { useGlobalContext } from "../../Context/ContextWrapper";
import { Spinner } from "../Loader/Spinner";

export default function Website() {
  const [plan, setProduct] = useState("Monthly");
  const getprodcutplan = () => {
    const URL = `${process.env.REACT_APP_APIENDPOINT}${endpoint.plan.plan}/${plan}`;
    return Http().get(URL);
  };
  const { data: plandata, isLoading } = useQuery({
    queryKey: ["getproductplan", plan],
    queryFn: getprodcutplan,
  });

  const { currency } = useGlobalContext();

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} next-arrow-slider`}
        style={{ ...style }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} prev-arrow-slider`}
        style={{ ...style }}
        onClick={onClick}
      />
    );
  }

  var settingsforTestimonial = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          centerPadding: "60px",
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const setting = {
    dots: false,
    infinite: true,
    slidesToShow: 10,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 1000,
    cssEase: "linear",
  };

  return (
    <>
      <Header />
      <section className="bg-gradient-to-r from-green-100 to-green-100 mt-[70px] font-sans  h-[650px] max-sm:h-[auto] relative">
        <video
          autoPlay
          muted
          loop
          id="myVideo"
          className="max-sm:h-[400px] object-cover"
        >
          <source
            src="https://www.sahaye.com/assets/sahaye.mp4"
            type="video/mp4"
          />
        </video>
        <div className="container max-sm:px-3 m-[auto] ">
          <div className="w-[40%] max-sm:w-[90%] py-[200px] max-sm:py-[80px] text-white absolute top-0 max-sm:left-[15px] max-sm:right-[15px]">
            <h1 className="text-[22px] font-sans font-bold text-white ">
              Intelligent CoffeeCrew Talent Platform
            </h1>
            <h1 className="text-[40px] max-sm:text-[25px] font-sans max-sm:w-full">
              Fast, Personalized Recruiting–At Scale
            </h1>
            <p className="max-sm:hidden">
              Accelerate hiring, automate engagement, and exceed recruiting
              targets - all while delivering a personalized candidate
              experience.
            </p>
            <div className="py-8">
              <Link
                to="/demo-request"
                className="text-white bg-[#3c81f4] px-6 py-2 rounded-full inline-block"
              >
                Book A Demo
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/*  */}
      <section className="py-[150px] max-sm:py-[50px]">
        <div className="container max-sm:px-3 m-[auto]">
          <div className="py-[50px]">
            <p className="text-[22px] text-center">
              {process.env.REACT_APP_APP_NAME} accelerates hiring for 1,000+
              market leaders.{" "}
              <Link to="/customer-stories" className="linktxt-blur">
                See how →
              </Link>
              <br />
            </p>
          </div>
        </div>
        <div className="">
          <Slider {...setting}>
            <img
              src="https://cdn.prod.website-files.com/613f2494a7d5cd1817022b81/64427a1027bb497c04410bdc_Coca-Cola_logo%201.png"
              alt="Coca Cola"
              className="w-[150px] h-[80px] object-contain py-3 px-2"
            />
            <img
              src="https://cdn.prod.website-files.com/613f2494a7d5cd1817022b81/667e9cb234449850fa709bf7_hca-healthcare-logo%20(1).webp"
              alt="Amazon"
              className="w-[150px] h-[80px] object-contain opy-3 px-2"
            />
            <img
              src="https://cdn.prod.website-files.com/613f2494a7d5cd1817022b81/6695273951f2fa07d38e6a92_carvana-logo.webp"
              alt="Sony"
              className="w-[150px] h-[80px] object-contain py-3 px-2"
            />
            <img
              src="https://cdn.prod.website-files.com/613f2494a7d5cd1817022b81/643f9318b0b60a3cb4725f74_image%2021.png"
              alt="Staffmark Group"
              className="w-[150px] h-[80px] object-contain py-3 px-2"
            />
            <img
              src="https://cdn.prod.website-files.com/613f2494a7d5cd1817022b81/66308efb20998471c2c30e27_662b94e083cfeb99936457b7_DellTech_Logo_Prm_Blue_rgb-p-500.png"
              alt="Dell"
              className="w-[150px] h-[80px] object-contain py-3 px-2"
            />
            <img
              src="https://cdn.prod.website-files.com/613f2494a7d5cd1817022b81/64427a52f35afaf07ada75af_hormel-foods%201.png"
              alt="Hormel Foods"
              className="w-[150px] h-[80px] object-contain py-3 px-2"
            />
            <img
              alt=""
              src="https://cdn.prod.website-files.com/613f2494a7d5cd1817022b81/66952831e3d8811a884e0d76_bath-body-works1-logo.webp"
              sizes="142px"
              className="w-[150px] h-[80px] object-contain py-3 px-2"
            />
            <img
              src="https://cdn.prod.website-files.com/613f2494a7d5cd1817022b81/641405930eac7c7103f23150_Image_93-re.webp"
              alt="Kelly"
              className="w-[150px] h-[80px] object-contain py-3 px-2"
            />
            <img
              src="https://cdn.prod.website-files.com/613f2494a7d5cd1817022b81/669526bb7d1fbb542197f834_insight-global.webp"
              alt="Core Medical Group"
              className="w-[150px] h-[80px] object-contain py-3 px-2"
            />
            <img
              src="https://cdn.prod.website-files.com/613f2494a7d5cd1817022b81/644901fa9e63b841c88a8772_scion-health.png"
              sizes="160px"
              alt=""
              className="w-[150px] h-[80px] object-contain py-3 px-2"
            />
            <img
              src="https://cdn.prod.website-files.com/613f2494a7d5cd1817022b81/64427a1027bb497c04410bdc_Coca-Cola_logo%201.png"
              alt="Coca Cola"
              className="w-[150px] h-[80px] object-contain py-3 px-2"
            />
            <img
              src="https://cdn.prod.website-files.com/613f2494a7d5cd1817022b81/667e9cb234449850fa709bf7_hca-healthcare-logo%20(1).webp"
              alt="Amazon"
              className="w-[150px] h-[80px] object-contain opy-3 px-2"
            />
            <img
              src="https://cdn.prod.website-files.com/613f2494a7d5cd1817022b81/6695273951f2fa07d38e6a92_carvana-logo.webp"
              alt="Sony"
              className="w-[150px] h-[80px] object-contain py-3 px-2"
            />
            <img
              src="https://cdn.prod.website-files.com/613f2494a7d5cd1817022b81/643f9318b0b60a3cb4725f74_image%2021.png"
              alt="Staffmark Group"
              className="w-[150px] h-[80px] object-contain py-3 px-2"
            />
            <img
              src="https://cdn.prod.website-files.com/613f2494a7d5cd1817022b81/66308efb20998471c2c30e27_662b94e083cfeb99936457b7_DellTech_Logo_Prm_Blue_rgb-p-500.png"
              alt="Dell"
              className="w-[150px] h-[80px] object-contain py-3 px-2"
            />
            <img
              src="https://cdn.prod.website-files.com/613f2494a7d5cd1817022b81/64427a52f35afaf07ada75af_hormel-foods%201.png"
              alt="Hormel Foods"
              className="w-[150px] h-[80px] object-contain py-3 px-2"
            />
            <img
            alt=""
              src="https://cdn.prod.website-files.com/613f2494a7d5cd1817022b81/66952831e3d8811a884e0d76_bath-body-works1-logo.webp"
              sizes="142px"
              className="w-[150px] h-[80px] object-contain py-3 px-2"
            />
            <img
              src="https://cdn.prod.website-files.com/613f2494a7d5cd1817022b81/641405930eac7c7103f23150_Image_93-re.webp"
              alt="Kelly"
              className="w-[150px] h-[80px] object-contain py-3 px-2"
            />
            <img
              src="https://cdn.prod.website-files.com/613f2494a7d5cd1817022b81/669526bb7d1fbb542197f834_insight-global.webp"
              alt="Core Medical Group"
              className="w-[150px] h-[80px] object-contain py-3 px-2"
            />
            <img
            alt=""
              src="https://cdn.prod.website-files.com/613f2494a7d5cd1817022b81/644901fa9e63b841c88a8772_scion-health.png"
              sizes="160px"
              className="w-[150px] h-[80px] object-contain py-3 px-2"
            />
          </Slider>
        </div>
      </section>
      {/*  */}

      {/*  */}
      <section className="py-[80px] mostpuplarimage">
        <div className="container max-sm:px-3 m-[auto]">
          <div className="flex justify-between pb-5 text-white flex-wrap">
            <div className="text-[40px] max-sm:text-[25px]">
              Most Popular Service
            </div>
            <div className="max-sm:text-[16px]">
              Undertakes laborious physical exercise except to obtain some
              advantage from it?
            </div>
          </div>
          <div className="flex flex-wrap justify-between">
            <div className="p-5 w-[32%] max-sm:w-full mb-5  bg-[#e2d1f8] hover:border border-[#e0e0e0] rounded-[15px] cursor-pointer duration-300 hover:translate-x-2 hover:translate-y-1 hover:shadow-[_5px_7px_0px_0px_#fff]">
              <Link className="text-[22px] py-5 inline-block">
                Content Generation
              </Link>
              <p>
                Toil and pain can procure him some of great pleasure take a
                trivial examples, which of us ever undertakes.
              </p>
              <Link className="py-5 inline-block">
                Read More
              </Link>
            </div>
            <div className="p-5 w-[32%] max-sm:w-full mb-5  bg-[#e2d1f8] hover:border border-[#e0e0e0] rounded-[15px] cursor-pointer duration-300 hover:translate-x-2 hover:translate-y-1 hover:shadow-[_5px_7px_0px_0px_#fff]">
              <Link className="text-[22px] py-5 inline-block">
                Content Generation
              </Link>
              <p>
                Toil and pain can procure him some of great pleasure take a
                trivial examples, which of us ever undertakes.
              </p>
              <Link className="py-5 inline-block">
                Read More
              </Link>
            </div>
            <div className="p-5 w-[32%] max-sm:w-full mb-5  bg-[#e2d1f8] hover:border border-[#e0e0e0] rounded-[15px] cursor-pointer duration-300 hover:translate-x-2 hover:translate-y-1 hover:shadow-[_5px_7px_0px_0px_#fff]">
              <Link className="text-[22px] py-5 inline-block">
                Content Generation
              </Link>
              <p>
                Toil and pain can procure him some of great pleasure take a
                trivial examples, which of us ever undertakes.
              </p>
              <Link className="py-5 inline-block">
                Read More
              </Link>
            </div>
            <div className="p-5 w-[32%] max-sm:w-full mb-5  bg-[#e2d1f8] hover:border border-[#e0e0e0] rounded-[15px] cursor-pointer duration-300 hover:translate-x-2 hover:translate-y-1 hover:shadow-[_5px_7px_0px_0px_#fff]">
              <Link className="text-[22px] py-5 inline-block">
                Content Generation
              </Link>
              <p>
                Toil and pain can procure him some of great pleasure take a
                trivial examples, which of us ever undertakes.
              </p>
              <Link className="py-5 inline-block">
                Read More
              </Link>
            </div>
            <div className="p-5 w-[32%] max-sm:w-full mb-5  bg-[#e2d1f8] hover:border border-[#e0e0e0] rounded-[15px] cursor-pointer duration-300 hover:translate-x-2 hover:translate-y-1 hover:shadow-[_5px_7px_0px_0px_#fff]">
              <Link className="text-[22px] py-5 inline-block">
                Content Generation
              </Link>
              <p>
                Toil and pain can procure him some of great pleasure take a
                trivial examples, which of us ever undertakes.
              </p>
              <Link className="py-5 inline-block">
                Read More
              </Link>
            </div>
            <div className="p-5 w-[32%] max-sm:w-full mb-5  bg-[#e2d1f8] hover:border border-[#e0e0e0] rounded-[15px] cursor-pointer duration-300 hover:translate-x-2 hover:translate-y-1 hover:shadow-[_5px_7px_0px_0px_#fff]">
              <Link className="text-[22px] py-5 inline-block">
                Content Generation
              </Link>
              <p>
                Toil and pain can procure him some of great pleasure take a
                trivial examples, which of us ever undertakes.
              </p>
              <Link className="py-5 inline-block">
                Read More
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/*  */}

      {/*  */}
      <section className="py-[80px]] bg-[#0d0b15] max-sm:py-[50px]">
        <div className="bg py-[70px] max-sm:py-0 choosingaiservice">
          <div className="container max-sm:px-3 m-[auto]">
            <div className="flex justify-between flex-wrap">
              <div className="p-5 w-[32%] text-white max-sm:w-full">
                <Link
                
                  className="text-[35px] py-5 inline-block max-sm:text-[20px]"
                >
                  Reasons to Choosing Our AI Service
                </Link>
                <p>
                  Undertakes laborious physical exercise except to obtain some
                  advantage from it.
                </p>
              </div>
              <div className="p-5 w-[32%] max-sm:w-full mb-5 rounded border border-white text-white duration-500 hover:scale-105 hover:shadow  cursor-pointer">
                <div className="bg-white rounded-[50%] w-[80px] h-[80px] cursor-pointer">
                  <img
                    src="/public/images/icon/icon-27.png"
                    className="text-center px-[20px] py-[20px]"
                    alt=""
                  />
                </div>
                <Link className="text-[22px] py-5  inline-block">
                  10X Output
                </Link>
                <p>Dolore magnam aliquam quaer autem enim ad minima veniam.</p>
              </div>

              <div className="p-5 w-[32%] max-sm:w-full mb-5 rounded border border-white text-white duration-500 hover:scale-105 hover:shadow  cursor-pointer">
                <div className="bg-white rounded-[50%] w-[80px] h-[80px] cursor-pointer">
                  <img
                    src="/images/icon/icon-27.png"
                    className="text-center px-[20px] py-[20px]"
                    alt=""
                  />
                </div>
                <Link className="text-[22px] py-5 inline-block">
                  Multilingual Support
                </Link>
                <p>Dolore magnam aliquam quaer autem enim ad minima veniam.</p>
              </div>
              <div className="p-5 w-[32%] max-sm:w-full mb-5 rounded border border-white text-white duration-500 hover:scale-105 hover:shadow  cursor-pointer">
                <div className="bg-white rounded-[50%] w-[80px] h-[80px] cursor-pointer">
                  <img
                    src="/images/icon/icon-27.png"
                    className="text-center px-[20px] py-[20px]"
                    alt=""
                  />
                </div>
                <Link className="text-[22px] py-5 inline-block">
                  Time-saving Editing Tools
                </Link>
                <p>Dolore magnam aliquam quaer autem enim ad minima veniam.</p>
              </div>
              <div className="p-5 w-[32%] max-sm:w-full mb-5 rounded border border-white text-white duration-500 hover:scale-105 hover:shadow  cursor-pointer">
                <div className="bg-white rounded-[50%] w-[80px] h-[80px] cursor-pointer">
                  <img
                    src="/images/icon/icon-27.png"
                    className="text-center px-[20px] py-[20px]"
                    alt=""
                  />
                </div>
                <Link className="text-[22px] py-5 inline-block">
                  Continuous Improvement
                </Link>
                <p>Dolore magnam aliquam quaer autem enim ad minima veniam.</p>
              </div>
              <div className="p-5 w-[32%] max-sm:w-full mb-5 rounded border border-white text-white duration-500 hover:scale-105 hover:shadow  cursor-pointer">
                <div className="bg-white rounded-[50%] w-[80px] h-[80px] cursor-pointer">
                  <img
                    src="/images/icon/icon-27.png"
                    className="text-center px-[20px] py-[20px]"
                    alt=""
                  />
                </div>
                <Link className="text-[22px] py-5 inline-block">
                  Efficiency & Consistency
                </Link>
                <p>Dolore magnam aliquam quaer autem enim ad minima veniam.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Whats client says */}

      <section className="py-[70px]">
        <div className="container max-sm:px-3 m-[auto]">
          <div>
            <h3 className="text-center text-[40px] max-sm:text-[25px]">
              What Our Users Say About Us
            </h3>
          </div>
          <Slider {...settingsforTestimonial}>
            <div className=" max-sm:w-full p-5 border rounded-[10px] bg-green-700 text-white">
              <div className="w-full text-center pb-[30px]">
                <img alt="" src="/images/clients-3.png" className="m-[auto] pt-5" />
              </div>
              <div>
                <h3 className="text-[22px] py-2">My Favorite Tool</h3>
                <p>
                  The claims of duty or the obligations of business it will
                  frequently occur pleasures repudiated and annoyances.
                </p>
              </div>
              <div className="flex py-[30px] justify-between">
                <img alt="" src="/images/testimonial/testimonial-3.png" />
                <div>
                  <h3>Jack Oliver</h3>
                  <p>Project Head - Data robot</p>
                </div>
              </div>
            </div>
            <div className=" max-sm:w-full p-5 border rounded-[10px] bg-green-700 text-white">
              <div className="w-full text-center pb-[30px]">
                <img alt="" src="/images/clients-3.png" className="m-[auto] pt-5" />
              </div>
              <div>
                <h3 className="text-[22px] py-2">My Favorite Tool</h3>
                <p>
                  The claims of duty or the obligations of business it will
                  frequently occur pleasures repudiated and annoyances.
                </p>
              </div>
              <div className="flex py-[30px] justify-between">
                <img alt="" src="/images/testimonial/testimonial-3.png" />
                <div>
                  <h3>Jack Oliver</h3>
                  <p>Project Head - Data robot</p>
                </div>
              </div>
            </div>
            <div className=" max-sm:w-full p-5 border rounded-[10px] bg-green-700 text-white">
              <div className="w-full text-center pb-[30px]">
                <img alt="" src="/images/clients-3.png" className="m-[auto] pt-5" />
              </div>
              <div>
                <h3 className="text-[22px] py-2">My Favorite Tool</h3>
                <p>
                  The claims of duty or the obligations of business it will
                  frequently occur pleasures repudiated and annoyances.
                </p>
              </div>
              <div className="flex py-[30px] justify-between">
                <img alt="" src="/images/testimonial/testimonial-3.png" />
                <div>
                  <h3>Jack Oliver</h3>
                  <p>Project Head - Data robot</p>
                </div>
              </div>
            </div>
            <div className=" max-sm:w-full p-5 border rounded-[10px] bg-green-700 text-white">
              <div className="w-full text-center pb-[30px]">
                <img alt="" src="/images/clients-3.png" className="m-[auto] pt-5" />
              </div>
              <div>
                <h3 className="text-[22px] py-2">My Favorite Tool</h3>
                <p>
                  The claims of duty or the obligations of business it will
                  frequently occur pleasures repudiated and annoyances.
                </p>
              </div>
              <div className="flex py-[30px] justify-between">
                <img alt="" src="/images/testimonial/testimonial-3.png" />
                <div>
                  <h3>Jack Oliver</h3>
                  <p>Project Head - Data robot</p>
                </div>
              </div>
            </div>
            <div className=" max-sm:w-full p-5 border rounded-[10px] bg-green-700 text-white">
              <div className="w-full text-center pb-[30px]">
                <img alt="" src="/images/clients-3.png" className="m-[auto] pt-5" />
              </div>
              <div>
                <h3 className="text-[22px] py-2">My Favorite Tool</h3>
                <p>
                  The claims of duty or the obligations of business it will
                  frequently occur pleasures repudiated and annoyances.
                </p>
              </div>
              <div className="flex py-[30px] justify-between">
                <img alt="" src="/images/testimonial/testimonial-3.png" />
                <div>
                  <h3>Jack Oliver</h3>
                  <p>Project Head - Data robot</p>
                </div>
              </div>
            </div>
            <div className=" max-sm:w-full p-5 border rounded-[10px] bg-green-700 text-white">
              <div className="w-full text-center pb-[30px]">
                <img alt="" src="/images/clients-3.png" className="m-[auto] pt-5" />
              </div>
              <div>
                <h3 className="text-[22px] py-2">My Favorite Tool</h3>
                <p>
                  The claims of duty or the obligations of business it will
                  frequently occur pleasures repudiated and annoyances.
                </p>
              </div>
              <div className="flex py-[30px] justify-between">
                <img alt="" src="/images/testimonial/testimonial-3.png" />
                <div>
                  <h3>Jack Oliver</h3>
                  <p>Project Head - Data robot</p>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </section>
      {/*  */}

      {/* Price Plane */}
      <section className="py-[70px]">
        <div className="container max-sm:px-3 m-[auto]">
          <div className="text-center">
            <h3 className="text-[40px]">Affordable Pricing Plans</h3>
            <p>Nor again is there anyone who loves or pursues desires.</p>
          </div>
          <div className="text-center mt-5">
            <div className="inline-flex bg-green-300  border-2 border-[green] rounded-full">
              <div
                className={`py-2 px-3 cursor-pointer ${
                  plan === "Monthly"
                    ? `bg-green-700 rounded-l-full text-[white]`
                    : ``
                }`}
                onClick={() => setProduct("Monthly")}
              >
                Monthly
              </div>
              <div className="border border-[black]"></div>
              <div
                className={`py-2 px-3 cursor-pointer ${
                  plan === "Yearly"
                    ? `bg-green-700 rounded-r-full text-[white]`
                    : ``
                }`}
                onClick={() => setProduct("Yearly")}
              >
                Yearly
              </div>
            </div>
          </div>
          <div className="flex py-[70px] justify-between items-center flex-wrap">
            {/* First */}
            {plandata?.data?.data.length === 0 ? `${plan} plan not found` : ""}
            {isLoading ? (
              <div className="text-center w-full">
                <Spinner />{" "}
              </div>
            ) : (
              ""
            )}
            {plandata?.data?.data.map((row, index) => {
              let pricedata = JSON.parse(row?.currency);
              let newprice = pricedata.filter(
                (mony) => mony?.currency === currency?.code
              );
              return (
                <div className="text-white bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-green-200 dark:focus:ring-green-800  w-[32%] max-sm:mb-[50px] max-sm:w-full border relative rounded-[10px] cursor-pointer hover:border-2 border-[green] hover:bg-green-100 ease-in-out duration-300 hover:scale-105 hover:shadow-[5px_5px_5px_5px_#e0e0e0]">
                  <div className="before:content-[attr(before)]">
                    <div className="text-center">
                      <div className="w-[100px] cursor-pointer hover:bg-[#e0e0e0] h-[100px] mt-[-40px] bg-white text-center inline-block  border rounded-[50%]">
                        <img
                        alt=""
                          src="/images/icon/icon-33.png"
                          className="py-[30px] px-[35px] text-center"
                        />
                      </div>
                    </div>
                    <div className="p-5">
                      <h3 className="text-[30px] font-bold py-3 text-center">
                        {row?.productname}
                      </h3>
                      <p className="pb-6 text-center">{row?.description}</p>
                      <div className="block text-center pb-5">
                          {/* <del className="text-[14px] text-[red]">
                            {Number(
                              newprice[0]?.original_price
                            ).toLocaleString()}
                          </del>{" "} */}

                          <sup className="text-[14px]">{newprice[0]?.currency} </sup>
                          <span className="text-[30px] font-bold">{Number(newprice[0]?.sale_price).toLocaleString()}{" "}</span>
                          <sub className="text-[14px]">/per {plan.toLowerCase()}</sub>
                      </div>
                      <div className="text-center my-3 mb-[30px]">
                        <Link to={`/cartproduct/${row?.productuid}`} className="text-white text-[20px] bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 shadow-lg shadow-green-500/50 dark:shadow-lg dark:shadow-green-800/80 rounded-lg px-5 py-2.5 text-center me-2 mb-2">Subscribe Now</Link>
                      </div>
                      {/* <div className="bg-[#e0e0e0] flex items-center justify-between py-2 my-3 mb-6 px-2 rounded">
                        <div className="text-[#3b0764] text-[22px] font-bold">
                          <del className="text-[14px] text-[red]">
                            {Number(
                              newprice[0]?.original_price
                            ).toLocaleString()}
                          </del>{" "}
                            <span className="text-[14px]">
                            {newprice[0]?.currency} {Number(newprice[0]?.sale_price).toLocaleString()}{" "}
                            </span>
                        </div>
                        <Link
                          to={`/cartproduct/${row?.productuid}`}
                          className="hover:bg-green-100 cursor-pointer py-2 px-2 border border-[green] rounded"
                        >
                          Choose Plan
                        </Link>
                      </div> */}
                      <ul className="priceplanlist">
                        <li>Generate 10k Words</li>
                        <li>40+ Use Cases</li>
                        <li>20+ Language</li>
                        <li>50+ templates</li>
                        <li>1 Brand Voice</li>
                        <li>Generate upto 5 images</li>
                      </ul>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      {/* Contact */}
      <section className="conatactbg py-[100px]">
        <div className="container max-sm:px-3 m-[auto]">
          <div className="flex items-center flex-wrap">
            <div className="w-[40%]  max-sm:w-full text-white">
              <h2 className="text-[40px] max-sm:text-[25px]">
                Let's Collaborate & Discuss Your Project
              </h2>
              <p className="text-[16px]">I want to discuss;</p>
              <ul className="py-[50px]">
                <li className="py-2">
                  <input type="checkbox" /> <span> User Experience</span>
                </li>
                <li className="py-2">
                  <input type="checkbox" />
                  <span> Quality & Reliability</span>
                </li>
                <li className="py-2">
                  <input type="checkbox" />
                  <span> Collaboration</span>
                </li>
                <li className="py-2">
                  <input type="checkbox" />
                  <span> Legal and Copyright</span>
                </li>
              </ul>
            </div>
            <div className="w-[60%]  max-sm:w-full">
              <div className="px-[100px] max-sm:px-0">
                <div className="bg-white border py-5 px-5 rounded-[10px]">
                  <form>
                    <div className="mb-5">
                      <label className="flex mb-3">
                         Your Name
                      </label>
                      <input
                        type="text"
                        className="border rounded w-full py-3 px-3"
                        placeholder="Enter your name"
                      />
                    </div>
                    <div className="mb-5">
                      <label className="flex mb-3">
                        Email Address
                      </label>
                      <input
                        type="text"
                        className="border rounded w-full py-3 px-3"
                        placeholder="Email Address"
                      />
                    </div>
                    <div className="mb-5">
                      <label className="flex mb-3">
                         Message
                      </label>
                      <textarea
                        type="text"
                        rows={5}
                        className="border rounded w-full py-3 px-3"
                        placeholder="Email Address"
                      />
                    </div>
                    <div className="mb-5">
                      <button
                        type="submit"
                        className="bg-[#000] text-white block w-full rounded py-4"
                      >
                        Send Your Message
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
