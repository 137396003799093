import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { BsFillPatchQuestionFill } from "react-icons/bs";
import { FaTimes, FaTrash } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import endpoint from "../../../Helpers/endpoint";
import Token from "../../../Helpers/Token";
import { useNormalization, useNormalizationDelete } from "../../../hooks/apihooks/useNormalization";
import Http from "../../../http";
import AlertConfirm from "../../Alert/AlertComfirm";
import { Spinner } from "../../Loader/Spinner";

export default function NormalizeData({ openNoramal, setOpenNormal, campainuid, wrapNormalizeRef}) {
  const [spinner, setSpiner]  = useState(false);
  const [managetab, setManageTab] = useState(false);
  const [normalizationTab, setNormalizationTab] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [resolvePromise, setResolvePromise] = useState(null); // to hold the promise resolution function
  const [message, setMessage] = useState({title : "", shortDesc : ""});
  const {mutateAsync} = useNormalization();
  const {mutateAsync:deleteMutateAsync} = useNormalizationDelete();

  const {register, handleSubmit, setValue, watch} = useForm({
    defaultValues: {
      specialization: "",
    },
  });


  // Function to show the confirmation dialog and hold execution
  const customConfirm = (title, shortDesc) => {
    setMessage({title, shortDesc});
    setIsDialogOpen(true);
    return new Promise((resolve, reject) => {
      setResolvePromise(() => resolve); // Store the resolve function to be called later
    });
  }

  // Handle when user clicks "Yes"
  const handleConfirm = () => {
    setIsDialogOpen(false);
    if (resolvePromise) {
      resolvePromise(true); // Resolve the promise with true (user confirmed)
    }
  };

  // Handle when user clicks "No"
  const handleCancel = () => {
    setIsDialogOpen(false);
    if (resolvePromise) {
      resolvePromise(false); // Resolve the promise with false (user canceled)
    }
  };


  const runNormalization  = (formData) => {
    setSpiner(true);
    let data = {
      specialization : formData?.specialization,
      campainuid : campainuid,
    }
    mutateAsync({data, token: Token.getAccessToken()}).then((resp) => {
      setTimeout(() => {
        setOpenNormal(false);
        setSpiner(false);
      }, 2000);
    })
  }

  const selectSpecilization = (data) => {
    setValue('specialization', data);
  }

  const specialization  = [
    'Please find candidates who have a PMP certification.',
    'Please find candidates with mandatory skills in Java and Spring Boot.',
    'Please find candidates living within 80 km of Gurugram, Haryana.',
    'Please find all Male candidates?'
  ];


  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (event.shiftKey) {
        // Allow Shift + Enter to create a newline
        return;
      }
      event.preventDefault(); // Prevents default behavior (e.g., new line in textarea)
      handleSubmit(runNormalization)(); // Trigger the form submission
    }
  };

  const removeMatch = async(questionid) => {
    const result = await customConfirm('Delete Question', 'Are you sure you want to delete this question remove you normalization')
    if(result){
      deleteMutateAsync({questionid, token : Token.getAccessToken()}).then((resp) => {
        console.log("Data delete", resp);
      })

    }

  }

  const getcampaingNorm = () => {
    const URL = `${endpoint.normalization.addNormalization}/${campainuid}`;
    return Http().get(URL, {
      'Authorization' : `Bearer ${Token.getAccessToken()}`,
    });
  }

  const {data} = useQuery({queryKey: ['getNormalization', campainuid], queryFn : getcampaingNorm});

  return (
    <>
    <AlertConfirm wrapNormalizeRef={wrapNormalizeRef}  title={message.title}  shortDesc={message.shortDesc} isDialogOpen={isDialogOpen} onConfirm={handleConfirm} onCancel={handleCancel} />
  <div
      className={`fixed top-0 right-0 w-[30%] shadow-lg h-[100vh] overflow-auto bg-[white] z-10 transform  transition-transform duration-500 ease-in-out  ${openNoramal ? ' translate-x-0 ' : 'translate-x-full'}`}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      ref={wrapNormalizeRef}
  >
    
    {spinner ? <Spinner size="medium" />:""}
    <div className="">
      <div className="flex min-h-full items-end justify-center text-center sm:items-center relative">
        <div className="relative transform text-left transition-all sm:w-full ">
          <div className="w-full break-words">
            <div className="sm:flex flex-col rounded sm:items-start w-full">
              <button className="" onClick={() => setOpenNormal(false)}><FaTimes  size={30} className="px-2 py-2 text-[black] border rounded ml-3 mt-4 hover:bg-gray-300 hover:border-black" /></button>
            </div>
            <div className="px-3">
              <form onSubmit={handleSubmit(runNormalization)}  onKeyDown={handleKeyDown}>
              <div className="py-2">
                <div className="mb-2">
                  <label className="mb-2">Suggested Question:</label>
                  <div className="flex flex-wrap">
                    {specialization.map((row) => (
                      <div className={`${watch('specialization')===row?'bg-green-600 text-white':''} px-2 py-1 text-[13px] cursor-pointer flex border items-center rounded mr-2 mb-1`} onClick={() => selectSpecilization(row)}><BsFillPatchQuestionFill  className="mr-1"/>{row}</div>
                    ))}
                  </div>
                </div>
                <div class="flex w-full cursor-text flex-col rounded-3xl px-2.5 py-1 transition-colors contain-inline-size bg-green-100 dark:bg-token-main-surface-secondary">
                    <div className="">
                      <textarea 
                      {...register("specialization", {required:true})}
                      className="w-full bg-green-100 outline-none mt-2 px-2 py-2 resize-none max-h-max"
                      placeholder="Enter your prompt here" value={watch('specialization')}
                      ></textarea>
                    </div>
                    <div class="flex h-[44px] items-center justify-end">
                      <span data-state="closed">
                        <button disabled="" aria-label="Send prompt" data-testid="send-button" class="flex h-8 w-8 items-center justify-center rounded-full transition-colors hover:opacity-70 focus-visible:outline-none focus-visible:outline-black disabled:text-[#f4f4f4] disabled:hover:opacity-100 dark:focus-visible:outline-white disabled:dark:bg-token-text-quaternary dark:disabled:text-token-main-surface-secondary bg-black text-white dark:bg-white dark:text-black disabled:bg-[#D7D7D7]">
                          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-2xl"><path fill-rule="evenodd" clip-rule="evenodd" d="M15.1918 8.90615C15.6381 8.45983 16.3618 8.45983 16.8081 8.90615L21.9509 14.049C22.3972 14.4953 22.3972 15.2189 21.9509 15.6652C21.5046 16.1116 20.781 16.1116 20.3347 15.6652L17.1428 12.4734V22.2857C17.1428 22.9169 16.6311 23.4286 15.9999 23.4286C15.3688 23.4286 14.8571 22.9169 14.8571 22.2857V12.4734L11.6652 15.6652C11.2189 16.1116 10.4953 16.1116 10.049 15.6652C9.60265 15.2189 9.60265 14.4953 10.049 14.049L15.1918 8.90615Z" fill="currentColor"></path></svg>
                        </button>
                      </span>
                    </div>
                </div>
              </div>
              </form>
            </div>
            <div className="px-3">
                <div>
                  <div className="flex justify-between items-center border rounded px-2 py-1 cursor-pointer">
                    <span className="">Manage Normalization:</span>
                    <span className=""><IoIosArrowDown onClick={() => setManageTab(!managetab)} /></span>
                  </div>

                  <div className={`${managetab?'':'hidden'} px-2 py-1 border`}>
                    {data?.data?.map((row,index) => (
                      <>
                      <div className="mb-2">
                        <div className={`${index===normalizationTab?'bg-green-400':''} px-2 hover:bg-green-400 w-full py-1 text-[13px] cursor-pointer flex justify-between border items-center rounded mr-2`}  onClick={() => setNormalizationTab(index)} >
                          <div className="flex items-center"><BsFillPatchQuestionFill size={10} className="mr-1"/> <span>Normalization #{index+1}</span></div>
                          <div className="flex items-center"><span className="mr-2">Match : {row?.candidate_id.split(',').length}</span> <FaTrash className="text-[red]" onClick={() => removeMatch(row?.id)} /></div>
                        </div>
                        <div className={`${normalizationTab===index? 'show':'hidden'} border border-t-0 px-2 py-1 bg-gray-100 `}>{row?.question}</div>
                      </div>
                      </>
                    ))}
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </>
  );
}
