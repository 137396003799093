import { useForm } from "react-hook-form";
import { useWorklist } from "../../../hooks/apihooks/useWorklist";
import Token from "../../../Helpers/Token";
import { Spinner } from "../../Loader/Spinner";
import { toast } from "react-toastify";

export default function CreateToList({ createTolist, sourcedataid, setCreateTolist, wrapWorklistPopupRef}) {
  const {mutateAsync, isPending} = useWorklist();
  const {register, handleSubmit} = useForm();
  const onSubmit = (data) =>{
    let payload = {
      label: data?.label,
      sourcedataid,
    }
    mutateAsync({payload, token : Token.getAccessToken()}).then((resp) => {
      if(resp?.status===true){
        setCreateTolist(false)
        toast.success(resp?.message);
      }
    });
  }

  return (
    <>
    {isPending? <Spinner size="medium" /> : ""}
    <div
      className={`relative z-10  ${createTolist ? `` : `hidden tansition-all`}`}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>

      <div className="fixed  inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className=" w-[500px] absolute top-20"  ref={wrapWorklistPopupRef}>
            <button
              type="button"
              className="bg-[red] absolute z-50 text-white right-[-10px] top-[-10px] rounded-full h-[30px] w-[30px]"
              onClick={() => setCreateTolist(false)}
            >
              X
            </button>
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 w-full rounded transition-all duration-[1000ms] h-auto">
              <h3
                className="text-[18px] font-semibold leading-6 text-gray-900"
                id="modal-title"
              >
                Create work to List              
              </h3>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="w-full mb-2 text-left">
                  <label>Label Name</label>
                  <input type="text" {...register('label', {required:true})} className="w-full border px-2 py-2" placeholder="Enter work list label" />
                </div>
                <div className="w-full ">
                  <button type="submit" className="border rounded  bg-green-600 text-white px-2 py-1" >Create</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}
