import { IoCloudUploadOutline } from "react-icons/io5";
import {
  useCopyPastJob,
  useDocumentUploadJob,
} from "../../../hooks/apihooks/useParseJob";
import { useState } from "react";
import Token from "../../../Helpers/Token";
import { toast } from "react-toastify";
import { FaEdit } from "react-icons/fa";
import { useAddJob } from "../../../hooks/apihooks/jobs/useAddJob";
import { Spinner } from "../../Loader/Spinner";
export default function ParserJob({ parsePopup, setOpenParse }) {
  const { mutateAsync, isPending } = useDocumentUploadJob();
  const { mutateAsync: mutateCopyPast } = useCopyPastJob();
  const [editJobDetail, seteditJobDetail] = useState(false);
  const [tabType, settabType] = useState("upload");
  const [jobData, setJobData] = useState("");
  const [file, setFile] = useState(null);
  const [data, setData] = useState({});
  const { isPending: isloaddata, mutateAsync: mutateAsyncAddJob } = useAddJob();

  const handleFileChange = (e) => {
    setFile(e.target.files[0]); // Get the selected file
  };

  const onSubmitHandle = (e) => {
    e.preventDefault();
    if (tabType === "upload") {
      const formData = new FormData();
      formData.append("file", file); // Append file
      mutateAsync({ data: formData, token: Token.getAccessToken() }).then(
        (resp) => {
          setFile(null);
          if (resp?.status === true) {
            setData(resp);
          } else {
            toast.error(resp?.error);
          }
        }
      );
    } else if (tabType === "copypast") {
      mutateCopyPast({
        JdDescription: jobData,
        token: Token.getAccessToken(),
      }).then((resp) => {
        if (resp?.status === true) {
          setJobData("");
          setData(resp);
        } else {
          toast.error(resp?.error);
        }
      });
    }
  };

  const saveJDdetails = () => {
    console.log("data", data?.extract);
    let payload = {
      jobtitle: data?.extract?.jobTitle,
      experiencefrom: data?.extract?.experienceFrom,
      experienceto: data?.extract?.experienceTo,
      location: data?.extract?.city,
      country: data?.extract?.country,
      mandatoryskills: data?.extract?.mandatorySkillsets,
      additionalskills: data?.extract?.goodToHaveSkillsets,
      description: data?.extract?.jobDescription,
      screeningquestions: data?.extract?.screeningQuestion,
    };

    if (data?.extract?.screeningQuestion?.length > 0) {
      payload["screeningquestions"] = data?.extract?.screeningQuestion?.map(
        (data) => {
          return {
            question: data,
            desiredanswer: "",
            answertype: "",
          };
        }
      );
    }

    mutateAsyncAddJob(payload).then((resp) => {
      if (resp?.status === true) {
        setOpenParse(false);
        toast.success(resp?.message);
      }
    });
  };

  return (
    <>
      <div
        className={`relative z-10 ${parsePopup ? `` : `hidden tansition-all`}`}
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          className="fixed  inset-0 bg-gray-500 bg-opacity-75"
          aria-hidden="true"
        ></div>
        <div className="fixed inset-0 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 relative">
            <div className="relative rounded transform bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[800px]">
              <button
                type="button"
                className="bg-[red] absolute z-50 text-white right-[-10px] top-[-10px] rounded-full h-[30px] w-[30px]"
                onClick={() => setOpenParse(false)}
              >
                X
              </button>
              <div className="mt-3 text-center sm:text-left w-full px-4 py-4">
                <h2 className="text-center mb-2 font-semibold">
                  Seamlessly Parse document and Text-Based Job Descriptions
                </h2>
                <div className="flex justify-start border-b">
                  <div
                    onClick={() => settabType("upload")}
                    className={`cursor-pointer px-2 py-1  mr-1 border ${
                      tabType === "upload" ? "bg-green-300" : "bg-green-100"
                    }`}
                  >
                    Document
                  </div>
                  <div
                    onClick={() => settabType("copypast")}
                    className={`cursor-pointer px-2 py-1  mr-1 border ${
                      tabType === "copypast" ? "bg-green-300" : "bg-green-100"
                    }`}
                  >
                    Copy & past
                  </div>
                </div>
                <form className="w-full" onSubmit={onSubmitHandle}>
                  {tabType === "upload" ? (
                    <div className="mt-3 text-center sm:text-left w-full">
                      <div className="w-full flex items-start justify-center flex-col gap-2">
                        <label
                          for="uploadFile1"
                          className={` ${
                            file?.name ? "bg-green-200" : "bg-white"
                          } w-full  text-gray-500 font-semibold text-base rounded  h-52 flex flex-col items-center justify-center cursor-pointer border-2 border-gray-300 border-dashed mx-auto`}
                        >
                          <IoCloudUploadOutline size={50} />
                          Upload file
                          <input
                            type="file"
                            accept=".pdf, .docx, .doc"
                            onChange={(e) => handleFileChange(e)}
                            id="uploadFile1"
                            class="hidden"
                          />
                          <p class="text-xs font-medium text-gray-400 mt-2">
                            Only PDF, Docx.
                          </p>
                          <p>{file ? file?.name : ""}</p>
                        </label>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <textarea
                        className="w-full border px-2 py-2 rounded"
                        onChange={(e) => setJobData(e.target.value)}
                        rows={5}
                        placeholder="Copy and past here"
                      ></textarea>
                    </div>
                  )}

                  <div className="py-3 flex justify-between">
                    <div className="text-center">
                      {isloaddata || isPending ? (
                        <Spinner fontsize={"18px"} />
                      ) : (
                        ""
                      )}
                    </div>
                    <div>
                      <button
                        type="submit"
                        disabled={isPending ? true : false}
                        className="inline-flex w-full justify-center rounded-md bg-green-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-600 sm:ml-3 sm:w-auto"
                      >
                        Parse Now
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              {!isPending === true && Object.keys(data).length > 0 ? (
                <div className="px-3">
                  <div className="w-full justify-between flex items-center rounded py-2 px-4">
                    <h2 className="text-center font-bold">Job Details</h2>
                    <p className="px-2 py-2 text-[green]">
                      <FaEdit
                        onClick={() => seteditJobDetail(!editJobDetail)}
                        size={20}
                      />
                    </p>
                  </div>
                  {editJobDetail === false && Object.keys(data).length > 0 ? (
                    <div className="py-5">
                      <div className="font-bold bg-[#e0e0e0] w-full">
                        Job Title
                      </div>
                      {data?.extract?.jobTitle}
                      <div className="font-bold  bg-[#e0e0e0] w-full">
                        Experience From
                      </div>
                      {data?.extract?.experienceFrom}
                      <div className="font-bold  bg-[#e0e0e0] w-full">
                        Experience To
                      </div>
                      {data?.extract?.experienceTo}
                      <div className="font-bold  bg-[#e0e0e0] w-full">
                        Good To Have Skills
                      </div>
                      {data?.extract?.goodToHaveSkillsets}
                      <div className="font-bold  bg-[#e0e0e0] w-full">
                        Mandatory Skills
                      </div>
                      {data?.extract?.mandatorySkillsets}
                      <div className="font-bold  bg-[#e0e0e0] w-full">
                        Education
                      </div>
                      {data?.extract?.qualification}
                      <div className="font-bold  bg-[#e0e0e0] w-full">
                        Country-Location
                      </div>
                      {data?.extract?.country}-{data?.extract?.city}
                      <div className="font-bold  bg-[#e0e0e0] w-full">
                        WorkMode
                      </div>
                      {data?.extract?.workMode}
                      <div className="font-bold  bg-[#e0e0e0] w-full">
                        Work Experience
                      </div>
                      {data?.extract?.workExperience?.map((row, index) => {
                        return (
                          <p>
                            {index + 1} : {row}
                          </p>
                        );
                      })}
                      <div className="font-bold  bg-[#e0e0e0] w-full">
                        KeyRequirements
                      </div>
                      {data?.extract?.keyRequirements?.map((row, index) => {
                        return (
                          <p>
                            {index + 1} : {row}
                          </p>
                        );
                      })}
                      <div className="font-bold  bg-[#e0e0e0] w-full">
                        Industry
                      </div>
                      <div>{data?.extract?.industry}</div>
                      <div className="font-bold  bg-[#e0e0e0] w-full">
                        Description
                      </div>
                      <div
                        className="px-4 py-4"
                        dangerouslySetInnerHTML={{
                          __html: data?.extract?.jobDescription,
                        }}
                      ></div>
                      <div className="font-bold  bg-[#e0e0e0] w-full">
                        Screening Questions
                      </div>
                      <div className="mb-3">
                        {data?.extract?.screeningQuestion?.map((row, index) => {
                          return (
                            <p>
                              Q{index + 1} : {row}
                            </p>
                          );
                        })}
                      </div>
                      <button
                        className="m-auto block text-center bg-green-600 text-white px-2 py-2 rounded"
                        onClick={() => saveJDdetails()}
                      >
                        Save
                      </button>
                    </div>
                  ) : editJobDetail === true && Object.keys(data).length > 0 ? (
                    <div className="py-5">
                      <div className="font-bold bg-[#e0e0e0] w-full">
                        Job Title
                      </div>
                      <input
                        type="text"
                        onChange={(e) =>
                          setData((prevData) => ({
                            ...prevData,
                            extract: {
                              ...prevData.extract,
                              jobTitle: e.target.value,
                            },
                          }))
                        }
                        className="w-full px-2 border rounded py-1 my-2"
                        value={data?.extract?.jobTitle}
                      />
                      <div className="font-bold  bg-[#e0e0e0] w-full">
                        Experience From
                      </div>
                      <input
                        type="text"
                        className="w-full px-2 border rounded py-1 my-2"
                        onChange={(e) =>
                          setData((prevData) => ({
                            ...prevData,
                            extract: {
                              ...prevData.extract,
                              experienceFrom: e.target.value,
                            },
                          }))
                        }
                        value={data?.extract?.experienceFrom}
                      />
                      <div className="font-bold  bg-[#e0e0e0] w-full">
                        Experience To
                      </div>
                      <input
                        type="text"
                        className="w-full px-2 border rounded py-1 my-2"
                        onChange={(e) =>
                          setData((prevData) => ({
                            ...prevData,
                            extract: {
                              ...prevData.extract,
                              experienceTo: e.target.value,
                            },
                          }))
                        }
                        value={data?.extract?.experienceTo}
                      />
                      <div className="font-bold  bg-[#e0e0e0] w-full">
                        Good To Have Skills
                      </div>
                      <input
                        type="text"
                        className="w-full px-2 border rounded py-1 my-2"
                        onChange={(e) =>
                          setData((prevData) => ({
                            ...prevData,
                            extract: {
                              ...prevData.extract,
                              goodToHaveSkillsets: e.target.value
                                .split(",")
                                .map((skill) => skill),
                            },
                          }))
                        }
                        value={data?.extract?.goodToHaveSkillsets}
                      />
                      <div className="font-bold  bg-[#e0e0e0] w-full">
                        Mandatory Skills
                      </div>
                      <input
                        type="text"
                        className="w-full px-2 border rounded py-1 my-2"
                        onChange={(e) =>
                          setData((prevData) => ({
                            ...prevData,
                            extract: {
                              ...prevData.extract,
                              mandatorySkillsets: e.target.value
                                .split(",")
                                .map((skill) => skill),
                            },
                          }))
                        }
                        value={data?.extract?.mandatorySkillsets}
                      />
                      <div className="font-bold  bg-[#e0e0e0] w-full">
                        Education
                      </div>
                      <input
                        type="text"
                        className="w-full px-2 border rounded py-1 my-2"
                        onChange={(e) =>
                          setData((prevData) => ({
                            ...prevData,
                            extract: {
                              ...prevData.extract,
                              education: e.target.value,
                            },
                          }))
                        }
                        value={data?.extract?.qualification}
                      />
                      <div className="font-bold  bg-[#e0e0e0] w-full">
                        Country-Location
                      </div>
                      <div className="flex">
                        <input
                          type="text"
                          className="w-full px-2 border rounded py-1 my-2"
                          onChange={(e) =>
                            setData((prevData) => ({
                              ...prevData,
                              extract: {
                                ...prevData.extract,
                                country: e.target.value,
                              },
                            }))
                          }
                          value={data?.extract?.country}
                          placeholder="Enter Country"
                        />
                        <input
                          type="text"
                          className="w-full px-2 border rounded py-1 my-2"
                          onChange={(e) =>
                            setData((prevData) => ({
                              ...prevData,
                              extract: {
                                ...prevData.extract,
                                city: e.target.value,
                              },
                            }))
                          }
                          value={data?.extract?.city}
                          placeholder="City Location"
                        />
                      </div>

                      <div className="font-bold  bg-[#e0e0e0] w-full">
                        WorkMode
                      </div>
                      <input
                        type="text"
                        className="w-full px-2 border rounded py-1 my-2"
                        onChange={(e) =>
                          setData((prevData) => ({
                            ...prevData,
                            extract: {
                              ...prevData.extract,
                              workMode: e.target.value,
                            },
                          }))
                        }
                        value={data?.extract?.workMode}
                      />
                      <div className="font-bold  bg-[#e0e0e0] w-full">
                        WorkExperience
                      </div>
                      {data?.extract?.workExperience?.map((value, index) => (
                        <input
                          key={index} // Add a unique key for each input
                          type="text"
                          className="w-full px-2 border rounded py-1 my-2"
                          value={value}
                          onChange={(e) =>
                            setData((prevData) => {
                              const updatedWorkExperience = [
                                ...prevData.extract.workExperience,
                              ];
                              updatedWorkExperience[index] = e.target.value; // Use the new input value
                              return {
                                ...prevData,
                                extract: {
                                  ...prevData.extract,
                                  workExperience: updatedWorkExperience,
                                },
                              };
                            })
                          }
                        />
                      ))}
                      <div className="font-bold  bg-[#e0e0e0] w-full">
                        KeyRequirements
                      </div>
                      {data?.extract?.keyRequirements?.map((value, index) => (
                        <input
                          key={index} // Add a unique key for each input
                          type="text"
                          className="w-full px-2 border rounded py-1 my-2"
                          value={value}
                          onChange={(e) =>
                            setData((prevData) => {
                              const updatedKeyRequirements = [
                                ...prevData.extract.KeyRequirements,
                              ];
                              updatedKeyRequirements[index] = e.target.value; // Use the new input value
                              return {
                                ...prevData,
                                extract: {
                                  ...prevData.extract,
                                  KeyRequirements: updatedKeyRequirements,
                                },
                              };
                            })
                          }
                        />
                      ))}
                      <div className="font-bold  bg-[#e0e0e0] w-full">
                        Industry
                      </div>
                      <input
                        type="text"
                        className="w-full px-2 border rounded py-1 my-2"
                        onChange={(e) =>
                          setData((prevData) => ({
                            ...prevData,
                            extract: {
                              ...prevData.extract,
                              industry: e.target.value,
                            },
                          }))
                        }
                        value={data?.extract?.industry}
                      />

                      <div className="font-bold  bg-[#e0e0e0] w-full">
                        Description
                      </div>
                      <textarea
                        type="text"
                        className="w-full px-2 border rounded py-1 my-2"
                        onChange={(e) =>
                          setData((prevData) => ({
                            ...prevData,
                            extract: {
                              ...prevData.extract,
                              jobDescription: e.target.value,
                            },
                          }))
                        }
                        value={data?.extract?.jobDescription}
                      />
                      <div className="font-bold  bg-[#e0e0e0] w-full">
                        Screening Questions
                      </div>
                      {data?.extract?.screeningQuestion?.map((value, index) => (
                        <div className="mt-3 mb-3">
                          <label>Question {index + 1}</label>
                          <input
                            key={index} // Add a unique key for each input
                            type="text"
                            className="w-full px-2 border rounded py-1 my-2"
                            value={value}
                            onChange={(e) =>
                              setData((prevData) => {
                                const updatedKeyRequirements = [
                                  ...prevData.extract.screeningQuestion,
                                ];
                                updatedKeyRequirements[index] = e.target.value; // Use the new input value
                                return {
                                  ...prevData,
                                  extract: {
                                    ...prevData.extract,
                                    screeningQuestion: updatedKeyRequirements,
                                  },
                                };
                              })
                            }
                          />
                        </div>
                      ))}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
