import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { DateRangePicker } from "react-date-range";
import { FiFilter } from "react-icons/fi";
import { TbFileExport } from "react-icons/tb";
import useDebounce from "../../../Helpers/useDebounce";
import AdminLayouts from "../../../Layouts/Admin/AdminLayouts";
import ImportCandidate from "../../../Popups/ImportCandidate";
import { Spinner } from "../../Loader/Spinner";
import CandidateCard from "../../Users/Candidates/CandidateCard";
import useCandidateItem from "../InfiniteScroll/InfiniteScrollCan";
// import AddManualCandidate from "../../../Popups/AddManualCandidate";
import Tooltip from "../../../Helpers/Tooltip";


export default function AdminCandidateList(){
  const [donwloadData, setdataDownload] = useState([]);
  const [manualCand, setManualCand] = useState(false);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState(false);
  const [toggleCalender, setToggleCalender] = useState(false);

  const specificDate = new Date();
  const previousYearSpecificDate = new Date(specificDate);
  previousYearSpecificDate.setFullYear(specificDate.getFullYear() - 1);

  const [daterange, setDateRange] = useState({
    startDate: previousYearSpecificDate,
    endDate: new Date(),
    key: "selection",
  });

  const searchdebounce = useDebounce(search, 500);
  const { data, fetchNextPage, hasNextPage, isFetching } = useCandidateItem({
    pageParam: 1,
    perPage: 10,
    searchdebounce,
    selectSource : '',
    status : '',
    fromdate: moment(daterange?.startDate.toUTCString()).format("YYYY-MM-DD"),
    todate: moment(daterange?.endDate.toUTCString()).format("YYYY-MM-DD"),
  });
  const loaderRef = useRef(null);

  useEffect(() => {
    let fetching = false;
    const onScroll = async (event) => {
      const { scrollHeight, scrollTop, clientHeight } =
        event.target.scrollingElement;
      if (!fetching && scrollHeight - scrollTop <= clientHeight * 1.7) {
        fetching = true;
        if (hasNextPage) await fetchNextPage();
        fetching = false;
      }
    };

    document.addEventListener("scroll", onScroll);
    return () => {
      document.removeEventListener("scroll", onScroll);
    };
  }, [hasNextPage, fetchNextPage]);

  const lengthofpages = data?.pages.length;

  function handleSelect(date) {
    setDateRange({
      startDate: date?.selection?.startDate,
      endDate: date?.selection?.endDate,
      key: "selection",
    });
  }

  // candidate
  /**
   * Hook that alerts clicks outside of the passed ref
   */
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          //  alert("You clicked outside of me!");
          setToggleCalender(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  /*
   * Component that alerts if you click outside of it
   */

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const csvDownload = () => {
    setdataDownload([]);
    let store = [];

    data?.pages?.forEach((page) => {
      page?.data?.data?.forEach((item) => {
        store.push(item);
      });
    });
    setdataDownload(store);
  };

    return (
        <AdminLayouts>
            <div className="px-10 py-5 w-full bg-green-50">
                <div className="w-full pb-5 bg-green-50 py-3 flex items-center justify-start flex-col relative">
                    <div className="bg-white w-full flex justify-between text-[18px] p-3 border border-gray-200 mb-5">
                        <div>Candidate Bank</div>
                        <div className="text-[16px]">
                        Displaying page{" "}
                        {data?.pages[lengthofpages - 1]?.data?.current_page} Out of{" "}
                        {data?.pages[lengthofpages - 1]?.data?.links.length - 2} &nbsp;
                        Total Record {data?.pages[lengthofpages - 1]?.data?.total}
                        </div>
                    </div>
                    <div className="w-full sticky z-20 top-1 mb-5">
                        <div className="sticky top-0 p-3 bg-white border border-gray-200">
                        <div className="w-full sticky top-1">
                            <div className="">
                                <div className="flex justify-between ">
                                <div className="flex items-center justify-center gap-2">
                                    <input
                                    type="search"
                                    placeholder="Search - Name | Email | Mobile"
                                    onChange={(e) => setSearch(e.target.value)}
                                    className="min-w-[300px] px-3 text-[14px] h-8 border border-gray-200"
                                    />
                                </div>
                                <div className="flex items-center justify-center gap-2">

                                    <Tooltip content="Filters" >
                                    <div className="h-8 w-8 border border-gray-200 p-2 rounded-md" ref={wrapperRef} onClick={() => {setFilter(!filter); setDateRange({
                                        startDate: previousYearSpecificDate,
                                        endDate: new Date(),
                                        key: 'selection',
                                        })}} >
                                        <FiFilter />
                                    </div>
                                    </Tooltip>
                                    <Tooltip content="Export">
                                    <div className="h-8 w-8 border border-gray-200 p-2 rounded-md" onClick={csvDownload}>
                                        <CSVLink data={donwloadData} > 
                                        <TbFileExport />
                                        </CSVLink>
                                    </div>
                                    </Tooltip>
                                    {/* <button
                                    onClick={() => setOpen(true)}
                                    className="text-white bg-green-600 text-[14px] px-4 py-1 rounded-[5px]"
                                    >
                                    + Add Candidates
                                    </button> */}
                                </div>
                                </div>
                            </div>
                            <div className={`text-right w-full justify-end flex items-end py-3 ${filter? "" : "hidden"}`}>
                                <div className="" ref={wrapperRef}>
                                <div className="border w-[250px] text-left inline-block px-2 py-1 rounded" onClick={() => setToggleCalender(!toggleCalender)}>{moment(daterange?.startDate.toUTCString()).format("DD-MM-YYYY")} - {moment(daterange?.endDate.toUTCString()).format("DD-MM-YYYY")}</div>
                                <div className={`absolute border rounded right-[10px] top-[90px] ${toggleCalender ? '' : 'hidden'}`} >
                                    <DateRangePicker 
                                    ranges={[daterange]}
                                    onChange={handleSelect}
                                    />
                                </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <ImportCandidate
                      manualCand={manualCand}
                      setManualCand={setManualCand}
                    />
                    
                    <div className="w-full flex flex-wrap items-start justify-between">
                        {data?.pages?.map((data, i) => {
                            return data?.data?.data?.map((data, index) => {
                                return (
                                    <CandidateCard ref={loaderRef} key={index} data={data} />
                                );
                            });
                        })}
                        {isFetching ? <Spinner size="small" /> : ""}
                    </div>
                </div>
            </div>
        </AdminLayouts>
    );
}