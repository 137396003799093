// Invoice.js
import React, { useState } from "react";
import UserLayout from "../../../Layouts/Users/UserLayout";
import { useQuery } from "@tanstack/react-query";
import { Link, useParams } from "react-router-dom";
import endpoint from "../../../Helpers/endpoint";
import Http from "../../../http";
import Token from "../../../Helpers/Token";
import { Spinner } from "../../Loader/Spinner";

const InvoiceOrder = () => {
  const {orderid} = useParams();
  const [items, setItems] = useState([
    { id: 1, description: "Web Development", quantity: 1, unitPrice: 500 },
    { id: 2, description: "Hosting", quantity: 1, unitPrice: 100 },
  ]);

  const getorderInvoce = ()  => {
      const URL = `${endpoint.billing.invoice}/${orderid}`;
      return Http().get(URL, {
        'Authorization' : `Bearer ${Token.getAccessToken()}`,
    });
  }

  const {data, isLoading} = useQuery({queryKey:['getinvoice',orderid], queryFn : getorderInvoce});

  // Helper functions
  const calculateTotal = () => {
    return items.reduce((acc, item) => acc + item.quantity * item.unitPrice, 0);
  };

  const calculateTax = (amount) => {
    return amount * 0.15; // Example tax rate of 15%
  };

  const calculateFinalTotal = () => {
    const subtotal = calculateTotal();
    const tax = calculateTax(subtotal);
    return subtotal + tax;
  };

  // Function to print the invoice
  const printInvoice = () => {
    const printContent = document.getElementById("invoice-container"); // Get the content you want to print
    const printWindow = window.open("", "_blank"); // Open a new blank window

    // Add the content to the print window
    printWindow.document.write(`
        <html>
          <head>
            <title>Invoice</title>
            <script src="https://cdn.tailwindcss.com"></script>
            <style>
              /* Optional: Add custom styles for printing */
              body {
                font-family: Arial, sans-serif;
                margin: 20px;
              }
              @media print {
                body {
                  width: 100%; /* Make sure the body is the full width */
                }
                .print-container {
                  width: 100%; /* Set the desired width for printing */
                  max-width: 800px; /* Optional: set max width */
                  margin: 0 auto; /* Center the content */
                }
              }
            .commingsoon1 {
                background-image: url(http://localhost:3000/pexels-enginakyurt-9899785.bfbd8407623986b335b5.jpg);
                background-repeat: no-repeat;
                background-position: center, center;
                background-size: cover;
            }
              .print-container {
                max-width: 800px; /* Adjust to your needs */
                margin: 0 auto;
              }

              .w-60px{
              width: 60px;
              }

              .bg-bgbrown-100{
                background-color:#ffd1af;
              }
            </style>
          </head>
          <body>
            <div class="print-container">
            ${printContent.innerHTML}
            <div>
          </body>
        </html>
      `);
    printWindow.document.close(); // Close the document to finish writing
    setTimeout(() => {
      printWindow.print();
    }, 1000);
  };

  if(data?.invoice===null){
    
    return <UserLayout>
      <div className="w-full mx-10 my-8 p-6 bg-white shadow-lg rounded-lg">
        <div>
          <Link to={'/transection-history'}>Back</Link>
          <p>
            Invoice Not found something went wrong
          </p>
        </div>
        
      </div>
  </UserLayout>
  }

  return (
    <UserLayout>
    {isLoading ? <Spinner size="medium" /> : ""}
    <div
      className="w-full mx-10 my-8 p-6 bg-white shadow-lg rounded-lg"
    >
      <div id="invoice-container" >
        {/* Invoice Header */}
        <div className="flex justify-between items-center">
          <h1 className="text-3xl font-bold text-gray-800">Invoice</h1>
          <div className="text-right">
            <p className="text-xl font-medium text-gray-700">Invoice #: {data?.invoice?.invoice_number}</p>
            <p className="text-sm text-gray-500">Date: {new Date(data?.invoice?.created_at).toLocaleDateString()}</p>
          </div>
        </div>

        {/* Client & Seller Info */}
        <div className="flex justify-between mt-6">
          <div className="space-y-2">
            <h3 className="text-lg font-semibold text-gray-700">Bill To:</h3>
            <p className="text-sm text-gray-600">Order ID # : <b>{data?.invoice?.order_id}</b></p>
            <p className="text-sm text-gray-600">{data?.billing?.first_name} {data?.billing?.last_name}</p>
            <p className="text-sm text-gray-600">{data?.billing?.address_line1}, <br></br>{data?.billing?.address_line2} {data?.billing?.city},<br /> {data?.billing?.state} {data?.billing?.zip_code}, {data?.billing?.country}</p>
          </div>
          <div className="space-y-2">
            <h3 className="text-lg font-semibold text-gray-700">Sold By:</h3>
            <p className="text-sm text-gray-600">CoffeeCrew.ai</p>
            <p className="text-sm text-gray-600">9 Gennela Square </p>
            <p className="text-sm text-gray-600">Toronto ON CAN M1B5A7</p>
          </div>
        </div>

        {/* Invoice Items Table */}
        <div className="mt-6">
          <table className="w-full table-auto">
            <thead className="bg-gray-100 text-sm text-left text-gray-600">
              <tr>
                <th className="py-2 px-4">Name</th>
                <th className="py-2 px-4">Duration</th>
                <th className="py-2 px-4">Price</th>
                <th className="py-2 px-4">Discount</th>
                <th className="py-2 px-4">Total</th>
              </tr>
            </thead>
            <tbody>
                <tr className="border-b">
                  <td className="py-2 px-4 text-gray-800">{data?.product?.productname}</td>
                  <td className="py-2 px-4 text-gray-800">{data?.subscription?.start_date} to {data?.subscription?.end_date}</td>
                  <td className="py-2 px-4 text-gray-800">{(Number(data?.data?.amount)+Number(data?.data?.discount_amount)).toFixed(2)}</td>
                  <td className="py-2 px-4 text-gray-800">{data?.data?.discount_amount}</td>
                  <td className="py-2 px-4 text-gray-800">
                    {data?.data?.amount}
                  </td>
                </tr>
            </tbody>
          </table>
        </div>

        {/* Invoice Summary */}
        <div className="mt-6 flex justify-between w-full">
          <div className="text-left">
            <p className="text-sm text-gray-600">Payment Method: {data?.subscription?.payment_method}</p>
            <p className="text-sm text-gray-600">Date:  - {new Date(data?.data?.created_at).toLocaleDateString()}</p>
          </div>
          <div className="text-right">
            <p className="text-sm text-gray-600">Subtotal: {data?.data?.currency} {(Number(data?.data?.amount) + Number(data?.data?.discount_amount)).toFixed(2)}</p>
            <p className="text-sm text-gray-600">Discount:  - {data?.data?.currency} {data?.data?.discount_amount}</p>
            {/* <p className="text-sm text-gray-600">Tax (15%): ${calculateTax(calculateTotal()).toFixed(2)}</p> */}
            <p className="text-xl font-semibold text-gray-800">Total: {data?.data?.currency} {data?.data?.amount}</p>
            <p className="text-sm">Total in words: <span className="text-gray-800 font-semibold">{data?.data?.inwords}</span></p>
          </div>
        </div>

        {/* Footer */}
        <div className="mt-8 text-center text-sm text-gray-500">
          <p>&copy;  2025 CoffeeCrew.ai. All rights reserved.</p>
        </div>
      </div>
      {/* Action Buttons */}
      <div className="flex justify-end mt-6 space-x-4">
          <button
            onClick={printInvoice}
            className="px-6 py-2 text-white bg-blue-500 hover:bg-blue-600 rounded-lg"
          >
            Print Invoice
          </button>
          <button
            onClick={printInvoice}
            className="px-6 py-2 text-white bg-green-500 hover:bg-green-600 rounded-lg"
          >
            Save as PDF (via Print)
          </button>
      </div>
    </div>
    </UserLayout>
  );
};

export default InvoiceOrder;
