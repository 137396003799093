import React, { useEffect, useRef, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { FaCircleCheck } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

function SelectCampaignPopUp({ setOpen, open, createCampaign }) {
  const handleClose = () => {
    setOpen(false);
  };

  const [selected, setSelected] = useState(null);

  const wrapperRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperRef]);

  let navigate = useNavigate();
  function onClihander() {
    if(selected === null){
      alert('Please select a option');
      return false;
    }
    if (selected === 1) {
      createCampaign();
    } else {
      navigate("/campaigns-create-campaign");
    }
  }

  return (
    <>
      {open && (
        <React.Fragment>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-primary bg-pop-up">
            <div className="absolute top-10 rounded-[10px] my-6 mx-auto lg:w-6/12 w-80   ">
              <div
                ref={wrapperRef}
                className="border-0  shadow-lg relative flex p-4  flex-col w-full bg-white outline-none focus:outline-none gap-3 rounded-[5px]"
              >
                <FaTimes
                  onClick={handleClose}
                  className="cursor-pointer text-[20px] absolute right-3 top-3 "
                />

                <div className="text-[18px] font-primary font-medium flex-col py-6  w-full flex items-center justify-center gap-4">
                  <p className="text-[24px]">Begin Your Campaign</p>
                  <div className="w-full flex items-center justify-center gap-2 flex-wrap">
                    <div
                      onClick={() => setSelected(1)}
                      className="w-[280px] hover:bg-green-100 cursor-pointer  p-3 border border-gray-200 rounded-md"
                    >
                      {selected === 1 ? (
                        <FaCircleCheck className="text-green-700" />
                      ) : (
                        <p className="w-5 h-5 border rounded-full bg-white border-gray-300"></p>
                      )}
                      <p className="flex items-start justify-center gap-1 text-[20px]">
                        1. AI-Powered Campaign Creation
                      </p>
                      <p className="text-[12px]">
                        Effortlessly create personalized campaigns using AI.
                        Automate messaging, source candidates directly from your
                        own database, and target the right audience to optimize
                        your outreach .
                      </p>
                    </div>
                    <div
                      to={`/campaigns-create-campaign`}
                      onClick={() => setSelected(2)}
                      className="w-[280px] hover:bg-green-100 cursor-pointer p-3 border border-gray-200 rounded-md"
                    >
                      {selected === 2 ? (
                        <FaCircleCheck className="text-green-700" />
                      ) : (
                        <p className="w-5 h-5 border rounded-full bg-white border-gray-300"></p>
                      )}
                      <p className="flex items-start justify-center gap-1 text-[20px]">
                        2. AI-Driven LinkedIn Lead Search
                      </p>
                      <p className="text-[12px]">
                        Leverage AI to effortlessly search and identify
                        high-quality leads on LinkedIn. Automate the lead
                        generation process and connect with potential candidates
                        directly, ensuring efficient and targeted outreach.
                      </p>
                    </div>
                  </div>
                  <div className="w-full flex mt-6 items-center justify-center gap-3">
                    <button
                      onClick={handleClose}
                      type="button"
                      className="px-3 hover:scale-105 transition-all text-[15px] text-black border-2 border-black py-1 rounded-sm"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => onClihander()}
                      type="button"
                      className="px-3 hover:scale-105 transition-all text-[15px] text-white border-2 border-green-700 py-1 bg-green-700 rounded-sm"
                    >
                      Start
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </React.Fragment>
      )}
    </>
  );
}

export default SelectCampaignPopUp;
