import { useQuery } from "@tanstack/react-query";
import Token from "../../../Helpers/Token";
import Http from "../../../http";
import moment from "moment";
import endpoint from "../../../Helpers/endpoint";
import { Spinner } from "../../Loader/Spinner";

export function SinglaHireMapper({dataFilter, setDataFilter, setopenModalCheck}){
    const getallquerylist = () => {
        const URL = `${endpoint.mapper.mapperlist}`;
        return Http().get(URL, {
            'Authorization' : `Bearer ${Token.getAccessToken()}`,
        });
    }

    const {data:dataList, isLoading} = useQuery({queryKey: ['getdatamapperlist'], queryFn : getallquerylist});

    return (
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th scope="col" className="px-6 py-3">
                        #ID
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Type of category
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Sync Date
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Mapper
                    </th>
                </tr>
            </thead>
            <tbody>
                {dataList?.map((row) => {
                    return (<tr className={`bg-white focus:bg-gray-400 cursor-pointer focus:text-black border-b 0  ${row?.status===true?'dark:bg-green-600 text-white':'dark:bg-gray-800  dark:border-gray-70 border-gray-200'}`}>
                        <td className="px-6 py-4">
                        {row?.id}
                        </td>
                        <td className="px-6 py-4">
                            {row?.getinfoats?.appId}
                        </td>
                        <td className="px-6 py-4">
                            {moment(row?.created_at).format("DD-MM-YYYY")}
                        </td>
                        <td className="px-6 py-4">
                            <button onClick={() => {setopenModalCheck(true); setDataFilter({...dataFilter, entity_id: row?.id, atsType: row?.getinfoats?.appId})}}>Check Mapper</button>
                        </td>
                    </tr>)
                })}
                {isLoading? 
                <tr>
                    <td colSpan={3} className="py-5"><Spinner size="small" /></td>
                </tr>:""}
            </tbody>
        </table>
    )
}