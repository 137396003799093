
import React, { useState } from "react"
import AdminLayouts from "../../../Layouts/Admin/AdminLayouts";
import { Link } from "react-router-dom";
import { AdminDataMapperPopup } from "./AdminDataMapperPopup";
import { KnitAtsHrisMapper } from "./KnitAtsHrisMapper";
import { SinglaHireMapper } from "./SinglaHireMapper";
import { Mappers } from "./Mapper";
const AdminDataMapper = ({open, setOpen}) => {
    const [tabknit, setTabKnit] = useState('mappers');
    const [openModal, setopenModalCheck] = useState(false);
    const [dataFilter, setDataFilter] = useState({
        entity_type: "candidate",
        call_entity: "knit",
        entity_source: "knit",
        entity_id: "",
        data : {}
    });

    return (
        <AdminLayouts>
        <AdminDataMapperPopup setopenModalCheck={setopenModalCheck} dataFilter={dataFilter} openModal={openModal}  />
        <div className={`w-full px-10 py-10 bg-green-50 flex items-center flex-col`}>
            <div className="border-b border-gray-200 dark:border-gray-700 w-full">
                <ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
                    <li className="me-2" onClick={() => setTabKnit('mappers')}>
                        <Link className={`${tabknit==='mappers'? 'active bg-green-500 text-white border-green-600' : ''} inline-flex items-center   border-b-2  justify-center p-4  rounded-t-lg dark:border-blue-500 group`}>
                            <svg className="w-4 h-4 me-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z"/>
                            </svg>Mappers
                        </Link>
                    </li>
                    <li className="me-2" onClick={() => setTabKnit('ats-and-hris')}>
                        <Link className={`${tabknit==='ats-and-hris'? 'active bg-green-500 text-white border-green-600' : ''} inline-flex items-center   border-b-2  justify-center p-4  rounded-t-lg dark:border-blue-500 group`}>
                            <svg className="w-4 h-4 me-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z"/>
                            </svg>ATS & HRIS (Knit)
                        </Link>
                    </li>
                    <li className="me-2" onClick={() => setTabKnit('signal-hire')}>
                        <Link className={`${tabknit==='signal-hire'? 'active  bg-green-500 text-white border-green-600' : ''} inline-flex items-center   border-b-2  justify-center p-4  rounded-t-lg dark:text-blue-500 dark:border-blue-500 group`}>
                            <svg className="w-4 h-4 me-2 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z"/>
                            </svg>Contact (Signal Hire)
                        </Link>
                    </li>
                </ul>
                <div className="bg-white px-3 w-[100%] overflow-x-auto py-3">
                    <div className="relative overflow-x-auto">
                        {
                            tabknit==='mappers'? (
                                <Mappers dataFilter={dataFilter}  setDataFilter={setDataFilter} setopenModalCheck={setopenModalCheck}/>
                            ): tabknit==='ats-and-hris'? (
                                <KnitAtsHrisMapper dataFilter={dataFilter}  setDataFilter={setDataFilter} setopenModalCheck={setopenModalCheck}/>
                            ):tabknit==='signal-hire'? (
                                <SinglaHireMapper dataFilter={dataFilter}  setDataFilter={setDataFilter} setopenModalCheck={setopenModalCheck} />
                            ):""
                        }
                    </div>
                </div>
            </div>
        </div>
        </AdminLayouts>
    );
}

export default AdminDataMapper;