import React from "react";
import LineChart from "../../Chart/LineChart";
import DoughnutChart from "../../Chart/Doughnut";
import BarlineChart from "../../Chart/BarlineChart";
import endpoint from "../../../Helpers/endpoint";
import Http from "../../../http";
import Token from "../../../Helpers/Token";
import { useQuery } from "@tanstack/react-query";

export default function JobReport(){
    const  getReportCandWeek = () => {
        const URL = `${endpoint.report.jobrep}?typeof=Week`;
        return Http().get(URL, {
            'Authorization' : `Bearer ${Token.getAccessToken()}`,
        });
    }

    const  getReportCandYear = () => {
        const URL = `${endpoint.report.jobrep}?typeof=Year`;
        return Http().get(URL, {
            'Authorization' : `Bearer ${Token.getAccessToken()}`,
        });
    }

    const  mulipleSource = () => {
        const URL = `${endpoint.report.jobrep}?typeof=mulipleSource`;
        return Http().get(URL, {
            'Authorization' : `Bearer ${Token.getAccessToken()}`,
        });
    }

    const {data:jobReportWeek} = useQuery({queryKey: ['getjobreportweek'], queryFn: getReportCandWeek});
    const {data:jobReportYear} = useQuery({queryKey: ['getjobreportyear'], queryFn: getReportCandYear});
    const {data:jobReportmultipleSource} = useQuery({queryKey: ['getjobreportSource'], queryFn: mulipleSource});

    return (
        <>
        <div className="flex flex-wrap  justify-between">
            <div className="w-[49%] mb-3 mr-2 px-3 py-3 bg-white rounded border">
                <h1 className="text-center">Job Report This Week</h1>
                <LineChart Report={jobReportWeek} chartType="Job" />
            </div>
            <div className="w-[49%] mb-3 mr-2 px-3 py-3 bg-white rounded border">
                <DoughnutChart Report={jobReportmultipleSource} datatype="location" />
            </div>
            <div className="w-[49%] mr-2 mb-3 px-3 py-3 bg-white rounded border">
                <h1 className="text-center">Job Report Montly</h1>
                <BarlineChart Report={jobReportYear}  chartLabel="Job" />
            </div>
        </div>
        </>
    )
}