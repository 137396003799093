
import React from "react";
import AddManualCandidate from "./AddManualCandidate";
export default function PopupAddCand({ manualCand, setManualCand }) {
  return (
    <>
      <div
        className={`relative z-10 ${manualCand ? `` : `hidden tansition-all`}`}
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          className="fixed inset-0 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="relative transform overflow-scroll rounded-lg bg-white text-left shadow-xl transition-all sm:w-full sm:max-w-[100%] sm:max-h-[100%]">
              <AddManualCandidate  manualCand={manualCand} setManualCand={setManualCand} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
