import { useMutation, useQueryClient } from "@tanstack/react-query";
import endpoint from "../../../Helpers/endpoint";
import Http from "../../../http";

const addJob = (postData) => {
  const URL = endpoint.job.create;
  return Http().post(URL, postData);
};

const saveJob = ({payload, jobuid}) => {
  const URL = `${endpoint.job.create}/${jobuid}`;
  return Http().post(URL, payload);
};

export const useAddJob = () => {
  const queryclient = useQueryClient();
  return useMutation({
    mutationFn: addJob,
    onSuccess: (data, variables, context) => {
      queryclient.invalidateQueries(["jobslisting"]);
      // I will fire first
    },
    onError: (error, variables, context) => {
      // I will fire first
    },
    onSettled: (data, error, variables, context) => {
      // I will fire first
    },
  });
};

export const useSaveJob = () => {
  return useMutation({
    mutationFn: saveJob,
    onSuccess: (data, variables, context) => {
      // I will fire first
    },
    onError: (error, variables, context) => {
      // I will fire first
    },
    onSettled: (data, error, variables, context) => {
      // I will fire first
    },
  });
};
