import { useMutation, useQueryClient } from "@tanstack/react-query";
import endpoint from "../../Helpers/endpoint";
import Http from "../../http";


const update = (postData) => {
  const URL = endpoint.communication.updatelinkedinChats.replace('{chatid}',postData.chat_id);
  return Http().post(URL, postData);
};

export const useUpdateLinkedinChat = () => {
  let queryclient = useQueryClient();
  return useMutation({
    mutationFn: update,
    onSuccess: (data, variables, context) => {
      queryclient.invalidateQueries(['Inbox']);
      queryclient.invalidateQueries(['Messages']);
      
      // I will fire first
    },
    onError: (error, variables, context) => {
        queryclient.invalidateQueries(['Inbox']);
        queryclient.invalidateQueries(['Messages']);
      // I will fire first
    },
    onSettled: (data, error, variables, context) => {
        queryclient.invalidateQueries(['Inbox']);
        queryclient.invalidateQueries(['Messages']);
      // I will fire first
    },
  });
};
