import React, { useEffect, useState } from "react";
import ProcessText from "../../../Helpers/ProcessText";
import CreateCampaignJob from "../../../Popups/CreateCampaignJob";

export default function MiniCampaign({ data, header }) {
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const print_text = ProcessText(data.message, data?.message_uid);
  const [campaignJob, setCampaignJob] = useState(false);

  window.handleCheckboxChange = (e) => {
    const candidateId = e.target.value;

    setSelectedCandidates((prev) =>
      e.target.checked
        ? [...prev, candidateId]
        : prev.filter((id) => id !== candidateId)
    );
  };

  useEffect(() => {
    let candidateIds = window.document.querySelectorAll(
      `[name="candidateid-${data?.message_uid}"]`
    );
    let ids = [];

    candidateIds?.forEach((element) => {
      ids.push({ message_uid: data?.message_uid, candidateid: element.value });
    });

    setSelectedCandidates((prevSelected) => {
      const combined = [...prevSelected, ...ids];
      const uniqueCandidates = Array.from(
        new Map(combined.map((item) => [item.candidateid, item])).values()
      );
  
      return uniqueCandidates;
    });
  }, [data?.message_uid]);


  return (
    <>
      <div
        id={data?.message_uid}
        className="w-full  pb-2 text-[14px]  px-[24px] overflow-y-scroll flex-col whitespace-pre-line"
        dangerouslySetInnerHTML={print_text}
      />
      <CreateCampaignJob
        Data={data}
        header={header}
        open={campaignJob}
        setOpen={setCampaignJob}
        selectedCandidates={selectedCandidates}
      />
      {data?.no_of_candidates > 0 ? (
        <div className="w-full sticky bottom-0 flex items-center justify-start gap-2">
          <input
            // value=${candidateId}
            checked
            name="candidateid"
            type="checkbox"
            className=" w-4 h-4 border-0 accent-[#91571C] top-1 inline"
          />
          <p>|</p>
          <button
            type="submit"
            onClick={() => setCampaignJob(true)}
            className="bg-lightbrown bg-opacity-85 text-white px-4 py-1 rounded-full"
          >
            Begin Campaign with <b>{data?.no_of_candidates}</b> Candidates
          </button>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
