import React from "react";

export default function WorkingHours() {
    
  const days = [
    { name: "Monday" },
    { name: "Tuesday" },
    { name: "Wednesday" },
    { name: "Thursday (today)", today: true },
    { name: "Friday" },
    { name: "Saturday" },
    { name: "Sunday" },
  ];

  return (
    <div className="p-4 bg-white border rounded-lg shadow w-[95%]">
      <div className="mb-4">
        <label className="block text-md font-medium mb-2">
          Choose your working hours:
        </label>
        <div className="flex items-center gap-2">
          <span className="text-sm font-medium">Time zone:</span>
          <select className="border border-gray-300 rounded-md p-2 text-sm focus:ring focus:ring-indigo-300">
            <option value="Asia/Calcutta">(UTC +05:30) Asia/Calcutta</option>
          </select>
        </div>
      </div>
      <div className="space-y-2">
        {days.map((day, index) => (
          <div
            key={index}
            className={`flex items-center justify-between p-2 rounded-lg ${
              day.today ? "bg-gray-100" : ""
            }`}
          >
            <span
              className={`text-sm font-medium ${
                day.today ? "text-gray-700" : ""
              }`}
            >
              {day.name}
            </span>
            <div className="flex items-center gap-2">
              <input
                type="time"
                defaultValue="09:00"
                className="border border-gray-300 rounded-md px-2 py-1 text-sm focus:ring focus:ring-indigo-300"
              />
              <span className="text-sm font-medium">-</span>
              <input
                type="time"
                defaultValue="20:00"
                className="border border-gray-300 rounded-md px-2 py-1 text-sm focus:ring focus:ring-indigo-300"
              />
              <button className="w-6 h-6 bg-green-500 text-white rounded-full flex items-center justify-center hover:bg-green-600">
                ✔
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
