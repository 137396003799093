import { useMutation } from "@tanstack/react-query";
import endpoint from "../../Helpers/endpoint";
import Http from "../../http";

const addTeamUser = ({data, token}) => {
    const URL = `${endpoint.team.team}`;
    const headers  = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}` 
    }
    return Http().post(URL, data, headers);
  }

const updateTeam = ({data, token}) => {
  const URL = `${endpoint.team.team}/${data?.user_uid}`;
  const headers  = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}` 
  }
  return Http().put(URL, data, headers);
}


export const useTeamAdd = () => {
    return useMutation({
        mutationFn:addTeamUser,
        onSuccess: (data, variables, context) => {
          // I will fire first
        },
        onError: (error, variables, context) => {
          // I will fire first
        },
        onSettled: (data, error, variables, context) => {
          // I will fire first
        },
    });
}

export const useTeamUpdate = () => {
  return useMutation({
      mutationFn:updateTeam,
      onSuccess: (data, variables, context) => {
        // I will fire first
      },
      onError: (error, variables, context) => {
        // I will fire first
      },
      onSettled: (data, error, variables, context) => {
        // I will fire first
      },
  });
}
