import { useMutation, useQueryClient} from "@tanstack/react-query";
import endpoint from "../../Helpers/endpoint";
import Http from "../../http";

const addworklist = ({payload, token}) => {
    const URL = `${endpoint.worklistaction.worklist}`;
    const headers  = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
    };
    return Http().post(URL, payload, headers);
}

const applyWorkList = ({payload, token}) => {
    const URL = `${endpoint.worklistaction.applyWorklist}`;
    const headers  = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
    };
    return Http().post(URL, payload, headers);
}

const removeWorklisttags = ({payload, token}) => {
  const URL = `${endpoint.worklistaction.removetags}`;
  const header  = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
  };
  return Http().delete(URL, payload, header);
}



export const useWorklist = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn:addworklist,
        onSuccess: (data, variables, context) => {
          // I will fire first
          queryClient.refetchQueries(['getcandsourcelist']);
        },
        onError: (error, variables, context) => {
          // I will fire first
        },
        onSettled: (data, error, variables, context) => {
          // I will fire first
        },
    });
}

export const useWorklistApply = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn:applyWorkList,
        onSuccess: (data, variables, context) => {
          // I will fire first
          queryClient.refetchQueries(['getcandsourcelist']);
        },
        onError: (error, variables, context) => {
          // I will fire first
        },
        onSettled: (data, error, variables, context) => {
          // I will fire first
        },
    });
}

export const useWorklistRemoveTags = () => {
  const queryClient = useQueryClient();
  return useMutation({
      mutationFn:removeWorklisttags,
      onSuccess: (data, variables, context) => {
        // I will fire first
        queryClient.refetchQueries(['getcandsourcelist']);
      },
      onError: (error, variables, context) => {
        // I will fire first
      },
      onSettled: (data, error, variables, context) => {
        // I will fire first
      },
  });
}

