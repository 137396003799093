import { useEffect, useState } from "react";
import { useMapperDetail } from "../../../hooks/apihooks/useAdminMapper";
import Token from "../../../Helpers/Token";
import { Spinner } from "../../Loader/Spinner";

export function AdminDataMapperPopup({
  setopenModalCheck,
  openModal,
  dataFilter,
}) {
  const { mutateAsync, isPending } = useMapperDetail();
  const [data, setData] = useState({});

  useEffect(() => {
    if (dataFilter?.entity_id !== "" && dataFilter?.call_entity === "knit") {
      mutateAsync({ data: dataFilter, token: Token?.getAccessToken() }).then(
        (resp) => {
          setData(resp);
        }
      );
    }
    if (dataFilter?.call_entity === "mapper") {
      setData({ data: dataFilter?.data });
    }
    //  eslint-disable-next-line
  }, [dataFilter]);

  return (
    <div
      className={`${openModal ? "" : "hidden"} relative z-10`}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        className="fixed inset-0 bg-gray-500/75 transition-opacity"
        aria-hidden="true"
      ></div>
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center text-center sm:items-center">
          <div className="relative transform text-left transition-all sm:my-8 h-[800px] w-[80%] mx-auto p-4 bg-white shadow-lg rounded-xl">
            <div
              className="absolute top-[-10px] right-[-10px] w-[30px] h-[30px] rounded-full bg-red-600 flex justify-center items-center cursor-pointer"
              onClick={() => setopenModalCheck(!openModal)}
            >
              x
            </div>
            <div className="relative flex items-center sm:text-left">
              <div className="w-full border">
                <div className="h-[740px] overflow-auto">
                  <h3 className="text-center font-semibold uppercase">
                    {dataFilter?.atsType} ({dataFilter?.entity_id})
                  </h3>
                  <table className="table border break-all break-words min-w-full text-wrap text-left w-full">
                    <thead>
                      <tr className="bg-yellow-200 border">
                        <th className="border w-[300px]">Source Field</th>
                        <th className="w-[300px] border">DB Field</th>
                        <th className="w-[300px] border">Value</th>
                      </tr>
                      {!isPending &&
                        data?.data?.map((row) => {
                          return (
                            <tr className={`border`}>
                              <th className="border w-[300px]">
                                {row?.keyname}
                              </th>
                              <th className="border w-[300px]">{row?.dbkey}</th>
                              <th className="border w-[300px]">{row?.value}</th>
                            </tr>
                          );
                        })}
                      {isPending ? (
                        <tr>
                          <td className="border" colSpan={3}>
                            <Spinner size="small" />
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
