import React, { useEffect, useState } from 'react';
import { usePaymentConfirm } from '../../../hooks/apihooks/useCheckout';
import {useNavigate } from 'react-router-dom';
import Token from '../../../Helpers/Token';
import { Spinner } from '../../Loader/Spinner';
import moment from 'moment/moment';
import { toast } from 'react-toastify';
import Confetti from 'react-confetti';

const PaymentStatus = () => {
  const [showConfetti, setShowConfetti] = useState(false);
  const {mutateAsync , isPending} = usePaymentConfirm();
  const navigate = useNavigate();
  const [orderId, setOrderId] = useState(null); // State to store order_id
  const [orderData, setOrderData] = useState(null); // State to store response data
  // Get the current URL's query string
  const queryString = window.location.search;  // "?order_id=CC-ORD-388529367"
  // Create a URLSearchParams object from the query string
  const urlParams = new URLSearchParams(queryString);

  // Get the value of the 'order_id' parameter
  const orderIdFromUrl = urlParams.get('order_id');

 // Set the orderId state only once (on mount or if changed)
  useEffect(() => {
    if (orderIdFromUrl) {
      setOrderId(orderIdFromUrl);
    }
  }, [orderIdFromUrl]);

  useEffect(() => {
    if (orderId) {
      // Only call mutateAsync if orderId is available
      const data = { orderId: btoa(orderId) };
      mutateAsync({ data, token: Token.getAccessToken() })
        .then((resp) => {
          if(resp?.status===false){

            toast.success(resp?.message);

          }else if(resp?.status===true){

            setOrderData(resp); // Optionally, store the response data if needed
            setShowConfetti(true);
            const timer = setTimeout(() => {
              setShowConfetti(false);  // Stop the confetti after 3 seconds
            }, 6000);  // 3000 ms = 3 seconds

            // Cleanup timer on component unmount
            return () => clearTimeout(timer);
          }
        })
        .catch((error) => {
          console.error("Error while confirming payment:", error);
        });
    }
  }, [orderId]); // Dependency on orderId to trigger the mutation once

  return (
    <>
    {isPending ? <Spinner size='medium' />:""}
    { showConfetti ? 
          <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          numberOfPieces={500}
        /> :"" }

        {orderData==null ? 
          <div className="min-h-screen flex items-center justify-center">
            <div className="bg-gray-200 p-6 rounded-lg shadow-lg">
              <h2 className="text-xl font-semibold text-center">Order Not Found</h2>
              <p className="text-center py-2">Sorry, we couldn't find the order you were looking for.</p>
              <div className="flex space-x-4">
                <button
                  className="w-full bg-green-500 text-white p-3 rounded-lg hover:bg-green-600 transition"
                  onClick={() => navigate('/dashboard')}
                >
                  Go to My Account
                </button>
                <button
                  className="w-full bg-blue-500 text-white p-3 rounded-lg hover:bg-blue-600 transition"
                  onClick={() => navigate('/transection-history')}
                >
                  View Order History
                </button>
              </div>
            </div>
          </div>
         : 
          <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
              {
                orderData?.data?.payment_status==='SUCCESS' ? <>
              <h2 className="text-2xl font-semibold text-green-600 mb-6">Payment Successfully Done!</h2> </>
                :orderData?.data?.order_status==='ACTIVE'? <h2 className="text-2xl font-semibold text-green-600 mb-6">Payment Not done for yet!</h2> 
                :orderData?.data?.order_status==='ACTIVE'? "" :""
              }
              
              <p className="text-lg text-gray-700 mb-4">Thank you for subscribing to our service!</p>

              <div className="mb-6">
                <p className="font-semibold text-gray-800">Subscription Plan: <span className="text-gray-600">{orderData?.product?.productname}</span></p>
                <p className="font-semibold text-gray-800">Price: <span className="text-gray-600"> {orderData?.data?.payment_currency}  {orderData?.data?.payment_amount} / month</span></p>
                <p className="font-semibold text-gray-800">Payment Date: <span className="text-gray-600"> {moment(orderData?.data?.payment_time).format('LLL')}</span></p>
                <p className="font-semibold text-gray-800">Order ID : <span className="text-gray-600"> {orderData?.data?.order_id}</span></p>

                <p className="font-semibold text-gray-800 mt-4">Features: <span className="text-gray-600"> {orderData?.product?.description}</span></p>
              </div>

              <div className="flex space-x-4">
                <button
                  className="w-full bg-green-500 text-white p-3 rounded-lg hover:bg-green-600 transition"
                  onClick={() => navigate('/dashboard')}
                >
                  Go to My Account
                </button>
                <button
                  className="w-full bg-blue-500 text-white p-3 rounded-lg hover:bg-blue-600 transition"
                  onClick={() => navigate('/transection-history')}
                >
                  View Order History
                </button>
              </div>
            </div>
          </div>
        }

        
    </>
  );
};

export default PaymentStatus;
