import React, { useState } from "react";
import { MdOutlineMessage } from "react-icons/md";
import { LuMail } from "react-icons/lu";
import { BsInbox } from "react-icons/bs";
import { FaRegStar } from "react-icons/fa";
import { IoTrashOutline } from "react-icons/io5";
import { PiDotsThreeVerticalBold } from "react-icons/pi";
import { TiAttachment } from "react-icons/ti";
import { CiFaceSmile } from "react-icons/ci";
import { IoMdPaperPlane } from "react-icons/io";
import endpoint from "../../../Helpers/endpoint";
import Http from "../../../http";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import moment from "moment/moment";
import Loader from "../../Loader/Loader";
import { FaStar } from "react-icons/fa";
import { useUpdateLinkedinChat } from "../../../hooks/apihooks/useUpdateLinkedinChat";
import { toast } from "react-toastify";
import { FaLinkedinIn } from "react-icons/fa6";
import { CiStickyNote } from "react-icons/ci";
import { IoLocationOutline } from "react-icons/io5";

export default function InboxLayout({ header }) {
  const queryClient = useQueryClient();
  const [chatid, setChatid] = useState(null);
  const [chatStatus, setChatStatus] = useState({
    tab: 1,
  });

  const { data, isLoading: loading } = useQuery({
    queryKey: ["Inbox", chatStatus.tab],
    queryFn: getChats,
  });

  const { data: chat, isLoading } = useQuery({
    queryKey: ["Messages", chatid],
    queryFn: getMessages,
    enabled: Boolean(chatid),
    onSuccess: () => {
     
      queryClient.invalidateQueries(["Inbox"]);
    },
    onError: (error) => {
      console.error("Query failed:", error);
    },
  });

  function getChats() {
    let endpoints =
      endpoint.communication.inboxalchats +
      `?action=${
        chatStatus.tab === 1
          ? ""
          : chatStatus.tab === 2
          ? "unviewed"
          : chatStatus.tab === 3
          ? "important"
          : chatStatus.tab === 4
          ? "archived"
          : ""
      }`;
    return Http().get(endpoints);
  }

  function getMessages() {
    let endpoints = endpoint.campaign.getchat.replace("{chatid}", chatid);
    return Http().get(endpoints);
  }

  const { mutateAsync, isPending } = useUpdateLinkedinChat();

  const updateChat = (action,params) => {
    let postData = {
      chat_id: chatid,
      action: action,
      [action]: params,
    };

    mutateAsync(postData).then((res) => {
      if (res?.status) {
        toast.success(params ? `Marked as ${action}` : `Umarked as ${action}`);
        queryClient.invalidateQueries(["Messages", chatid]);
      } else {
        toast.warn("Something went wrong");
      }
    });
  };

  return (
    <div className=" flex items-center justify-center  flex-col gap-3 py-4 w-full">
      {loading ? <Loader />  : ""}
      {isPending ? <Loader />  : ''}
      <div className="w-[95%] bg-gradient-leads flex py-4 px-2 rounded-[8px] items-start flex-col justify-center gap-2">
        <p className="flex items-center justify-center gap-2 text-[18px]">
          <MdOutlineMessage size={25} /> Inbox
        </p>
        <p className="text-[12px] text-gray-800">
          All messages are for your Leads.
        </p>
      </div>
      <div className="w-[95%] flex items-start justify-center flex-row gap-2  ">
        <div className={` ${chat?.candidate ? '2xl:w-[70%]' : '2xl:w-9/12'}  w-full rounded-[8px] shadow-sm bg-white border border-gray-200 flex items-center justify-center flex-col`}>
          <div className="w-full flex items-center justify-between p-3  border-b border-gray-200">
            <div className="text-[18px] font-semibold">Inbox</div>
            <div className="flex items-center justify-center gap-3 sm:gap-10 text-[13px]">
              <p
                onClick={() => setChatStatus({ tab: 1 })}
                className={`${
                  chatStatus.tab === 1 ? "text-lightbrown  font-semibold" : ""
                }  flex cursor-pointer items-center justify-center gap-1 `}
              >
                <BsInbox size={15} /> All messages
              </p>
              <p
                onClick={() => setChatStatus({ tab: 2 })}
                className={` ${
                  chatStatus.tab === 2 ? "text-lightbrown  font-semibold" : ""
                } flex cursor-pointer items-center justify-center gap-1`}
              >
                <LuMail size={15} />
                Unviewed
              </p>
              <p
                onClick={() => setChatStatus({ tab: 3 })}
                className={` ${
                  chatStatus.tab === 3 ? "text-lightbrown font-semibold" : ""
                } flex cursor-pointer items-center justify-center gap-1`}
              >
                <FaRegStar size={15} />
                Important
              </p>
              <p
                onClick={() => setChatStatus({ tab: 4 })}
                className={` ${
                  chatStatus.tab === 4 ? "text-lightbrown font-semibold" : ""
                } flex cursor-pointer items-center justify-center gap-1`}
              >
                <IoTrashOutline size={15} />
                Archived
              </p>
            </div>
          </div>
          <div className="w-full flex items-center justify-between  border-b border-gray-200">
            <div className="w-4/12 border-r border-gray-200  px-2 py-3">
              <input
                type="text"
                placeholder="Search"
                className="w-full placeholder:text-[14px] text-[14px] px-3 h-8 rounded-full outline-none placeholder:text-gray-600  bg-gray-100"
              />
            </div>
            <div className="w-8/12 px-2 py-3 flex items-center justify-between gap-2">
              <div className="flex items-center justify-center gap-2">
                {chat?.candidate?.profile_pic ? (
                  <img
                    className="w-8 h-8 rounded-full"
                    src={chat?.candidate?.profile}
                    alt="profile_pic"
                  />
                ) : (
                  ""
                )}
                <p className="text-sm font-medium flex items-center justify-center gap-1">
                  {chat?.candidate?.full_name
                    ? chat?.candidate?.full_name
                    : "Candidate"}

                  {chat?.candidate?.important ? (
                    <FaStar className="text-gray-800" />
                  ) : (
                    ""
                  )}
                </p>
              </div>
              <div className="px-2 cursor-pointer relative group">
                <PiDotsThreeVerticalBold size={25} />
                <div className="w-[150px] right-[18px] group-hover:flex hidden top-3 absolute p-2  items-start justify-center bg-white border border-gray-300 rounded-md flex-col">
                  {chat?.candidate?.important ? (
                    <p
                      onClick={() => updateChat('important',false)}
                      className="text-[12px] border-b border-gray-200 py-1"
                    >
                      Unmark as Important
                    </p>
                  ) : (
                    <p onClick={() => updateChat('important',true)} className="text-[12px] border-b border-gray-200 py-1">
                      Mark as Important
                    </p>
                  )}
                   {chat?.candidate?.archived ? (
                    <p
                      onClick={() => updateChat('archived',false)}
                      className="text-[12px] border-b border-gray-200 py-1 w-full text-left"
                    >
                      UnArchived
                    </p>
                  ) : (
                    <p onClick={() => updateChat('archived',true)} className="text-[12px] border-b border-gray-200 py-1 w-full text-left">
                      Mark as Archived
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex items-center justify-between  border-b border-gray-200">
            <div className="w-4/12 border-r h-[75vh] overflow-y-scroll border-gray-200 ">
              <ul className=" flex items-start justify-start flex-col">
                {data?.data?.map((user, index, users) => (
                  <li
                    key={index}
                    className={`w-full flex cursor-pointer items-center space-x-4 hover:bg-gray-100 px-2 py-[6px] ${
                      users.length === index ? "" : "border-b border-gray-200"
                    } ${
                      chatid === user?.chat_id
                        ? "bg-lightbrown bg-opacity-20"
                        : ""
                    } ${
                      user?.unseencount > 0
                        ? "bg-lightbrown bg-opacity-20"
                        : ""
                    } `}
                    onClick={() => setChatid(user?.chat_id)}
                  >
                    {user?.profile ? (
                      <img
                        alt="profile_pic"
                        src={user?.profile}
                        className="h-10 w-10 rounded-full"
                      />
                    ) : (
                      <div className="h-10 w-10 rounded-full bg-gray-200"></div>
                    )}

                    <div className="flex-1">
                      <div className="font-medium text-[14px]">
                        {user.full_name}
                      </div>
                      <div
                        className={`${
                          user.unread === 1 ? "font-semibold" : ""
                        }  text-[12px] text-blue-950  flex items-center justify-between`}
                      >
                        <p className="truncate  md:w-[100px] max:w-[150px]">
                          {" "}
                          {user.text}
                        </p>
                      </div>
                    </div>
                    <div className="text-xs text-gray-400 flex items-end justify-center flex-col gap-1">
                      <p className="flex items-center justify-center gap-1">
                        <span>
                          {moment(user?.timestamp).local().format("DD-MM-YYYY")}
                        </span>
                        <span className="ml-auto inline-block">
                          {user?.important ? (
                            <FaStar className="text-gray-800" />
                          ) : (
                            ""
                          )}
                        </span>
                      </p>

                      {user?.unseencount > 0 ? (
                        <p className="h-5 w-5 bg-lightbrown rounded-full text-white flex items-center justify-center  z-[10]">
                          {user?.unseencount}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className="w-8/12  border-r flex items-center justify-center gap-4 flex-col border-gray-200   py-3">
              <div className="h-[65vh] w-full flex items-center justify-start flex-col px-2 gap-2 overflow-auto">
                {isLoading ? (
                  <p>loading</p>
                ) : (
                  chat?.data?.map((data) => (
                    <div
                      key={data?.text}
                      className={`flex items-center gap-1  ${
                        data?.is_sender === 1 ? "ml-auto" : "mr-auto"
                      } ${data?.text ? "" : "hidden"}`}
                    >
                      {data?.is_sender === 1 ? (
                        <img
                          src={header?.linkedin?.profile_picture}
                          alt="profile_picture"
                          className="h-8 w-8 rounded-full"
                        />
                      ) : data?.profile_pic ? (
                        <img
                          src={data?.profile}
                          alt="profile_pic"
                          className="h-8 w-8 rounded-full"
                        />
                      ) : (
                        <div className="rounded-full h-8 w-8 bg-gray-300"></div>
                      )}

                      <div
                        className={`${
                          data?.is_sender === 0
                            ? "bg-lightbrown bg-opacity-80 text-white"
                            : "bg-gray-200 "
                        }  py-1 px-2 rounded-lg max-w-xs`}
                      >
                        <span className="text-[14px]">{data?.text}</span>
                      </div>
                    </div>
                  ))
                )}
              </div>
              <div className="w-[98%] rounded-[4px] flex items-center p-1  justify-center gap-2 bg-gray-100">
                <TiAttachment size={25} />
                <input
                  type="text"
                  placeholder="Type your message here"
                  className="w-full placeholder:text-[14px] text-[14px] px-3 h-8 rounded-full outline-none placeholder:text-gray-600 bg-gray-100 "
                />
                <CiFaceSmile size={25} />
                <IoMdPaperPlane size={25} />
              </div>
            </div>
          </div>
        </div>
        {chat?.candidate ? (
          <div className="lg:w-[30%] gap-1 w-0 rounded-[6px] shadow-sm flex items-center justify-center flex-col ">
          
            <div className="w-full bg-white border border-gray-200 p-2 shadow-sm rounded-[4px] flex items-start justify-start flex-row gap-1">
              <div className="w-[12%]">
                <img
                  src={chat?.candidate?.profile}
                  alt="profile_pic"
                  className="h-9 w-9 rounded-full border border-gray-300"
                />
                {/* <p className="h-16 w-16 bg-red-600 rounded-full"></p> */}
              </div>
              <div className=" w-[88%] flex items-start justify-center flex-col gap-1">
                <p className=" text-[15px]">
                  <span className="font-semibold">{chat?.candidate?.full_name}</span> | <span className="text-[10px] text-blue-900 font-semibold">
                   {chat?.candidate?.followers}+ connections
                </span>
                </p>
                <p className=" text-[10px]">{chat?.candidate?.headline}</p>
                <div className="w-full flex items-center justify-between gap-2">
                  <button type="button" className="flex items-center justify-center gap-1 text-[14px]">
                    <CiStickyNote size={18} /> Notes
                  </button>
                <button type="button" className="flex items-center justify-center gap-1 bg-[#0077B5] text-white py-1 px-2 rounded-[5px] text-[12px]"> <FaLinkedinIn /> Linkedin Account</button>
                </div>
               
              </div>
            </div>

          

            <div className="   bg-white border border-gray-200 p-2 py-3 shadow-sm rounded-[4px] flex items-start justify-center flex-col w-full gap-2">
              <p className="w-full text-[14px] font-semibold">All Experiences</p>

              <div className="flex items-center justify-center flex-col w-full gap-3">
                {chat?.candidate?.experience?.map((data) => (
                  <div className="w-full flex items-start justify-center flex-row  gap-2">
                    <img className="w-10 h-10 " src={data?.logo} alt={"logo"} />
                    <div className="w-full flex items-start justify-start gap-1 flex-col">
                      <p className="text-[12px] font-semibold">
                      
                        <span className="text-blue-950">{data?.subtitle}</span>
                      </p>
                      <p className="text-[12px] font-semibold "> {data?.title} -  <span className="text-[11px] font-normal text-gray-800 ">{data?.caption}</span></p>
                      <p className="text-[12px] flex items-center justify-start gap-1 "><IoLocationOutline size={16} /> {data?.metadata}</p>
                    
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="pt-2  bg-white border border-gray-200 p-2 shadow-sm rounded-[4px]  flex items-start justify-center flex-col w-full gap-2">
              <p className="w-full text-[14px] font-semibold">Skill set</p>
              <p className="text-blue-950 text-[12px] flex items-center justify-start gap-1  flex-wrap">
                {chat?.candidate?.skills?.split(',')?.map(data => (
                  <span className="px-2 py-1 bg-gray-300 rounded-full" key={data}>{data}</span>
                ))}
              </p>
            </div>
          </div>
        ) : (
          <div className="w-0 lg:w-3/12"></div>
        )}
      </div>
    </div>
  );
}
