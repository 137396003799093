
import React, { useEffect, useState } from "react";
import UserLayout from "../../../Layouts/Users/UserLayout";
import { Controller, useForm } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import endpoint from "../../../Helpers/endpoint";
import Http from "../../../http";
import Token from "../../../Helpers/Token";
import { useBillingInfo } from "../../../hooks/apihooks/useBillingInfo";
import { toast } from "react-toastify";
import { Spinner } from "../../Loader/Spinner";
import useDebounce from "../../../Helpers/useDebounce";
import Select from "react-select";

const BillingInformation = () => {
  const {register, reset, handleSubmit, control,setValue} = useForm();
  const {mutateAsync, isPending} = useBillingInfo();
  const getBillingInfo = () => {
    const URL = `${endpoint.billing.billing}`
    return Http().get(URL, {
      'Authorization' : `Bearer ${Token.getAccessToken()}`,
    });
  }

  const {data, isLoading}  = useQuery({queryKey:['getBillingInfo'], queryFn: getBillingInfo});

  useEffect(() => {
    if(data?.data){
      reset(data?.data);
      setValue('state', {value : 122,  label : data?.data?.state})
      setValue('country', {value : 123,  label : data?.data?.country})
    }
  },[data?.data])

 
  const SubmitBilling = (data) => {
    console.log(data, "data");
    // return ;
    mutateAsync({payload:data, token: Token.getAccessToken()}).then((resp) => {
      toast.success(resp?.message);
    })
  };

  const [searchAddress, setSearchAddress] = useState('');
  const [enabletosearch, setenableToSearch] = useState(true);
  const [dropDownEnable, setDropDownEnable] = useState(true);

  const [countrySearch, setCountrySearch] = useState({
    id: "",
    country_id  : "",
    search : ""
  });
  const [stateSearch, setStateSearch] = useState({
      id: "",
      state_id  : "",
      search : ""
  });

  const debcountrysearch = useDebounce(countrySearch, 500)
  const debstateSearch = useDebounce(stateSearch, 500)
  const debouncedSearchAddress = useDebounce(searchAddress, 2000)


  const getcountries = () => {
    let url = endpoint.libraries.counties+'?search='+debcountrysearch?.search;
    return Http().get(url);
  };


  const { data: Counties, isLoading : loadcountries} = useQuery({
      queryKey: ["getcountries", debcountrysearch ,debcountrysearch],
      queryFn: () => getcountries(debcountrysearch),
      enabled:Boolean(debcountrysearch)
  });

    // States
    const getstates = (name='') => {
        let url = endpoint.libraries.states+'?search='+name.search+'&country_id='+debcountrysearch.country_id;
        return Http().get(url);
    };

    const { data: States, isLoading : loadstates} = useQuery({
        queryKey: ["getstates", debstateSearch ,debcountrysearch],
        queryFn: () => getstates(debstateSearch),
        enabled:Boolean(debcountrysearch.country_id)
    });

    const getSearchGooglePlace = () => {
      const URL = `${endpoint.libraries.googleSearch}?search=${debouncedSearchAddress}`
      return Http().get(URL, {
        'Authorization' : `Bearer ${Token.getAccessToken()}`,
      });
    }

    const { data: placedata, isLoading : loadSearchPlace} = useQuery({
      queryKey: ["searchplaceapi", debouncedSearchAddress],
      queryFn: () => getSearchGooglePlace(),
      enabled:Boolean(enabletosearch)
    });

    function extractCountryCityStateZip(addressComponents) {
      let city = null;
      let state = null;
      let country = null;
      let zipCode = null;
      let streetAddress = "";
  
      addressComponents.forEach(component => {
          if (component.types.includes("premise")) {
             streetAddress = component?.long_name + ", "; // Add premise
          } else if (component.types.includes("sublocality")) {
              streetAddress += component?.long_name + ", "; // Add sublocality
          } else if (component.types.includes("locality")) {
              streetAddress += component?.long_name + ", "; // Add locality (city)
          }

          console.log(streetAddress, "streetAddress");
          
          if (component.types.includes("locality")) {
              city = component.long_name; // city
          } else if (component.types.includes("administrative_area_level_1")) {
              state = component.long_name; // state/region
          } else if (component.types.includes("country")) {
              country = component.long_name; // country
          } else if (component.types.includes("postal_code")) {
              zipCode = component.long_name; // postal/zip code
          }
      });

      console.log("all Data", {
        streetAddress: streetAddress || "Not available",
        city: city || "Not available",
        state: state || "Not available",
        country: country || "Not available",
        zipCode: zipCode || "Not available"
      });

      setValue("country", {value : 123, label : country});
      setValue("state", {value : 123, label : state});
      setValue("city", city);
      setValue("addressLine1", streetAddress);
      setValue("zipCode", zipCode);
      setCountrySearch({...countrySearch, ...{search : country}})
      setStateSearch({...stateSearch, ...{search : state}})
      return {
          city: city || "Not available",
          state: state || "Not available",
          country: country || "Not available",
          zipCode: zipCode || "Not available"
      };
  }
  

  return (
    <UserLayout>
      {isLoading|| isPending?  <Spinner size="medium" /> :""}
     
      <div className="w-[95%] mx-auto my-10 p-6 bg-white shadow-lg rounded-lg">
      <h1 className="text-3xl font-bold text-gray-800 mb-6">Billing Information</h1>
      <form onSubmit={handleSubmit(SubmitBilling)} className="space-y-6">
        <div className="bg-gray-100 p-4 rounded-lg">
          <h2 className="text-xl font-semibold text-gray-700">Billing Information</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-4">
            <div>
              <label className="block text-sm font-medium text-gray-600">Full Name</label>
              <input
                type="text"
                name="firstName"
                {...register('firstName')}
                required
                className="p-2 w-full border border-gray-300 rounded-lg"
                placeholder="John"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-600">Full Name</label>
              <input
                type="text"
                name="lastName"
                {...register('lastName')}
                required
                className="p-2 w-full border border-gray-300 rounded-lg"
                placeholder="Doe"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-600">Email Address</label>
              <input
                type="email"
                name="email"
                {...register('email')}
                required
                className="p-2 w-full border border-gray-300 rounded-lg"
                placeholder="example@domain.com"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-600">Company</label>
              <input
                type="text"
                name="company"
                {...register('company')}
                required
                className="p-2 w-full border border-gray-300 rounded-lg"
                placeholder="example@domain.com"
              />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-4">
            <div>
              <label className="block text-sm font-medium text-gray-600">Phone Number</label>
              <input
                type="text"
                name="phone"
                {...register('phone')}
                required
                className="p-2 w-full border border-gray-300 rounded-lg"
                placeholder="(123) 456-7890"
              />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-4">
            <div className="relative">
              <label className="block text-sm font-medium text-gray-600">Search your address</label>
              <input
                type="text"
                name="search_addres"
                autoFocus={false}
                onChange={(e) => {setSearchAddress(e.target.value); setenableToSearch(true); setDropDownEnable(true)}}
                value={searchAddress}
                className="p-2 w-full border border-gray-300 rounded-lg"
              />
              <div className={dropDownEnable?'absolute w-full bg-white z-10' :'hidden'}>
                {loadSearchPlace? <div>loading...</div>:""}
                {dropDownEnable &&  placedata?.results?.map((row) => {
                  return <div className="px-2 py-2 cursor-pointer border-b hover:bg-gray-300" onClick={() => {setSearchAddress(row?.formatted_address); setenableToSearch(false); setDropDownEnable(false); extractCountryCityStateZip(row?.address_components)
                  }}>{row?.formatted_address}</div>
                })}
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-4">
          <div>
              <label className="block text-sm font-medium text-gray-600">Country</label>
              <Controller
                    name="country"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                        <Select
                            {...field}
                            Searchable
                            onChange={(e) => 
                                {field.onChange(e); setCountrySearch({...countrySearch, ...{country_id : e.value}})}
                            }
                            onInputChange={(e) => setCountrySearch({...countrySearch, ...{search : e}})}
                            className="w-full font-primary text-sm  rounded-sm"
                            options={Counties?.data}
                            isLoading={loadcountries}
                            placeholder="--Select Country--"
                        />
                    )}
                />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-600">State</label>
              <Controller
                name="state"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                      {...field}
                      onChange={(e) => 
                          {field.onChange(e); setStateSearch({...stateSearch, ...{state_id : e.value}})}
                      }
                      onInputChange={(e) => setStateSearch({...stateSearch, ...{search : e}})}
                      className="w-full font-primary text-sm rounded-sm"
                      options={States?.data}
                      isLoading={loadstates}
                      isDisabled={countrySearch.country_id===""?true :false}
                      placeholder="--Select State--"
                  />
                )}
              />
            </div>
            
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-4">
            <div>
              <label className="block text-sm font-medium text-gray-600">City</label>
              <input
                type="text"
                name="city"
                {...register('city')}
                required
                className="p-2 w-full border border-gray-300 rounded-lg"
                placeholder="City"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-600">Postal Code</label>
              <input
                type="text"
                name="zipCode"
                {...register('zipCode')}
                required
                className="p-2 w-full border border-gray-300 rounded-lg"
                placeholder="ZIP Code"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-600">Street Address 1</label>
              <input
                type="text"
                name="addressLine1"
                {...register('addressLine1')}
                required
                className="p-2 w-full border border-gray-300 rounded-lg"
                placeholder="123 Main St"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-600">Street Address 2</label>
              <input
                type="text"
                name="addressLine2"
                {...register('addressLine2')}
                className="p-2 w-full border border-gray-300 rounded-lg"
                placeholder="123 Main St"
              />
            </div>
          </div>
        </div>

        {/* Submit Button */}
        <div className="mt-6 flex justify-end">
          <button
            type="submit"
            className="px-6 py-3 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700 transition-colors"
          >
            Save
          </button>
        </div>
      </form>
    </div>
    </UserLayout>
  );
};

export default BillingInformation;
