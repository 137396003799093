import {FaStar, FaTimes, FaRegFilePdf } from "react-icons/fa";
import ReactDOMServer from 'react-dom/server';
import { MdArrowForwardIos,MdArrowBackIosNew, MdOutlineContactPhone } from "react-icons/md";
import Tooltip from "../../../Helpers/Tooltip";
import {RiUserStarLine} from "react-icons/ri";
import { Link } from "react-router-dom";
import Candidateprintaspd from "./Candidateprintaspd";
import { useState } from "react";
import AlertConfirm from "../../Alert/AlertComfirm";
import { toast } from "react-toastify";
import Token from "../../../Helpers/Token";
import { useContactDetail } from "../../../hooks/apihooks/useContactDetailParse";
export default function Detailpopup({ openDetail, setOpenDetail, data, nextSlide, prevSlide, wrapperRef}) {
  const {mutateAsync} = useContactDetail();
  const stars = Array(data?.overall_rating).fill(<FaStar className="text-[orange]" />);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [resolvePromise, setResolvePromise] = useState(null); // to hold the promise resolution function
  const [message, setMessage] = useState({title : "", shortDesc : "", addButton: ""});

  const customConfirm = (title, shortDesc, addButton) => {
    setMessage({title, shortDesc, addButton});
    setIsDialogOpen(true);
    return new Promise((resolve, reject) => {
      setResolvePromise(() => resolve); // Store the resolve function to be called later
    });
  }

  // Handle when user clicks "Yes"
  const handleConfirm = () => {
    setIsDialogOpen(false);
    if (resolvePromise) {
      resolvePromise(true); // Resolve the promise with true (user confirmed)
    }
  };

  // Handle when user clicks "No"
  const handleCancel = () => {
    setIsDialogOpen(false);
    if (resolvePromise) {
      resolvePromise(false); // Resolve the promise with false (user canceled)
    }
  };


  const getContactDetail = async(profileLink) => {
    const result = await customConfirm("Get Contact Detail", "Are you sure you want to run to fetch the contact detail pls keep patience?");
    if(result){
      const postData = profileLink;
      mutateAsync({postdata : {profileLink : postData}, token: Token.getAccessToken()}).then((resp) => {
        if (resp.status === true) {
          const message = `
                      <strong>Please Hold On!</strong><br />
                      Your request is being processed. This may take a few moments.<br />
                      Thank you for your patience!
                  `;
          toast.success(<div dangerouslySetInnerHTML={{ __html: message }} />);
        }else if(resp.status === false){
          toast.error(resp?.error);
        }
      });
    }
  };


  const getRatingDetail = async(profileLink) => {
    const result = await customConfirm("Get Rating Detail", "Are you sure you want to fetch the rating details for this job? Please keep patience while the information is being retrieved.");
    if(result){
      const message = `
                  <strong>Please Hold On!</strong><br />
                  Your request rating  is being processed. This may take a few moments.<br />
                  Thank you for your patience!
              `;
      toast.success(<div dangerouslySetInnerHTML={{ __html: message }} />);
    }
  };



  const printDownload = async() => {
    const result =  data?.contactdata?.contact ? true : await customConfirm("Profile print", "Do you want to print the data without including contact details?",<Tooltip message={'Fetch Contact'}>
      <button  className="mt-3 inline-flex w-full justify-center rounded-md bg-bgbrown-100 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset sm:ml-3 ring-gray-300 hover:bg-green-500 sm:mt-0 sm:w-auto">
        <MdOutlineContactPhone size={22} />
      </button>
    </Tooltip>);
    // let flag = (data?.contactdata?.contact) ? true : window.confirm('Do you want to print the data without including contact details?');
    if(result){
      // Render the React component to static HTML string
      const content = ReactDOMServer.renderToStaticMarkup(<Candidateprintaspd data={data} />);
      // Open a new window
      const printWindow = window.open();
      // Write the content into the new window's document
      printWindow.document.write(`
        <html>
          <head>
            <title>${data?.first_name} ${data?.last_name}</title>
            <script src="https://cdn.tailwindcss.com"></script>
            <style>
              /* Optional: Add custom styles for printing */
              body {
                font-family: Arial, sans-serif;
                margin: 20px;
              }
              @media print {
                body {
                  width: 100%; /* Make sure the body is the full width */
                }
                .print-container {
                  width: 100%; /* Set the desired width for printing */
                  max-width: 800px; /* Optional: set max width */
                  margin: 0 auto; /* Center the content */
                }
              }
            .commingsoon1 {
                background-image: url(http://localhost:3000/pexels-enginakyurt-9899785.bfbd8407623986b335b5.jpg);
                background-repeat: no-repeat;
                background-position: center, center;
                background-size: cover;
            }
              .print-container {
                max-width: 800px; /* Adjust to your needs */
                margin: 0 auto;
              }

              .w-60px{
              width: 60px;
              }

              .bg-bgbrown-100{
                background-color:#ffd1af;
              }
            </style>
          </head>
          <body>
            <div class="print-container">
            ${content}
            <div>
          </body>
        </html>
      `);
      // Close the document to make sure everything is fully rendered
      printWindow.document.close();
      // Delay the print dialog by 500ms
      setTimeout(() => {
        printWindow.print();
      }, 500);
    }
  };
  return (
    <>
    <AlertConfirm title={message.title}  shortDesc={message.shortDesc} addbutton={message.addButton} isDialogOpen={isDialogOpen} onConfirm={handleConfirm}
          onCancel={handleCancel} />
    <div
      className={`fixed top-0 right-0 w-[40%] shadow-lg h-[100vh] overflow-auto bg-[white] z-10 transform  transition-transform duration-500 ease-in-out  ${openDetail ? ' translate-x-0 ' : 'translate-x-full'}`}
      aria-labelledby="modal-title"
      role="dialog"
      id="printable-div"
      aria-modal="true"
      ref={wrapperRef}
    >
      
      <div className="">
        <div className="flex min-h-full items-end justify-center text-center sm:items-center relative">
          <div className="relative transform text-left shadow-xl transition-all sm:w-full ">
            <div className="w-full break-words">
              <div className="sm:flex flex-col rounded sm:items-start w-full">
                <div className="commingsoon1 w-full sticky top-0">
                  <div className=" bg-[#00000063] text-white py-4 px-4">
                    <button type="button" className="absolute z-50 text-white left-[10px] top-[8px] rounded-full h-[30px] w-[30px]" onClick={() => setOpenDetail(false)}><FaTimes /></button>
                    <div className="flex w-full flex-wrap">
                      <div className="w-[25%]">
                        {data?.profile ? 
                          <img className="border-2 mt-5 rounded-[50%] w-[100px] h-[100px]" src={data?.profile} alt="" /> :
                          <div className="border-2  mt-5 rounded-[50%] w-[100px] h-[100px] text-[30px] bg-green-600 font-bold text-center leading-[100px]">{data?.firstname?.substr(0,1)}</div> }
                      </div>
                      <div className="flex flex-wrap w-[75%]">
                        <div className="w-[75%]">
                          {data?.overall_rating? <div className="flex items-center  mt-6 mb-1">
                            {stars}
                          </div> : ""}
                          <div className="w-full pb-1 font-bold order-first">{data?.firstname} {data?.lastname}</div>
                            {
                              data?.contactdata?.contact?.map((row, index) => {
                                return <div className="w-full pb-1 font-bold">{row?.value}</div>
                              })
                            }
                            <div>
                              {
                                data?.worklist_id?.map((row, index) => {
                                  return <span className="bg-gray-300 text-[10px] mr-1 px-[3px] py-[2px] rounded text-[black]">{row?.label} x</span>
                                })
                              }
                            </div>
                        </div>
                        <div className="w-[25%] flex justify-end">
                          <MdArrowBackIosNew size={10} onClick={() => {prevSlide()}} className="mr-2 hover:bg-bgbrown-100 cursor-pointer w-[28px] h-[28px] p-1 border rounded" />
                          <MdArrowForwardIos size={15} onClick={() => {nextSlide()}} className="cursor-pointer hover:bg-bgbrown-100 w-[28px] h-[28px] p-1 border rounded"  />
                        </div>
                        <div className="flex w-full items-end">
                          <div className="w-[50%]"></div>
                          <div className="w-[50%] flex justify-end">
                            <Tooltip message={'Fetch Rating'}>
                              <button  className="px-1 py-1 hover:bg-bgbrown-100  border mr-2 rounded" onClick={() => getRatingDetail()}>
                                <RiUserStarLine size={18} />
                              </button>
                            </Tooltip>
                            <Tooltip message={'Fetch Contact'}>
                              <button  className="px-1 py-1 hover:bg-bgbrown-100 mr-2  border rounded" onClick={() => getContactDetail()}>
                                <MdOutlineContactPhone size={18} />
                              </button>
                            </Tooltip>
                            <Tooltip message={'Download pdf profile file'}>
                              {/* to={`${process.env.REACT_APP_APIENDPOINT}/manage-user-service/v1/pdf-donwload-candidate/${btoa(data?.urn)}/${btoa(data?.id)}`}  */}
                              <Link onClick={() => printDownload()} className="px-1 py-1 hover:bg-bgbrown-100 block  border rounded">
                                <FaRegFilePdf size={18} />
                              </Link>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>

                <div className="bg-bgbrown-100 w-full py-2 px-2 ">Rating</div>
                <div className="w-full px-4 py-3">
                  {data?.overall_rating? <>
                  <div>
                      <p><strong>About :</strong>Satya Keerthi Singamsetty, assessing their suitability for a "Project Manager – Professional Services" role. The evaluation highlights that while Satya has extensive experience in data engineering and related technologies, they lack specific project management skills and experience, which are critical for this role.</p>
                      <p><strong>Project Management:</strong> Rated 1 out of 5 stars – No explicit experience or skills in project management are mentioned.</p>
                      <p><strong>ERP Implementation:</strong> Rated 1 out of 5 stars – No mention of experience in ERP implementation.</p>
                      <p><strong>MS Project:</strong> Rated 1 out of 5 stars – No mention of experience using MS Project.</p>
                      <p><strong>Visio:</strong> Rated 1 out of 5 stars – No mention of experience using Visio.</p>
                      <p><strong>Client Interaction:</strong> Rated 2 out of 5 stars – There is likely some experience in client interaction within a professional services context, but it is not explicitly detailed.</p>
                      <p><strong>Communication Skills:</strong> Rated 4 out of 5 stars – Given Satya’s technical background, they likely possess good communication skills, though this is not explicitly confirmed.</p>
                  </div>
                  </>:""}
                </div>
                <div className="bg-bgbrown-100 w-full py-2 px-2 ">Title</div>
                <div className="w-full px-4 py-3">{data?.headline}</div>
                <div className="bg-bgbrown-100 w-full py-2 px-2 ">Skills</div>
                <div className="w-full px-4 py-2">{data?.skills}</div>
                <div className="bg-bgbrown-100 w-full py-2 px-2 ">Education</div>
                <div className="w-full px-4 py-2">
                  {
                    data?.education?.map((row, index) => {
                      return <div>
                          <div className="py-2 flex">
                            <div className="w-[60px]"><img src={row?.logo ? row?.logo : '/images/school.png' } className="w-[50px]" alt="logo"/></div>
                            <div className="">
                              <p>{row?.title}</p>
                              <p>{row?.subtitle}</p>
                              <p>{row?.caption}</p>
                            </div>
                          </div>
                        </div>
                    })
                  }
                </div>
                <div className="bg-bgbrown-100 w-full py-2 px-2 ">
                  Experience
                </div>
                <div className="w-full px-4 py-2">
                  {
                    data?.experience?.map((row, index) => {
                      return <div>
                        <div className="py-2 flex">
                          <div className="w-[60px]"><img src={row?.logo ? row?.logo : '/images/school.png' }  alt="logo" className="w-[50px]"/></div>
                          <div className="">
                            <p>{row?.title}</p>
                            <p>{row?.subtitle}</p>
                            <p>{row?.caption}</p>
                          </div>
                        </div>
                        
                        </div>
                    })
                  }
                </div>
                <div className="bg-bgbrown-100 w-full py-2 px-2 ">
                  Projects
                </div>
                <div className="w-full px-4 py-2">
                  {
                    data?.projects?.map((row, index) => {
                      return <div>
                          <div className="py-2 flex">
                            <div className="w-[60px]"><img src={row?.logo ? row?.logo : '/images/school.png' } className="w-[50px]" alt=""/></div>
                            <div className="">
                              <p>{row?.title}</p>
                              <p>{row?.subtitle}</p>
                              <p>{row?.caption}</p>
                            </div>
                          </div>
                        </div>
                    })
                  }
                </div>
                <div className="bg-bgbrown-100 w-full py-2 px-2 ">
                Certificates
                </div>
                <div className="w-full px-4 py-2">
                  {
                    data?.certificates?.map((row, index) => {
                      return <div>
                        <div className="py-2 flex">
                          <div className="w-[60px]"><img src={row?.logo ? row?.logo : '/images/school.png' } className="w-[50px]" alt=""/></div>
                          <div className="">
                            <p>{row?.title}</p>
                            <p>{row?.subtitle}</p>
                            <p>{row?.caption}</p>
                          </div>
                        </div>
                        </div>
                    })
                  }
                </div>
                
                <div className="bg-bgbrown-100 w-full py-2 px-2 ">Summary</div>
                <div className="w-full px-4 py-2">{data?.about}</div>
                <div className="bg-bgbrown-100 w-full py-2 px-2 ">Language</div>
                <div className="w-full px-4 py-2">{data?.language}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}
