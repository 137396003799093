// import Select from "react-select";
import { useEffect, useState } from "react";
import { FaPlus, FaTimes } from "react-icons/fa";
// import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import Token from "../../Helpers/Token";
import { useCandDeleteEvent } from "../../hooks/apihooks/useCandidate";

export function ProjectCand ({project, setValue}){
    // const [enabled, setEnabled] = useState({
    //     department: false,
    //     city: false,
    //     skills: false,
    // });

    // const enabled  = {
    //     department: false,
    //     city: false,
    //     skills: false,
    // }
    
    const {mutateAsync} = useCandDeleteEvent();

    // const [countrySearch, setCountrySearch] = useState("");
    const [Project, setProject] = useState([]);

    // const getCity = (city) => {
    //     let url = `http://hiringplug.local/manage-helpdesk-service/v1/city?keyword=${city}`;
    //     return Http().get(url);
    //   };
    
    // const { data: dataArr, isLoading } = useQuery({
    //     queryKey: ["getallcity", countrySearch],
    //     queryFn: () => getCity(countrySearch),
    //     enabled: Boolean(enabled.city),
    // });

    // const LocationOptions = dataArr?.data.map((city) => ({
    //     value: city.cityid,
    //     label: city.city,
    // }));

    const handleAddProject = () => {
        setProject([
          ...Project,
          {
            job_title: "",
            company_name: "",
            location: "",
            start_date: "",
            end_date: "",
          },
        ]);
    };

    // const handleProLocation = (e, action, index) => {
    //     const { value, label } = e;
    //     const { name } = action;
    //     const list = [...Project];
    //     list[index][name] = { value: value, label: label };
    //     setProject(list);
    // };

    const onChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...Project];
        list[index][name] = value;
        setProject(list);
    };

    const handleRemoveProject = (index, actionid) => {
        const List = [...Project];
        List.splice(index, 1);
        setProject(List);

        if(actionid){
            let data = {
                action : "project",
                actionid : actionid
            }
            mutateAsync({data, token:Token.getAccessToken()}).then((resp) => {
                if(resp?.status===true){
                    toast.success(resp?.message);
                }else{
                    toast.warning(resp?.message);
                }
            });
        }

    };

    useEffect(() => {
        setValue('project', Project);
        // eslint-disable-next-line
    }, [Project]);

    useEffect(() => {
        if(project){
            setProject(project)
        }
    }, [project]);

    return(
        <>
        <div  className="border w-full py-4 rounded">
        <div className="w-full flex justify-between items-end gap-3 flex-col md:flex-row">
            <div className="gap-5 flex flex-col items-start pl-4">
                <span className="text-[16px] font-semibold">
                    Project
                </span>
            </div>
            <p
            className={`flex text-[green] items-center text-green cursor-pointer pr-4`}
            onClick={() => handleAddProject()}
            >
            <FaPlus /> Add
            </p>
        </div>
        {Project?.map((data, index) => (
            <div
            key={index + index}
            className="w-full relative flex py-2 px-2 border-b-2 last:border-b-0 border-gray-300 hover:bg-green-200 cursor-pointer items-center justify-center flex-col gap-4"
            >
                <button type="button" className="text-red-600 absolute right-[-10px] top-[-10px] bg-gray-400 w-[30px] h-[30px] rounded-full items-center text-center flex justify-center" onClick={()=> handleRemoveProject(index, data?.id)}><FaTimes /></button>
                <div className="w-full flex items-center justify-center gap-3 flex-col md:flex-row">
                    <input
                    type="text"
                    onChange={(e) => onChange(e, index)}
                    value={data.title}
                    name="title"
                    placeholder="Project name"
                    className="w-full px-3 text-sm h-10 border border-border-col rounded-[4px] "
                    />
                    <input
                        type="text"
                        onChange={(e) => onChange(e, index)}
                        value={data.link}
                        name="link"
                        placeholder="Project Link"
                        className="w-full px-3 text-sm h-10 border border-border-col rounded-[4px] "
                    />
                </div>
                <div className="w-full flex items-center justify-center gap-3 flex-col md:flex-row">
                    <input
                        type="text"
                        onChange={(e) => onChange(e, index)}
                        value={data.technologies}
                        name="technologies"
                        placeholder="Add skills"
                        className="w-full px-3 text-sm h-10 border border-border-col rounded-[4px] "
                    />
                    {/* <input
                        type="date"
                        value={data.description}
                        name="description"
                        placeholder="Project Description"
                        onChange={(e) => onChange(e, index)}
                        className="w-full px-3 text-sm h-10 border border-border-col rounded-[4px] "
                    /> */}
                </div>
            </div>
        ))}
        </div>
    </>
    )
}