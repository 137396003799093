import { useMutation } from "@tanstack/react-query";
import endpoint from "../../Helpers/endpoint";
import Http from "../../http";

const subscribeManual = (data) => {
  const URL = `${endpoint.transection.manSubscription}`;
  return Http().post(URL, data);
}

export const useManualSubscriber = () => {
  return useMutation({
      mutationFn:subscribeManual,
      onSuccess: (data, variables, context) => {
        // I will fire first
      },
      onError: (error, variables, context) => {
        // I will fire first
      },
      onSettled: (data, error, variables, context) => {
        // I will fire first
      },
  });
}
