import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation
} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AdminCandidateList from "./Components/Admin/AdminCandidate/AdminCandidateList";
import AdminConnectATS from "./Components/Admin/AdminConnectATS/AdminConnectATS";
import AdminReport from "./Components/Admin/AdminReport/AdminReport";
import AddEditProduct from "./Components/Admin/Product/AddEditProduct";
import AdminProduct from "./Components/Admin/Product/AdminProduct";
import ProfilePage from "./Components/Admin/Users/AddEditProfile";
import AdminUsers from "./Components/Admin/Users/AdminUsers";
import LinkedInOAuth from "./Components/Auth/LinkedInOAuth";
import Errors from "./Components/Error/Error";
import Campaign from "./Components/Users/Campaign";
import Listing from "./Components/Users/Campaign/Listings";
import Candidates from "./Components/Users/Candidates";
import CandidateEdit from "./Components/Users/Candidates/CandidateEdit";
import CartProduct from "./Components/Users/CartProduction/Cartproduct";
import ChangePassword from "./Components/Users/ChanagePassword/ChangePassword";
import ConnectAts from "./Components/Users/connect-ats/ConnectAts";
import Jobs from "./Components/Users/Jobs";
import JobDetail from "./Components/Users/Jobs/JobDetail/JobDetail";
import JobListing from "./Components/Users/Jobs/Listing";
import Plug from "./Components/Users/Plug";
import Profile from "./Components/Users/Profile/Profile";
import Team from "./Components/Users/Team";
import AddTeam from "./Components/Users/Team/AddTeam/AddTeam";
import Verification from "./Components/Users/Verification/Verification";
import Website from "./Components/Website";
import AboutUS from "./Components/Website/About/AboutUs";
import ContactUS from "./Components/Website/Contact/ContactUs";
import DemoRequest from "./Components/Website/DemoRequest/DemoRequest";
import ForgotPassword from "./Components/Website/ForgotPassword/Forgotpassword";
import Legal from "./Components/Website/Legal/Legal";
import PrivacyPolicy from "./Components/Website/PrivacyPolicy/PrivacyPolicy";
import ResetPassword from "./Components/Website/Resetpassword/ResetPassword";
import Signin from "./Components/Website/Signin/Signin";
import Signup from "./Components/Website/Signup/Signup";
import TermsAndCondition from "./Components/Website/TermsAndCondition/TermsAndCondition";
import Role from "./Helpers/Role";
import Token from "./Helpers/Token";
import AdminLayouts from "./Layouts/Admin/AdminLayouts";
import UserLayout from "./Layouts/Users/UserLayout";
import { UserReport } from "./Components/Users/Reports";
import { CandidatSourcing } from "./Components/Users/Candidatesource";
import LinkedinCampaign from "./Components/Users/Campaign/LinkedinCampaign";
import CampaignDetail from "./Components/Users/Campaign/LinkedinCampaign/CampaignDetail";
import Leads from "./Components/Users/Leads";
import Accounts from "./Components/Users/Accounts";
import { CandList } from "./Components/Users/Candidatesource/CandList";
import Notification from "./Components/Users/Notification";
import Inbox from "./Components/Users/Inbox/Inbox";
import Subscription from "./Components/Users/Subscription";
import BillingInformation from "./Components/Users/Subscription/BillingInformation";
import TransactionHistory from "./Components/Users/Subscription/TransactionHistory";
import APIDashboard from "./Components/Admin/APis";
import ApiList from "./Components/Admin/APis/APiList";
import ApiDetails from "./Components/Admin/APis/APiDetail";
import Candidateprintaspd from "./Components/Users/Candidatesource/Candidateprintaspd";
import UserDashboard from "./Components/Users/Dashboard/UserDashboard";
import AdminDashboard from "./Components/Admin/AdminDashboard/AdminDashboard";
import AdminNotification from "./Components/Admin/Notification";
import AdminJobList from "./Components/Admin/AdminJobList";
import Career from "./Components/Users/Career";
import { AdminJobDetail } from "./Components/Admin/AdminJobList/AdminJobDetail";
import PageNotFound from "./Components/NotFound/PageNotFound";
import PaymentStatus from "./Components/Website/PaymentStatus/PaymentStatus";
import InvoiceOrder from "./Components/Users/Invoice/InvoiceOrder";
import AdminOrderSubscription from "./Components/Admin/AdminSubscription/AdminOrderSubscription";
import AdminTransections from "./Components/Admin/AdminSubscription/AdminTransections";
import AdminBillingAndInvoice from "./Components/Admin/AdminSubscription/AdminBillingAndInvoice";
import AdminPromocode from "./Components/Admin/Promocode/AdminPromocode";
import AddPromocode from "./Components/Admin/Promocode/AddPromocode";
import AdminDataMapper from "./Components/Admin/AdminDataMapper/AdminDataMapper";
import StreamedData from "./Components/Website/StreamingTest";

function App() {
  return (
    <div className="App font-primary">
      <ToastContainer />
      <BrowserRouter>
        <Routes>
            {/* <Route path="/" element={<ComingSoon />} /> */}
            <Route path="/" element={<Website />} />
            <Route path="/test-stream" element={<StreamedData />} />
            <Route path="/about" element={<AboutUS />} />
            <Route path="/career/:campaignid" element={<Career />} />
            <Route path="/contact-us" element={<ContactUS />} />
            <Route path="/demo-request" element={<DemoRequest />} />
            <Route path="/signin" element={<Signin />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-and-condition" element={<TermsAndCondition />} />
            <Route path="/legal" element={<Legal />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="/verification/:verifytoken" element={<Verification />} />
            <Route path="/cartproduct/:productuid" element={<CartProduct />} />
            <Route path="/error" element={<Errors />} />
            <Route path="/auth/linkedin/callback" element={<LinkedInOAuth />} />
            <Route path="/subscription-confirmation/" element={<PaymentStatus />} />
            
            <Route
              className="User_dashboard"
              element={<AuthMiddleware allowedRole={'User'} />}
            >
              <Route path="/dashboard" element={<UserDashboard />} />
              <Route path="/campaigns/:campaignuid" element={<Campaign />} />
              <Route path="/campaigns" element={<Campaign />} />
              <Route path="/campaigns-create-campaign" element={<LinkedinCampaign />} />
              <Route path="/campaigns-external/:campaignuid" element={<CampaignDetail />} />
              <Route path="/campaigns-list" element={<Listing />} />
              <Route path="/campaigns-list/:type" element={<Listing />} />
              <Route path="/leads" element={<Leads />} />
              <Route path="/inbox" element={<Inbox />} />
              <Route path="/candidates" element={<Candidates />} />
              <Route path="/add-team" element={<AddTeam title="Add Member" />} />
              <Route path="/plug" element={<Plug />} />
              <Route path="/plug/:chat_uid" element={<Plug />} />
              <Route path="/jobs" element={<Jobs />} />
              <Route path="/job-listing" element={<JobListing />} />
              <Route path="/team" element={<Team />} />
              <Route path="/add-team/:panelid" element={<AddTeam title="Edit Member" action={'edit'} />} />
              <Route path="/candidate/preview/:candidateuid" element={
                  <UserLayout>
                    <CandidateEdit />
                  </UserLayout>
              } />

              <Route path="/jobdetail/:jobuid" element={
                  <UserLayout>
                    <JobDetail />
                  </UserLayout>
              } />
              <Route path="/change-password" element={<ChangePassword />} />
              <Route path="/connect-ats" element={<ConnectAts />} />
              <Route path="/forgot-password" element={<p>forgot-password</p>} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/accounts" element={<Accounts />} />
              <Route path="/ratings" element={<p>ratings</p>} />
              <Route path="/reportdata" element={<UserReport />} />
              <Route path="/source-public-candidate" element={<CandidatSourcing />} />
              <Route path="/candidate-public/:campainuid" element={<CandList />} />
              <Route path="/notifications" element={<Notification />} />
              <Route path="/subscription-plan" element={<Subscription />} />
              <Route path="/billing-information" element={<BillingInformation />} />
              <Route path="/transection-history" element={<TransactionHistory />} />
              <Route path="/candidate-public/print-candiate-as-pdf" element={<Candidateprintaspd />} />
              <Route path="/download/invoice/:orderid" element={<InvoiceOrder />} />

            </Route>

            {/* Admin Dashboard */}
            <Route
              className="Admin_Dashboard"
              element={<AuthMiddleware allowedRole={'Admin'} />}
            >
              <Route index path={"/admin/dashboard"} element={<AdminDashboard />} />
              <Route path="/admin/job-list" element={<AdminJobList />} />
              <Route path="/admin/candidates" element={<AdminCandidateList />} />
              <Route path="/admin/job-detail/:jobuid" element={<AdminJobDetail />} />
              <Route path="/admin/component" element={<p>/admin/component</p>} />
              <Route path="/admin/product" element={<AdminProduct />} />
              <Route path="/admin/product/add" element={<AddEditProduct title="Add Product"/>} />
              <Route path="admin/promocode" element={<AdminPromocode/>} />
              <Route path="admin/promocode/add" element={<AddPromocode/>} />
              

              
              <Route path="/admin/users/edit/:userid" element={<ProfilePage />} />
              <Route path="/admin/product/add/:productuid" element={<AddEditProduct title="Edit Product" />} />
              <Route path="/admin/preview/:candidateid" element={
                <AdminLayouts>
                <CandidateEdit />
                </AdminLayouts>
              } />
              <Route path="/admin/users" element={<AdminUsers />} />
              <Route path="/admin/admin-report" element={<AdminReport />} />
              <Route path="/admin/connect-ats" element={<AdminConnectATS />} />
              <Route path="/admin/datasource-mappers" element={<AdminDataMapper />} />
              <Route path="/admin/apis" element={<APIDashboard />} />
              <Route path="/admin/api-list" element={<ApiList />} />
              <Route path="/admin/api-details/add" element={<ApiDetails />} />
              <Route path="/admin/api-details/edit/:apiuid" element={<ApiDetails />} />
              <Route path="/admin/notifications" element={<AdminNotification />} />
              <Route path="/admin/order-and-subscription" element={<AdminOrderSubscription />} />
              <Route path="/admin/transection-history" element={<AdminTransections />} />
              <Route path="/admin/billing-and-invoices" element={<AdminBillingAndInvoice />} />
              
            </Route>
            {/* Page Not Found */}
            <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

function AuthMiddleware({ allowedRole }) {
  const accessToken = Token.getAccessToken();
  const userRole = Role.getAccessRole();
  let location = useLocation();
  if (accessToken && userRole === allowedRole) {
    return <Outlet />;
  } else {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
}
