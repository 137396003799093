import React from "react";
import AdminLayouts from "../../../Layouts/Admin/AdminLayouts";
import { Page } from "./Page";

export default function AdminNotification() {
  return (
    <AdminLayouts>
      <Page />
    </AdminLayouts>
  );
}
