import { useEffect, useState } from "react";
import { FaEdit } from "react-icons/fa";
import { useParseProfile } from "../../../../hooks/apihooks/useParseProfile";
import { Spinner } from "../../../Loader/Spinner";
import Token from "../../../../Helpers/Token";
import { toast } from "react-toastify";

export default function PreviewPopup({ open, setOpen, getdata}) {
  const [newdata, setData] = useState(getdata);
  const [editJobDetail, seteditJobDetail] = useState(false);
  const [totalnorecord, setTotalRecord] = useState(20);
  const {mutateAsync, isPending} = useParseProfile();
  const handleSearch = () => {
      const includedKeywords = newdata?.extract?.Skills.map(item => `"${item}"`).join(' AND '); 
      const query = `"${newdata?.extract?.JobTitle}" "${newdata?.extract?.Location}" AND ${includedKeywords} -intitle:"profiles" -inurl:"dir/ "site:in.linkedin.com/in/ OR site:in.linkedin.com/pub/`;
      // Encode the query for the URL
      const encodedQuery = encodeURIComponent(query);
      // Generate the Google search URL
      // Open the URL in a new tab
      setOpen(false);
      mutateAsync({encodedQuery,totalnorecord, campaign:{campaign : "yes"}, token: Token.getAccessToken()}).then((resp) => {
        if(resp?.status===true){
          toast.success(resp?.message);
          setOpen(false);
        }
      })
  };

  useEffect(() => {
    if (open) {
        setData(getdata); // Update state when popup opens
    }
}, [open, getdata]); // Dependencies: re-run effect when open or getdata changes

  
  return (
    <>
    {isPending ? <Spinner size="medium" /> : ""}
    <div
      className={`relative z-10 ${open ? `` : `hidden tansition-all`}`}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        className="fixed  inset-0 bg-gray-500 bg-opacity-75"
        aria-hidden="true"></div>
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 relative">
          <div className="relative transform bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[800px]">
          <button type="button" className="bg-[red] absolute z-50 text-white right-[-10px] top-[-10px] rounded-full h-[30px] w-[30px]" onClick={() => setOpen(false)}>X</button>
            <div className="bg-white  px-4 pb-4 pt-5 sm:p-6 sm:pb-4 w-full">
              <div className="sm:flex flex-col rounded sm:items-start w-full">
                <div className="w-full justify-between flex items-center rounded py-2">
                    <h2 className="text-center font-bold">Job Details</h2>
                    <p className="px-2 py-2 text-[green]"><FaEdit onClick={() => seteditJobDetail(!editJobDetail)} size={20} /></p>
                </div>
                {editJobDetail===false && Object.keys(newdata).length > 0  ? 
                <>
                    <div className="font-bold bg-[#e0e0e0] w-full">Job Title</div>
                    {newdata?.extract?.jobTitle}
                    <div className="font-bold  bg-[#e0e0e0] w-full">Experience</div>
                    {newdata?.extract?.experience}
                    <div className="font-bold  bg-[#e0e0e0] w-full">Skills</div>
                    {newdata?.extract?.goodToHaveSkillsets}
                    <div className="font-bold  bg-[#e0e0e0] w-full">Education</div>
                    {newdata?.extract?.qualification}
                    <div className="font-bold  bg-[#e0e0e0] w-full">Location</div>
                    {newdata?.extract?.city}
                    <div className="font-bold  bg-[#e0e0e0] w-full">WorkMode</div>
                    {newdata?.extract?.workMode}
                    <div className="font-bold  bg-[#e0e0e0] w-full">KeyRequirements</div>
                    {newdata?.extract?.keyRequirements?.map((row, index) => {
                    return <p>{index+1} : {row}</p>
                    })}
                    <div className="font-bold  bg-[#e0e0e0] w-full">Industry</div>
                    <div>{newdata?.extract?.industry}</div>
                    <div className="font-bold  bg-[#e0e0e0] w-full">Total Record</div>
                    <input type="text" onChange={(e) => setTotalRecord(e.target.value)} className="w-full px-2 border rounded py-1 my-2"  value={totalnorecord} />
                </>
                : editJobDetail===true && Object.keys(newdata).length > 0  ?
                <>
                    <div className="font-bold bg-[#e0e0e0] w-full">Job Title</div>
                    <input type="text" onChange={(e) => setData(prevData => ({
                        ...prevData,
                        extract: {
                            ...prevData.extract,
                            jobTitle: e.target.value
                        }
                    }))} className="w-full px-2 border rounded py-1 my-2"  value={newdata?.extract?.jobTitle} />
                    <div className="font-bold  bg-[#e0e0e0] w-full">Experience</div>
                    <input type="text" className="w-full px-2 border rounded py-1 my-2" onChange={(e) => setData(prevData => ({
                        ...prevData,
                        extract: {
                            ...prevData.extract,
                            experience: e.target.value
                        }
                    }))} value={newdata?.extract?.experience} />
                    <div className="font-bold  bg-[#e0e0e0] w-full">Skills</div>
                    <input type="text" className="w-full px-2 border rounded py-1 my-2" onChange={(e) => setData(prevData => ({
                        ...prevData,
                        extract: {
                            ...prevData.extract,
                            goodToHaveSkillsets: e.target.value.split(',').map(skill => skill)
                        }
                    }))} value={newdata?.extract?.goodToHaveSkillsets} />
                    <div className="font-bold  bg-[#e0e0e0] w-full">Education</div>
                    <input type="text" className="w-full px-2 border rounded py-1 my-2" onChange={(e) => setData(prevData => ({
                        ...prevData,
                        extract: {
                            ...prevData.extract,
                            education: e.target.value
                        }
                    }))} value={newdata?.extract?.qualification} />
                    <div className="font-bold  bg-[#e0e0e0] w-full">Location</div>
                    <input type="text" className="w-full px-2 border rounded py-1 my-2" onChange={(e) => setData(prevData => ({
                        ...prevData,
                        extract: {
                            ...prevData.extract,
                            city: e.target.value
                        }
                    }))} value= {newdata?.extract?.city} />
                    <div className="font-bold  bg-[#e0e0e0] w-full">WorkMode</div>
                    <input type="text" className="w-full px-2 border rounded py-1 my-2" onChange={(e) => setData(prevData => ({
                        ...prevData,
                        extract: {
                            ...prevData.extract,
                            workMode: e.target.value
                        }
                    }))}  value={newdata?.extract?.workMode}/>
                    <div className="font-bold  bg-[#e0e0e0] w-full">KeyRequirements</div>
                    {newdata?.extract?.keyRequirements?.map((value, index) => (
                        <input
                            key={index} // Add a unique key for each input
                            type="text"
                            className="w-full px-2 border rounded py-1 my-2"
                            value={value}
                            onChange={(e) => setData(prevData => {
                                const updatedKeyRequirements = [...prevData.extract.KeyRequirements];
                                updatedKeyRequirements[index] = e.target.value; // Use the new input value
                                return {
                                    ...prevData,
                                    extract: {
                                        ...prevData.extract,
                                        KeyRequirements: updatedKeyRequirements
                                    }
                                };
                            })}
                        />
                    ))}
                    <div className="font-bold  bg-[#e0e0e0] w-full">Industry</div>
                    <input type="text" className="w-full px-2 border rounded py-1 my-2" onChange={(e) => setData(prevData => ({
                        ...prevData,
                        extract: {
                            ...prevData.extract,
                            industry: e.target.value
                        }
                    }))}  value={newdata?.extract?.industry}/>
                    <div className="font-bold  bg-[#e0e0e0] w-full">WorkExperience</div>
                    {newdata?.extract?.workExperience?.map((value, index) => (
                        <input
                        key={index} // Add a unique key for each input
                        type="text"
                        className="w-full px-2 border rounded py-1 my-2"
                        value={value}
                        onChange={(e) => setData(prevData => {
                            const updatedWorkExperience = [...prevData.extract.workExperience];
                            updatedWorkExperience[index] = e.target.value; // Use the new input value
                            return {
                                ...prevData,
                                extract: {
                                    ...prevData.extract,
                                    workExperience: updatedWorkExperience
                                }
                            };
                        })}
                    />
                    ))}
                    <div className="font-bold  bg-[#e0e0e0] w-full">Total Record</div>
                    <input type="text" onChange={(e) => setTotalRecord(e.target.value)} className="w-full px-2 border rounded py-1 my-2"  value={totalnorecord} />
                </> 
                : "" }
              </div>
              <div>
              <button type="button" class="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2" onClick={() => handleSearch()}>Search</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}
