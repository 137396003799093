import moment from "moment";
import { FaTimes } from "react-icons/fa";
export default function PopupSubscription ({popup, setPopup, data}){
    let productname = '';
    let describe = '';
    

    data?.meta_info?.map((metavalue) => {
        if(metavalue?.meta_key==='product'){
          productname = metavalue?.meta_value?.productname;
          describe = metavalue?.meta_value?.description;
        }
    });

    return (
        <>
        <div class={`relative z-10 ${popup?'':'hidden'}`} aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div class="fixed inset-0 bg-gray-500/75 transition-opacity" aria-hidden="true"></div>
            <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <div class="relative transform text-left transition-all sm:w-[800px] sm:max-w-[800px] max-w-3xl mx-auto p-8">
                        <div className="w-full relative mx-auto bg-white rounded-lg shadow-lg">
                        <div className="absolute top-[-10px] right-[-10px] w-[30px] h-[30px] rounded-full bg-red-600 flex justify-center items-center cursor-pointer" onClick={() => setPopup(false)}><FaTimes /></div>
                            <div className="p-6 border-b border-gray-200">
                                <h1 className="text-2xl font-semibold text-gray-800">Subscription Details</h1>
                            </div>
                            <div className="p-6">
                                <div className="mb-6">
                                <h2 className="text-xl font-semibold text-gray-700">Subscription Plan</h2>
                                <div className="mt-2">
                                    <p className="text-gray-500">Order ID: <span className="font-semibold">#{data?.order_id}</span></p>
                                    <p className="text-gray-500">Status: <span className="text-green-500 font-semibold">{data?.status}</span></p>
                                    <p className="text-gray-500">Validity: <span className="text-green-500 font-semibold">{moment(data?.start_date).format('ll')} - {moment(data?.end_date).format('ll')}</span></p>
                                    <p className="text-gray-500">Plan: {productname}</p>
                                    <p className="text-gray-500">Price: {data?.currency} {Number(data?.amount)?.toFixed(2)}</p>
                                </div>
                                </div>

                                <div className="mb-6">
                                <h2 className="text-xl font-semibold text-gray-700">Plan Benefits</h2>
                                <p>{describe}</p>
                                </div>


                                {/* <div className="flex justify-between items-center mt-8">
                                <button className="bg-red-200 text-red-700 px-4 py-2 rounded-md border border-red-300 hover:bg-red-300">
                                    Cancel Subscription
                                </button>
                                <button className="bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700">
                                    Update Payment Info
                                </button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}