import { useEffect, useState } from 'react';

const TypingEffect = ({ text, speed = 100 }) => {
  const [displayedText, setDisplayedText] = useState('');

  useEffect(() => {
    
    let index = 0;
    const interval = setInterval(() => {
      setDisplayedText((prev) => prev + text[index]);
      index++;
      if (index === text.length) {
        clearInterval(interval);
        return;
      }
    }, speed);

    return () => clearInterval(interval);
  }, [text, speed]);



  return (
    <p className="text-[14px] font-medium ">{displayedText.replace('undefined','')}</p>
  );
};

export default TypingEffect;
