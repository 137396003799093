import { useEffect, useState } from "react";
import { FaPlus, FaTimes } from "react-icons/fa";
import { toast } from "react-toastify";
import Token from "../../Helpers/Token";
import { useCandDeleteEvent } from "../../hooks/apihooks/useCandidate";

export function CertificationCand({ certification, setValue }) {
  const { mutateAsync } = useCandDeleteEvent();

//   const [enabled] = useState({
//     department: false,
//     city: false,
//     skills: false,
//   });

//   const [countrySearch] = useState("");
  const [Certification, setCertification] = useState([]);

//   const getCity = (city) => {
//     let url = `http://hiringplug.local/manage-helpdesk-service/v1/city?keyword=${city}`;
//     return Http().get(url);
//   };

//   const { data: dataArr } = useQuery({
//     queryKey: ["getallcity", countrySearch],
//     queryFn: () => getCity(countrySearch),
//     enabled: Boolean(enabled.city),
//   });

//   const LocationOptions = dataArr?.data.map((city) => ({
//     value: city.cityid,
//     label: city.city,
//   }));

  const handleAddExperience = () => {
    setCertification([
      ...Certification,
      {
        title: "",
        issuer: "",
        location: "",
        cert_date: "",
      },
    ]);
  };

//   const handleExLocation = (e, action, index) => {
//     const { value, label } = e;
//     const { name } = action;
//     const list = [...Certification];
//     list[index][name] = { value: value, label: label };
//     setCertification(list);
//   };

  const onChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...Certification];
    list[index][name] = value;
    setCertification(list);
  };

  const handleRemoveExperience = (index, actionid) => {
    const List = [...Certification];
    List.splice(index, 1);
    setCertification(List);
    if (actionid) {
      let data = {
        action: "certification",
        actionid: actionid,
      };
      mutateAsync({ data, token: Token.getAccessToken() }).then((resp) => {
        if (resp?.status === true) {
          toast.success(resp?.message);
        } else {
          toast.warning(resp?.message);
        }
      });
    }
  };

  useEffect(() => {
    setValue("certification", Certification);
    // eslint-disable-next-line
  }, [Certification]);

  useEffect(() => {
    if (certification) {
      setCertification(certification);
    }
  }, [certification]);



  return (
    <>
      <div className="border w-full py-4 rounded">
        <div className="w-full flex justify-between items-end gap-3 flex-col md:flex-row">
          <div className="gap-5 flex flex-col items-start pl-4">
            <span className="text-[16px] font-semibold">Certification</span>
          </div>
          <p
            className={`flex text-[green] items-center text-green cursor-pointer pr-4`}
            onClick={() => handleAddExperience()}
          >
            <FaPlus /> Add
          </p>
        </div>

        {Certification?.map((data, index) => (
          <div
            key={index + index}
            className="w-full mb-2 relative fle px-2 py-2 border-b-2 last:border-b-0 border-gray-300 hover:bg-green-200 cursor-pointer items-center justify-center flex-col gap-4"
          >
            <button
              type="button"
              className="text-red-600 absolute right-[-10px] top-[-10px] bg-gray-400 w-[30px] h-[30px] rounded-full items-center text-center flex justify-center"
              onClick={() => handleRemoveExperience(index, data?.id)}
            >
              <FaTimes />
            </button>
            <div className="w-full flex mb-2 items-center justify-center gap-3 flex-col md:flex-row">
              <input
                type="text"
                onChange={(e) => onChange(e, index)}
                value={data.title}
                name="title"
                placeholder="Certification name"
                className="w-full px-3 text-sm h-10 border border-border-col rounded-[4px] "
              />
              <input
                type="text"
                onChange={(e) => onChange(e, index)}
                value={data.issuer}
                name="issuer"
                placeholder="issuer"
                className="w-full px-3 text-sm h-10 border border-border-col rounded-[4px] "
              />
            </div>
            <div className="w-full flex items-center justify-center gap-3 flex-col md:flex-row">
              <input
                type="text"
                onChange={(e) => onChange(e, index)}
                value={data.location}
                name="location"
                placeholder="location"
                className="w-full px-3 text-sm h-10 border border-border-col rounded-[4px] "
              />
              <input
                type="date"
                value={data.cert_date}
                name="cert_date"
                placeholder="Certification Date"
                onChange={(e) => onChange(e, index)}
                className="w-full md:w-6/12 px-3 text-sm h-10 border border-border-col rounded-[4px] "
              />
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
