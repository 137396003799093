import { useContext, useEffect, useState } from "react";
import ContextProvider from "./ContextProvider";
import currencyData from "../hooks/useCurrencyCode";
import Token from "../Helpers/Token";
import Http from "../http";
import endpoint from "../Helpers/endpoint";
import { useQuery } from "@tanstack/react-query";
import { get } from "../Helpers/Storage";

export default function ContextWrapper({ children }) {
  const [websocket, setWebSocket] = useState(null);
  const [currency, setCurrency] = useState({
    code: "USD",
    codeSym: "$",
  });

  const useruid = get("userAuth")?.useruid;

  const [toggleHam, settoogleHam] = useState(false);
  const [location, setLocation] = useState({
    name: "Canada",
    code: "en",
    flag: "https://www.sahaye.com/flags/ca.png",
  });

  useEffect(() => {
    async function getIpaddress() {
      const response = await fetch(`https://api.db-ip.com/v2/free/self`);
      const data = await response.json();
      let currencydata = currencyData.filter(
        (value) => value?.country === data?.countryName
      );

      setCurrency(currencydata[0]);
    }
    getIpaddress();
  }, []);

  const [progress, setProgress] = useState(0);

  // Notification Count
  const getnotificationCount = () => {
    const URL = `${endpoint.notification.count}`;
    return Http().get(URL, {
      "Content-type": "application/json",
      Authorization: `Bearer ${Token.getAccessToken()}`,
    });
  };
  const { data: notification } = useQuery({
    queryKey: ["notficationcount", Token.getAccessToken()],
    queryFn: getnotificationCount,
    enabled: Boolean(Token.getAccessToken()),
  });

  // Websocket
  useEffect(() => {
    if (useruid) {
      const socket = new WebSocket(
        process.env.REACT_APP_WEBSOCKET_ENDPOINT +
          "?userid=" +
          useruid +
          `&token=` +
          process.env.REACT_APP_WEBSOCKET_TOKEN
      );
      socket.onopen = (connect) => {
        console.log("WebSocket connection established", connect);
        setWebSocket(socket);
      };
      socket.onerror = (error) => {
        console.error("WebSocket error:", error);
      };

      socket.onclose = () => {
        console.log("WebSocket connection closed");
      };
      // Cleanup function
      return () => {
        if (socket) {
          socket.close();
        }
      };
    }
  }, [useruid]); // Include deCode?.uid in the dependency array


  return (
    <ContextProvider.Provider
      value={{
        currency,
        toggleHam,
        location,
        notification,
        websocket,
        setLocation,
        settoogleHam,
        setCurrency,
        progress,
        setProgress,
      }}
    >
      {children}
    </ContextProvider.Provider>
  );
}

export const useGlobalContext = () => useContext(ContextProvider);
