import React from "react";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { CiMail } from "react-icons/ci";
import { Link } from "react-router-dom";
import { useRunLinkedinCampaign } from "../../../../hooks/apihooks/Campaigns/useRunLinkedinCampaign";
import Http from "../../../../http";
import endpoint from "../../../../Helpers/endpoint";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import Loader from "../../../Loader/Loader";

const CampaignCard = ({ data, type }) => {
  const { mutateAsync, isPending } = useRunLinkedinCampaign();

  function getUser() {
    let endpoints = endpoint.user.linkedinAccount;
    return Http().get(endpoints);
  }

  const { data : User } = useQuery({
    queryKey: ["User-Account"],
    queryFn: getUser,
  });

  const syncInvitation = () => {

    let postData = {
      campaignid : data?.campaignid,
      account_id : User?.data?.account_id,
      syncprofiles : 1
    };

    mutateAsync(postData).then(res => {
      if(res?.status){
        toast.success('Profiles sync started successfully')
      }else{
        toast.warn('Something went wrong')
      }
    });
  };

  return (
    <div className="border border-gray-300 rounded-lg shadow-sm p-4 bg-white flex justify-between items-center flex-col w-full">
      <div className="w-full flex items-start justify-between gap-2 ">
        <div className="flex flex-col w-4/12">
          <div className="flex items-center space-x-2 mb-2">
            {/* <div className="w-8 h-8 bg-orange-500 rounded-full flex items-center justify-center text-white font-bold">
            <span>↗</span>
          </div> */}
         {isPending ? <Loader /> : ''}
            <Link
              target="_blank"
              to={
                data?.is_for_linkedin
                  ? `/campaigns-external/${data?.campaignuid}`
                  : `/campaigns/${data?.campaignuid}/`
              }
            >
              {" "}
              <h3 className="text-[16px] font-semibold">
                {data?.campaigntitle}
              </h3>
            </Link>
          </div>
          <p className="text-[12px] text-gray-600">
            This campaign is all about expanding your reach and getting your
            message out to more people.
          </p>
        </div>
        <div className="flex flex-row gap-2 items-center justify-center">
          <div className="text-sm text-gray-500 flex items-center space-x-4">
            <div className="flex items-center space-x-1">
              <CiMail />
              <span>02</span>
            </div>
          </div>
          <div className="flex items-center space-x-2 ">
            <span className="bg-white text-green-600 border border-green-600 text-xs font-medium px-2 py-1 rounded-full">
              {data?.status === "ACTIVE"
                ? "Running"
                : data?.status === "INACTIVE"
                ? "In-Active"
                : data?.status === "DRAFT"
                ? "Draft"
                : "Closed"}
            </span>
            <div className="border relative border-gray-200 group cursor-pointer rounded-md p-1">
              <HiOutlineDotsHorizontal />
              <div className="absolute bg-white p-2 w-[120px] rounded-sm border hidden group-hover:flex items-start justify-center flex-col border-gray-200 right-0">
                <p onClick={syncInvitation} className="text-[12px] w-full border-b border-dotted border-gray-300 ">Sync profiles</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex items-center justify-between">
        <div className="flex space-x-2 mt-2">
          {data?.skills?.split(",")?.map((data) => (
            <span
              className="bg-gray-200 text-gray-700 text-xs px-2 py-1 rounded"
              key={data}
            >
              {data}
            </span>
          ))}
        </div>
        <div className="flex items-center space-x-6">
          <div className="text-center border-r border-gray-300 pr-3 flex items-end justify-center gap-1">
            <p className="text-[16px] font-semibold">
              {data?.open_count +
                data?.submitted_count +
                data?.pending_count +
                data?.decline_count}

              {/* {randomCount} */}
            </p>
            <div className="w-full flex items-start justify-center flex-col">
              <p className="text-[11px] text-gray-500 font-semibold">
                Delivered
              </p>
              <p className="text-[11px] text-gray-500">Last 24 Hours</p>
            </div>
          </div>
          <div className="text-center border-r border-gray-300 pr-3 flex items-end justify-center gap-1">
            <p className="text-[16px]  font-semibold">
              {data?.open_count + data?.submitted_count + data?.decline_count}
              {/* {randomCount} */}
            </p>
            <div className="w-full flex items-start justify-center flex-col">
              <p className="text-[11px] text-gray-500 font-semibold">Opened</p>
              <p className="text-[11px] text-gray-500">Last 24 Hours</p>
            </div>
          </div>
          <div className="text-center border-r border-gray-300 pr-3 flex items-end justify-center gap-1">
            <p className="text-[16px] font-semibold">
              {data?.submitted_count + data?.open_count}
              {/* {randomCount} */}
            </p>
            <div className="w-full flex items-start justify-center flex-col">
              <p className="text-[11px] text-gray-500 font-semibold">Clicked</p>
              <p className="text-[11px] text-gray-500">Last 24 Hours</p>
            </div>
          </div>
          <div className="text-center  pr-3 flex items-end justify-center gap-1">
            <p className="text-[16px]  font-semibold">
              {data?.suitable_count ? data?.suitable_count : 0}
            </p>
            <div className="w-full flex items-start justify-center flex-col">
              <p className="text-[11px] text-gray-500 font-semibold">
                Converted
              </p>
              <p className="text-[11px] text-gray-500">Last 24 Hours</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignCard;
