import React, { useState } from "react";
import { CiEdit } from "react-icons/ci";
import { FaLinkedin } from "react-icons/fa";
import endpoint from "../../../Helpers/endpoint";
import Http from "../../../http";
import { useQuery } from "@tanstack/react-query";
import { useLinkLinkedin } from "../../../hooks/apihooks/Integrations/useLinkLinkedin";
import { useForm } from "react-hook-form";
import Loader from "../../Loader/Loader";
import { IoMdSettings } from "react-icons/io";
import Settings from "./Settings";

export default function AccountsLayout() {
  const [isToggled, setIsToggled] = useState(false);
  const { handleSubmit, register } = useForm();
  const [step, setStep] = useState(1);
  const toggle = () => {
    setIsToggled(!isToggled);
  };

  function getUser() {
    let endpoints = endpoint.user.linkedinAccount;
    return Http().get(endpoints);
  }

  const { data } = useQuery({
    queryKey: ["User-Account"],
    queryFn: getUser,
  });

  const {
    profile_picture,
    city,
    country,
    email,
    firstname,
    headline,
    lastname,
    phone,
    phonecode,
    pincode,
    name,
    profile_url,
  } = data?.data || {};

  const { mutateAsync, isPending } = useLinkLinkedin();

  function ConnectLinkedin(data) {
    let postData = {
      link: data.link,
      success_redirect_url: "http://localhost:3000/accounts",
      failure_redirect_url: "http://localhost:3000/accounts",
      notify_url:
        "https://ai-api-dev.hiringplug.com/manage-user-service/v1/webhookurl-accounts",
    };
    mutateAsync(postData).then((res) => {
      if (res?.status) {
        window.location.href = res?.data?.url;
      }
    });
  }

  //   R.wP*%pb)s8^6w5

  return (
    <div className="w-full flex items-center justify-center flex-col gap-4 py-8 font-primary">
      <div className="w-[95%] flex items-center justify-between gap-3 bg-gradient-leads p-4 rounded-[6px] rounded-b-none">
        <div>
          <p className="text-[20px] font-semibold">
            Accounts/<span className="text-[15px]">Integrations</span>
          </p>
          <p className="text-[12px]"> Manage accounts connected to ai plug.</p>
        </div>
      </div>

      {isPending ? <Loader /> : ""}
      {data?.data?.account_id ? (
        <>
          {step === 1 ? (
            <div className="w-full flex items-center justify-center gap-3 flex-col">
              <div className="w-[95%] flex items-center justify-between gap-2">
                <p className="font-semibold  text-[14px] ">
                  My Linkedin Account
                </p>
                <div className="flex items-center justify-center gap-1">
                  <button
                    onClick={() => setStep(2)}
                    type="button"
                    className="text-[12px] text-blue-800 rounded-full px-3 py-1 font-medium hover:text-white hover:bg-blue-800 duration-[700ms] flex items-center justify-center gap-1 hover:scale-105 transition-all border border-blue-800"
                  >
                    Settings <IoMdSettings size={18} />
                  </button>
                  <button
                    type="button"
                    className="text-[12px] text-red-500 rounded-full px-3 py-1 font-medium hover:text-white hover:bg-red-500 duration-[700ms] hover:scale-105 transition-all border border-red-500"
                  >
                    Delete Account
                  </button>
                </div>
              </div>
              <div className="w-[95%] flex items-center  justify-between gap-3 p-4 rounded-[6px] border border-gray-200 bg-white">
                <div className="w-8/12 flex items-center justify-start flex-row gap-2">
                  <div className="w-[100px] h-[100px] rounded-full border border-gray-200 overflow-hidden">
                    {profile_picture ? <div></div> : ""}{" "}
                    <img src={profile_picture} alt="profilepic" />
                  </div>
                  <div className="flex items-start justify-center flex-col gap-1">
                    <p className="text-[15px]">{name}</p>
                    <p className="text-[13px] text-blue-950">{headline}</p>
                    <p className="text-[11px] text-gray-500">
                      {city}, {country}
                    </p>
                  </div>
                </div>
                <div className="w-4/12 flex items-center justify-end ">
                  {/* <p className="border border-gray-400 flex items-center justify-center gap-1 px-4 py-[6px] rounded-full text-[12px]">
              Edit <CiEdit size={15} />{" "}
            </p> */}
                </div>
              </div>
              <div className="w-[95%] flex items-center  justify-center gap-3 p-4 flex-col rounded-[6px] border border-gray-200 bg-white">
                <p className="text-[14px] font-semibold w-full text-left">
                  Personal information
                </p>
                <div className="w-full flex items-center justify-center">
                  <div className="w-8/12 flex items-center justify-between gap-2">
                    <div className="w-6/12 flex items-start justify-center flex-col">
                      <p className="text-[13px] text-blue-950">First Name</p>
                      <p className="text-[14px]">{firstname}</p>
                    </div>
                    <div className="w-6/12 flex items-start justify-center flex-col">
                      <p className="text-[13px] text-blue-950">Last Name</p>
                      <p className="text-[14px]">{lastname}</p>
                    </div>
                  </div>
                  <div className="w-4/12 flex items-center justify-end ">
                    {/* <p className="border border-gray-400 flex items-center justify-center gap-1 px-4 py-[6px] rounded-full text-[12px]">
                Edit <CiEdit size={15} />{" "}
              </p> */}
                  </div>
                </div>
                <div className="w-full flex items-center justify-start">
                  <div className="w-8/12 flex items-center justify-between gap-2">
                    <div className="w-6/12 flex items-start justify-center flex-col">
                      <p className="text-[13px] text-blue-950">Email Address</p>
                      <p className="text-[14px]">{email ?? "NA"}</p>
                    </div>
                    <div className="w-6/12 flex items-start justify-center flex-col">
                      <p className="text-[13px] text-blue-950">Phone</p>
                      <p className="text-[14px]">
                        {phonecode ? `+${phonecode}` : ""} {phone ?? "NA"}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-full flex items-center justify-start">
                  <div className="w-8/12 flex items-center justify-between gap-2">
                    <div className="w-6/12 flex items-start justify-center flex-col">
                      <p className="text-[13px] text-blue-950">Bio</p>
                      <p className="text-[14px]">{headline}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-[95%] flex items-center  justify-center gap-3 p-4 flex-col rounded-[6px] border border-gray-200 bg-white">
                <p className="text-[14px] font-semibold w-full text-left">
                  Address
                </p>
                <div className="w-full flex items-center justify-center">
                  <div className="w-8/12 flex items-center justify-between gap-2">
                    <div className="w-6/12 flex items-start justify-center flex-col">
                      <p className="text-[13px] text-blue-950">Country</p>
                      <p className="text-[14px]">{country}</p>
                    </div>
                    <div className="w-6/12 flex items-start justify-center flex-col">
                      <p className="text-[13px] text-blue-950">City/State</p>
                      <p className="text-[14px]">{city}</p>
                    </div>
                  </div>
                  <div className="w-4/12 flex items-center justify-end ">
                    {/* <p className="border border-gray-400 flex items-center justify-center gap-1 px-4 py-[6px] rounded-full text-[12px]">
                Edit <CiEdit size={15} />{" "}
              </p> */}
                  </div>
                </div>
                <div className="w-full flex items-center justify-start">
                  <div className="w-8/12 flex items-center justify-between gap-2">
                    <div className="w-6/12 flex items-start justify-center flex-col">
                      <p className="text-[13px] text-blue-950">Postal Code</p>
                      <p className="text-[14px]">{pincode ?? "NA"}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="w-full flex items-center justify-center gap-3 flex-col">
                <div className="w-[95%] flex items-center justify-between gap-2">
                <p className="font-semibold  text-[14px] ">
                  Settings
                </p>
                <div className="flex items-center justify-center gap-1">
                  <button
                    onClick={() => setStep(1)}
                    type="button"
                    className="text-[12px] text-blue-800 rounded-full px-3 py-1 font-medium hover:text-white hover:bg-blue-800 duration-[700ms] flex items-center justify-center gap-1 hover:scale-105 transition-all border border-blue-800"
                  >
                   Switch to Account 
                  </button>
                  <button
                    type="button"
                    className="text-[12px] text-red-500 rounded-full px-3 py-1 font-medium hover:text-white hover:bg-red-500 duration-[700ms] hover:scale-105 transition-all border border-red-500"
                  >
                    Delete Account
                  </button>
                </div>
              </div>
              <Settings />
            </div>
          )}
        </>
      ) : (
        <div className="w-[95%] flex items-center justify-between gap-2 ">
          <form
            onSubmit={handleSubmit(ConnectLinkedin)}
            className="w-4/12 rounded-[6px] border border-gray-200  cursor-pointer relative p-4"
          >
            <FaLinkedin
              size={40}
              className="text-blue-700 absolute right-[-3px] top-[-3px]"
            />

            <div className="w-full flex items-center justify-center flex-col gap-3 px-4">
              <div className="w-full flex items-center justify-center flex-col gap-1">
                <p className="w-full left text-[14px]">Linkedin</p>
                <p className="w-full left text-[10px]">linkedin.com</p>
                <p className="w-full left text-[12px] text-blue-950 select-none">
                  Connect your linkedin account and Leverage AI to effortlessly
                  search and identify high-quality leads on LinkedIn. Automate
                  the lead generation process and connect with potential
                  candidates directly, ensuring efficient and targeted outreach.
                </p>
              </div>
            </div>

            {profile_url ? (
              <p className="w-10/12 px-4 text-[14px] mt-3 text-red-600 flex items-start justify-center flex-col gap-2">
                <span className="inline-block">
                  Account connection is still pending
                </span>{" "}
                <span
                  onClick={() => ConnectLinkedin({ link: profile_url })}
                  className="px-3 inline-block py-1 text-[12px] bg-red-500 text-white rounded-full "
                >
                  Connect
                </span>
              </p>
            ) : (
              <div
                className={`w-10 mb-3 h-5 flex items-center bg-gray-300 rounded-full p-1 cursor-pointer ml-auto ${
                  isToggled ? "bg-blue-600" : "bg-gray-300"
                }`}
                onClick={toggle}
              >
                <div
                  className={`bg-white w-5 h-5 rounded-full shadow-md transform duration-300 ease-in-out ${
                    isToggled ? "translate-x-4" : "translate-x-[-4px]"
                  }`}
                />
              </div>
            )}

            <div
              className={`${
                isToggled ? "max-h-[200px]" : "max-h-0  "
              } overflow-hidden transition-all  duration-300 w-full flex items-center justify-center flex-col gap-3`}
            >
              <div className="w-full flex items-center justify-center flex-col mt-3">
                <input
                  name="link"
                  {...register("link", {
                    required: true,
                    pattern: {
                      value:
                        /^https:\/\/(www\.)?linkedin\.com\/[a-zA-Z]{2,3}\/[a-zA-Z0-9-]+\/?$/,
                      message: "Not a valid link",
                    },
                  })}
                  className="w-full h-8 rounded-[5px] text-[14px] placeholder:text-[13px] border border-gray-300 px-3"
                  placeholder="Enter your linkedin profile url"
                />
              </div>

              <div className="w-full flex items-center justify-end flex-row mt-3 gap-2">
                <button
                  type="button"
                  className="px-3 py-1 text-[13px] border border-gray-600 text-gray-600 rounded-full "
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-3 py-1 text-[13px] bg-blue-600 text-white rounded-full "
                >
                  Connect
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}
