import { useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { FaArrowUp } from "react-icons/fa";
import { FaUser } from "react-icons/fa6";
import { FiEdit3 } from "react-icons/fi";
import { IoMicOutline } from "react-icons/io5";
import { SlMenu } from "react-icons/sl";
import { WiStars } from "react-icons/wi";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import endpoint from "../../../Helpers/endpoint";
import extractJSON from "../../../Helpers/extractJSON";
import { getQueryType } from "../../../Helpers/getQueryType";
import { getHeader } from "../../../Helpers/header";
import ProcessText from "../../../Helpers/ProcessText";
import { useSpeechRecognition } from "../../../Helpers/useSpeechRecognition";
import { useCreateMessage } from "../../../hooks/useCreateMessage";
import Http, { Colors, TYPES } from "../../../http";
import Loader from "../../Loader/Loader";
import MiniCampaign from "./MiniCampaign";
import TypingEffect from "./TypingEffect";

function getMultipleResponse(string) {
  if (!string || typeof string !== "string") {
    return [];
  }

  const parts = string.split(
    /<b>\s*On hiringplug network:-\s*<\/b>|<b>\s*From the internet:-\s*<\/b>|<b>\s*From blog\.hiringplug\.com:-\s*<\/b>/i
    // /<b>.*?On hiringplug network:-.*?<\/b>|<b>.*?From the internet:-.*?<\/b>|<b>.*?From blog.hiringplug.com:-.*?<\/b>/
  );

  const hiringplugNetwork = parts[1]?.trim() || "";
  const fromTheInternet = parts[2]?.trim() || "";
  const fromTheBlog = parts[3]?.trim() || "";

  let arr = [
    { type: "HIRINGPLUG", content: hiringplugNetwork },
    { type: "INTERNET", content: fromTheInternet },
    { type: "BLOG", content: fromTheBlog },
  ];

  return arr.filter((entry) => entry.content);
}

function formatNonCandQuery(processedChunk = "") {

  const containsAll =
    processedChunk.includes("#HPNORESPONSE") &&
    processedChunk.includes("#BLOGNORESPONSE") &&
    processedChunk.includes("#INTERNETNORESPONSE");
  if (!containsAll) {
    const replacements = [
      { target: "#HPNORESPONSE", replacement: "" },
      { target: "#BLOGNORESPONSE", replacement: "" },
      { target: "#INTERNETNORESPONSE", replacement: "" },
      { target: "#SOURCEHPBLOGSTART", replacement: "" },
      { target: "#SOURCEHPBLOGEND", replacement: "<br>" },
      { target: "#SOURCEINTERNETSTART", replacement: "" },
      { target: "#SOURCEINTERNETEND", replacement: "<br>" },
      { target: "#SOURCEHIRINGPLUGSTART", replacement: "" },
      { target: "#SOURCEHIRINGPLUGEND", replacement: "<br>" },
      {
        target: "From hiringplug.com:-",
        replacement: "<b>From hiringplug.com:-</b><br>",
      },
      {
        target: "From blog.hiringplug.com:-",
        replacement: "<br><b>From blog.hiringplug.com:-</b><br>",
      },
      {
        target: "From the internet:-",
        replacement: "<br><b>From the internet:-</b><br>",
      },
      {
        target: "From bloghiringplug.com:-",
        replacement: "<br><b>From blog.hiringplug.com:-</b><br>",
      },
      {
        target: "On hiringplug network:-",
        replacement: "<br><b> On hiringplug network:-</b><br>",
      },
      {
        target: /<p>Job Id : <a href="([^"]+)">([^<]+)<\/a><\/p>/g,
        replacement: (match, p1) =>
          `<button type="button" class="px-4 whitespace-nowrap text-white py-1 bg-lightbrown rounded-full" onclick="reviewPipeline('${p1}')">Review Pipeline</button>`,
      },
      {
        target:
          /<a href="https:\/\/my\.hiringplug\.com\/pipeline\/([a-zA-Z0-9]+)">Review Pipeline<\/a>/g,
        replacement: (match, p1) =>
          `<button type="button" class="px-4 whitespace-nowrap text-white py-1 bg-lightbrown rounded-full" onclick="reviewPipeline('${p1}')">Review Pipeline</button>`,
      },
      {
        target: "https://my.hiringplug.com/agency-billing",
        replacement: `<button class="px-4 whitespace-nowrap text-white py-1 bg-lightbrown rounded-full" onclick="alert('Billing')">Review Billing</button>`,
      },
    ];

   


    replacements.forEach(({ target, replacement }) => {
      processedChunk = processedChunk.replaceAll(target, replacement);
    });

    const questionRegex = /#QUESTIONSTART\s*([\s\S]*?)#QUESTIONSEND/;
    
    const questionMatch = processedChunk.match(questionRegex);


    if (questionMatch) {
      const questions = questionMatch[1].trim().split("\n").map((q) => q.replace(/^\d+\.\s*/, "").trim());

      questions.forEach((question, index) => {
       
        processedChunk = processedChunk.replace(question, index < 3 ? `<br><p onclick="onChatUpdate(${null},{ query: '${question}'},${true})" style="width:fit-content" class="px-4 cursor-pointer py-1 text-[12px] bg-lightbrown bg-opacity-15 rounded-full border border-lightbrown ">${question}</p>` : ``);
      });
      processedChunk = processedChunk.replace('#QUESTIONSTART', "<b>Recommendation Queries</b><br");
      processedChunk = processedChunk.replace('#QUESTIONSEND', "");
    }

    processedChunk = processedChunk
      .replace(
        /\d+\./g,
        (match) => `<span>${match}</span>`
      )
      .replace(/\*(.*?)\*/g, (match, p1) => `<strong>${p1}</strong>`)
      .replace(/<\/li><\/ul>/g, "</li></ul><br>");

    processedChunk = `<div>${processedChunk.replaceAll("\n\n", "</p><p>")}</div>`;
  } else {
    processedChunk =
      "It seems there's no response available at the moment. Let me know if you'd like to try again or need help with something else!";
  }
  return processedChunk;
}

export default function PlugComponent({ open, setOpen, header }) {
  
  const { data: { campaignuid } = {} } = header || {};
  console.log(campaignuid)

  const speech = useSpeechRecognition();
  const { chat_uid } = useParams();
  const chatEndRef = useRef(null);
  const location = useLocation();
  const [streams, setStreams] = useState("");
  const [, setIsLoading] = useState(false);
  const [isStreaming, setIsStreaming] = useState(false);
  const [chat, setChat] = useState([]);
  let queryclient = useQueryClient();
  let navigate = useNavigate();
  const { mutateAsync } = useCreateMessage();
  const handleAction = () => {
    setOpen((open) => !open);
  };

  useEffect(() => {
    if (location?.search && atob(location?.search.split("&prompt=")[1])) {
      setInputValue(atob(location?.search.split("&prompt=")[1]));
    }
  }, [location]);

  const [inputValue, setInputValue] = useState("");
  function getMessages() {
    let endpoints = endpoint.communication.getmessages.replace(
      "{chatuid}",
      chat_uid
    );
    return Http().get(endpoints);
  }

  window.reviewPipeline = (jobid) => {
    alert(`Pipeline for ${jobid}`);
  };

  const { data, isLoading: status } = useQuery({
    queryKey: ["Messages"],
    queryFn: getMessages,
  });

  function getChat() {
    let endpoints = endpoint.communication.getchats;
    return Http().get(endpoints);
  }

  const { Chats } = useQuery({
    queryKey: ["Chats"],
    queryFn: getChat,
    enabled: Boolean(!chat_uid),
  });

  useEffect(() => {
    if (!chat_uid && Chats?.data[0]?.chat_uid) {
      navigate(`/plug/${Chats?.data[0]?.chat_uid}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chat_uid, Chats?.data]);

  useMemo(() => {
    if (data?.data) {
      setChat(data.data);
    }
  }, [data?.data]);

  useEffect(() => {
    if (!speech.transcript) return;

    setInputValue(speech.transcript);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [speech.transcript]);

  async function handleCandidateQuery(query) {
    setStreams("");
    let session = Number(localStorage.getItem("user_chat_session")) || 1;

    let payLoad = {
      prompt: query,
      prompt_chat_count: session,
      user_id: "SYSTEM",
      agency_id: "F7u4YBzv9E8LrdjX",
      employer_id: "",
      admin_id: "",
    };

    session += 1;
    localStorage.setItem("user_chat_session",session)
    

    try {
      setIsLoading(true);
      await mutateAsync({
        type: "USER",
        message: query,
        chat_uid,
        query_type: "",
      });

      let header = getHeader("genai");
      const response = await fetch(process.env.REACT_APP_STREAMING_API, {
        method: "POST",
        headers: header,
        body: JSON.stringify(payLoad),
      });

      if (!response.ok) {
        setIsLoading(false);
        setIsStreaming(false);
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      setIsLoading(false);
      setIsStreaming(true);
      const reader = response.body.getReader();
      let eventStream = "";
      let metadata = false;
      let leftData = "";
      let rightData = "";
      let accumulatedStream = "";
      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          let jsonResponse = eventStream.split("#FINAL_RESPONSE#")[1];
          let response = extractJSON(jsonResponse ? jsonResponse : eventStream);
          if (response) {
            let json = response[0] || {};
            if (json?.code !== 200) {
              setStreams("");
              toast.warn(json?.message);
            }
          }
          break;
        } else {
          eventStream = new TextDecoder().decode(value);
          let lines = eventStream;
          if (lines?.includes("#FINAL_RESPONSE#") || metadata) {
            metadata = true;
            const metaKEY = lines;
            const finalResponseIndex = metaKEY.indexOf("#FINAL_RESPONSE#");

            if (lines?.includes("#FINAL_RESPONSE#")) {
              if (finalResponseIndex !== -1) {
                leftData = metaKEY.substring(0, finalResponseIndex);
              }
              accumulatedStream += leftData || "";
              // eslint-disable-next-line
              setStreams((prevStreams) => prevStreams + (leftData || ""));
            }

            if (finalResponseIndex !== -1) {
              rightData =
                metaKEY.substring(
                  finalResponseIndex + `#FINAL_RESPONSE#`.length
                ) || lines;
            }
          } else {
            // setStreams(streams, lines);
            setStreams((prevStreams) => prevStreams + lines);
            accumulatedStream += lines;
          }
        }
      }

      let json = extractJSON(rightData);
      let candidates = json.length > 0 ? json[0]?.data : {};
     
      await mutateAsync({
        type: "ASSISTANT",
        message: accumulatedStream,
        chat_uid,
        query_type: "CANDIDATE",
        no_of_candidates: candidates?.file_metadata?.length || 0,
      });
      setChat((prevChat) => {
        const updatedChat = prevChat.filter(
          (data) => data.message !== "Generating Response..."
        );
        return [
          ...updatedChat,
          {
            type: "ASSISTANT",
            message: accumulatedStream,
            query_type: "CANDIDATE",
            chat_uid,
            no_of_candidates: candidates?.file_metadata?.length || 0,
            chat_logs: JSON.stringify(candidates),
          },
        ];
      });

      setIsStreaming(false);
      return true;
    } catch (error) {
      console.error("Error:", error);
    }
  }

  async function handleNonCandidateQuery(query) {
    const payload = {
      nc_userid: "CJegrdtttw1vleG6",
      query,
      nc_usertype: 0,
      blog_category_ids: [],
    };

    try {
      setIsLoading(true);
      await mutateAsync({
        type: "USER",
        message: query,
        chat_uid,
        query_type: "",
      });

      const header = getHeader("genai");
      const response = await fetch(process.env.REACT_APP_OPEN_GENAI_API, {
        method: "POST",
        headers: header,
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reader = response.body.getReader();
      let accumulatedText = "";

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;

        const chunk = new TextDecoder().decode(value);

        let processedChunk = chunk.split("#FINAL_RESPONSE#")[0];

        processedChunk = formatNonCandQuery(processedChunk);

        accumulatedText += processedChunk;
      }
      await mutateAsync({
        type: "ASSISTANT",
        message: accumulatedText,
        chat_uid,
        query_type: "NONCANDIDATE",
      });
      setChat((prevChat) => {
        const updatedChat = prevChat.filter(
          (data) => data.message !== "Generating Response..."
        );
        return [
          ...updatedChat,
          { type: "ASSISTANT", message: accumulatedText },
        ];
      });

      setIsLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setIsLoading(false);
      toast.error("Failed to fetch response. Please try again.");
    }
    queryclient.invalidateQueries(["Messages"]);
    setStreams("");
  }


   async function onChatUpdate(event, data, localCall) {
    // event.preventDefault();
    const newUrl = window.location.pathname;
    window.history.replaceState(null, "", newUrl);

    if (event && event.preventDefault) {
      event.preventDefault();
    }

    let query = "";
    if (!localCall) {
      const formData = new FormData(event.target);
      query = formData.get("query");
      event.target.reset();
    } else {
      query = data.query;
    }
    setInputValue("");

    setChat((prevChat) => [
      ...prevChat,
      { type: "USER", message: query },
      { type: "ASSISTANT", message: "Generating Response..." },
    ]);

    const type = await getQueryType(query);

    if (TYPES[type] === "BOTHTYPEQUERY") {
      try {
        await handleCandidateQuery(query);
        await handleNonCandidateQuery(query);
      } catch (error) {
        console.error("Error:", error);
        toast.error("Failed to fetch response. Please try again.");
      } finally {
        setIsLoading(false);
      }

      return;
    }

    if (TYPES[type] === "CANDIDATE") {
      await handleCandidateQuery(query);
      setIsLoading(false);
    } else if (TYPES[type] === "NONCANDIDATE") {
      await handleNonCandidateQuery(query);
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }

  window.onChatUpdate = async function (event, data, localCall) {
    // event.preventDefault();
    const newUrl = window.location.pathname;
    window.history.replaceState(null, "", newUrl);

    if (event && event.preventDefault) {
      event.preventDefault();
    }

    let query = "";
    if (!localCall) {
      const formData = new FormData(event.target);
      query = formData.get("query");
      event.target.reset();
    } else {
      query = data.query;
    }
    setInputValue("");

    setChat((prevChat) => [
      ...prevChat,
      { type: "USER", message: query },
      { type: "ASSISTANT", message: "Generating Response..." },
    ]);

    const type = await getQueryType(query);

    if (TYPES[type] === "BOTHTYPEQUERY") {
      try {
        await handleCandidateQuery(query);
        await handleNonCandidateQuery(query);
      } catch (error) {
        console.error("Error:", error);
        toast.error("Failed to fetch response. Please try again.");
      } finally {
        setIsLoading(false);
      }

      return;
    }

    if (TYPES[type] === "CANDIDATE") {
      // await handleCandidateQuery(query);
      await handleNonCandidateQuery(query);
      setIsLoading(false);
    } else if (TYPES[type] === "NONCANDIDATE") {
      await handleNonCandidateQuery(query);
      // await handleCandidateQuery(query);
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }

  function getSummary(summary) {
    const TextFromDatabase = summary;
    return TextFromDatabase;
  }

  let yourTextFromDatabase = useMemo(() => getSummary(streams), [streams]);

  const processedText = ProcessText(yourTextFromDatabase);

  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chat, processedText]);

  return (
    <div
      className={`h-[100vh] w-full   pb-5 bg-extralightbrown  flex items-center gap-3  flex-col ${
        chat?.length ? "justify-end" : "justify-center"
      } `}
    >
      {status ? <Loader /> : ""}

      <div className="w-full fixed top-0 block sm:hidden">
        <div className="w-full flex items-center justify-between px-6 min-h-[80px]">
          <p className={`font-robo text-[20px] text-[${Colors.lightbrown}]`}>
            {process.env.REACT_APP_APP_NAME}
          </p>
          {open ? "" : <SlMenu onClick={handleAction} size={20} />}
        </div>
      </div>
      <div
        className={` ${
          chat.length > 0 ? "items-end h-[80vh]" : "items-center "
        } w-10/12 flex  justify-end  scrollbar-hide overflow-scroll flex-col sm:flex-row sm:flex-wrap gap-4 px-2`}
      >
        {chat?.length > 0 ? (
          <div className="w-full flex items-center justify-center flex-col gap-2">
            {chat?.map((data, index) => {
              let response = getMultipleResponse(data.message);
              return (
                <div
                  key={index}
                  className="w-full flex items-start justify-center flex-col gap-2"
                >
                  <div
                    className={` max:w-10/12 rounded-[20px]  flex items-center font-normal text-[14px]`}
                  >
                    <div className="w-full flex items-start justify-center flex-col gap-2">
                      {data?.type === "USER" ? (
                        <div className="flex items-center justify-start gap-1 ">
                          <p className="bg-gray-400 w-5 h-5 flex items-center justify-center rounded-full text-white">
                            <FaUser />
                          </p>
                          <p className="text-[12px] font-medium">You</p>{" "}
                        </div>
                      ) : (
                        <div className="flex items-center justify-start gap-1">
                          <p className="aibutton w-5 h-5 flex items-center justify-center rounded-full text-white">
                            <WiStars />
                          </p>
                          <p className="text-[12px] font-medium">AI Plug</p>{" "}
                        </div>
                      )}

                      <>
                        <div className={`p-2 relative`}>
                          {data?.typing ? (
                            <TypingEffect text={data.message} speed={10} />
                          ) : data?.query_type === "NONCANDIDATE" ? (
                            <div className="flex flex-wrap gap-4 items-stretch">
                              {response?.length > 0 ? (
                                response?.map((data) => {
                                  return (
                                    <>
                                      {data?.content && (
                                        <div className="border border-lightbrown rounded-[4px] p-3 flex-1 flex flex-col gap-2 min-w-[30%]">
                                          {data?.type === "HIRINGPLUG" ? (
                                            <p className="text-white bg-darkbrown py-1 px-2 rounded-[4px] text-[12px]">
                                              From hiringplug network
                                            </p>
                                          ) : data?.type === "INTERNET" ? (
                                            <p className="text-white bg-darkbrown py-1 px-2 rounded-[4px] text-[12px]">
                                              From internet
                                            </p>
                                          ) : data?.type === "BLOG" ? (
                                            <p className="text-white bg-darkbrown py-1 px-2 rounded-[4px] text-[12px]">
                                              From hiringplug blog
                                            </p>
                                          ) : null}

                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: formatNonCandQuery(
                                                data?.content
                                              ),
                                            }}
                                          />
                                        </div>
                                      )}
                                    </>
                                  );
                                })
                              ) : (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: data?.message,
                                  }}
                                />
                              )}
                            </div>
                          ) : (
                            <>
                              {data?.query_type === "CANDIDATE" ? (
                                <MiniCampaign data={data} header={header} />
                              ) : (
                                <div
                                  className="w-full  pb-2 text-[14px]  px-[24px] overflow-y-scroll flex-col whitespace-pre-line"
                                  dangerouslySetInnerHTML={{
                                    __html: data.message,
                                  }}
                                />
                              )}
                            </>
                          )}
                          {/*  */}
                        </div>
                      </>
                    </div>
                  </div>
                </div>
              );
            })}
            {isStreaming ? (
              <div
                dangerouslySetInnerHTML={processedText}
                className="w-full border-b pb-2 text-[14px] border-gray-100  overflow-y-scroll flex-col whitespace-pre-line"
              />
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
        <div ref={chatEndRef} />
      </div>

      <form onSubmit={onChatUpdate} className="w-10/12 pt-5 relative">
        {chat?.length ? (
          <>
            <IoMicOutline
              onClick={speech.startListening}
              className={`text-[20px] cursor-pointer absolute bottom-2 left-[10px]   ${
                speech.listening.toString() === "true"
                  ? "text-blue-600 animate-pulse"
                  : ""
              }`}
            />
            <input
              className={`border border-gray-300 shadow-lg placeholder:text-[13px] w-full h-10 rounded-full px-10 text-[15px] outline-lightbrown `}
              placeholder="Message Ai Plug"
              name="query"
              required
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
            <button
              type="submit"
              className={`bg-darkbrown p-2 rounded-[20px] absolute right-3 bottom-1`}
            >
              {" "}
              <FaArrowUp color="white" />
            </button>
          </>
        ) : (
          <div className="relative w-full flex items-center gap-4 flex-col justify-center mb-3">
            <IoMicOutline
              onClick={speech.startListening}
              className={`text-[20px] cursor-pointer absolute bottom-2 left-[105px]   ${
                speech.listening.toString() === "true"
                  ? "text-blue-600 animate-pulse"
                  : ""
              }`}
            />

            <p className="text-lightbrown text-[35px]">
              How may I assist you today?
            </p>
            <textarea
              className={`border py-2 border-gray-300 shadow-lg placeholder:text-[13px] w-10/12  rounded-[14px] px-5 text-[15px] outline-lightbrown `}
              placeholder="Message Ai Plug"
              name="query"
              required
              rows={4}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
            <button
              type="submit"
              className={`bg-darkbrown p-2 rounded-[20px] absolute right-[100px] bottom-[12px]`}
            >
              {" "}
              <FaArrowUp color="white" />
            </button>
          </div>
        )}

        {!chat?.length ? (
          <div className="w-full flex items-center justify-center flex-row flex-wrap gap-2 mb-3">
            <p
              onClick={(e) =>
                onChatUpdate(
                  e,
                  {
                    query:
                      "Looking for recruitment agencies that specialize in IT roles?",
                  },
                  true
                )
              }
              className="text-[12px] w-[38%] bg-white p-3 border border-gray-300 shadow-sm hover:scale-[1.02] transition-all cursor-pointer rounded-full flex items-center justify-center gap-1 "
            >
              <FiEdit3 className={`text-lightbrown text-[15px]`} />{" "}
              <p>
                Looking for recruitment agencies that specialize in IT roles?
              </p>{" "}
            </p>
            <p
              onClick={(e) =>
                onChatUpdate(
                  e,
                  { query: "How can I post a new job opening?" },
                  true
                )
              }
              className="text-[12px] w-[38%] bg-white p-3 border border-gray-300 shadow-sm hover:scale-[1.02] transition-all cursor-pointer rounded-full flex items-center justify-center gap-1 "
            >
              <FiEdit3 className={`text-lightbrown text-[15px]`} />{" "}
              <p> How can I post a new job opening?</p>
            </p>
            <p
              onClick={(e) =>
                onChatUpdate(
                  e,
                  {
                    query:
                      "What services do recruitment agencies offer for IT hiring?",
                  },
                  true
                )
              }
              className="text-[12px] w-[38%] bg-white p-3 border border-gray-300 shadow-sm hover:scale-[1.02] transition-all cursor-pointer rounded-full flex items-center justify-center gap-1 "
            >
              <FiEdit3 className={`text-lightbrown text-[15px]`} />
              <p> What services do recruitment agencies offer for IT hiring?</p>
            </p>
            <p
              onClick={(e) =>
                onChatUpdate(
                  e,
                  {
                    query:
                      " Can you show me how to create a recruitment campaign?",
                  },
                  true
                )
              }
              className="text-[12px] w-[38%] bg-white p-3 border border-gray-300 shadow-sm hover:scale-[1.02] transition-all cursor-pointer rounded-full flex items-center justify-center gap-1 "
            >
              <FiEdit3 className={`text-lightbrown text-[15px]`} />{" "}
              <p>Can you show me how to create a recruitment campaign?</p>
            </p>
          </div>
        ) : (
          ""
        )}
      </form>
      {chat?.length ? (
        <p className={`text-[10px]  text-[${Colors.darkbrown}]`}>
          Ai Plug can make mistakes. Check important info.
        </p>
      ) : (
        <></>
      )}
    </div>
  );
}
