import React from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import Header from "../../Website/Header";
import Footer from "../../Website/Footer";

const genderOptions = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "other", label: "Other" },
];

const fresherOptions = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

export default function Career() {
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
  };

  const resumeWatch = watch("resume");

  return (
    <>
      <Header />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-11/12 mt-[150px] mx-auto p-6 bg-whiterounded-md"
      >
        <h2 className="text-2xl font-bold mb-4">Campaign Application Form</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* First Name */}
          <div className="flex flex-col">
            <label htmlFor="firstname" className="font-medium">
              First Name
            </label>
            <input
              type="text"
              {...register("firstname", { required: "First name is required" })}
              id="firstname"
              className="border rounded px-3 py-2 mt-1"
            />
            {errors.firstname && (
              <span className="text-red-500 text-sm">
                {errors.firstname.message}
              </span>
            )}
          </div>

          {/* Last Name */}
          <div className="flex flex-col">
            <label htmlFor="lastname" className="font-medium">
              Last Name
            </label>
            <input
              type="text"
              {...register("lastname", { required: "Last name is required" })}
              id="lastname"
              className="border rounded px-3 py-2 mt-1"
            />
            {errors.lastname && (
              <span className="text-red-500 text-sm">
                {errors.lastname.message}
              </span>
            )}
          </div>

          {/* Email */}
          <div className="flex flex-col">
            <label htmlFor="email" className="font-medium">
              Email
            </label>
            <input
              type="email"
              {...register("email", { required: "Email is required" })}
              id="email"
              className="border rounded px-3 py-2 mt-1"
            />
            {errors.email && (
              <span className="text-red-500 text-sm">
                {errors.email.message}
              </span>
            )}
          </div>

          {/* Date of Birth */}
          <div className="flex flex-col">
            <label htmlFor="dateofbirth" className="font-medium">
              Date of Birth
            </label>
            <input
              type="date"
              {...register("dateofbirth", {
                required: "Date of birth is required",
              })}
              id="dateofbirth"
              className="border rounded px-3 py-2 mt-1"
            />
            {errors.dateofbirth && (
              <span className="text-red-500 text-sm">
                {errors.dateofbirth.message}
              </span>
            )}
          </div>

          {/* Gender */}
          <div className="flex flex-col">
            <label htmlFor="gender" className="font-medium">
              Gender
            </label>
            <Controller
              name="gender"
              control={control}
              rules={{ required: "Gender is required" }}
              render={({ field }) => (
                <Select
                  {...field}
                  options={genderOptions}
                  className="mt-1"
                  placeholder="Select Gender"
                />
              )}
            />
            {errors.gender && (
              <span className="text-red-500 text-sm">
                {errors.gender.message}
              </span>
            )}
          </div>

          {/* Phone */}
          <div className="flex flex-col">
            <label htmlFor="phone" className="font-medium">
              Phone Number
            </label>
            <input
              type="text"
              {...register("phone")}
              id="phone"
              className="border rounded px-3 py-2 mt-1"
            />
          </div>

          {/* Alternate Contact */}
          <div className="flex flex-col">
            <label htmlFor="alternatecontact" className="font-medium">
              Alternate Contact
            </label>
            <input
              type="text"
              {...register("alternatecontact")}
              id="alternatecontact"
              className="border rounded px-3 py-2 mt-1"
            />
          </div>

          {/* Qualification */}
          <div className="flex flex-col">
            <label htmlFor="qualification" className="font-medium">
              Qualification
            </label>
            <input
              type="text"
              {...register("qualification")}
              id="qualification"
              className="border rounded px-3 py-2 mt-1"
            />
          </div>

          {/* Total Experience */}
          <div className="flex flex-col">
            <label htmlFor="totalexperience" className="font-medium">
              Total Experience (Years)
            </label>
            <input
              type="number"
              {...register("totalexperience")}
              id="totalexperience"
              className="border rounded px-3 py-2 mt-1"
            />
          </div>

          {/* LinkedIn Address */}
          <div className="flex flex-col">
            <label htmlFor="linkedlnaddress" className="font-medium">
              LinkedIn Address
            </label>
            <input
              type="url"
              {...register("linkedlnaddress")}
              id="linkedlnaddress"
              className="border rounded px-3 py-2 mt-1"
            />
          </div>

          {/* Diversity */}
          <div className="flex flex-col">
            <label htmlFor="diversity" className="font-medium">
              Diversity
            </label>
            <input
              type="text"
              {...register("diversity")}
              id="diversity"
              className="border rounded px-3 py-2 mt-1"
            />
          </div>

          {/* Fresher */}
          <div className="flex flex-col">
            <label htmlFor="fresher" className="font-medium">
              Are You a Fresher?
            </label>
            <Controller
              name="fresher"
              control={control}
              rules={{ required: "This field is required" }}
              render={({ field }) => (
                <Select
                  {...field}
                  options={fresherOptions}
                  className="mt-1"
                  placeholder="Select Option"
                />
              )}
            />
            {errors.fresher && (
              <span className="text-red-500 text-sm">
                {errors.fresher.message}
              </span>
            )}
          </div>

          {/* Resume */}
          <div className="flex flex-col">
            <label htmlFor="resume" className="font-medium">
              Upload Resume
            </label>
            <input
              type="file"
              {...register("resume", {
                required: "Resume is required",
              })}
              id="resume"
              className="border rounded px-3 py-2 mt-1"
            />
            {resumeWatch && (
              <p className="text-sm mt-1">
                Selected File: {resumeWatch[0]?.name}
              </p>
            )}
            {errors.resume && (
              <span className="text-red-500 text-sm">
                {errors.resume.message}
              </span>
            )}
          </div>
        </div>

        <div className="w-full flex items-center justify-end">
          <button
            type="submit"
            className="mt-6  px-6 py-2 bg-lightbrown text-white rounded hover:bg-darkbrown"
          >
            Submit
          </button>
        </div>
      </form>
      <Footer />
    </>
  );
}
