import { useQuery } from "@tanstack/react-query";
import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FaCamera, FaPlus, FaTimes } from "react-icons/fa";
import { SiGooglegemini } from "react-icons/si";
import Select, { createFilter } from "react-select";
import CreatableSelect from "react-select/creatable";
import { toast } from "react-toastify";
import AlertCandConfirm from "../../Components/Alert/AlertCandConfirm";
import { Spinner } from "../../Components/Loader/Spinner";
import Token from "../../Helpers/Token";
import useDebounce from "../../Helpers/useDebounce";
import { customStyles, ErrorStyles } from "../../Helpers/Utils";
import { useCandidate } from "../../hooks/apihooks/useCandidate";
import CountryCodes, {
  CountryOptions,
  currencyCode,
} from "../../hooks/CountryCodes";
import Http from "../../http";
import { CertificationCand } from "./CertificationCand";
import { EducationCand } from "./EducationCand";
import { ExperienceCand } from "./ExperienceCand";
import { ProjectCand } from "./ProjectCand";

export default function AddManualCandidate({
  loading,
  typeofCand,
  steamDataSet,
  manualCand,
  setManualCand,
}) {
  const {
    register,
    reset,
    control,
    watch,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [resolvePromise, setResolvePromise] = useState(null); // to hold the promise resolution function
  const [message, setMessage] = useState({ title: "", shortDesc: "" });
  const [diversity, setDiverstiy] = useState(false);
  const [profile, setProfile] = useState(null);
  const [images, setImages] = useState([]);
  const { mutateAsync: addCandidate, isPending } = useCandidate();
  const [file, setFile] = useState(null);
  const [imageSelected, setImageSelected] = useState([]);
  const [AddInput, setAddInput] = useState("");

  const [enabled, setEnabled] = useState({
    department: false,
    city: false,
    skills: false,
  });

  const [countrySearch, setCountrySearch] = useState("");
  const debounceSkillsSearch = useDebounce(AddInput, 500);

  const getskills = (skill) => {
    let url = `http://hiringplug.local/manage-helpdesk-service/v1/skills?keyword=${skill}`;
    return Http().get(url);
  };

  const getCity = (city) => {
    let url = `http://hiringplug.local/manage-helpdesk-service/v1/city?keyword=${city}`;
    return Http().get(url);
  };

  const { data: SkillsData, isLoading: SkillsLoading } = useQuery({
    queryKey: ["getallskilss", debounceSkillsSearch],
    queryFn: () => getskills(debounceSkillsSearch),
  });
  const { data: dataArr, isLoading } = useQuery({
    queryKey: ["getallcity", countrySearch],
    queryFn: () => getCity(countrySearch),
    enabled: Boolean(enabled.city),
  });

  const SkillsOptions = SkillsData?.data.map((skill) => ({
    value: skill.skillid,
    label: skill.skill,
  }));

  const CurrencyOptions = currencyCode?.map((lists) => ({
    value: lists.value,
    label: `${lists.label}-${lists.symbol}`,
  }));

  const handleAdditionalSkillsInput = (e) => {
    setAddInput(e);
  };

  const LocationOptions = dataArr?.data.map((city) => ({
    value: city.cityid,
    label: city.city,
  }));

  const customConfirm = (title, shortDesc) => {
    setMessage({ title, shortDesc });
    setIsDialogOpen(true);
    return new Promise((resolve, reject) => {
      setResolvePromise(() => resolve); // Store the resolve function to be called later
    });
  };

  // Handle when user clicks "Yes"
  const handleConfirm = () => {
    setIsDialogOpen(false);
    if (resolvePromise) {
      resolvePromise(true); // Resolve the promise with true (user confirmed)
    }
  };

  // Handle when user clicks "No"
  const handleCancel = () => {
    setIsDialogOpen(false);
    if (resolvePromise) {
      resolvePromise(false); // Resolve the promise with false (user canceled)
    }
  };

  const onSubmit = async (postdata) => {
    var result = true;
    if (
      (postdata?.email === undefined || postdata?.email === "") &&
      typeofCand === "linkedin"
    ) {
      result = await customConfirm(
        "Warning",
        "It's highly recommended to add the Email and Mobile number for Contactability during your outreach campaigns."
      );
    }

    if (result) {
      let data = { ...postdata, resume: file, profile: profile };
      data.status = data.status === "1" ? "active" : "deactive";
      delete data.active;
      addCandidate({ data, token: Token.getAccessToken() }).then((resp) => {
        if (resp?.status === true) {
          setManualCand(false);
          reset();
          toast.success(resp?.message);
        }
      });
    }
  };

  const handleOnImageClick = () => {
    document.getElementById("image-input-box").click();
  };

  const handleImageChange = useCallback(
    (e) => {
      let fileList = Array.from(e.target.files);
      // File Reader for Each file and and update state arrays
      fileList.forEach((files, i) => {
        let reader = new FileReader();
        reader.onloadend = () => {
          setImages((prevImages) => [...prevImages, reader.result]);
        };
        reader.readAsDataURL(files);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [images, file]
  );

  useEffect(() => {
    const fetchData = async () => {
      setImages((prevImages) => [...prevImages, steamDataSet?.profile]);
      const filedata = await imageUrlToFile(steamDataSet?.profile);
      setProfile(filedata);
    };

    if (steamDataSet?.profile) {
      fetchData();
    }
  }, [steamDataSet?.profile]);

  // console.log("Files", file);

  useEffect(() => {
    // Function to handle page unload (when refresh or navigation happens)
    const handleBeforeUnload = (event) => {
      const message =
        "Are you sure you want to leave? Any unsaved data will be lost.";
      // Standard for most browsers
      event.returnValue = message;
      // Some browsers may require this for custom messages
      return message;
    };
    // Add event listener when the component mounts
    window.addEventListener("beforeunload", handleBeforeUnload);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []); // Empty dependency array ensures this effect runs only once

  // Handle button click
  const handleButtonClick = () => {
    if (
      window.confirm("Are you want to cancel?  Any unsaved data will be lost.")
    ) {
      setManualCand(false);
    }
  };

  const AMAZONS3 =
    "https://hp-in-web-prod.s3.ap-south-1.amazonaws.com/public/my-assets";
  const FormAssets = [
    { head: "Age diversity", img_src: `${AMAZONS3}/form-assets/div-age.jpeg` },
    {
      head: "Career break",
      img_src: `${AMAZONS3}/form-assets/div-career-break.jpeg`,
    },
    {
      head: "Cultural diversity",
      img_src: `${AMAZONS3}/form-assets/div-cultural.jpeg`,
    },
    {
      head: "LGBTQI diversity",
      img_src: `${AMAZONS3}/form-assets/div-LGBTQI.jpeg`,
    },
    {
      head: "Differently-abled",
      img_src: `${AMAZONS3}/form-assets/div-diffable.jpeg`,
    },
    {
      head: "Gender diversity",
      img_src: `${AMAZONS3}/form-assets/div-gender.jpeg`,
    },
    {
      head: "International",
      img_src: `${AMAZONS3}/form-assets/div-international.jpeg`,
    },
    {
      head: "Religious diversity",
      img_src: `${AMAZONS3}/form-assets/div-religion.jpeg`,
    },
  ];

  const Genders = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Undisclosed", label: "Prefer not to say" },
    { value: "Not Listed", label: "I do not identify with the listed options" },
  ];

  function GET_All_DEPARTMENT() {
    const newEndpoints =
      "http://hiringplug.local/manage-helpdesk-service/v1/department";
    return Http().get(newEndpoints);
  }

  const { data, isLoading: isFetchingDepartment } = useQuery({
    queryKey: ["Departments"],
    queryFn: () => GET_All_DEPARTMENT(),
    enabled: Boolean(enabled.department),
  });
  const DepartmentOptions = data?.data?.map((list) => ({
    value: list.departmentid,
    label: list.title,
  }));

  const handleImageSelect = (head) => {
    if (imageSelected?.includes(head)) {
      let list = [...imageSelected];
      let val = list?.findIndex((v) => v === head);
      list?.splice(val, 1);
      setImageSelected(list);
    } else {
      setImageSelected([...imageSelected, head]);
    }
  };

  const NoticePeriod = [
    { value: "Immediate", label: "Immediate" },
    { value: "Serving_Notice", label: "Serving Notice period" },
    { value: "15", label: "15 days" },
    { value: "30", label: "30 days" },
    { value: "45", label: "45 days" },
    { value: "60", label: "60 days" },
    { value: "75", label: "75 days" },
    { value: "90", label: "90 days" },
    { value: "Other", label: "Other - specify days" },
  ];

  const CVSource = [
    { value: "Naukri - Job portal", label: "Naukri - Job portal" },
    { value: "Linkedin", label: "Linkedin" },
    { value: "Indeed - Job portal", label: "Indeed - Job portal" },
    { value: "Monster - Job portal", label: "Monster - Job portal" },
    { value: "Shine - Job portal", label: "Shine - Job portal" },
    { value: "Timesjobs - Job portal", label: "Timesjobs - Job portal" },
    { value: "IIMjobs - Job portal", label: "IIM jobs - Job portal" },
    { value: "Reference", label: "Reference" },
    { value: "Internal Database", label: "Internal Database" },
    { value: "Others", label: "Others (Please specify)" },
  ];

  const handleOnChange = useCallback((e) => {
    setFile(e.target.files[0]);
  }, []);

  async function imageUrlToFile(imageUrl) {
    // Fetch the image from the URL
    const response = await fetch(imageUrl);

    // Check if the response is ok (status code 200)
    if (!response.ok) {
      throw new Error("Failed to fetch image");
    }

    // Convert the response to a Blob
    const blob = await response.blob();

    // Create a File object from the Blob
    const files = new File([blob], "linkedin-profile", { type: blob.type });

    return files;
  }

  useEffect(() => {
    setValue("firstname", steamDataSet?.first_name);
    setValue("middlename", steamDataSet?.middle_name);
    setValue("lastname", steamDataSet?.last_name);
    setValue("email", steamDataSet?.email);
    setValue("dateofbirth", steamDataSet?.dob);
    setValue("urn", steamDataSet?.urn);
    setValue("dateofbirth", steamDataSet?.dob);
    // setValue('domain', {value: 1, label:steamDataSet?.domain});
    // setValue('countryid', {value: 1, label:steamDataSet?.country});
    // setValue('location', {value: 1, label:steamDataSet?.city});
    setValue("cvsource", typeofCand ? CVSource[1] : undefined);
    setValue("phone", steamDataSet?.phone);
    setValue("synopsis", steamDataSet?.synopsis);
    setValue("alternatecontact", steamDataSet?.alternatecontact);
    let skills = steamDataSet?.skills?.map((skill) => ({
      value: 1,
      label: skill,
    }));
    setValue("skills", skills);
    // eslint-disable-next-line
  }, [steamDataSet]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // console.log("Error", errors);
  // console.log("Steam DAta", steamDataSet);

  return (
    <>
      {isPending ? <Spinner size="medium" /> : ""}
      <AlertCandConfirm
        title={message.title}
        shortDesc={message.shortDesc}
        isDialogOpen={isDialogOpen}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
      <div className="relative bg-[white] border rounded w-full">
        <img
          src={`/images/profile/profile-image1.png`}
          className="w-full p-1 h-[250px] object-cover"
          alt=""
        />
        <div className="flex p-4 font-primary items-center flex-col my-10 justify-center">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="w-[95%] gap-10 flex-col md:flex-row flex  my-10 items-start justify-center"
          >
            <div className="md:w-11/12 w-full gap-3 flex items-center flex-col justify-center">
              <div className="w-full flex items-start justify-center gap-4 md:gap-8 flex-col">
                <div className="absolute my-auto top-[100px] left-[50px] ">
                  <div className="w-[200px] flex-col flex items-center  rounded-full top-0 justify-center relative bg-[#fcfcfc] border-[#e0e0e0]  border h-[200px]">
                    <FaCamera
                      className="absolute cursor-pointer right-[5px] bottom-[30px] text-[25px] text-[gray] z-[9]"
                      onClick={() => handleOnImageClick()}
                    />
                    <p className="relative text-center top-[45%]">
                      {" "}
                      Profile Image
                    </p>
                    <input
                      type="file"
                      name="image"
                      id="image-input-box"
                      onChange={handleImageChange}
                      className="w-[200px] h-[200px] opacity-0"
                      accept=".png,.jpg,.jpeg,.webp,.svg"
                    />
                    {images?.map((images, i, row) => {
                      if (i + 1 === row.length) {
                        return (
                          <div
                            className="w-[200px] rounded-full absolute flex items-center justify-center h-[200px]"
                            key={i}
                          >
                            <img
                              type="url"
                              src={images}
                              alt="a"
                              onClick={() => handleOnImageClick()}
                              className="w-[200px] h-[200px] object-cover rounded-full"
                            />
                          </div>
                        );
                      } else {
                        return <p></p>;
                      }
                    })}
                  </div>
                </div>
                {loading ? (
                  <div className="w-full flex justify-between items-center">
                    <div className="">
                      {" "}
                      <SiGooglegemini
                        className="text-indigo-500 mr-2 animate-spin"
                        size={25}
                      />{" "}
                    </div>
                    <Spinner size="linebar" />
                  </div>
                ) : (
                  ""
                )}
                <div className="w-full flex items-center justify-center flex-col  gap-2">
                  <label className="relative w-full flex items-start justify-center flex-col">
                    <span className="text-[14px]">
                      First Name <i className="text-red-600">*</i>
                    </span>
                    <input
                      type="text"
                      name="firstname"
                      {...register("firstname", {
                        required: "Firstname is required !",
                      })}
                      placeholder="Name"
                      disabled={loading}
                      className={`w-full px-3 text-sm h-10 border rounded-[4px] ${
                        errors.firstname ? "border-red" : "border-border-col "
                      }`}
                    />
                    {errors.firstname && (
                      <span className="absolute font-gothic bottom-[-20px] font-semibold  text-[12px] text-red-600 left-0">
                        {errors.firstname.message}
                      </span>
                    )}
                  </label>

                  <div className="w-full flex items-center gap-5 my-2 flex-col md:flex-row">
                    <label className="w-full text-left">
                      <span className="text-[14px]">Middle Name</span>
                      <input
                        type="text"
                        name="middlename"
                        {...register("middlename")}
                        placeholder="Middle Name"
                        disabled={loading}
                        className="w-full px-3 text-sm h-10 border border-border-col rounded-[4px] "
                      />
                    </label>

                    <lable className="w-full text-left">
                      <span className="text-[14px]">Last Name</span>
                      <input
                        type="text"
                        name="lastname"
                        {...register("lastname")}
                        placeholder="Last Name"
                        disabled={loading}
                        className="w-full px-3 text-sm h-10 border border-border-col rounded-[4px] "
                      />
                    </lable>
                  </div>
                  {/*  */}
                  <div className="w-full flex items-center justify-center gap-5 mb-2 flex-col md:flex-row">
                    <label className="w-full relative flex items-start justify-center flex-col">
                      <span className="text-[14px]">
                        Email address <i className="text-red-600">*</i>
                      </span>
                      <input
                        type="email"
                        name="email"
                        {...register("email", {
                          required:
                            typeofCand === "linkedin"
                              ? false
                              : "Email is required !",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "invalid email address",
                          },
                        })}
                        disabled={loading}
                        placeholder="john@example.com"
                        className="w-full px-3 text-sm h-10 border border-border-col rounded-[4px] "
                      />
                      {errors.email && (
                        <span className="absolute font-gothic bottom-[-20px] font-semibold  text-[12px] text-red-600 left-0">
                          {errors.email.message}
                        </span>
                      )}
                    </label>

                    <label className="w-full relative flex items-start justify-center flex-col">
                      <span className="text-[14px]">Date of birth</span>
                      <input
                        type="date"
                        name="dateofbirth"
                        disabled={loading}
                        {...register("dateofbirth")}
                        placeholder="Date of Birth"
                        className="w-full px-3 text-sm h-10 border border-border-col rounded-[4px] "
                      />
                    </label>
                  </div>
                </div>
              </div>
              <div className="w-full flex items-center justify-center gap-3 flex-col">
                <div className="w-full  flex items-center justify-center gap-5 flex-col md:flex-row">
                  <label className="w-full flex items-start  justify-center flex-col">
                    <span className="text-[14px]">Gender</span>
                    <Controller
                      name="gender"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          isDisabled={loading}
                          options={Genders}
                          className=" w-full font-primary text-black text-sm h-10 rounded-sm"
                          styles={
                            errors.gender && errors.gender.type === "required"
                              ? ErrorStyles
                              : customStyles
                          }
                          cacheOptions
                          placeholder="Select Gender"
                          Searchable
                        />
                      )}
                    />
                  </label>
                  <label className="w-full flex items-start  justify-center flex-col">
                    <span className="text-[14px]">Domain</span>
                    <Controller
                      name="domain"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          isDisabled={loading}
                          onFocus={() =>
                            setEnabled((prev) => {
                              return {
                                department: true,
                                city: prev.city,
                                skills: prev.skills,
                              };
                            })
                          }
                          isLoading={isFetchingDepartment}
                          options={DepartmentOptions}
                          id="departmentField"
                          placeholder="Select Domain"
                          className={` w-full font-primary text-sm h-10 rounded-sm`}
                          styles={
                            errors.domain && errors.domain.type === "required"
                              ? ErrorStyles
                              : customStyles
                          }
                        />
                      )}
                    />
                  </label>
                </div>
                {/*  */}
                {/*  */}
                <div className="w-full  flex items-center justify-center gap-3 flex-col md:flex-row">
                  <label className="w-full md:w-6/12 flex items-start justify-center flex-col mt-1">
                    <span className="text-[14px]">Country</span>
                    <Controller
                      name="countryid"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          isDisabled={loading}
                          onFocus={() =>
                            setEnabled((prev) => {
                              return {
                                department: prev.department,
                                city: true,
                                skills: prev.skills,
                              };
                            })
                          }
                          Searchable
                          isLoading={isLoading}
                          className=" w-full  font-primary text-sm h-10 rounded-sm"
                          options={CountryOptions}
                          placeholder="--Select Country--"
                          styles={
                            errors.countryid &&
                            errors.countryid.type === "required"
                              ? ErrorStyles
                              : customStyles
                          }
                        />
                      )}
                    />
                  </label>
                  <label className="w-full md:w-6/12 flex items-start justify-center flex-col mt-1">
                    <span className="text-[14px]">
                      Location <i className="text-red-600">*</i>
                    </span>
                    <Controller
                      name="location"
                      control={control}
                      rules={{
                        required: typeofCand === "linkedin" ? false : true,
                      }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          isDisabled={loading}
                          onFocus={() =>
                            setEnabled((prev) => {
                              return {
                                department: prev.department,
                                city: true,
                                skills: prev.skills,
                              };
                            })
                          }
                          Searchable
                          onInputChange={(e) => setCountrySearch(e)}
                          id="locationField"
                          isLoading={isLoading}
                          className=" w-full  font-primary text-sm h-10 rounded-sm"
                          options={LocationOptions}
                          placeholder="--Select City--"
                          styles={
                            errors.location &&
                            errors.location.type === "required"
                              ? ErrorStyles
                              : customStyles
                          }
                        />
                      )}
                    />
                  </label>
                </div>
                {/*  */}
                <div className="w-full flex items-center justify-center gap-3 flex-col md:flex-row mt-1">
                  <label className="w-full md:w-6/12 flex items-start justify-center flex-col mt-1">
                    <span className="text-[14px]">
                      Phone Code <i className="text-red-600">*</i>
                    </span>
                    <select
                      name="countrycode"
                      disabled={loading}
                      {...register("countrycode")}
                      className="w-full  px-3 text-sm h-10 border border-border-col rounded-[4px] bg-white"
                    >
                      <CountryCodes />
                    </select>
                  </label>
                  <label className="w-full md:w-6/12 relative flex items-start justify-center flex-col">
                    <span className="text-[14px]">
                      Contact No. <i className="text-red-600">*</i>
                    </span>
                    <input
                      type="text"
                      name="phone"
                      disabled={loading}
                      {...register("phone", {
                        required:
                          typeofCand === "linkedin"
                            ? false
                            : "Phone number is required !",
                        minLength: {
                          value: 10,
                          message: "Phone number must be at least 10 numbers !",
                        },
                        maxLength: {
                          value: 15,
                          message: "This input exceed maxLength.",
                        },
                        pattern: {
                          value: /^[0-9 +-]+$/,
                          message: "Please enter a number",
                        },
                      })}
                      placeholder="98765XXXXX"
                      className={`w-full px-3 text-sm h-10 border  rounded-[4px] ${
                        errors.phone ? "border-red" : "border-border-col "
                      }`}
                    />
                    {errors.phone ? (
                      <span className="absolute font-gothic bottom-[-20px] font-semibold  text-[12px] text-red-600 left-0">
                        {errors.phone.message}
                      </span>
                    ) : (
                      ""
                    )}
                  </label>
                  {/* <PhoneInputForm
                      Controller={Controller}
                      control={control}
                      watch={watch}
                      name="alternatecontact"
                      register={register}
                      error={error}
                      setValue={setValue}
                    /> */}
                </div>
                <div className="w-full flex items-center justify-start gap-3 flex-col md:flex-row mt-1">
                  <label className="w-full md:w-6/12 relative flex items-start justify-center flex-col">
                    <span className="text-[14px]">Alternate Contact No. </span>
                    <input
                      name="alternatecontact"
                      type="text"
                      disabled={loading}
                      {...register("alternatecontact", {
                        minLength: {
                          value: 10,
                          message: "Phone number must be at least 10 numbers !",
                        },
                        maxLength: {
                          value: 15,
                          message: "This input exceed maxLength.",
                        },
                        pattern: {
                          value: /^[0-9 +-]+$/,
                          message: "Please enter a number",
                        },
                      })}
                      placeholder="98765XXXXX"
                      className="w-full px-3 text-sm h-10 border border-border-col rounded-[4px] "
                    />
                    {errors.alternatecontact ? (
                      <span className="absolute font-gothic bottom-[-14px] text-[12px] text-red-600 left-0">
                        {errors.alternatecontact.message}
                      </span>
                    ) : (
                      ""
                    )}
                  </label>
                </div>

                <label className="w-full flex flex-col items-start">
                  <span className="text-[14px]">Qualification</span>
                  <textarea
                    type="text"
                    rows="4"
                    disabled={loading}
                    name="qualification"
                    {...register("qualification")}
                    placeholder="Mention the Qualifications/ Education/Academics"
                    className="w-full h-full p-3 text-sm  border border-border-col rounded-[4px] "
                  />
                </label>
                <label className="w-full flex flex-col items-start">
                  <span className="text-[14px]">Synopsis</span>
                  <textarea
                    type="text"
                    rows="4"
                    disabled={loading}
                    name="synopsis"
                    {...register("synopsis")}
                    placeholder="Mention the synopsis"
                    className="w-full h-full p-3 text-sm  border border-border-col rounded-[4px] "
                  />
                </label>

                <label className="w-full flex flex-col items-start">
                  <span className="text-[14px]">
                    Add Skills <i className="text-red-600">*</i>
                  </span>
                  <Controller
                    name="skills"
                    control={control}
                    rules={{ required: true, minLength: 3 }}
                    render={({ field }) => (
                      <CreatableSelect
                        {...field}
                        isDisabled={loading}
                        isLoading={SkillsLoading}
                        onFocus={() =>
                          setEnabled((prev) => {
                            return {
                              department: prev.department,
                              city: prev.city,
                              skills: true,
                            };
                          })
                        }
                        options={SkillsOptions}
                        onInputChange={handleAdditionalSkillsInput}
                        className="outline-none table  w-full font-primary text-sm h-10 rounded-sm"
                        styles={
                          errors.skills && errors.skills.type === "required"
                            ? ErrorStyles
                            : customStyles
                        }
                        Searchable
                        id="mandatoryskills"
                        isMulti
                        placeholder="Search... Skills"
                      />
                    )}
                  />
                </label>

                <ExperienceCand
                  experience={steamDataSet?.workexper}
                  setValue={setValue}
                />
                <EducationCand
                  education={steamDataSet?.education}
                  setValue={setValue}
                />
                <ProjectCand
                  project={steamDataSet?.project}
                  setValue={setValue}
                />
                <CertificationCand
                  certification={steamDataSet?.certification}
                  setValue={setValue}
                />

                <div className="w-full my-10 flex items-center justify-center gap-3 flex-col md:flex-row">
                  <label className="md:w-3/12 w-full flex items-start justify-center flex-col">
                    <span className="text-[14px]">Currency</span>
                    <Controller
                      name="currencyid"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          isDisabled={loading}
                          filterOption={createFilter({
                            ignoreAccents: false,
                          })}
                          options={CurrencyOptions}
                          className=" w-full font-primary text-sm h-10 rounded-sm"
                          styles={customStyles}
                          Searchable
                          placeholder="Search... Currency"
                        />
                      )}
                    />
                  </label>
                  <label className="md:w-6/12 relative w-full flex items-start justify-center flex-col">
                    <span className="text-[14px]">Current Salary</span>
                    <input
                      type="text"
                      name="currentsalary"
                      disabled={loading}
                      {...register("currentsalary")}
                      placeholder="Current Salary"
                      className="w-full px-3 text-sm h-10 border border-border-col rounded-[4px] "
                    />
                    {errors.currentsalary ? (
                      <span className="absolute font-gothic bottom-[-14px] text-[12px] text-red-600 left-0">
                        {errors.currentsalary.message}
                      </span>
                    ) : (
                      ""
                    )}
                  </label>
                  <label className="md:w-3/12 w-full flex items-start justify-center flex-col">
                    <span className="text-[14px]">Salary Tenure</span>
                    <select
                      name="salary_tenure"
                      disabled={loading}
                      {...register("salary_tenure")}
                      className="w-full px-3 text-sm h-10 border border-border-col rounded-[4px] bg-white"
                    >
                      <option value="1">Yearly</option>
                      <option value="2">Monthly</option>
                      <option value="3">Hourly</option>
                    </select>
                  </label>
                </div>

                <div className="w-full my- flex items-center justify-center gap-3 flex-col md:flex-row">
                  <label className="md:w-3/12 w-full flex items-start justify-center flex-col">
                    <span className="text-[14px]">Currency</span>
                    <Controller
                      name="currencyid2"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          isDisabled={loading}
                          filterOption={createFilter({
                            ignoreAccents: false,
                          })}
                          options={CurrencyOptions}
                          className=" w-full font-primary text-sm h-10 rounded-sm"
                          styles={customStyles}
                          Searchable
                          placeholder="Search... Currency"
                        />
                      )}
                    />
                  </label>
                  <label className="md:w-6/12 w-full relative flex items-start justify-center flex-col">
                    <span className="text-[14px]">Expected Salary</span>
                    <input
                      type="text"
                      disabled={loading}
                      name="expectedsalary"
                      {...register("expectedsalary")}
                      placeholder="Expected Salary"
                      className="w-full px-3 text-sm h-10 border border-border-col rounded-[4px] "
                    />
                    {errors.expectedsalary ? (
                      <span className="absolute font-gothic bottom-[-14px] text-[12px] text-red-600 left-0">
                        {errors.expectedsalary.message}
                      </span>
                    ) : (
                      ""
                    )}
                  </label>
                  <label className="md:w-3/12 w-full flex items-start justify-center flex-col">
                    <span className="text-[14px]">Salary Tenure</span>
                    <select
                      name="salary_tenure"
                      disabled={loading}
                      {...register("salary_tenure")}
                      className="w-full px-3 text-sm h-10 border border-border-col rounded-[4px] bg-white"
                    >
                      <option value="1">Yearly</option>
                      <option value="2">Monthly</option>
                      <option value="3">Hourly</option>
                    </select>
                  </label>
                </div>

                <div className="w-full flex items-center justify-start gap-3 flex-col md:flex-row">
                  <label className=" md:w-6/12 w-full flex items-start justify-center flex-col">
                    <span className="text-[14px]">Notice Period</span>
                    <Controller
                      name="noticeperiod"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          isDisabled={loading}
                          Searchable
                          id="noticeperiod"
                          isLoading={isLoading}
                          className=" w-full font-primary text-sm h-10 rounded-sm"
                          options={NoticePeriod}
                          placeholder="--Select Notice--"
                          styles={
                            errors.noticeperiod &&
                            errors.noticeperiod.type === "required"
                              ? ErrorStyles
                              : customStyles
                          }
                        />
                      )}
                    />
                  </label>
                  {watch("noticeperiod")?.value === "Serving_Notice" && (
                    <label className=" md:w-6/12 w-full flex relative items-start justify-center flex-col">
                      <p className="opacity-0">null</p>
                      <input
                        name="noticeenddate"
                        {...register("noticeenddate", {
                          required: "Required",
                        })}
                        type="date"
                        placeholder="Enter Notice Period in days"
                        className="w-full rounded-[5px] px-2 border border-border-col h-10"
                      />
                      {errors.noticeenddate ? (
                        <span className="absolute font-gothic bottom-[-14px] text-[12px] text-red-600 left-0">
                          {errors.noticeenddate.message}
                        </span>
                      ) : (
                        ""
                      )}
                    </label>
                  )}
                  {watch("noticeperiod")?.value === "Immediate" && (
                    <label className=" md:w-6/12 w-full flex relative items-center justify-start flex-row">
                      <input
                        name="offerinhand"
                        {...register("offerinhand")}
                        value="1"
                        type="checkbox"
                        className={"mr-1"}
                      />
                      <p className={`${errors.offerinhand && "text-red-600"}`}>
                        {" "}
                        Offer In Hand
                      </p>
                      {errors.offerinhand ? (
                        <span className="absolute font-gothic bottom-[-14px] text-[12px] text-red-600 left-0">
                          {errors.offerinhand.message}
                        </span>
                      ) : (
                        ""
                      )}
                    </label>
                  )}
                  {watch("noticeperiod")?.value === "Other" && (
                    <label className=" md:w-6/12 w-full flex relative items-start justify-center flex-col">
                      <p className="opacity-0">null</p>
                      <input
                        name="othernoticeperiod"
                        {...register("othernoticeperiod", {
                          required: "Required",
                        })}
                        type="text"
                        placeholder="Enter Notice Period in days"
                        className="w-full rounded-[5px] px-2 border border-border-col h-10"
                      />
                      {errors.noticeperioddays ? (
                        <span className="absolute font-gothic bottom-[-14px] text-[12px] text-red-600 left-0">
                          {errors.noticeperioddays.message}
                        </span>
                      ) : (
                        ""
                      )}
                    </label>
                  )}
                </div>

                <div className="w-full flex items-center justify-start gap-3 flex-col md:flex-row">
                  <label className=" md:w-6/12 w-full flex items-start justify-center flex-col">
                    <span className="text-[14px]">
                      Source of CV <i className="text-red-600">*</i>
                    </span>
                    {/* <Controller
                        name="location"
                        control={control}
                        rules={{ required:typeofCand==='linkedin'? false:true }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            isDisabled={loading}
                            onFocus={() =>
                              setEnabled((prev) => {
                                return {
                                  department: prev.department,
                                  city: true,
                                  skills: prev.skills,
                                };
                              })
                            }
                            Searchable
                            onInputChange={(e) => setCountrySearch(e)}
                            id="locationField"
                            isLoading={isLoading}
                            className=" w-full  font-primary text-sm h-10 rounded-sm"
                            options={LocationOptions}
                            placeholder="--Select City--"
                            styles={
                              errors.location &&
                              errors.location.type === "required"
                                ? ErrorStyles
                                : customStyles
                            }
                          />
                        )}
                      /> */}

                    <Controller
                      name="cvsource"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          Searchable
                          isDisabled={loading || typeofCand === "linkedin"}
                          id="cvsource"
                          className=" w-full font-primary text-sm h-10 rounded-sm"
                          options={CVSource}
                          placeholder="--CV Source--"
                          styles={
                            errors.cvsource &&
                            errors.cvsource.type === "required"
                              ? ErrorStyles
                              : customStyles
                          }
                        />
                      )}
                    />
                  </label>
                </div>
                {/*  */}
                {/* {Assets.map(())} */}
                <div className="w-full flex my-4 items-start justify-center flex-col">
                  <p className="text-[14px] my-3 flex items-center">
                    <span className="text-[16px] font-semibold">
                      Please identify the candidate's Diversity parameter
                    </span>
                    <span
                      className="ml-2 cursor-pointer"
                      onClick={() => setDiverstiy(!diversity)}
                    >
                      {diversity === false ? (
                        <FaPlus className="text-green-700" size={20} />
                      ) : (
                        <FaTimes className="text-red-600" size={20} />
                      )}
                    </span>
                  </p>
                  {diversity ? (
                    <div className="w-full xl:w-9/12 flex items-center justify-between flex-wrap mr-auto gap-3 lg:ml-6">
                      {FormAssets?.map(({ img_src, head }, index) => (
                        <div
                          onClick={() => handleImageSelect(head)}
                          className=" flex items-center justify-center flex-col gap-y-4"
                          key={index}
                        >
                          <p className="font-normal font-primary text-[10px]">
                            {head}
                          </p>
                          <div
                            className={` ${
                              imageSelected?.includes(head)
                                ? " border-border-col "
                                : "border-white"
                            } w-15  p-3 transition-all duration-300 border relative`}
                          >
                            {imageSelected?.includes(head) ? (
                              <div className=" transition-all duration-300 w-5 h-5 bg-green text-white -top-1 -left-1 rounded-full absolute flex items-center justify-center">
                                &#10003;
                              </div>
                            ) : (
                              ""
                            )}
                            <img
                              src={img_src}
                              alt={head}
                              className={` ${
                                imageSelected?.includes(head)
                                  ? "   border-border-col image_box_shadow "
                                  : "border-white"
                              }  z-[1] w-10 border cursor-pointer transition-all duration-300`}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {/*  */}
                <label className="w-full flex flex-col items-start">
                  <span className="text-[14px]">
                    Please describe about candidate's Diversity parameter
                  </span>
                  <textarea
                    type="text"
                    rows="1"
                    name="diversitycomment"
                    {...register("diversitycomment")}
                    placeholder="Mention specific comments regarding the Diversity of this candidate, if any."
                    className="w-full p-3 text-sm  border border-border-col rounded-[4px] "
                  />
                </label>
                {/*  */}
                <div className="w-full">
                  <h3 className="text-left text-[14px]">CV Document</h3>
                </div>

                <div className="w-full flex items-center justify-between">
                  <div className="w-full flex-col  flex gap-5 mb-5 items-start justify-start ">
                    {file?.name ? (
                      <div
                        style={{ wordBreak: "break-all" }}
                        className="text-xs flex items-end justify-center"
                      >
                        <p className="flex text-[16px] text-base-col items-center justify-center gap-2">
                          {file?.name}
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="flex gap-4 items-center justify-center">
                      <label className="w-[50px] h-[53px] bg-white rounded-[4px] border cursor-pointer border-border-col">
                        <img src={`${AMAZONS3}/images/upload.png`} alt="" />
                        <input
                          type="file"
                          name="resume"
                          required={typeofCand === "linkedin" ? false : true}
                          onChange={(e) => handleOnChange(e)}
                          className=" w-[100px] relative bottom-10 opacity-0"
                          accept=".pdf,.doc,.docx"
                        />
                      </label>{" "}
                      <span className="text-[14px]">Upload Resume</span>
                    </div>

                    <label>
                      <input
                        type="checkbox"
                        {...register("status")}
                        checked={true}
                        name="status"
                        className="mr-2"
                        value="1"
                      />
                      Active
                    </label>
                  </div>
                </div>
                {/*  */}

                <div className="flex items-center w-full justify-center gap-3">
                  <button
                    type="button"
                    onClick={() => handleButtonClick()}
                    className="px-3 py-1 bg-white border cursor-pointer border-green text-green rounded-full"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="bg-[green] text-white px-3 rounded-full py-1"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
