import React, { useContext, useState } from "react";
import { CgProfile } from "react-icons/cg";
import { CiSearch } from "react-icons/ci";
import { GrConnect } from "react-icons/gr";
import { IoSettingsOutline } from "react-icons/io5";
import { LiaUsersSolid } from "react-icons/lia";
import { MdArrowDropDown, MdDashboard, MdLogout, MdOutlineContentPasteSearch } from "react-icons/md";
import { SlMenu } from "react-icons/sl";
import { Link, NavLink, useNavigate } from "react-router-dom";
import ContextProvider from "../../Context/ContextProvider";
import Token from "../../Helpers/Token";
import { useLogout } from "../../hooks/apihooks/useProfile";
import { remove } from "../../Helpers/Storage";
import { MdOutlinePayment } from "react-icons/md";
import Role from "../../Helpers/Role";
import { TbTransactionDollar } from "react-icons/tb";
import { PiUserListFill } from "react-icons/pi";
import { Spinner } from "../../Components/Loader/Spinner";
import { HiDocumentReport } from "react-icons/hi";
import { FaProductHunt, FaRegUserCircle } from "react-icons/fa";
import { FaCircleInfo } from "react-icons/fa6";
import { IoIosNotifications } from "react-icons/io";
export default function SideBar({ open, setOpen }) {
  const [handleDrowpDown, sethandleDrowpDown] = useState();
  const { toggleHam, settoogleHam, notification} = useContext(ContextProvider);

  const navigate = useNavigate();
  const { mutateAsync: logoutUser, isPending } = useLogout();

  const logout = () => {
    logoutUser({ token: Token.getAccessToken() }).then((resp) => {
      if (resp?.status === true) {
        remove("userAuth");
        Token.clearToken();
        Role.clearRole();
        navigate("/signin");
      }
    });
  };

  return (
    <>
      {isPending ? <Spinner size="medium" /> : ""}
      <div
        className={`${open ? "left-0" : "left-[-500px] sm:left-0"} ${
          toggleHam
            ? "transition-all sm:w-[70px] w-[70px] "
            : "transition-all sm:w-[20%] w-12/12"
        } sticky top-0 border-r h-[100vh] shadow-gray-30 z-10 sm:z-[1] bg-white border-gray-200 gap-4`}
      >
        <div
          className={`w-full flex  px-2 items-center ${
            toggleHam ? `justify-center` : `justify-between`
          }  flex-wrap-reverse  h-[80px]`}
        >
          {!toggleHam ? (
            <p className={`text-[20px] font-bold font-robo  text-green-900`}>{process.env.REACT_APP_APP_NAME}</p>
          ) : (
            ""
          )}

          <SlMenu
            onClick={() => {
              setOpen((v) => !v);
              settoogleHam(!toggleHam);
            }}
            size={25}
            className="cursor-pointer"
          />
        </div>
        <div
          className={` ${
            toggleHam ? "hidden" : "flex "
          } w-full  items-center justify-center  mt-6`}
        >
          <div className="w-11/12 flex items-center justify-center border-b py-2 border-green-950">
            <CiSearch />
            <input
              className="outline-none w-full px-2 font-sans text-[14px]"
              placeholder="Search"
            />
          </div>
        </div>
        <div
          className={` ${
            toggleHam ? "" : ""
          } w-full flex items-center justify-center  flex-col `}
        >
          <NavLink
            to="/admin/dashboard"
            className={({ isActive }) =>
              isActive
                ? `bg-green-100  border-green-500 border-l-4  p-3 justify-start w-full hover:bg-green-100 flex  cursor-pointer items-center gap-2 text-[16px] relative group`
                : "hover:border-l-4 border-l-4 border-white hover:border-green-500  p-3 justify-start w-full hover:bg-green-100 flex  cursor-pointer items-center gap-2 text-[16px] relative group"
            }
          >
            <MdDashboard size={25} /> {toggleHam ? " " : <p>Dashboard</p>}
            {toggleHam ? (
              <div
                className={`absolute whitespace-no-wrap bg-gray-700 text-white text-sm p-2 rounded shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 left-full top-1/2 transform -translate-y-1/2 ml-2`}
              >
                Search
              </div>
            ) : (
              ""
            )}
          </NavLink>

          <NavLink
            to="/admin/job-list"
            className={({ isActive }) =>
              isActive
                ? `bg-green-100  border-green-500 border-l-4  p-3 justify-start w-full hover:bg-green-100 flex  cursor-pointer items-center gap-2 text-[16px] relative group`
                : "hover:border-l-4 border-l-4 border-white hover:border-green-500  p-3 justify-start w-full hover:bg-green-100 flex  cursor-pointer items-center gap-2 text-[16px] relative group"
            }
          >
            <MdOutlineContentPasteSearch size={25} /> {toggleHam ? " " : <p>Jobs</p>}
            {toggleHam ? (
              <div
                className={`absolute whitespace-no-wrap bg-gray-700 text-white text-sm p-2 rounded shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 left-full top-1/2 transform -translate-y-1/2 ml-2`}
              >
                Jobs
              </div>
            ) : (
              ""
            )}
          </NavLink>
         
          <NavLink
            to="/admin/candidates"
            className={({ isActive }) =>
              isActive
                ? `bg-green-100  border-green-500 border-l-4  p-3 justify-start w-full hover:bg-green-100 flex  cursor-pointer items-center gap-2 text-[16px] relative group`
                : "hover:border-l-4 border-l-4 border-white hover:border-green-500  p-3 justify-start w-full hover:bg-green-100 flex  cursor-pointer items-center gap-2 text-[16px] relative group"
            }
          >
            <LiaUsersSolid size={25} /> {toggleHam ? " " : <p>Candidates </p>}
            {toggleHam ? (
              <div
                className={`absolute whitespace-no-wrap bg-gray-700 text-white text-sm p-2 rounded shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 left-full top-1/2 transform -translate-y-1/2 ml-2`}
              >
                Candidates
              </div>
            ) : (
              ""
            )}
          </NavLink>
          <NavLink
            to="/admin/product"
            className={({ isActive }) =>
              isActive
                ? `bg-green-100  border-green-500 border-l-4  p-3 justify-start w-full hover:bg-green-100 flex  cursor-pointer items-center gap-2 text-[16px] relative group`
                : "hover:border-l-4 border-l-4 border-white hover:border-green-500  p-3 justify-start w-full hover:bg-green-100 flex  cursor-pointer items-center gap-2 text-[16px] relative group"
            }
          >
          <FaProductHunt size={25} /> {toggleHam ? " " : <p>Product </p>}
          </NavLink>
          <NavLink
            to="/admin/users"
            className={({ isActive }) =>
              isActive
                ? `bg-green-100  border-green-500 border-l-4  p-3 justify-start w-full hover:bg-green-100 flex  cursor-pointer items-center gap-2 text-[16px] relative group`
                : "hover:border-l-4 border-l-4 border-white hover:border-green-500  p-3 justify-start w-full hover:bg-green-100 flex  cursor-pointer items-center gap-2 text-[16px] relative group"
            }
          >
            <FaRegUserCircle  size={25} /> {toggleHam ? " " : <p>Users </p>}
          </NavLink>
          <NavLink
            to="/admin/admin-report"
            className={({ isActive }) =>
              isActive
                ? `bg-green-100  border-green-500 border-l-4  p-3 justify-start w-full hover:bg-green-100 flex  cursor-pointer items-center gap-2 text-[16px] relative group`
                : "hover:border-l-4 border-l-4 border-white hover:border-green-500  p-3 justify-start w-full hover:bg-green-100 flex  cursor-pointer items-center gap-2 text-[16px] relative group"
            }
          >
            <HiDocumentReport size={25} /> {toggleHam ? " " : <p>Report </p>}
            {toggleHam ? (
              <div
                className={`absolute whitespace-no-wrap bg-gray-700 text-white text-sm p-2 rounded shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 left-full top-1/2 transform -translate-y-1/2 ml-2`}
              >
                Report
              </div>
            ) : (
              ""
            )}
          </NavLink>
          <NavLink
            to="/admin/notifications"
            className={({ isActive }) =>
              isActive
                ? `bg-green-100  border-green-500 border-l-4  p-3 justify-start w-full hover:bg-green-100 flex  cursor-pointer items-center gap-2 text-[16px] relative group`
                : "hover:border-l-4 border-l-4 border-white hover:border-green-500  p-3 justify-start w-full hover:bg-green-100 flex  cursor-pointer items-center gap-2 text-[16px] relative group"
            }
          >
            <span className="relative"><IoIosNotifications size={25} /> <span className="bg-red-600 text-white absolute top-[-10px] w-[20px] max-w-[40px] h-[20px] max-h-[40px] text-center rounded-full text-[12px]">{notification?.notification}</span></span> {toggleHam ? " " : <p>Notifications </p>}
            {toggleHam ? (
              <div
                className={`absolute whitespace-no-wrap bg-gray-700 text-white text-sm p-2 rounded shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 left-full top-1/2 transform -translate-y-1/2 ml-2`}
              >
                Notifications
              </div>
            ) : (
              ""
            )}
          </NavLink>
          <NavLink
            className={
              "hover:border-l-4 border-l-4 border-white hover:border-green-500  flex-wrap p-3 justify-between w-full hover:bg-green-100 flex  cursor-pointer items-center gap-2 text-[16px] relative group"
            }
          >
            <div className={`flex justify-between  w-full relative`}>
              <div className="flex">
                <IoSettingsOutline className="mr-2" size={25} />
                <p>Settings</p>
              </div>
              <span>
                <MdArrowDropDown
                  size={25}
                  onClick={() =>
                    handleDrowpDown === "settings"
                      ? sethandleDrowpDown("")
                      : sethandleDrowpDown("settings")
                  }
                  className={`${toggleHam ? "" : "mr-2"}`}
                />
              </span>
            </div>
            <div
              className={`dropdonw w-full ${
                handleDrowpDown === "settings"
                  ? "max-h-99 block"
                  : "max-h-0 hidden"
              } transition-all duration-700 select-none`}
            >
              <Link to={"/admin/datasource-mappers"} className="flex py-3 px-3">
                <GrConnect size={20} className="mr-3" />{" "}
                <p className="text-[14px]">Mappers</p>
              </Link>
              <Link to={"/admin/connect-ats"} className="flex py-3 px-3">
                <GrConnect size={20} className="mr-3" />{" "}
                <p className="text-[14px]">Integration (ATS & HRIS)</p>
              </Link>
              <Link to={"/admin/apis"} className="flex py-3 px-3">
                <GrConnect size={20} className="mr-3" />{" "}
                <p className="text-[14px]">APIs</p>
              </Link>
              <Link to={"/profile"} className="flex py-3 px-3">
                <CgProfile size={20} className="mr-3" />{" "}
                <p className="text-[14px]">Profile</p>
              </Link>
            </div>
          </NavLink>
          <NavLink
            className={
              "hover:border-l-4 border-l-4 border-white hover:border-green-500 flex-wrap p-3 justify-between w-full hover:bg-green-100 flex cursor-pointer items-center gap-2 text-[16px] relative group"
            }
          >
            <div className={`flex justify-between w-full relative`}>
              <div className="flex">
                <MdOutlinePayment className="mr-2" size={25} />
                <p className={` text-[15px] ${toggleHam ? 'hidden' : ''}`}>Billing</p>
              </div>
              <span>
                <MdArrowDropDown
                  size={25}
                  onClick={() =>
                    handleDrowpDown === "billing"
                      ? sethandleDrowpDown("")
                      : sethandleDrowpDown("billing")
                  }
                  className={`${toggleHam ? "" : "mr-2"}`}
                />
              </span>
            </div>
          </NavLink>

          {/* Dropdown Links */}
          <div
            className={`dropdown w-full ml-0 md:ml-auto md:w-11/12 ${
              handleDrowpDown === "billing" ? "max-h-99 block" : "max-h-0 hidden"
            } transition-all duration-700 select-none`}
          >
            <NavLink to={"/admin/transection-history"} className={`flex py-3 px-3 hover:bg-green-100`}>
              <TbTransactionDollar size={20} className="mr-3" />
              <p className="text-[15px]">Transactions</p>
            </NavLink>
            <NavLink to={"/admin/order-and-subscription"} className={`flex py-3 px-3 hover:bg-green-100`}>
              <PiUserListFill size={20} className="mr-3" />
              <p className="text-[15px]">Orders & Subscriptions </p>
            </NavLink>
            <NavLink to={"/admin/billing-and-invoices"} className={`flex py-3 px-3 hover:bg-green-100`}>
              <PiUserListFill size={20} className="mr-3" />
              <p className="text-[15px]">Billings & Invoices </p>
            </NavLink>
          </div>

          <NavLink
            className={
              "hover:border-l-4 border-l-4 border-white hover:border-green-500  p-3 justify-start w-full hover:bg-green-100 flex  cursor-pointer items-center gap-2 text-[16px] relative group"
            }
          >
            <MdLogout size={25} />{" "}
            {toggleHam ? " " : <p onClick={() => logout()}>Log Out</p>}
            {toggleHam ? (
              <div
                className={`absolute whitespace-no-wrap bg-gray-700 text-white text-sm p-2 rounded shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 left-full top-1/2 transform -translate-y-1/2 ml-2`}
              >
                Logout
              </div>
            ) : (
              ""
            )}
          </NavLink>
        </div>
      </div>
    </>
  );
}

