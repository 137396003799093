import { useMutation, useQueryClient } from "@tanstack/react-query";
import endpoint from "../Helpers/endpoint";
import Http from "../http";

const createChat = (postData) => {
  const URL = endpoint.communication.createchat;
  return Http().post(URL, postData);
};

export const useCreateChat = () => {
  let queryclient = useQueryClient();
  
  return useMutation({
    mutationFn: createChat,
    onSuccess: (data, variables, context) => {
      queryclient.invalidateQueries(['Chats'])
    },
    onError: (error, variables, context) => {
      queryclient.invalidateQueries(['Chats'])
    },
    onSettled: (data, error, variables, context) => {
      queryclient.invalidateQueries(['Chats'])
    },
  });
};
