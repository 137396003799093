
import React, { useState } from "react";
import ConfirmationModal from "./ConfirmationModal";
import { FaTimes } from "react-icons/fa";
// import { useQuery } from "@tanstack/react-query";
export default function DuplicateCand({ manualCand, setManualCand }) {
    const [duplicates, setDuplicates] = useState([
      { id: 1, name: 'John Doe', email: 'john.doe@example.com' },
      { id: 2, name: 'Jane Smith', email: 'jane.smith@example.com' },
      { id: 3, name: 'John Doe', email: 'john.doe@example.com' },
      // Add more sample data here as needed
    ]);
    const [selectedCandidates] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [actionType, setActionType] = useState('');

    const totalDuplicates = duplicates.length;

    const handleRemoveDuplicates = (action) => {
      setActionType(action);
      setShowModal(true);
    };

    const handleConfirmRemoval = () => {
      if (actionType === 'system') {
        // Remove duplicates by system logic
        // In a real application, you'd add logic here to handle the removal
        console.log('System will remove duplicates');
      } else if (actionType === 'user') {
        // Remove manually selected duplicates
        const remainingCandidates = duplicates.filter(
          (candidate) => !selectedCandidates.includes(candidate.id)
        );
        setDuplicates(remainingCandidates);
        console.log('User removed selected duplicates');
      }
      setShowModal(false);
    };

    // const handleToggleCandidateSelection = (candidateId) => {
    //   setSelectedCandidates((prevSelected) =>
    //     prevSelected.includes(candidateId)
    //       ? prevSelected.filter((id) => id !== candidateId)
    //       : [...prevSelected, candidateId]
    //   );
    // };

    // const  getDuplicate = () => {
    //   const URL = `${endpoint.duplicate.checkcand}`;
    //   return Http().get(URL, {
    //     'Authorization' : `Bearer ${Token.getAccessToken()}`,
    //   });
    // }

    // const {data, isLoading} = useQuery({queryKey: ['getduplicatecand'], queryFn: getDuplicate});

    // console.log("Data Cand Duplicate", data);

  return (
    <div
    className={`relative z-10 ${manualCand ? `` : `hidden tansition-all`}`}
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
      aria-hidden="true"
    ></div>
    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
      <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <div className="relative transform rounded bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[800px]">
          <button
          className="absolute right-[-10px] bg-red-600 top-[-10px] w-[30px] h-[30px] rounded-full flex justify-center items-center text-white"
            onClick={() => {setManualCand(false)}}
          ><FaTimes />
          </button>
          <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 w-full">
            <div className="sm:flex sm:items-start w-full">
              <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
              <div className="mx-auto p-6 bg-white rounded-lg">
                <h1 className="text-2xl font-semibold mb-4">Duplicate Candidate Records</h1>
                <p className="mb-4 text-lg">
                  Total duplicate candidates: <span className="font-bold">{totalDuplicates}</span>
                </p>
                <div className="mb-6">
                  <button
                    onClick={() => handleRemoveDuplicates('system')}
                    className="bg-blue-500 text-white px-4 py-2 rounded-lg mr-4"
                  >
                    Remove by System
                  </button>
                  <button
                    onClick={() => handleRemoveDuplicates('user')}
                    className="bg-green-500 text-white px-4 py-2 rounded-lg"
                  >
                    Remove by User
                  </button>
                </div>

                <div>
                  {/* <h2 className="text-xl font-semibold mb-2">Candidates List</h2> */}
                  <ul>
                    {/* {duplicates.map((candidate) => (
                      <li key={candidate.id} className="flex items-center mb-4">
                        <input
                          type="checkbox"
                          checked={selectedCandidates.includes(candidate.id)}
                          onChange={() => handleToggleCandidateSelection(candidate.id)}
                          className="mr-2"
                        />
                        <div className="flex-1">
                          <p>{candidate.name}</p>
                          <p className="text-sm text-gray-500">{candidate.email}</p>
                        </div>
                      </li>
                    ))} */}
                  </ul>
                </div>

                {/* Confirmation Modal */}
                {showModal && (
                  <ConfirmationModal
                    actionType={actionType}
                    onCancel={() => setShowModal(false)}
                    onConfirm={handleConfirmRemoval}
                  />
                )}
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}
