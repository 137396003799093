import { useMutation } from "@tanstack/react-query";
import endpoint from "../Helpers/endpoint";
import Http from "../http";

const createMessage = (postData) => {
  const URL = endpoint.communication.createmessage;
  return Http().post(URL, postData);
};

export const useCreateMessage = () => {
  // let queryclient = useQueryClient();
  
  return useMutation({
    mutationFn: createMessage,
    onSuccess: (data, variables, context) => {
    
    },
    onError: (error, variables, context) => {
      
    },
    onSettled: (data, error, variables, context) => {
    
    },
  });
};
