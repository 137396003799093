import { useMutation } from "@tanstack/react-query";
import endpoint from "../../Helpers/endpoint";
import Http from "../../http";

const addcandidate = ({payload, token}) => {
    const URL = `${endpoint.billing.billing}`;
    const headers  = {
        "Content-Type": "multipart/form-data", 
        "Authorization": `Bearer ${token}` 
    }
    return Http().post(URL, payload, headers);
}

export const useBillingInfo = () => {
  return useMutation({
      mutationFn:addcandidate,
      onSuccess: (data, variables, context) => {
        // I will fire first
      },
      onError: (error, variables, context) => {
        // I will fire first
      },
      onSettled: (data, error, variables, context) => {
        // I will fire first
      },
  });
}


