import { Link } from "react-router-dom";
import { FaUser, FaUserCircle } from "react-icons/fa";
import { useEffect, useState } from "react";
import useNotificationItem from "../../Admin/InfiniteScroll/InfiniteScrollNotify";
import useDebounce from "../../../Helpers/useDebounce";
import { Spinner } from "../../Loader/Spinner";

  const Notification = ({ type, message, link, toUser }) => {
    return (
      <div className={`flex items-center p-4 mb-2 shadow rounded border bg-white`}>
        <div className="flex-grow">
            <div className="flex items-start">
                <FaUserCircle size={30}  className="text-[#bcbbbb] mr-3"/>
                <div className="flex-grow">
                    <div><strong>{type? type+':':""} </strong> {message}</div>
                    {
                      link ?  <Link to={link} className="text-sm text-blue-500 hover:underline mt-2 inline-block">See Details</Link>:""
                    }
                </div>
                <div className="flex items-center"><FaUser className="mr-2" /> {toUser?.fname}</div>
            </div>
        </div>
      </div>
    );
  };

export function Page(){
    const [search, setSearch] = useState("");
    const searchdebounce = useDebounce(search, 500);
    const { data, fetchNextPage, hasNextPage, isFetching } = useNotificationItem({
      pageParam: 1,
      perPage: 10,
      searchdebounce,
    });
    
    // Scroll event listener
    useEffect(() => {
      let fetching = false;
      const onScroll = async (event) => {
        const { scrollHeight, scrollTop, clientHeight } =
          event.target.scrollingElement;
        if (!fetching && scrollHeight - scrollTop <= clientHeight * 1.7) {
          fetching = true;
          if (hasNextPage) await fetchNextPage();
          fetching = false;
        }
      };

      document.addEventListener("scroll", onScroll);
      return () => {
        document.removeEventListener("scroll", onScroll);
      };
    }, [hasNextPage, fetchNextPage]);
    
    return (
        <>
          {isFetching ? <Spinner size="medium" /> : ""}
           <div className="h-[auto] w-full">
                <div className="px-5 py-5">
                    <div className="rounded font-semibold text-gray-800 mb-4 bg-white border w-full px-5 py-2 flex justify-between items-center"><h3>Manage your notifications</h3> <div><input type="search" onChange={(e) => setSearch(e.target.value)} className="border py-2 text-[13px] px-2 rounded w-[250px]"  placeholder="Search notifications"/></div></div>
                </div>
                <div className="min-h-screen px-5 w-full flex justify-between">
                    <div className="w-[70%]">
                    {data?.pages?.map((data, i) => {
                      return data?.data?.data?.map((notif, index) => {
                        return (
                          <Notification key={index} type={notif.subject} message={notif.message} link={notif.link} toUser={notif?.getuser} />
                        );
                      });
                    })}
                    </div>
                    <div className="w-[28%]">
                        <div className="bg-white p-4 border rounded shadow">
                            <p>Yogesh, unklock you full potential with coffee crew premium</p>
                            <div className="text-center py-10">
                                <span className="text-[20px] font-bold">Coffee Crew</span>
                            </div>
                            <p>Unlimeted plan for all autonomus functionality </p>
                            <div className="text-center mt-3">
                                <button className="border border-green-600 rounded-full px-3 ">Try for free</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}