
import { useQuery } from "@tanstack/react-query";
import React from "react";
import DataTable from "react-data-table-component";
import { FaEdit, FaTrash } from "react-icons/fa";
import { SlMenu } from "react-icons/sl";
import AdminLayouts from "../../../Layouts/Admin/AdminLayouts";
import endpoint from "../../../Helpers/endpoint";
import Http from "../../../http";
import { Link } from "react-router-dom";
import Token from "../../../Helpers/Token";

export default function AdminPromocode({open , setOpen}) {

  const getalluserlist = () => {
    const URL = `${process.env.REACT_APP_APIENDPOINT}${endpoint.product.product}`;
    return Http().get(URL,{
        "Content-type": "application/json",
        "Authorization": `Bearer ${Token.getAccessToken()}`,
    });
  }

  // const {isLoading, data: usedata} = useQuery({queryKey: ['getproductlist'], queryFn: getalluserlist, enabled : Boolean(Token.getAccessToken())});

  // const columns = [
  //   {
  //     name: 'Product',
  //     selector: row => row.productname,
  //   },
  //   {
  //     name: 'Uid',
  //     selector: row => row.productuid,
  //   },
  //   {
  //     name: 'Category',
  //     selector: row => row.category,
  //   },
  //   {
  //     name: 'Price',
  //     selector: (row) => {
  //       let currencydata = JSON.parse(row?.currency);
  //       let text = '';
  //       currencydata.forEach(element => {
  //         text += `[{${element.currency}}`+element?.original_price+`/`+element?.sale_price+`]\n`
  //       });
  //       return text;
  //     }
  //   },
  //   {
  //     name: 'Status',
  //     selector: row => row?.status ? 'active' : 'pending'
  //   },
  //   {
  //     name: 'Action',
  //     selector: (row) => (
  //       <div className="flex">
  //         <Link to={`/admin/product/add/${row?.productuid}`} className="text-[green]"><FaEdit size={20} /></Link>
  //         <button><FaTrash size={20} className="text-[red]" /></button>
  //       </div>
  //     )
  //   }
  // ];

  return (
    <AdminLayouts>
      <div className="px-10 py-5 h-[100vh] w-full bg-green-50">
        <div className="flex justify-center max-sm:mt-[50px]">
          <div className="mt-[30px] mb-[30px] w-full">
              <div className="p-[30px] bg-[white] max-sm:p-[15px]">
                <div className="flex justify-between">
                  <h3>Promo code List</h3>
                  <div>
                    <Link to={'/admin/product/add'} className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-green-400 to-blue-600 group-hover:from-green-400 group-hover:to-blue-600 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-green-200 dark:focus:ring-green-800">
                    <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                    Add Product
                    </span>
                    </Link>

                    <Link to={'/admin/promocode/add'} className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-green-400 to-blue-600 group-hover:from-green-400 group-hover:to-blue-600 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-green-200 dark:focus:ring-green-800">
                    <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                      Add code
                    </span>
                    </Link>
                  </div>
                </div>

                {/* <DataTable
                  pagination
                  columns={columns}
                  progressPending={isLoading}
                  data={usedata?.data?.data}
                /> */}
              </div>
          </div>
        </div>
      </div>
    </AdminLayouts>
  );
}
