import React, { useMemo, useState } from "react";
import { useRunLinkedinCampaign } from "../../../../hooks/apihooks/Campaigns/useRunLinkedinCampaign";
import Loader from "../../../Loader/Loader";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";
import endpoint from "../../../../Helpers/endpoint";
import Http from "../../../../http";
import { useQuery } from "@tanstack/react-query";
import { IoCreate } from "react-icons/io5";
import { Controller, useForm } from "react-hook-form";

export default function Campaign() {
  // params
  let navigate = useNavigate();
  // states
  const [value, setValue] = useState(10);
  const [message, setMessage] = useState(`Hi`);
  const [isChecked, setIsChecked] = useState(false);
  // hooks
  const { handleSubmit, register, control } = useForm();
  const { mutateAsync, isPending } = useRunLinkedinCampaign();
  // query
  // functions
  // handlers
  // life cycle

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleToggle = () => {
    setIsChecked(!isChecked);
  };

  const placeholderhandler = (e) => {
    setMessage((prevMessage) => prevMessage + "" + e);
  };

  const onplaceholderChange = (e) => {
    setMessage(e.target.value);
  };

  function getUser() {
    let endpoints = endpoint.user.linkedinAccount;
    return Http().get(endpoints);
  }

  const { data } = useQuery({
    queryKey: ["User-Account"],
    queryFn: getUser,
  });

  const onSubmit = (fields) => {
    let postData = {
      campaigntitle: fields.campaigntitle,
      message: fields.message,
      account_id: data?.data?.account_id,
      country : fields.country?.label,
      jobid : fields.jobid.value,
      meeting_link : fields.meeting_link,
      skills : fields?.skills?.map(data => data.value).join(',')
    };
   
    mutateAsync(postData).then((res) => {
      if (res?.status) {
        // navigate(`/campaigns-external/${res?.campaign?.campaignuid}`)
        navigate("/campaigns-list");
      } else {
        toast.warn("Something went wrong");
      }
    });
  };

  async function getJobs() {
    let endpoints = `${endpoint.job.listing}`;
    try {
      const response = await Http().get(endpoints);
      return response;
    } catch (error) {
      throw error;
    }
  }

  const { data: Jobs } = useQuery({
    queryKey: ["jobslisting"],
    queryFn: getJobs,
  });

  const jobOptions = useMemo(() => {
    return Jobs?.data?.map((data) => {
      return { value: data?.jobid, label: data?.jobtitle };
    });
  }, [Jobs]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-full bg-green-50 min-h-[100vh]  py-10 flex items-center justify-start flex-col gap-3"
    >
      <div className="flex items-start flex-col justify-center w-[95%] rounded-md  bg-gradient-leads p-3">
        <div className="flex items-center justify-start gap-1">
          <IoCreate size={25} />
          <p className="text-[18px] font-medium">Create Campaign </p>
        </div>

        <p className="text-[13px]">
          Launch AI-powered LinkedIn campaigns to automate outreach and maximize
          engagement.
        </p>
      </div>
      {isPending ? <Loader /> : ""}

      <div className="flex gap-4 items-center justify-center flex-col w-[95%] px-3 py-6 bg-white border border-gray-300 rounded-md">
        <div className="w-full flex items-center justify-between gap-1">
          <p className="font-semibold">
            {" "}
            Set Campaign parameters to run your campaign
          </p>
          <button
            className="px-4 py-1 text-[14px] bg-green-600 text-white rounded-md"
            type="submit"
          >
            Save & Continue
          </button>
        </div>
        <div className="w-full p-3 border rounded-[5px] flex-col border-gray-300 flex items-center justify-between gap-1">
          <label className="w-full flex items-start justify-center flex-col gap-2">
            <p className="text-[14px] font-medium">Campaign Name:</p>
            <input
              placeholder="Senior Java Developer"
              // value={campaigntitle}
              // onChange={(e) => setCampaignTitle(e.target.value)}
              name="campaigntitle"
              {...register("campaigntitle", { required: true })}
              className="w-full h-10 p-2 rounded-[4px] border border-gray-300 placeholder:text-[14px] text-[14px]"
            />
            <p className="ml-auto text-[12px]">Characters 10/100</p>
          </label>

          <div className="w-full flex items-center justify-center gap-2 flex-col">
            <div className="w-full flex items-start justify-center gap-2 flex-col">
              <p className="text-[14px] font-medium">
                Target candidate persona
              </p>

              <div className="w-full flex items-center justify-center flex-col md:flex-row gap-3">
                <div className="md:w-6/12 w-full flex items-start justify-center flex-col">
                  <p className="text-[13px] font-medium">Country</p>
                  <Controller
                    control={control}
                    name="country"
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={[
                          { value: "India", label: "India" },
                          { value: "USA", label: "USA" },
                          { value: "Canada", label: "Canada" },
                          { value: "Australia", label: "Australia" },
                          { value: "Germany", label: "Germany" },
                          { value: "France", label: "France" },
                          { value: "Japan", label: "Japan" },
                          { value: "China", label: "China" },
                          { value: "Brazil", label: "Brazil" },
                          { value: "UK", label: "UK" },
                        ]}
                        Searchable
                        className="w-full text-[12px]"
                        placeholder="Select Country"
                      />
                    )}
                  />
                </div>
                <div className="md:w-6/12 w-full flex items-start flex-col justify-center">
                  <p className="text-[13px] font-medium">Job Title</p>
                  <Controller
                    control={control}
                    name="jobid"
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={jobOptions}
                        className="w-full text-[12px]"
                        placeholder="Select Job"
                        Searchable
                      />
                    )}
                  />
                </div>
              </div>
              <div className="w-full flex items-start justify-center flex-col">
                <p className="text-[13px] font-medium">Skills</p>
                <Controller
                  control={control}
                  name="skills"
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={[
                        { value: "Java", label: "Java" },
                        { value: "Python", label: "Python" },
                        { value: "React", label: "React" },
                        { value: "Node.js", label: "Node.js" },
                        { value: "SQL", label: "SQL" },
                        { value: "HTML", label: "HTML" },
                        { value: "CSS", label: "CSS" },
                        { value: "Angular", label: "Angular" },
                        { value: "PHP", label: "PHP" },
                        { value: "C++", label: "C++" },
                      ]}
                      isMulti
                      className="w-full text-[12px]"
                      placeholder="Select Country"
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full p-3 border rounded-[5px] border-gray-300 flex items-start justify-between gap-4 flex-col">
          <div className="w-full flex items-start justify-between gap-4">
            <div className="w-6/12 flex items-start justify-center flex-col">
              <p className="text-[14px] font-medium">Schedule campaign</p>
              <p className="text-[12px]">
                You can schedule when campaign will be active. just set date and
                time. Once the campaign is set to active,messages will start
                begin sent as soon as the associated LinkedIn profile has
                entered its active hours
              </p>
            </div>
            <div className="w-3/12 flex items-start justify-center flex-col gap-1 ml-3">
              <p className="text-[14px] font-medium">Select date and time</p>
              <input
                type="datetime-local"
                className="text-[12px] border-none placeholder:text-[14px]"
              />
            </div>
            <div className="w-3/12 flex items-center justify-end gap-2">
              <p className="text-[14px] font-medium">Start immediately</p>
              <label className="flex items-center cursor-pointer">
                <div className="relative">
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleToggle}
                    className="sr-only"
                  />
                  <div
                    className={`block w-10 h-5 rounded-full ${
                      isChecked ? "bg-green-600" : "bg-gray-300"
                    }`}
                  ></div>
                  <div
                    className={`dot absolute left-1 top-0.5 bg-white w-4 h-4 rounded-full transition ${
                      isChecked ? "transform translate-x-[18px]" : ""
                    }`}
                  ></div>
                </div>
              </label>
            </div>
          </div>
          <div className="w-full flex items-start justify-between gap-4 md:flex-row flex-col">
            <div className="w-6/12 flex items-center justify-center gap-2">
              <label className="w-full flex items-center justify-center flex-col gap-1">
                <p className="text-[14px] font-medium w-full text-left">
                  Your Calendar Meeting link{" "}
                  <span className="font-normal">(Optional)</span>{" "}
                </p>
                <input
                  name="meeting_link"
                  {...register('meeting_link')}
                  className="w-full border border-gray-200 h-10 p-2 placeholder:text-[14px] text-[14px] "
                  placeholder="Ex. https://meet.google.com/abc-123-pqr"
                />
              </label>
            </div>
            <div className="w-6/12 flex items-center justify-center gap-2"></div>
          </div>
        </div>
        <div className="w-full p-3 border rounded-[5px] border-gray-300 flex items-start justify-between gap-4">
          <div className="w-6/12">
            <p className="text-[14px] font-medium">Campaign Limits</p>
            <p className="text-[10px]">
              Specify the daily limit for this campaign. These limits will be
              applied to reach out to your leads.
            </p>
          </div>
          <div className="w-6/12 flex items-center justify-center flex-col gap-2 ">
            <div className="w-full flex items-start justify-center flex-col gap-1">
              <p className="text-[14px] font-medium">
                Set the number of new people to be connected by this campaign
                daily:
              </p>
              <div className="flex flex-col items-center space-y-4 w-full mt-2">
                <div className="flex items-center space-x-2 w-full">
                  <span className="text-[12px]">0</span>
                  <div className="relative w-full">
                    <input
                      type="range"
                      min="0"
                      max="100"
                      value={value}
                      onChange={handleChange}
                      className="w-full h-2 appearance-none rounded-full cursor-pointer placeholder:text-[14px]"
                      style={{
                        background: `linear-gradient(to right, #3b82f6 ${value}%, #d1d5db ${value}%)`,
                      }}
                    />
                    <div
                      className="absolute  -top-1 left-0 transform -translate-x-1/2 -translate-y-1/2 text-black  text-[10px]"
                      style={{
                        left: `${value}%`,
                      }}
                    >
                      {value}
                    </div>
                  </div>
                  <span className="text-[12px]">100</span>
                </div>
              </div>
            </div>
            <div className="w-full flex items-start justify-center flex-col gap-1">
              <p className="text-[14px] font-medium">
                Set the number of follow up messages to be sent from this
                campaign daily:
              </p>
              <div className="flex flex-col items-center space-y-4 w-full mt-2">
                <div className="flex items-center space-x-2 w-full">
                  <span className="text-[12px]">0</span>
                  <div className="relative w-full">
                    <input
                      type="range"
                      min="0"
                      max="100"
                      value={value}
                      onChange={handleChange}
                      className="w-full h-2 appearance-none rounded-full cursor-pointer"
                      style={{
                        background: `linear-gradient(to right, #3b82f6 ${value}%, #d1d5db ${value}%)`,
                      }}
                    />
                    <div
                      className="absolute  -top-1 left-0 transform -translate-x-1/2 -translate-y-1/2 text-black  text-[10px]"
                      style={{
                        left: `${value}%`,
                      }}
                    >
                      {value}
                    </div>
                  </div>
                  <span className="text-[12px]">100</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full p-3 border rounded-[5px] border-gray-300 flex items-start justify-between gap-4">
          <div className="w-6/12 flex items-start justify-center flex-col ">
            <p className="text-[14px] font-medium">Invitation message</p>
            <p className="text-[12px]">
              Create and modify the messages sent throughout the different steps
              of your campaign. Upon making changes, all campaign activity will
              be paused. Note that messages left with empty bodies will simply
              be ignored. The only exception to that is leaving the first
              message of a connector campaign blank. This will lead to a
              connection request being requested without a connector message
            </p>
          </div>
          <div className="w-6/12 flex items-start justify-center flex-col gap-2">
            <p className="text-[14px] font-medium">Body</p>
            <textarea
              rows={5}
              name="message"
              {...register('message')}
              className="w-full border text-[14px] border-gray-300 rounded-[5px] p-3"
              value={message}
              onChange={onplaceholderChange}
            />
            <div className="w-full items-center justify-start gap-1 flex-row flex-wrap flex">
              <p
                onClick={() => placeholderhandler(`{first_name}`)}
                className="px-3 py-1 text-[12px] cursor-pointer rounded-full bg-gray-300"
              >{`{first_name}`}</p>
              <p
                onClick={() => placeholderhandler(`{last_name}`)}
                className="px-3 py-1 text-[12px] cursor-pointer rounded-full bg-gray-300"
              >{`{last_name}`}</p>
              <p
                onClick={() => placeholderhandler(`{company_name}`)}
                className="px-3 py-1 text-[12px] cursor-pointer rounded-full bg-gray-300"
              >{`{company_name}`}</p>
              <p
                onClick={() => placeholderhandler(`{job_title}`)}
                className="px-3 py-1 text-[12px] cursor-pointer rounded-full bg-gray-300"
              >{`{job_title}`}</p>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
