import { useQuery } from "@tanstack/react-query";
import endpoint from "../../../Helpers/endpoint";
import Token from "../../../Helpers/Token";
import Http from "../../../http";
import BarlineChart from "../../Chart/BarlineChart";
import DoughnutChart from "../../Chart/Doughnut";
import LineChart from "../../Chart/LineChart";

export const JobsReport = () => {
    const  getReportCandWeek = () => {
        const URL = `${endpoint.report.jobrep}?typeof=Week`;
        return Http().get(URL, {
            'Authorization' : `Bearer ${Token.getAccessToken()}`,
        });
    }

    const  getReportCandYear = () => {
        const URL = `${endpoint.report.jobrep}?typeof=Year`;
        return Http().get(URL, {
            'Authorization' : `Bearer ${Token.getAccessToken()}`,
        });
    }

    const  mulipleSource = () => {
        const URL = `${endpoint.report.jobrep}?typeof=mulipleSource`;
        return Http().get(URL, {
            'Authorization' : `Bearer ${Token.getAccessToken()}`,
        });
    }

    const {data:jobReportWeek} = useQuery({queryKey: ['getjobreportweek'], queryFn: getReportCandWeek});
    const {data:jobReportYear} = useQuery({queryKey: ['getjobreportyear'], queryFn: getReportCandYear});
    const {data:jobReportmultipleSource} = useQuery({queryKey: ['getjobreportSource'], queryFn: mulipleSource});

    return (<div className="flex flex-wrap  justify-between">
        <div className="w-[49%] mb-3 mr-2 px-3 py-3 bg-white rounded border">
            <LineChart  Report={jobReportWeek} chartType="Weekly Job" />
        </div>
        <div className="w-[49%] mb-3 mr-2 px-3 py-3 bg-white rounded border">
            <DoughnutChart  Report={jobReportmultipleSource} datatype="location"/>
        </div>
        {/* <div className="w-[49%] mr-2 mb-3 px-3 py-3 bg-white rounded border">
            <RadarChart  />
        </div> */}
        <div className="w-[49%] mr-2 mb-3 px-3 py-3 bg-white rounded border">
            <BarlineChart  Report={jobReportYear}  chartLabel="Montly Job" />
        </div>
    </div>)
}