import { FaTimes } from "react-icons/fa";

export function PopupOrderDetail({popupOrder, setOrderPopup, data}){
    let product = data?.meta_info?.filter((row) => row?.meta_key==='product')[0] || {};
    let billingInfo = data?.meta_info?.filter((row) => row?.meta_key==='billing_info')[0] || {};
    return (
        <div>
            <div class={`relative z-10 ${popupOrder?'':'hidden'}`} aria-labelledby="modal-title" role="dialog" aria-modal="true">
                <div class="fixed inset-0 bg-gray-500/75 transition-opacity" aria-hidden="true"></div>
                <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <div class="relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[900px]">
                        <div className="absolute right-[-10px] top-[-10px] w-[30px] h-[30px] block px-2 py-[7px] text-center rounded-full leading-[30px] bg-red-500 text-white cursor-pointer" onClick={() => setOrderPopup(false)}><FaTimes /></div>
                        <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                            <div class="w-full">
                                <h3 class="text-base font-semibold text-gray-900 w-full" id="modal-title">Order Detail and (Subscription)</h3>
                                <div className="flex w-full mt-5">
                                    <div className="w-[70%]">
                                        <div className="border rounded mb-2 mr-3">
                                            <h3 className="bg-gray-100 px-2 py-2 ">Order Item</h3>
                                            <div className="px-2 py-2 ">
                                                <div><span className="font-bold">Product Name:</span> <span>{product?.meta_value?.productname}</span></div>
                                                <div><span className="font-bold">Category : </span> <span>{product?.meta_value?.category}</span></div>
                                                <div><span className="font-bold">Description : </span> <span>{product?.meta_value?.description}</span></div>
                                                <div><span className="font-bold">Note :</span> <span>{data?.note}</span></div>
                                                <div><span className="font-bold">Price :</span> {data?.currency} {data?.amount}</div>
                                            </div>
                                           
                                        </div>
                                        <div className="border rounded mb-2 mr-3">
                                            <h3 className="bg-gray-100 px-2 py-2 ">Order Summary</h3>
                                            <div className="px-2 py-2 break-words">
                                                <div><span className="font-bold">Order Id :</span> <span>#{data?.order_id}</span></div>
                                                <div><span className="font-bold">Order Date :</span> <span>{data?.created_at}</span></div>
                                                <div><span className="font-bold">Status:</span> <span>{data?.status}</span></div>
                                                <div><span className="font-bold">Plan Validity:</span> <span>{data?.start_date} {data?.end_date}</span></div>
                                                <div><span className="font-bold">Payment Method:</span> <span>{data?.payment_method}</span></div>
                                                <div><span className="font-bold">Transaction ID:</span> <span>{data?.transaction_id}</span></div>
                                                <div><span className="font-bold">Amount:</span> <span>{data?.currency} {data?.amount}</span></div>
                                                <div><span className="font-bold">Discount:</span> <span>{data?.currency} {data?.discount_amount}</span></div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-[30%]">
                                        <div className="border rounded mb-2 break-words">
                                            <h3 className="bg-gray-100 px-2 py-2 ">Notes</h3>
                                            <div className="px-2 py-2">
                                            </div>
                                        </div>
                                        <div className="border rounded mb-2 break-words">
                                            <h3 className="bg-gray-100 px-2 py-2 ">Customer</h3>
                                            <div className="px-2 py-2">
                                                <div><span className="font-bold">Name :</span> <span>{data?.user_data?.fname} {data?.user_data?.lname}</span></div>
                                                <div><span className="font-bold">Email :</span> <span>{data?.user_data?.email}</span></div>
                                                <div><span className="font-bold">Status:</span> <span>{data?.user_data?.status}</span></div>
                                            </div>
                                        </div>
                                        <div className="border rounded mb-2 break-words">
                                            <h3 className="bg-gray-100 px-2 py-2">Contact Information</h3>
                                            <div className="px-2 py-2 break-words">
                                                <span className="font-bold"> Email : </span> <span>{data?.email}</span>
                                            </div>
                                        </div>
                                        <div className="border rounded mb-2 break-words">
                                            <h3 className="bg-gray-100 px-2 py-2 ">Billing Address</h3>
                                            <div className="px-2 py-2 break-words">
                                                <div><span className="font-bold">Name :</span> <span>{billingInfo?.meta_value?.first_name} {billingInfo?.meta_value?.last_name}</span></div>
                                                <div><span className="font-bold">Email :</span> <span>{billingInfo?.meta_value?.email}</span></div>
                                                <div><span className="font-bold">Phone :</span> <span>{billingInfo?.meta_value?.phone}</span></div>
                                                <div><span className="font-bold">City :</span> <span>{billingInfo?.meta_value?.city}</span></div>
                                                <div><span className="font-bold">State :</span> <span>{billingInfo?.meta_value?.state}</span></div>
                                                <div><span className="font-bold">Zip Code :</span> <span>{billingInfo?.meta_value?.zip_code}</span></div>
                                                <div><span className="font-bold">Country :</span> <span>{billingInfo?.meta_value?.country}</span></div>
                                                <div><span className="font-bold">Address Line 1 :</span> <span>{billingInfo?.meta_value?.address_line1}</span></div>
                                                <div><span className="font-bold">Address Line 2 :</span> <span>{billingInfo?.meta_value?.address_line2}</span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    )
}
    
    
    