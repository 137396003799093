
import React from 'react'
import { TiTick } from "react-icons/ti";
import { useForm } from "react-hook-form"
import { useRequestDemo } from '../../../hooks/apihooks/useRequestDemo';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { Spinner } from '../../Loader/Spinner';
export default function DemoRequest() {
  const {
    register,
    // watch,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const {mutateAsync : requestDemo, isPending} = useRequestDemo();
  
  const formSubmit = (data) => {
    requestDemo(data).then((resp) => {
      if(resp?.status===true){
        toast.success(resp?.message);
        reset();
      }
    })
  };
  
  
  return (
    <>
      {isPending ? <Spinner size='medium' />  : " " }
      <section className='bg-green-100 h-[100vh]'>
        <div className='container m-[auto] max-sm:px-3 relative'>
          <div className='py-5 absolute'><Link to={'/'} className='font-bold'>{process.env.REACT_APP_APP_NAME}</Link></div>
          <div className='flex items-center flex-wrap max-sm:flex-wrap-reverse justify-between h-[100vh] max-sm:h-[auto]'>
            <div className='w-[45%] max-sm:w-full max-sm:mt-[40px]'>
              <h1 className='text-[40px] font-extrabold'>Talent Engagement Platform</h1>
              <ul className='mt-[20px]'>
                <li className='py-2 flex items-center'><TiTick className='text-[25px] text-[green]' /> 55% faster time-to-hire</li>
                <li className='py-2 flex items-center'><TiTick className='text-[25px] text-[green]' /> Candidate response time down to {'<'} 10 minutes</li>
                <li className='py-2 flex items-center'><TiTick className='text-[25px] text-[green]' /> 3X more applicants for hard-to-fill roles</li>
                <li className='py-2 flex items-center'><TiTick className='text-[25px] text-[green]' /> 92% candidate show rate for scheduled interviews</li>
              </ul>
            </div>
            <div className='w-[50%] max-sm:w-full max-sm:mt-[70px]'>
              <div className='bg-[#fff] border rounded-[10px] py-8 px-8'>
                <div className='text-center mb-5'>
                  <h3 className='text-[20px]'>See the power of <br/><span className='text-[green]'>{process.env.REACT_APP_APP_NAME} </span>
                  in action today</h3>
                </div>
                <form onSubmit={handleSubmit(formSubmit)}>
                  <div className='flex flex-wrap justify-between mb-3'>
                    <div className='w-[48%] max-sm:w-full'>
                      <label>First Name<sup className='text-[red]'>*</sup></label>
                      <input type='text' {...register("fname", { required: true })} className='w-full px-2 py-2 border  rounded' placeholder='First Name' />
                      {errors.fname && <p className="text-[red]">First Name is required.</p>}
                    </div>
                    <div className='w-[48%] max-sm:w-full'>
                      <label>Last Name<sup className='text-[red]'>*</sup></label>
                      <input type='text' {...register("lname", { required: true })} className='w-full px-2 py-2 border  rounded' placeholder='Last Name' />
                      {errors.lname && <p className="text-[red]">Last Name is required.</p>}
                    </div>
                  </div>
                  <div className='flex  flex-wrap justify-between mb-3'>
                    <div className='w-[48%] max-sm:w-full'>
                      <label>Company Name<sup className='text-[red]'>*</sup></label>
                      <input type='text' {...register("companyname", { required: true })} className='w-full px-2 py-2 border  rounded' placeholder='Company Name' />
                      {errors.companyname && <p className="text-[red]">Company Name is required.</p>}
                    </div>
                    <div className='w-[48%] max-sm:w-full'>
                      <label>Job Title<sup className='text-[red]'>*</sup></label>
                      <input type='text' {...register("jobtitle", { required: true })} className='w-full px-2 py-2 border  rounded' placeholder='Job Title' />
                      {errors.jobtitle && <p className="text-[red]">Job Title is required.</p>}
                    </div>
                  </div>
                  <div className='flex justify-between mb-3'>
                    <div className='w-[100%] max-sm:w-full'>
                      <label>Work Email<sup className='text-[red]'>*</sup></label>
                      <input type='text' {...register("email", { required: true })} className='w-full px-2 py-2 border  rounded' placeholder='Company Name' />
                      {errors.email && <p className="text-[red]">Email is required.</p>}
                    </div>
                  </div>
                  <div className='flex justify-between mb-3'>
                    <div className='w-[100%] max-sm:w-full'>
                      <label>What ATS do you use?<sup className='text-[red]'>*</sup></label>
                      <select className="w-full px-2 py-2 border  rounded" name="atsuse" {...register("atsuse", {required:true})}>
                        <option disabled="" value="">Please Select</option>
                        <option value="ABD Ultrastaff EDGE">ABD Ultrastaff EDGE</option>
                        <option value="ADP RM">ADP RM</option>
                        <option value="ADP WFN">ADP Workforce Now</option>
                        <option value="Avionte Bold">Avionte Bold</option>
                        <option value="Avionte Classic">Avionte Classic</option>
                        <option value="Bullhorn">Bullhorn</option>
                        <option value="Ceipal">Ceipal</option>
                        <option value="Crelate Talent">Crelate Talent	</option>
                        <option value="eRecruit">eRecruit</option>
                        <option value="Greenhouse">Greenhouse</option>
                        <option value="iCIMS">iCIMS</option>
                        <option value="JobAdder">JobAdder</option>
                        <option value="JobDiva">JobDiva</option>
                        <option value="JobVite">JobVite</option>
                        <option value="LaborEdge">LaborEdge</option>
                        <option value="Lever">Lever</option>
                        <option value="Microsoft Dynamics">Microsoft Dynamics</option>
                        <option value="Oracle Cloud">Oracle Recruiting Cloud</option>
                        <option value="Other">Other</option>
                        <option value="PCRecruiter">PCRecruiter</option>
                        <option value="Salesforce">Salesforce</option>
                        <option value="SmartRecruiters">SmartRecruiters</option>
                        <option value="SuccessFactors">SuccessFactors</option>
                        <option value="Symplr-CTM">Symplr-CTM</option>
                        <option value="Taleo Enterprise">Taleo Enterprise</option>
                        <option value="TargetRecruit">TargetRecruit</option>
                        <option value="Tempworks Beyond">Tempworks Beyond</option>
                        <option value="Tempworks Enterprise">Tempworks Enterprise</option>
                        <option value="Vincere">Vincere</option>
                        <option value="Workday Recruiting">Workday Recruiting</option>
                        <option value="Zenople">Zenople</option>
                      </select>
                      {errors.atsuse && <p className="text-[red]">Select your ATS.</p>}
                    </div>
                  </div>
                  <div className='flex flex-wrap justify-between mb-3'>
                    <div className='w-[48%] max-sm:w-full'>
                      <label>Choose your industry<sup className='text-[red]'>*</sup></label>
                      <select required="" className="w-full px-2 py-2 border  rounded" name='industry' {...register("industry", { required: true })} >
                        <option disabled="" value="">Please Select</option>
                        <option value="Aerospace">Aerospace</option>
                        <option value="Agriculture">Agriculture</option>
                        <option value="Chemical">Chemical</option>
                        <option value="Construction">Construction</option>
                        <option value="Defense">Defense</option>
                        <option value="Education">Education</option>
                        <option value="Energy">Energy</option>
                        <option value="Entertainment">Entertainment</option>
                        <option value="Finance &amp; Insurance">Finance &amp; Insurance</option>
                        <option value="Government">Government</option>
                        <option value="Healthcare">Healthcare</option>
                        <option value="Hospitality">Hospitality</option>
                        <option value="Manufacturing">Manufacturing</option>
                        <option value="Nonprofit">Nonprofit</option>
                        <option value="Other">Other</option>
                        <option value="Professional Services">Professional Services</option
                        ><option value="Retail">Retail</option>
                        <option value="Staffing">Staffing</option>
                        <option value="Technology">Technology</option>
                        <option value="Transportation">Transportation</option>
                      </select>
                      {errors.industry && <p className="text-[red]">Select your industry.</p>}
                    </div>
                    <div className='w-[48%] max-sm:w-full'>
                      <label>No. of employees<sup className='text-[red]'>*</sup></label>
                      <select  className='w-full px-2 py-2 border  rounded' {...register("noofemployee", { required: true })} name='noofemployee'>
                        <option disabled="" value="">Please Select</option>
                        <option value="1 - 9">1 - 9</option>
                        <option value="10 - 99">10 - 99</option>
                        <option value="100 - 499">100 - 499</option>
                        <option value="500 - 999">500 - 999</option>
                        <option value="1000 - 2499">1000 - 2499</option>
                        <option value="2500 - 4999">2500 - 4999</option>
                        <option value="5000 - 9999">5000 - 9999</option>
                        <option value="10000+">10000+</option>
                        <option value="1- 99">1- 99</option>
                      </select>
                      {errors.noofemployee && <p className="text-[red]">No of employees is required.</p>}
                    </div>
                  </div>
                  <div className='flex flex-wrap justify-between mb-3'>
                    <div className='w-[100%] max-sm:w-full'>
                      <label>Location<sup className='text-[red]'>*</sup></label>
                      <select {...register("location", { required: true })} className="w-full px-2 py-2 border  rounded" name="location">
                        <option disabled="" value="">Please Select</option>
                        <option value="United States">United States</option>
                        <option value="Europe">Europe</option>
                        <option value="India"> India</option>
                        <option value="Other">Other</option>
                      </select>
                      {errors.location && <p className="text-[red]">Location is required.</p>}
                    </div>
                  </div>
                  <div className='flex justify-between mb-3'>
                    <div className='w-[100%]'>
                      <button type='text' className='w-full px-2 py-2 bg-green-700 text-white  rounded'>Submit</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
