import React from 'react';
import Http from '../../http';
import { useQuery } from '@tanstack/react-query';
import Token from '../../Helpers/Token';
import Role from '../../Helpers/Role';
import { set } from '../../Helpers/Storage';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Spinner } from '../Loader/Spinner';

const LINKEDIN_CLIENT_SECRET = 'uay15MGlalKD5kfE';
const LINKEDIN_CLIENT_ID = '77jgag3zl94i3l';
const LINKEDIN_CALLBACK_URL = 'http://localhost:3000/auth/linkedin/callback';
const linkedinOAuthURL = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${LINKEDIN_CLIENT_ID}&redirect_uri=${encodeURIComponent(
  LINKEDIN_CALLBACK_URL
)}&state=UEHSHE3947DEED&scope=openid%20profile%20email`;

const LinkedInOAuth = () => {
  const navigate  = useNavigate();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get('code');

    const getAccesstoken = () => {
      let payload = {
        grant_type: 'authorization_code',
        code,
        client_id: LINKEDIN_CLIENT_ID,
        client_secret: LINKEDIN_CLIENT_SECRET,
        redirect_uri : encodeURIComponent(LINKEDIN_CALLBACK_URL)
      };
      // Create a URLSearchParams object from the payload
      const params = new URLSearchParams(payload);
      // Construct the query string
      const queryString = params.toString();
      const URL = `${process.env.REACT_APP_APIENDPOINT}/manage-user-service/v1/getAccessToken`
      const headers  = 
        { 
          "Content-Type": "application/x-www-form-urlencoded",
        }
      return Http().post(URL, queryString, headers);
    }

    const {data: getAccessToken, isLoading : tokenload} = useQuery({
      queryKey : ['getAccessTokne'], queryFn : () => getAccesstoken(), enabled : Boolean(code)
    });

    const getUserDataProfiel = (token) => {
      const datatoke = {accessToken: token}
      const URL = `${process.env.REACT_APP_APIENDPOINT}/manage-user-service/v1/getprofilelinkedin`
      const headers  = {
          "Content-Type": "application/x-www-form-urlencoded",
        }
      return Http().post(URL, datatoke, headers);
    }

    const {data: userData, isLoading : profileload} = useQuery({
      queryKey : ['getuserData'], queryFn : () => getUserDataProfiel(getAccessToken?.access_token), enabled : Boolean(getAccessToken?.access_token)
    });

    if(userData?.status===true){
        set("userAuth", userData?.data);
        Token.setToken(userData?.token)
        Role.setRole(userData?.data?.role);
        if(userData?.data?.role==='Admin'){
          navigate('/admin/dashboard');
        }else{
          navigate('/dashboard');
        }
        toast.success(userData?.data?.message);
    }
      
    if(profileload || tokenload){
      return <Spinner size='medium' />
    }

    if(userData?.status===false || getAccessToken){
        let error  = getAccessToken?.error ? getAccessToken?.error_description :
        userData?.message ? userData?.message : "" ;
        return (
          <div className="flex h-screen">
            <div className='m-auto rounded w-[50%] border px-3 py-3'>
              <p className='mb-3'>{error}</p>
              <Link to={'/'} className='inline-block text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800'>Go Home</Link>
            </div>
          </div>
        )
    }
    
    return (
      <>
       <Link to={linkedinOAuthURL} className='w-[49%] max-sm:w-full border-2 py-3 rounded-[8px] flex justify-center items-center'>
        <div className='w-[20px] mr-3'>
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 48 48">
            <path fill="#0288D1" d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"></path><path fill="#FFF" d="M12 19H17V36H12zM14.485 17h-.028C12.965 17 12 15.888 12 14.499 12 13.08 12.995 12 14.514 12c1.521 0 2.458 1.08 2.486 2.499C17 15.887 16.035 17 14.485 17zM36 36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698-1.501 0-2.313 1.012-2.707 1.99C24.957 25.543 25 26.511 25 27v9h-5V19h5v2.616C25.721 20.5 26.85 19 29.738 19c3.578 0 6.261 2.25 6.261 7.274L36 36 36 36z"></path>
          </svg>
        </div>
        <p className='text-[13px]'>Continue with LinkedIn</p>
      </Link>
      </>
    );
};

export default LinkedInOAuth;