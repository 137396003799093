import React from "react";

export default function CountryCodes() {
  return (
    <>
      <option data-countrycode="IN" value="91">
        India (+91)
      </option>
      <option data-countrycode="GB" value="44">
        UK (+44)
      </option>
      <option data-countrycode="CA" value="1">
        Canada (+1)
      </option>
      <option data-countrycode="US" value="1">
        USA (+1)
      </option>
      <optgroup label="Other countries">
        <option data-countrycode="DZ" value="213">
          Algeria (+213)
        </option>
        <option data-countrycode="AD" value="376">
          Andorra (+376)
        </option>
        <option data-countrycode="AO" value="244">
          Angola (+244)
        </option>
        <option data-countrycode="AI" value="1264">
          Anguilla (+1264)
        </option>
        <option data-countrycode="AG" value="1268">
          Antigua &amp; Barbuda (+1268)
        </option>
        <option data-countrycode="AR" value="54">
          Argentina (+54)
        </option>
        <option data-countrycode="AM" value="374">
          Armenia (+374)
        </option>
        <option data-countrycode="AW" value="297">
          Aruba (+297)
        </option>
        <option data-countrycode="AU" value="61">
          Australia (+61)
        </option>
        <option data-countrycode="AT" value="43">
          Austria (+43)
        </option>
        <option data-countrycode="AZ" value="994">
          Azerbaijan (+994)
        </option>
        <option data-countrycode="BS" value="1242">
          Bahamas (+1242)
        </option>
        <option data-countrycode="BH" value="973">
          Bahrain (+973)
        </option>
        <option data-countrycode="BD" value="880">
          Bangladesh (+880)
        </option>
        <option data-countrycode="BB" value="1246">
          Barbados (+1246)
        </option>
        <option data-countrycode="BY" value="375">
          Belarus (+375)
        </option>
        <option data-countrycode="BE" value="32">
          Belgium (+32)
        </option>
        <option data-countrycode="BZ" value="501">
          Belize (+501)
        </option>
        <option data-countrycode="BJ" value="229">
          Benin (+229)
        </option>
        <option data-countrycode="BM" value="1441">
          Bermuda (+1441)
        </option>
        <option data-countrycode="BT" value="975">
          Bhutan (+975)
        </option>
        <option data-countrycode="BO" value="591">
          Bolivia (+591)
        </option>
        <option data-countrycode="BA" value="387">
          Bosnia Herzegovina (+387)
        </option>
        <option data-countrycode="BW" value="267">
          Botswana (+267)
        </option>
        <option data-countrycode="BR" value="55">
          Brazil (+55)
        </option>
        <option data-countrycode="BN" value="673">
          Brunei (+673)
        </option>
        <option data-countrycode="BG" value="359">
          Bulgaria (+359)
        </option>
        <option data-countrycode="BF" value="226">
          Burkina Faso (+226)
        </option>
        <option data-countrycode="BI" value="257">
          Burundi (+257)
        </option>
        <option data-countrycode="KH" value="855">
          Cambodia (+855)
        </option>
        <option data-countrycode="CM" value="237">
          Cameroon (+237)
        </option>

        <option data-countrycode="CV" value="238">
          Cape Verde Islands (+238)
        </option>
        <option data-countrycode="KY" value="1345">
          Cayman Islands (+1345)
        </option>
        <option data-countrycode="CF" value="236">
          Central African Republic (+236)
        </option>
        <option data-countrycode="CL" value="56">
          Chile (+56)
        </option>
        <option data-countrycode="CN" value="86">
          China (+86)
        </option>
        <option data-countrycode="CO" value="57">
          Colombia (+57)
        </option>
        <option data-countrycode="KM" value="269">
          Comoros (+269)
        </option>
        <option data-countrycode="CG" value="242">
          Congo (+242)
        </option>
        <option data-countrycode="CK" value="682">
          Cook Islands (+682)
        </option>
        <option data-countrycode="CR" value="506">
          Costa Rica (+506)
        </option>
        <option data-countrycode="HR" value="385">
          Croatia (+385)
        </option>
        <option data-countrycode="CU" value="53">
          Cuba (+53)
        </option>
        <option data-countrycode="CY" value="90392">
          Cyprus North (+90392)
        </option>
        <option data-countrycode="CY" value="357">
          Cyprus South (+357)
        </option>
        <option data-countrycode="CZ" value="42">
          Czech Republic (+42)
        </option>
        <option data-countrycode="DK" value="45">
          Denmark (+45)
        </option>
        <option data-countrycode="DJ" value="253">
          Djibouti (+253)
        </option>
        <option data-countrycode="DM" value="1809">
          Dominica (+1809)
        </option>
        <option data-countrycode="DO" value="1809">
          Dominican Republic (+1809)
        </option>
        <option data-countrycode="EC" value="593">
          Ecuador (+593)
        </option>
        <option data-countrycode="EG" value="20">
          Egypt (+20)
        </option>
        <option data-countrycode="SV" value="503">
          El Salvador (+503)
        </option>
        <option data-countrycode="GQ" value="240">
          Equatorial Guinea (+240)
        </option>
        <option data-countrycode="ER" value="291">
          Eritrea (+291)
        </option>
        <option data-countrycode="EE" value="372">
          Estonia (+372)
        </option>
        <option data-countrycode="ET" value="251">
          Ethiopia (+251)
        </option>
        <option data-countrycode="FK" value="500">
          Falkland Islands (+500)
        </option>
        <option data-countrycode="FO" value="298">
          Faroe Islands (+298)
        </option>
        <option data-countrycode="FJ" value="679">
          Fiji (+679)
        </option>
        <option data-countrycode="FI" value="358">
          Finland (+358)
        </option>
        <option data-countrycode="FR" value="33">
          France (+33)
        </option>
        <option data-countrycode="GF" value="594">
          French Guiana (+594)
        </option>
        <option data-countrycode="PF" value="689">
          French Polynesia (+689)
        </option>
        <option data-countrycode="GA" value="241">
          Gabon (+241)
        </option>
        <option data-countrycode="GM" value="220">
          Gambia (+220)
        </option>
        <option data-countrycode="GE" value="7880">
          Georgia (+7880)
        </option>
        <option data-countrycode="DE" value="49">
          Germany (+49)
        </option>
        <option data-countrycode="GH" value="233">
          Ghana (+233)
        </option>
        <option data-countrycode="GI" value="350">
          Gibraltar (+350)
        </option>
        <option data-countrycode="GR" value="30">
          Greece (+30)
        </option>
        <option data-countrycode="GL" value="299">
          Greenland (+299)
        </option>
        <option data-countrycode="GD" value="1473">
          Grenada (+1473)
        </option>
        <option data-countrycode="GP" value="590">
          Guadeloupe (+590)
        </option>
        <option data-countrycode="GU" value="671">
          Guam (+671)
        </option>
        <option data-countrycode="GT" value="502">
          Guatemala (+502)
        </option>
        <option data-countrycode="GN" value="224">
          Guinea (+224)
        </option>
        <option data-countrycode="GW" value="245">
          Guinea - Bissau (+245)
        </option>
        <option data-countrycode="GY" value="592">
          Guyana (+592)
        </option>
        <option data-countrycode="HT" value="509">
          Haiti (+509)
        </option>
        <option data-countrycode="HN" value="504">
          Honduras (+504)
        </option>
        <option data-countrycode="HK" value="852">
          Hong Kong (+852)
        </option>
        <option data-countrycode="HU" value="36">
          Hungary (+36)
        </option>
        <option data-countrycode="IS" value="354">
          Iceland (+354)
        </option>

        <option data-countrycode="ID" value="62">
          Indonesia (+62)
        </option>
        <option data-countrycode="IR" value="98">
          Iran (+98)
        </option>
        <option data-countrycode="IQ" value="964">
          Iraq (+964)
        </option>
        <option data-countrycode="IE" value="353">
          Ireland (+353)
        </option>
        <option data-countrycode="IL" value="972">
          Israel (+972)
        </option>
        <option data-countrycode="IT" value="39">
          Italy (+39)
        </option>
        <option data-countrycode="JM" value="1876">
          Jamaica (+1876)
        </option>
        <option data-countrycode="JP" value="81">
          Japan (+81)
        </option>
        <option data-countrycode="JO" value="962">
          Jordan (+962)
        </option>
        <option data-countrycode="KZ" value="7">
          Kazakhstan (+7)
        </option>
        <option data-countrycode="KE" value="254">
          Kenya (+254)
        </option>
        <option data-countrycode="KI" value="686">
          Kiribati (+686)
        </option>
        <option data-countrycode="KP" value="850">
          Korea North (+850)
        </option>
        <option data-countrycode="KR" value="82">
          Korea South (+82)
        </option>
        <option data-countrycode="KW" value="965">
          Kuwait (+965)
        </option>
        <option data-countrycode="KG" value="996">
          Kyrgyzstan (+996)
        </option>
        <option data-countrycode="LA" value="856">
          Laos (+856)
        </option>
        <option data-countrycode="LV" value="371">
          Latvia (+371)
        </option>
        <option data-countrycode="LB" value="961">
          Lebanon (+961)
        </option>
        <option data-countrycode="LS" value="266">
          Lesotho (+266)
        </option>
        <option data-countrycode="LR" value="231">
          Liberia (+231)
        </option>
        <option data-countrycode="LY" value="218">
          Libya (+218)
        </option>
        <option data-countrycode="LI" value="417">
          Liechtenstein (+417)
        </option>
        <option data-countrycode="LT" value="370">
          Lithuania (+370)
        </option>
        <option data-countrycode="LU" value="352">
          Luxembourg (+352)
        </option>
        <option data-countrycode="MO" value="853">
          Macao (+853)
        </option>
        <option data-countrycode="MK" value="389">
          Macedonia (+389)
        </option>
        <option data-countrycode="MG" value="261">
          Madagascar (+261)
        </option>
        <option data-countrycode="MW" value="265">
          Malawi (+265)
        </option>
        <option data-countrycode="MY" value="60">
          Malaysia (+60)
        </option>
        <option data-countrycode="MV" value="960">
          Maldives (+960)
        </option>
        <option data-countrycode="ML" value="223">
          Mali (+223)
        </option>
        <option data-countrycode="MT" value="356">
          Malta (+356)
        </option>
        <option data-countrycode="MH" value="692">
          Marshall Islands (+692)
        </option>
        <option data-countrycode="MQ" value="596">
          Martinique (+596)
        </option>
        <option data-countrycode="MR" value="222">
          Mauritania (+222)
        </option>
        <option data-countrycode="YT" value="269">
          Mayotte (+269)
        </option>
        <option data-countrycode="MX" value="52">
          Mexico (+52)
        </option>
        <option data-countrycode="FM" value="691">
          Micronesia (+691)
        </option>
        <option data-countrycode="MD" value="373">
          Moldova (+373)
        </option>
        <option data-countrycode="MC" value="377">
          Monaco (+377)
        </option>
        <option data-countrycode="MN" value="976">
          Mongolia (+976)
        </option>
        <option data-countrycode="MS" value="1664">
          Montserrat (+1664)
        </option>
        <option data-countrycode="MA" value="212">
          Morocco (+212)
        </option>
        <option data-countrycode="MZ" value="258">
          Mozambique (+258)
        </option>
        <option data-countrycode="MN" value="95">
          Myanmar (+95)
        </option>
        <option data-countrycode="NA" value="264">
          Namibia (+264)
        </option>
        <option data-countrycode="NR" value="674">
          Nauru (+674)
        </option>
        <option data-countrycode="NP" value="977">
          Nepal (+977)
        </option>
        <option data-countrycode="NL" value="31">
          Netherlands (+31)
        </option>
        <option data-countrycode="NC" value="687">
          New Caledonia (+687)
        </option>
        <option data-countrycode="NZ" value="64">
          New Zealand (+64)
        </option>
        <option data-countrycode="NI" value="505">
          Nicaragua (+505)
        </option>
        <option data-countrycode="NE" value="227">
          Niger (+227)
        </option>
        <option data-countrycode="NG" value="234">
          Nigeria (+234)
        </option>
        <option data-countrycode="NU" value="683">
          Niue (+683)
        </option>
        <option data-countrycode="NF" value="672">
          Norfolk Islands (+672)
        </option>
        <option data-countrycode="NP" value="670">
          Northern Marianas (+670)
        </option>
        <option data-countrycode="NO" value="47">
          Norway (+47)
        </option>
        <option data-countrycode="OM" value="968">
          Oman (+968)
        </option>
        <option data-countrycode="PW" value="680">
          Palau (+680)
        </option>
        <option data-countrycode="PA" value="507">
          Panama (+507)
        </option>
        <option data-countrycode="PAK" value="92">
          Pakistan (+92)
        </option>
        <option data-countrycode="PG" value="675">
          Papua New Guinea (+675)
        </option>
        <option data-countrycode="PY" value="595">
          Paraguay (+595)
        </option>
        <option data-countrycode="PE" value="51">
          Peru (+51)
        </option>
        <option data-countrycode="PH" value="63">
          Philippines (+63)
        </option>
        <option data-countrycode="PL" value="48">
          Poland (+48)
        </option>
        <option data-countrycode="PT" value="351">
          Portugal (+351)
        </option>
        <option data-countrycode="PR" value="1787">
          Puerto Rico (+1787)
        </option>
        <option data-countrycode="QA" value="974">
          Qatar (+974)
        </option>
        <option data-countrycode="RE" value="262">
          Reunion (+262)
        </option>
        <option data-countrycode="RO" value="40">
          Romania (+40)
        </option>
        <option data-countrycode="RU" value="7">
          Russia (+7)
        </option>
        <option data-countrycode="RW" value="250">
          Rwanda (+250)
        </option>
        <option data-countrycode="SM" value="378">
          San Marino (+378)
        </option>
        <option data-countrycode="ST" value="239">
          Sao Tome &amp; Principe (+239)
        </option>
        <option data-countrycode="SA" value="966">
          Saudi Arabia (+966)
        </option>
        <option data-countrycode="SN" value="221">
          Senegal (+221)
        </option>
        <option data-countrycode="CS" value="381">
          Serbia (+381)
        </option>
        <option data-countrycode="SC" value="248">
          Seychelles (+248)
        </option>
        <option data-countrycode="SL" value="232">
          Sierra Leone (+232)
        </option>
        <option data-countrycode="SG" value="65">
          Singapore (+65)
        </option>
        <option data-countrycode="SK" value="421">
          Slovak Republic (+421)
        </option>
        <option data-countrycode="SI" value="386">
          Slovenia (+386)
        </option>
        <option data-countrycode="SB" value="677">
          Solomon Islands (+677)
        </option>
        <option data-countrycode="SO" value="252">
          Somalia (+252)
        </option>
        <option data-countrycode="ZA" value="27">
          South Africa (+27)
        </option>
        <option data-countrycode="ES" value="34">
          Spain (+34)
        </option>
        <option data-countrycode="LK" value="94">
          Sri Lanka (+94)
        </option>
        <option data-countrycode="SH" value="290">
          St. Helena (+290)
        </option>
        <option data-countrycode="KN" value="1869">
          St. Kitts (+1869)
        </option>
        <option data-countrycode="SC" value="1758">
          St. Lucia (+1758)
        </option>
        <option data-countrycode="SD" value="249">
          Sudan (+249)
        </option>
        <option data-countrycode="SR" value="597">
          Suriname (+597)
        </option>
        <option data-countrycode="SZ" value="268">
          Swaziland (+268)
        </option>
        <option data-countrycode="SE" value="46">
          Sweden (+46)
        </option>
        <option data-countrycode="CH" value="41">
          Switzerland (+41)
        </option>
        <option data-countrycode="SI" value="963">
          Syria (+963)
        </option>
        <option data-countrycode="TW" value="886">
          Taiwan (+886)
        </option>
        <option data-countrycode="TJ" value="7">
          Tajikstan (+7)
        </option>
        <option data-countrycode="TH" value="66">
          Thailand (+66)
        </option>
        <option data-countrycode="TG" value="228">
          Togo (+228)
        </option>
        <option data-countrycode="TO" value="676">
          Tonga (+676)
        </option>
        <option data-countrycode="TT" value="1868">
          Trinidad &amp; Tobago (+1868)
        </option>
        <option data-countrycode="TN" value="216">
          Tunisia (+216)
        </option>
        <option data-countrycode="TR" value="90">
          Turkey (+90)
        </option>
        <option data-countrycode="TM" value="7">
          Turkmenistan (+7)
        </option>
        <option data-countrycode="TM" value="993">
          Turkmenistan (+993)
        </option>
        <option data-countrycode="TC" value="1649">
          Turks &amp; Caicos Islands (+1649)
        </option>
        <option data-countrycode="TV" value="688">
          Tuvalu (+688)
        </option>
        <option data-countrycode="UG" value="256">
          Uganda (+256)
        </option>

        <option data-countrycode="UA" value="380">
          Ukraine (+380)
        </option>
        <option data-countrycode="AE" value="971">
          United Arab Emirates (+971)
        </option>
        <option data-countrycode="UY" value="598">
          Uruguay (+598)
        </option>

        <option data-countrycode="UZ" value="7">
          Uzbekistan (+7)
        </option>
        <option data-countrycode="VU" value="678">
          Vanuatu (+678)
        </option>
        <option data-countrycode="VA" value="379">
          Vatican City (+379)
        </option>
        <option data-countrycode="VE" value="58">
          Venezuela (+58)
        </option>
        <option data-countrycode="VN" value="84">
          Vietnam (+84)
        </option>
        <option data-countrycode="VG" value="84">
          Virgin Islands - British (+1284)
        </option>
        <option data-countrycode="VI" value="84">
          Virgin Islands - US (+1340)
        </option>
        <option data-countrycode="WF" value="681">
          Wallis &amp; Futuna (+681)
        </option>
        <option data-countrycode="YE" value="969">
          Yemen (North)(+969)
        </option>
        <option data-countrycode="YE" value="967">
          Yemen (South)(+967)
        </option>
        <option data-countrycode="ZM" value="260">
          Zambia (+260)
        </option>
        <option data-countrycode="ZW" value="263">
          Zimbabwe (+263)
        </option>
      </optgroup>
    </>
  );
}

export const CountryOptions = [
  {
    value: 1,
    label: "Afghanistan",
    active: 1,
    deleted: 0,
  },
  {
    value: 2,
    label: "Albania",
    active: 1,
    deleted: 0,
  },
  {
    value: 3,
    label: "Algeria",
    active: 1,
    deleted: 0,
  },
  {
    value: 4,
    label: "Andorra",
    active: 1,
    deleted: 0,
  },
  {
    value: 5,
    label: "Angola",
    active: 1,
    deleted: 0,
  },
  {
    value: 6,
    label: "Antigua and Barbuda",
    active: 1,
    deleted: 0,
  },
  {
    value: 7,
    label: "Argentina",
    active: 1,
    deleted: 0,
  },
  {
    value: 8,
    label: "Armenia",
    active: 1,
    deleted: 0,
  },
  {
    value: 9,
    label: "Aruba",
    active: 1,
    deleted: 0,
  },
  {
    value: 10,
    label: "Australia",
    active: 1,
    deleted: 0,
  },
  {
    value: 11,
    label: "Austria",
    active: 1,
    deleted: 0,
  },
  {
    value: 12,
    label: "Azerbaijan",
    active: 1,
    deleted: 0,
  },
  {
    value: 13,
    label: "Bahamas, The",
    active: 1,
    deleted: 0,
  },
  {
    value: 14,
    label: "Bahrain",
    active: 1,
    deleted: 0,
  },
  {
    value: 15,
    label: "Bangladesh",
    active: 1,
    deleted: 0,
  },
  {
    value: 16,
    label: "Barbados",
    active: 1,
    deleted: 0,
  },
  {
    value: 17,
    label: "Belarus",
    active: 1,
    deleted: 0,
  },
  {
    value: 18,
    label: "Belgium",
    active: 1,
    deleted: 0,
  },
  {
    value: 19,
    label: "Belize",
    active: 1,
    deleted: 0,
  },
  {
    value: 20,
    label: "Benin",
    active: 1,
    deleted: 0,
  },
  {
    value: 21,
    label: "Bhutan",
    active: 1,
    deleted: 0,
  },
  {
    value: 22,
    label: "Bolivia",
    active: 1,
    deleted: 0,
  },
  {
    value: 23,
    label: "Bosnia and Herzegovina",
    active: 1,
    deleted: 0,
  },
  {
    value: 24,
    label: "Botswana",
    active: 1,
    deleted: 0,
  },
  {
    value: 25,
    label: "Brazil",
    active: 1,
    deleted: 0,
  },
  {
    value: 26,
    label: "Brunei",
    active: 1,
    deleted: 0,
  },
  {
    value: 27,
    label: "Bulgaria",
    active: 1,
    deleted: 0,
  },
  {
    value: 28,
    label: "Burkina Faso",
    active: 1,
    deleted: 0,
  },
  {
    value: 29,
    label: "Burma",
    active: 1,
    deleted: 0,
  },
  {
    value: 30,
    label: "Burundi",
    active: 1,
    deleted: 0,
  },
  {
    value: 34,
    label: "Cabo Verde",
    active: 1,
    deleted: 0,
  },
  {
    value: 31,
    label: "Cambodia",
    active: 1,
    deleted: 0,
  },
  {
    value: 32,
    label: "Cameroon",
    active: 1,
    deleted: 0,
  },
  {
    value: 33,
    label: "Canada",
    active: 1,
    deleted: 0,
  },
  {
    value: 35,
    label: "Central African Republic",
    active: 1,
    deleted: 0,
  },
  {
    value: 36,
    label: "Chad",
    active: 1,
    deleted: 0,
  },
  {
    value: 37,
    label: "Chile",
    active: 1,
    deleted: 0,
  },
  {
    value: 38,
    label: "China",
    active: 1,
    deleted: 0,
  },
  {
    value: 39,
    label: "Colombia",
    active: 1,
    deleted: 0,
  },
  {
    value: 40,
    label: "Comoros",
    active: 1,
    deleted: 0,
  },
  {
    value: 41,
    label: "Congo, Democratic Republic of the",
    active: 1,
    deleted: 0,
  },
  {
    value: 42,
    label: "Congo, Republic of the",
    active: 1,
    deleted: 0,
  },
  {
    value: 43,
    label: "Costa Rica",
    active: 1,
    deleted: 0,
  },
  {
    value: 44,
    label: "Cote d'Ivoire",
    active: 1,
    deleted: 0,
  },
  {
    value: 45,
    label: "Croatia",
    active: 1,
    deleted: 0,
  },
  {
    value: 46,
    label: "Cuba",
    active: 1,
    deleted: 0,
  },
  {
    value: 47,
    label: "Curacao",
    active: 1,
    deleted: 0,
  },
  {
    value: 48,
    label: "Cyprus",
    active: 1,
    deleted: 0,
  },
  {
    value: 49,
    label: "Czechia",
    active: 1,
    deleted: 0,
  },
  {
    value: 50,
    label: "Denmark",
    active: 1,
    deleted: 0,
  },
  {
    value: 51,
    label: "Djibouti",
    active: 1,
    deleted: 0,
  },
  {
    value: 52,
    label: "Dominica",
    active: 1,
    deleted: 0,
  },
  {
    value: 53,
    label: "Dominican Republic",
    active: 1,
    deleted: 0,
  },
  {
    value: 54,
    label: "East Timor (see Timor-Leste)",
    active: 1,
    deleted: 0,
  },
  {
    value: 55,
    label: "Ecuador",
    active: 1,
    deleted: 0,
  },
  {
    value: 56,
    label: "Egypt",
    active: 1,
    deleted: 0,
  },
  {
    value: 57,
    label: "El Salvador",
    active: 1,
    deleted: 0,
  },
  {
    value: 58,
    label: "Equatorial Guinea",
    active: 1,
    deleted: 0,
  },
  {
    value: 59,
    label: "Eritrea",
    active: 1,
    deleted: 0,
  },
  {
    value: 60,
    label: "Estonia",
    active: 1,
    deleted: 0,
  },
  {
    value: 61,
    label: "Ethiopia",
    active: 1,
    deleted: 0,
  },
  {
    value: 62,
    label: "Fiji",
    active: 1,
    deleted: 0,
  },
  {
    value: 63,
    label: "Finland",
    active: 1,
    deleted: 0,
  },
  {
    value: 64,
    label: "France",
    active: 1,
    deleted: 0,
  },
  {
    value: 65,
    label: "Gabon",
    active: 1,
    deleted: 0,
  },
  {
    value: 66,
    label: "Gambia, The",
    active: 1,
    deleted: 0,
  },
  {
    value: 67,
    label: "Georgia",
    active: 1,
    deleted: 0,
  },
  {
    value: 68,
    label: "Germany",
    active: 1,
    deleted: 0,
  },
  {
    value: 69,
    label: "Ghana",
    active: 1,
    deleted: 0,
  },
  {
    value: 70,
    label: "Greece",
    active: 1,
    deleted: 0,
  },
  {
    value: 71,
    label: "Grenada",
    active: 1,
    deleted: 0,
  },
  {
    value: 72,
    label: "Guatemala",
    active: 1,
    deleted: 0,
  },
  {
    value: 73,
    label: "Guinea",
    active: 1,
    deleted: 0,
  },
  {
    value: 74,
    label: "Guinea-Bissau",
    active: 1,
    deleted: 0,
  },
  {
    value: 75,
    label: "Guyana",
    active: 1,
    deleted: 0,
  },
  {
    value: 76,
    label: "Haiti",
    active: 1,
    deleted: 0,
  },
  {
    value: 77,
    label: "Holy See",
    active: 1,
    deleted: 0,
  },
  {
    value: 78,
    label: "Honduras",
    active: 1,
    deleted: 0,
  },
  {
    value: 79,
    label: "Hong Kong",
    active: 1,
    deleted: 0,
  },
  {
    value: 80,
    label: "Hungary",
    active: 1,
    deleted: 0,
  },
  {
    value: 81,
    label: "Iceland",
    active: 1,
    deleted: 0,
  },
  {
    value: 82,
    label: "India",
    active: 1,
    deleted: 0,
  },
  {
    value: 83,
    label: "Indonesia",
    active: 1,
    deleted: 0,
  },
  {
    value: 84,
    label: "Iran",
    active: 1,
    deleted: 0,
  },
  {
    value: 85,
    label: "Iraq",
    active: 1,
    deleted: 0,
  },
  {
    value: 86,
    label: "Ireland",
    active: 1,
    deleted: 0,
  },
  {
    value: 87,
    label: "Israel",
    active: 1,
    deleted: 0,
  },
  {
    value: 88,
    label: "Italy",
    active: 1,
    deleted: 0,
  },
  {
    value: 89,
    label: "Jamaica",
    active: 1,
    deleted: 0,
  },
  {
    value: 90,
    label: "Japan",
    active: 1,
    deleted: 0,
  },
  {
    value: 91,
    label: "Jordan",
    active: 1,
    deleted: 0,
  },
  {
    value: 92,
    label: "Kazakhstan",
    active: 1,
    deleted: 0,
  },
  {
    value: 93,
    label: "Kenya",
    active: 1,
    deleted: 0,
  },
  {
    value: 94,
    label: "Kiribati",
    active: 1,
    deleted: 0,
  },
  {
    value: 95,
    label: "Korea, North",
    active: 1,
    deleted: 0,
  },
  {
    value: 96,
    label: "Korea, South",
    active: 1,
    deleted: 0,
  },
  {
    value: 97,
    label: "Kosovo",
    active: 1,
    deleted: 0,
  },
  {
    value: 98,
    label: "Kuwait",
    active: 1,
    deleted: 0,
  },
  {
    value: 99,
    label: "Kyrgyzstan",
    active: 1,
    deleted: 0,
  },
  {
    value: 100,
    label: "Laos",
    active: 1,
    deleted: 0,
  },
  {
    value: 101,
    label: "Latvia",
    active: 1,
    deleted: 0,
  },
  {
    value: 102,
    label: "Lebanon",
    active: 1,
    deleted: 0,
  },
  {
    value: 103,
    label: "Lesotho",
    active: 1,
    deleted: 0,
  },
  {
    value: 104,
    label: "Liberia",
    active: 1,
    deleted: 0,
  },
  {
    value: 105,
    label: "Libya",
    active: 1,
    deleted: 0,
  },
  {
    value: 106,
    label: "Liechtenstein",
    active: 1,
    deleted: 0,
  },
  {
    value: 107,
    label: "Lithuania",
    active: 1,
    deleted: 0,
  },
  {
    value: 108,
    label: "Luxembourg",
    active: 1,
    deleted: 0,
  },
  {
    value: 109,
    label: "Macau",
    active: 1,
    deleted: 0,
  },
  {
    value: 110,
    label: "Macedonia",
    active: 1,
    deleted: 0,
  },
  {
    value: 111,
    label: "Madagascar",
    active: 1,
    deleted: 0,
  },
  {
    value: 112,
    label: "Malawi",
    active: 1,
    deleted: 0,
  },
  {
    value: 113,
    label: "Malaysia",
    active: 1,
    deleted: 0,
  },
  {
    value: 114,
    label: "Maldives",
    active: 1,
    deleted: 0,
  },
  {
    value: 115,
    label: "Mali",
    active: 1,
    deleted: 0,
  },
  {
    value: 116,
    label: "Malta",
    active: 1,
    deleted: 0,
  },
  {
    value: 117,
    label: "Marshall Islands",
    active: 1,
    deleted: 0,
  },
  {
    value: 118,
    label: "Mauritania",
    active: 1,
    deleted: 0,
  },
  {
    value: 119,
    label: "Mauritius",
    active: 1,
    deleted: 0,
  },
  {
    value: 120,
    label: "Mexico",
    active: 1,
    deleted: 0,
  },
  {
    value: 121,
    label: "Micronesia",
    active: 1,
    deleted: 0,
  },
  {
    value: 122,
    label: "Moldova",
    active: 1,
    deleted: 0,
  },
  {
    value: 123,
    label: "Monaco",
    active: 1,
    deleted: 0,
  },
  {
    value: 124,
    label: "Mongolia",
    active: 1,
    deleted: 0,
  },
  {
    value: 125,
    label: "Montenegro",
    active: 1,
    deleted: 0,
  },
  {
    value: 126,
    label: "Morocco",
    active: 1,
    deleted: 0,
  },
  {
    value: 127,
    label: "Mozambique",
    active: 1,
    deleted: 0,
  },
  {
    value: 128,
    label: "Namibia",
    active: 1,
    deleted: 0,
  },
  {
    value: 129,
    label: "Nauru",
    active: 1,
    deleted: 0,
  },
  {
    value: 130,
    label: "Nepal",
    active: 1,
    deleted: 0,
  },
  {
    value: 131,
    label: "Netherlands",
    active: 1,
    deleted: 0,
  },
  {
    value: 132,
    label: "New Zealand",
    active: 1,
    deleted: 0,
  },
  {
    value: 133,
    label: "Nicaragua",
    active: 1,
    deleted: 0,
  },
  {
    value: 134,
    label: "Niger",
    active: 1,
    deleted: 0,
  },
  {
    value: 135,
    label: "Nigeria",
    active: 1,
    deleted: 0,
  },
  {
    value: 136,
    label: "North Korea",
    active: 1,
    deleted: 0,
  },
  {
    value: 137,
    label: "Norway",
    active: 1,
    deleted: 0,
  },
  {
    value: 138,
    label: "Oman",
    active: 1,
    deleted: 0,
  },
  {
    value: 139,
    label: "Pakistan",
    active: 1,
    deleted: 0,
  },
  {
    value: 140,
    label: "Palau",
    active: 1,
    deleted: 0,
  },
  {
    value: 141,
    label: "Palestinian Territories",
    active: 1,
    deleted: 0,
  },
  {
    value: 142,
    label: "Panama",
    active: 1,
    deleted: 0,
  },
  {
    value: 143,
    label: "Papua New Guinea",
    active: 1,
    deleted: 0,
  },
  {
    value: 144,
    label: "Paraguay",
    active: 1,
    deleted: 0,
  },
  {
    value: 145,
    label: "Peru",
    active: 1,
    deleted: 0,
  },
  {
    value: 146,
    label: "Philippines",
    active: 1,
    deleted: 0,
  },
  {
    value: 147,
    label: "Poland",
    active: 1,
    deleted: 0,
  },
  {
    value: 148,
    label: "Portugal",
    active: 1,
    deleted: 0,
  },
  {
    value: 149,
    label: "Qatar",
    active: 1,
    deleted: 0,
  },
  {
    value: 150,
    label: "Romania",
    active: 1,
    deleted: 0,
  },
  {
    value: 151,
    label: "Russia",
    active: 1,
    deleted: 0,
  },
  {
    value: 152,
    label: "Rwanda",
    active: 1,
    deleted: 0,
  },
  {
    value: 153,
    label: "Saint Kitts and Nevis",
    active: 1,
    deleted: 0,
  },
  {
    value: 154,
    label: "Saint Lucia",
    active: 1,
    deleted: 0,
  },
  {
    value: 155,
    label: "Saint Vincent and the Grenadines",
    active: 1,
    deleted: 0,
  },
  {
    value: 156,
    label: "Samoa",
    active: 1,
    deleted: 0,
  },
  {
    value: 157,
    label: "San Marino",
    active: 1,
    deleted: 0,
  },
  {
    value: 158,
    label: "Sao Tome and Principe",
    active: 1,
    deleted: 0,
  },
  {
    value: 159,
    label: "Saudi Arabia",
    active: 1,
    deleted: 0,
  },
  {
    value: 160,
    label: "Senegal",
    active: 1,
    deleted: 0,
  },
  {
    value: 161,
    label: "Serbia",
    active: 1,
    deleted: 0,
  },
  {
    value: 162,
    label: "Seychelles",
    active: 1,
    deleted: 0,
  },
  {
    value: 163,
    label: "Sierra Leone",
    active: 1,
    deleted: 0,
  },
  {
    value: 164,
    label: "Singapore",
    active: 1,
    deleted: 0,
  },
  {
    value: 165,
    label: "Sint Maarten",
    active: 1,
    deleted: 0,
  },
  {
    value: 166,
    label: "Slovakia",
    active: 1,
    deleted: 0,
  },
  {
    value: 167,
    label: "Slovenia",
    active: 1,
    deleted: 0,
  },
  {
    value: 168,
    label: "Solomon Islands",
    active: 1,
    deleted: 0,
  },
  {
    value: 169,
    label: "Somalia",
    active: 1,
    deleted: 0,
  },
  {
    value: 170,
    label: "South Africa",
    active: 1,
    deleted: 0,
  },
  {
    value: 171,
    label: "South Korea",
    active: 1,
    deleted: 0,
  },
  {
    value: 172,
    label: "South Sudan",
    active: 1,
    deleted: 0,
  },
  {
    value: 173,
    label: "Spain",
    active: 1,
    deleted: 0,
  },
  {
    value: 174,
    label: "Sri Lanka",
    active: 1,
    deleted: 0,
  },
  {
    value: 175,
    label: "Sudan",
    active: 1,
    deleted: 0,
  },
  {
    value: 176,
    label: "Suriname",
    active: 1,
    deleted: 0,
  },
  {
    value: 177,
    label: "Swaziland",
    active: 1,
    deleted: 0,
  },
  {
    value: 178,
    label: "Sweden",
    active: 1,
    deleted: 0,
  },
  {
    value: 179,
    label: "Switzerland",
    active: 1,
    deleted: 0,
  },
  {
    value: 180,
    label: "Syria",
    active: 1,
    deleted: 0,
  },
  {
    value: 181,
    label: "Taiwan",
    active: 1,
    deleted: 0,
  },
  {
    value: 182,
    label: "Tajikistan",
    active: 1,
    deleted: 0,
  },
  {
    value: 183,
    label: "Tanzania",
    active: 1,
    deleted: 0,
  },
  {
    value: 184,
    label: "Thailand",
    active: 1,
    deleted: 0,
  },
  {
    value: 185,
    label: "Timor-Leste",
    active: 1,
    deleted: 0,
  },
  {
    value: 186,
    label: "Togo",
    active: 1,
    deleted: 0,
  },
  {
    value: 187,
    label: "Tonga",
    active: 1,
    deleted: 0,
  },
  {
    value: 188,
    label: "Trinidad and Tobago",
    active: 1,
    deleted: 0,
  },
  {
    value: 189,
    label: "Tunisia",
    active: 1,
    deleted: 0,
  },
  {
    value: 190,
    label: "Turkey",
    active: 1,
    deleted: 0,
  },
  {
    value: 191,
    label: "Turkmenistan",
    active: 1,
    deleted: 0,
  },
  {
    value: 192,
    label: "Tuvalu",
    active: 1,
    deleted: 0,
  },
  {
    value: 193,
    label: "Uganda",
    active: 1,
    deleted: 0,
  },
  {
    value: 194,
    label: "Ukraine",
    active: 1,
    deleted: 0,
  },
  {
    value: 195,
    label: "United Arab Emirates",
    active: 1,
    deleted: 0,
  },
  {
    value: 196,
    label: "United Kingdom",
    active: 1,
    deleted: 0,
  },
  {
    value: 197,
    label: "Uruguay",
    active: 1,
    deleted: 0,
  },
  {
    value: 198,
    label: "Uzbekistan",
    active: 1,
    deleted: 0,
  },
  {
    value: 199,
    label: "Vanuatu",
    active: 1,
    deleted: 0,
  },
  {
    value: 200,
    label: "Venezuela",
    active: 1,
    deleted: 0,
  },
  {
    value: 201,
    label: "Vietnam",
    active: 1,
    deleted: 0,
  },
  {
    value: 202,
    label: "Yemen",
    active: 1,
    deleted: 0,
  },
  {
    value: 203,
    label: "Zambia",
    active: 1,
    deleted: 0,
  },
  {
    value: 204,
    label: "Zimbabwe",
    active: 1,
    deleted: 0,
  },
];

export const currencyCode = [
  {
    value: 1,
    name: "Rupees",
    label: "INR",
    symbol: "₹",
  },
  {
    value: 2,
    name: "Dollars",
    label: "USD",
    symbol: "$    ",
  },
  {
    value: 3,
    name: "Dollars",
    label: "CAD",
    symbol: "$    ",
  },
  {
    value: 4,
    name: "Pounds",
    label: "GBP",
    symbol: "£    ",
  },
  {
    value: 5,
    name: "Euro",
    label: "EUR",
    symbol: "€    ",
  },
  {
    value: 6,
    name: "Emirati Dirham",
    label: "AED",
    symbol: "د.إ  ",
  },
  {
    value: 7,
    name: "Riyals",
    label: "SAR",
    symbol: "﷼    ",
  },
  {
    value: 8,
    name: "Afghanis",
    label: "AFN",
    symbol: "؋    ",
  },
  {
    value: 9,
    name: "Pesos",
    label: "ARS",
    symbol: "$    ",
  },
  {
    value: 10,
    name: "Guilders",
    label: "AWG",
    symbol: "ƒ    ",
  },
  {
    value: 11,
    name: "Dollars",
    label: "AUD",
    symbol: "$    ",
  },
  {
    value: 12,
    name: "New Manats",
    label: "AZN",
    symbol: "ман  ",
  },
  {
    value: 13,
    name: "Dollars",
    label: "BSD",
    symbol: "$    ",
  },
  {
    value: 14,
    name: "Dollars",
    label: "BBD",
    symbol: "$    ",
  },
  {
    value: 15,
    name: "Rubles",
    label: "BYR",
    symbol: "p.   ",
  },
  {
    value: 16,
    name: "Dollars",
    label: "BZD",
    symbol: "BZ$  ",
  },
  {
    value: 17,
    name: "Dollars",
    label: "BMD",
    symbol: "$    ",
  },
  {
    value: 18,
    name: "Bolivianos",
    label: "BOB",
    symbol: "$b   ",
  },
  {
    value: 19,
    name: "Convertible Marka",
    label: "BAM",
    symbol: "KM   ",
  },
  {
    value: 20,
    name: "Pula",
    label: "BWP",
    symbol: "P    ",
  },
  {
    value: 21,
    name: "Leva",
    label: "BGN",
    symbol: "лв   ",
  },
  {
    value: 22,
    name: "Reais",
    label: "BRL",
    symbol: "R$   ",
  },
  {
    value: 23,
    name: "Dollars",
    label: "BND",
    symbol: "$    ",
  },
  {
    value: 24,
    name: "Riels",
    label: "KHR",
    symbol: "៛    ",
  },
  {
    value: 25,
    name: "Dollars",
    label: "KYD",
    symbol: "$    ",
  },
  {
    value: 26,
    name: "Pesos",
    label: "CLP",
    symbol: "$    ",
  },
  {
    value: 27,
    name: "Yuan Renminbi",
    label: "CNY",
    symbol: "¥    ",
  },
  {
    value: 28,
    name: "Pesos",
    label: "COP",
    symbol: "$    ",
  },
  {
    value: 29,
    name: "Colón",
    label: "CRC",
    symbol: "₡    ",
  },
  {
    value: 30,
    name: "Kuna",
    label: "HRK",
    symbol: "kn   ",
  },
  {
    value: 31,
    name: "Pesos",
    label: "CUP",
    symbol: "₱    ",
  },
  {
    value: 32,
    name: "Koruny",
    label: "CZK",
    symbol: "Kč   ",
  },
  {
    value: 33,
    name: "Kroner",
    label: "DKK",
    symbol: "kr   ",
  },
  {
    value: 34,
    name: "Pesos",
    label: "DOP",
    symbol: "RD$  ",
  },
  {
    value: 35,
    name: "Dollars",
    label: "XCD",
    symbol: "$    ",
  },
  {
    value: 36,
    name: "Pounds",
    label: "EGP",
    symbol: "£    ",
  },
  {
    value: 37,
    name: "Colones",
    label: "SVC",
    symbol: "$    ",
  },
  {
    value: 38,
    name: "Pounds",
    label: "FKP",
    symbol: "£    ",
  },
  {
    value: 39,
    name: "Dollars",
    label: "FJD",
    symbol: "$    ",
  },
  {
    value: 40,
    name: "Cedis",
    label: "GHC",
    symbol: "¢    ",
  },
  {
    value: 41,
    name: "Pounds",
    label: "GIP",
    symbol: "£    ",
  },
  {
    value: 42,
    name: "Quetzales",
    label: "GTQ",
    symbol: "Q    ",
  },
  {
    value: 43,
    name: "Pounds",
    label: "GGP",
    symbol: "£    ",
  },
  {
    value: 44,
    name: "Dollars",
    label: "GYD",
    symbol: "$    ",
  },
  {
    value: 45,
    name: "Lempiras",
    label: "HNL",
    symbol: "L    ",
  },
  {
    value: 46,
    name: "Dollars",
    label: "HKD",
    symbol: "$    ",
  },
  {
    value: 47,
    name: "Forint",
    label: "HUF",
    symbol: "Ft   ",
  },
  {
    value: 48,
    name: "Kronur",
    label: "ISK",
    symbol: "kr   ",
  },
  {
    value: 49,
    name: "Rupees",
    label: "INR",
    symbol: "Rp   ",
  },
  {
    value: 50,
    name: "Rupiahs",
    label: "IDR",
    symbol: "Rp   ",
  },
  {
    value: 51,
    name: "Rials",
    label: "IRR",
    symbol: "﷼    ",
  },
  {
    value: 52,
    name: "Pounds",
    label: "IMP",
    symbol: "£    ",
  },
  {
    value: 53,
    name: "New Shekels",
    label: "ILS",
    symbol: "₪    ",
  },
  {
    value: 54,
    name: "Dollars",
    label: "JMD",
    symbol: "J$   ",
  },
  {
    value: 55,
    name: "Yen",
    label: "JPY",
    symbol: "¥    ",
  },
  {
    value: 56,
    name: "Pounds",
    label: "JEP",
    symbol: "£    ",
  },
  {
    value: 57,
    name: "Tenge",
    label: "KZT",
    symbol: "лв   ",
  },
  {
    value: 58,
    name: "Won",
    label: "KPW",
    symbol: "₩    ",
  },
  {
    value: 59,
    name: "Won",
    label: "KRW",
    symbol: "₩    ",
  },
  {
    value: 60,
    name: "Soms",
    label: "KGS",
    symbol: "лв   ",
  },
  {
    value: 61,
    name: "Kips",
    label: "LAK",
    symbol: "₭    ",
  },
  {
    value: 62,
    name: "Lati",
    label: "LVL",
    symbol: "Ls   ",
  },
  {
    value: 63,
    name: "Pounds",
    label: "LBP",
    symbol: "£    ",
  },
  {
    value: 64,
    name: "Dollars",
    label: "LRD",
    symbol: "$    ",
  },
  {
    value: 65,
    name: "Switzerland Francs",
    label: "CHF",
    symbol: "CHF  ",
  },
  {
    value: 66,
    name: "Litai",
    label: "LTL",
    symbol: "Lt   ",
  },
  {
    value: 67,
    name: "Denars",
    label: "MKD",
    symbol: "ден  ",
  },
  {
    value: 68,
    name: "Ringgits",
    label: "MYR",
    symbol: "RM   ",
  },
  {
    value: 69,
    name: "Rupees",
    label: "MUR",
    symbol: "₨    ",
  },
  {
    value: 70,
    name: "Pesos",
    label: "MXN",
    symbol: "$    ",
  },
  {
    value: 71,
    name: "Tugriks",
    label: "MNT",
    symbol: "₮    ",
  },
  {
    value: 72,
    name: "Meticais",
    label: "MZN",
    symbol: "MT   ",
  },
  {
    value: 73,
    name: "Dollars",
    label: "NAD",
    symbol: "$    ",
  },
  {
    value: 74,
    name: "Rupees",
    label: "NPR",
    symbol: "₨    ",
  },
  {
    value: 75,
    name: "Guilders",
    label: "ANG",
    symbol: "ƒ    ",
  },
  {
    value: 76,
    name: "Dollars",
    label: "NZD",
    symbol: "$    ",
  },
  {
    value: 77,
    name: "Cordobas",
    label: "NIO",
    symbol: "C$   ",
  },
  {
    value: 78,
    name: "Nairas",
    label: "NGN",
    symbol: "₦    ",
  },
  {
    value: 79,
    name: "Krone",
    label: "NOK",
    symbol: "kr   ",
  },
  {
    value: 80,
    name: "Rials",
    label: "OMR",
    symbol: "﷼    ",
  },
  {
    value: 81,
    name: "Rupees",
    label: "PKR",
    symbol: "₨    ",
  },
  {
    value: 82,
    name: "Balboa",
    label: "PAB",
    symbol: "B/.  ",
  },
  {
    value: 83,
    name: "Guarani",
    label: "PYG",
    symbol: "Gs   ",
  },
  {
    value: 84,
    name: "Nuevos Soles",
    label: "PEN",
    symbol: "S/.  ",
  },
  {
    value: 85,
    name: "Pesos",
    label: "PHP",
    symbol: "Php  ",
  },
  {
    value: 86,
    name: "Zlotych",
    label: "PLN",
    symbol: "zł   ",
  },
  {
    value: 87,
    name: "Rials",
    label: "QAR",
    symbol: "﷼    ",
  },
  {
    value: 88,
    name: "New Lei",
    label: "RON",
    symbol: "lei  ",
  },
  {
    value: 89,
    name: "Rubles",
    label: "RUB",
    symbol: "руб  ",
  },
  {
    value: 90,
    name: "Pounds",
    label: "SHP",
    symbol: "£    ",
  },
  {
    value: 91,
    name: "Dinars",
    label: "RSD",
    symbol: "Дин. ",
  },
  {
    value: 92,
    name: "Rupees",
    label: "SCR",
    symbol: "₨    ",
  },
  {
    value: 93,
    name: "Dollars",
    label: "SGD",
    symbol: "$    ",
  },
  {
    value: 94,
    name: "Dollars",
    label: "SBD",
    symbol: "$    ",
  },
  {
    value: 95,
    name: "Shillings",
    label: "SOS",
    symbol: "S    ",
  },
  {
    value: 96,
    name: "Rand",
    label: "ZAR",
    symbol: "R    ",
  },
  {
    value: 97,
    name: "Rupees",
    label: "LKR",
    symbol: "₨    ",
  },
  {
    value: 98,
    name: "Kronor",
    label: "SEK",
    symbol: "kr   ",
  },
  {
    value: 99,
    name: "Dollars",
    label: "SRD",
    symbol: "$    ",
  },
  {
    value: 100,
    name: "Pounds",
    label: "SYP",
    symbol: "£    ",
  },
  {
    value: 101,
    name: "New Dollars",
    label: "TWD",
    symbol: "NT$  ",
  },
  {
    value: 102,
    name: "Baht",
    label: "THB",
    symbol: "฿    ",
  },
  {
    value: 103,
    name: "Dollars",
    label: "TTD",
    symbol: "TT$  ",
  },
  {
    value: 104,
    name: "Lira",
    label: "TRY",
    symbol: "₺    ",
  },
  {
    value: 105,
    name: "Liras",
    label: "TRL",
    symbol: "£    ",
  },
  {
    value: 106,
    name: "Dollars",
    label: "TVD",
    symbol: "$    ",
  },
  {
    value: 107,
    name: "Hryvnia",
    label: "UAH",
    symbol: "₴    ",
  },
  {
    value: 108,
    name: "Pesos",
    label: "UYU",
    symbol: "$U   ",
  },
  {
    value: 109,
    name: "Sums",
    label: "UZS",
    symbol: "лв   ",
  },
  {
    value: 110,
    name: "Bolivares Fuertes",
    label: "VEF",
    symbol: "Bs   ",
  },
  {
    value: 111,
    name: "Dong",
    label: "VND",
    symbol: "₫    ",
  },
  {
    value: 112,
    name: "Rials",
    label: "YER",
    symbol: "﷼    ",
  },
  {
    value: 113,
    name: "Zimbabwe Dollars",
    label: "ZWD",
    symbol: "Z$   ",
  },
  {
    value: 114,
    name: "Leke",
    label: "ALL",
    symbol: "Lek  ",
  },
];
