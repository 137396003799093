import { useEffect, useState } from "react";
import { FaPlus, FaTimes } from "react-icons/fa";
import { toast } from "react-toastify";
import Token from "../../Helpers/Token";
import { useCandDeleteEvent } from "../../hooks/apihooks/useCandidate";

export function EducationCand ({education, setValue}){
    // const [enabled] = useState({
    //     department: false,
    //     city: false,
    //     skills: false,
    // });

    const {mutateAsync} = useCandDeleteEvent();
    
    // const [countrySearch] = useState("");
    const [Education, setEducation] = useState([]);

    // const getCity = (city) => {
    //     let url = `http://hiringplug.local/manage-helpdesk-service/v1/city?keyword=${city}`;
    //     return Http().get(url);
    //   };
    
    // const { data: dataArr, isLoading } = useQuery({
    //     queryKey: ["getallcity", countrySearch],
    //     queryFn: () => getCity(countrySearch),
    //     enabled: Boolean(enabled.city),
    // });

    // const LocationOptions = dataArr?.data.map((city) => ({
    //     value: city.cityid,
    //     label: city.city,
    // }));

    const handleAddExperience = () => {
        setEducation([
          ...Education,
          {
            degree: "",
            institution: "",
            location: "",
            start_date: "",
            end_date: "",
          },
        ]);
    };

    // const handleExLocation = (e, action, index) => {
    //     const { value, label } = e;
    //     const { name } = action;
    //     const list = [...Education];
    //     list[index][name] = { value: value, label: label };
    //     setEducation(list);
    // };

    const onChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...Education];
        list[index][name] = value;
        setEducation(list);
    };

    const handleRemoveExperience = (index, actionid) => {
        const List = [...Education];
        List.splice(index, 1);
        setEducation(List);
        if(actionid){
            let data = {
                action : "education",
                actionid : actionid
            }
            mutateAsync({data, token:Token.getAccessToken()}).then((resp) => {
                if(resp?.status===true){
                    toast.success(resp?.message);
                }else{
                    toast.warning(resp?.message);
                }
            });
        }
    };

    useEffect(() => {
        setValue('education', Education);
        // eslint-disable-next-line
    }, [Education])

    useEffect(() => {
        if(education){
            setEducation(education)
        }
    },[education])


    return(
        <>
            <div className="border w-full py-4 rounded">
                <div className="w-full flex justify-between items-end gap-3 flex-col md:flex-row">
                    <div className="gap-5 flex flex-col items-start pl-4">
                        <span className="text-[16px] font-semibold">
                            Education
                        </span>
                    </div>
                    <p
                    className={`flex text-[green] items-center text-green cursor-pointer pr-4`}
                    onClick={() => handleAddExperience()}
                    >
                    <FaPlus /> Add
                    </p>
                </div>
            
                {Education?.map((data, index) => (
                    <div
                    key={index + index}
                    className="w-full relative flex border-b-2 last:border-b-0 px-2 py-2 hover:bg-green-200 cursor-pointer items-center justify-center flex-col gap-4"
                    >
                        <button type="button" className="text-red-600 absolute right-[-10px] top-[-10px] bg-gray-400 w-[30px] h-[30px] rounded-full items-center text-center flex justify-center" onClick={()=> handleRemoveExperience(index, data?.id)}><FaTimes /></button>
                        <div className="w-full flex items-center justify-center gap-3 flex-col md:flex-row">
                        <input
                            type="text"
                            onChange={(e) => onChange(e, index)}
                            value={data.degree}
                            name="degree"
                            placeholder="Enter Degree"
                            className="w-full px-3 text-sm h-10 border border-border-col rounded-[4px] "
                        />
                        <input
                            type="text"
                            onChange={(e) => onChange(e, index)}
                            value={data.institution}
                            name="institution"
                            placeholder="Enter institute"
                            className="w-full px-3 text-sm h-10 border border-border-col rounded-[4px] "
                        />
                        </div>
                        <div className="w-full flex items-center justify-center gap-3 flex-col md:flex-row">
                            <input
                                type="text"
                                value={data.location}
                                name="location"
                                placeholder="Location"
                                onChange={(e) => onChange(e, index)}
                                className="w-full md:w-6/12 px-3 text-sm h-10 border border-border-col rounded-[4px] "
                            />
                            <input
                                type="date"
                                value={data.start_date}
                                name="start_date"
                                placeholder="Job Period"
                                onChange={(e) => onChange(e, index)}
                                className="w-full md:w-6/12 px-3 text-sm h-10 border border-border-col rounded-[4px] "
                            />
                            <input
                                type="date"
                                value={data.end_date}
                                name="end_date"
                                placeholder=""
                                onChange={(e) => onChange(e, index)}
                                className="w-full md:w-6/12 px-3 text-sm h-10 border border-border-col rounded-[4px] "
                            />
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}