import { useQuery } from "@tanstack/react-query";
import React from "react";
import endpoint from "../../../Helpers/endpoint";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Spinner } from "../../Loader/Spinner";
import Http from "../../../http";
import { useForm } from "react-hook-form";
import { useChangePasswordVerification } from "../../../hooks/apihooks/useProfile";
import { toast } from "react-toastify";

export default function Verification() {
  const navigate = useNavigate();
  const {
    handleSubmit,
    watch,
    register,
    formState: { errors },
  } = useForm();
  const { mutateAsync, isPending } = useChangePasswordVerification();
  const { verifytoken } = useParams();
  const verification = () => {
    const URL = `${process.env.REACT_APP_APIENDPOINT}${endpoint.user.verification}`;
    let data = {
      verifytoken: verifytoken,
    };
    return Http().post(URL, data);
  };

  const onSubmit = (data) => {
    let newdata = { verifytoken: verifytoken, passType: "fromVerify", ...data };
    mutateAsync(newdata).then((resp) => {
      if (resp?.status === true) {
        toast.success("Password change successfully!");
        navigate("/signin");
      }
      console.log("response", resp);
    });
  };

  const { isLoading, data: verfication } = useQuery({
    queryKey: ["verification", verifytoken],
    queryFn: () => verification(),
  });

  return (
    <>
      {isLoading ? (
        <>
          <Spinner size={"medium"} />
          <section className="bg-green-100 h-[100vh]">
            <div className="m-[auto] max-sm:px-3 relative">
              <div className="w-full max-sm:w-full px-10">
                <div className="py-5 absolute top-0 left-[3%] max-sm:ml-5">
                  <Link to="/" className="font-bold">
                    {process.env.REACT_APP_APP_NAME}
                  </Link>
                </div>
              </div>
              <div className="w-full flex justify-center items-center h-[100vh]">
                <div className="w-[50%] bg-white px-5 py-10 rounded-[10px]">
                  <h1 className="text-[40px] font-extrabold">
                    CoffeeCrew Talent Engagement Platform: Accelerate Your
                    Hiring Success
                  </h1>
                  <h3 className="text-[25px] font-extrabold">
                    Verification is process
                  </h3>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : verfication?.data ? (
        <>
          {isPending ? <Spinner size="medium" /> : ""}
          <section className="bg-green-100 h-[100vh]">
            <div className="m-[auto] max-sm:px-3 relative">
              <div className="flex items-center flex-wrap max-sm:flex-wrap-reverse">
                <div className="w-[50%] max-sm:w-full px-10">
                  <div className="py-5 absolute top-0 left-[3%] max-sm:ml-5">
                    <Link to="/" className="font-bold">
                      {process.env.REACT_APP_APP_NAME}
                    </Link>
                  </div>
                  <h1 className="text-[40px] font-extrabold">
                    CoffeeCrew Talent Engagement Platform: Accelerate Your
                    Hiring Success
                  </h1>
                  <img src="/images/loginpage.png" alt="loginpage" />
                  <p>
                    Accelerate hiring, strengthen your brand, and exceed
                    recruiting targets - all while delivering a personalized
                    candidate experience.
                  </p>
                </div>
                <div className="w-[50%] max-sm:w-full bg-[white] h-[100vh]">
                  <div className="px-10 flex flex-wrap items-center h-[100vh] max-sm:p-0">
                    <div className="px-5 py-5 w-[70%] max-sm:w-full m-[auto] max-sm:m-0">
                      <div className="w-full">
                        <h2 className="text-[25px] py-5 font-semibold">
                          Hi,{" "}
                          <span className="text-green-700">
                            {verfication?.data?.fname}
                          </span>{" "}
                          Welcome to {process.env.REACT_APP_APP_NAME} Set your
                          password
                        </h2>
                      </div>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="flex flex-wrap justify-between mb-3">
                          <div className="w-[100%] mb-5">
                            <label className="py-2 block">
                              Password<sup className="text-[red]">*</sup>
                            </label>
                            <input
                              type="password"
                              {...register("password", { required: true })}
                              name="password"
                              className="w-full px-2 py-2 border  rounded"
                              placeholder="password"
                            />
                            {errors.password && (
                              <p className="text-red-500">
                                {errors.password.message}
                              </p>
                            )}
                          </div>
                          <div className="w-[100%] mb-5">
                            <label className="py-2 block">
                              Confirm Password{" "}
                              <sup className="text-[red]">*</sup>
                            </label>
                            <input
                              type="password"
                              name="confirm_password"
                              {...register("confirm_password", {
                                required: true,
                                validate: (value) =>
                                  value === watch("password") ||
                                  "Passwords do not match",
                              })}
                              className="w-full px-2 py-2 border  rounded"
                              placeholder="Confirm Password"
                            />
                            {errors.confirm_password && (
                              <p className="text-red-500">
                                {errors.confirm_password.message}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="flex justify-between flex-wrap mb-3">
                          <div className="w-[100%]">
                            <button
                              type="submit"
                              className="w-full px-2 py-2 bg-green-700 text-white  rounded"
                            >
                              {" "}
                              Save
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <section className="bg-green-100 h-[100vh]">
          <div className="m-[auto] max-sm:px-3 relative">
            <div className="w-full max-sm:w-full px-10">
              <div className="py-5 absolute top-0 left-[3%] max-sm:ml-5">
                <Link to="/" className="font-bold">
                  {process.env.REACT_APP_APP_NAME}
                </Link>
              </div>
            </div>
            <div className="w-full flex justify-center items-center h-[100vh]">
              <div className="w-[50%] bg-white px-5 py-10 rounded-[10px]">
                <h1 className="text-[40px] font-extrabold">
                  CoffeeCrew Talent Engagement Platform: Accelerate Your Hiring
                  Success
                </h1>
                <h3 className="text-[25px] font-extrabold">
                  Token already activated or expired.
                </h3>
                <p>
                  Accelerate hiring, strengthen your brand, and exceed
                  recruiting targets—all while delivering a personalized
                  candidate experience.
                </p>
                <Link
                  to={`/`}
                  className="inline-block text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                >
                  Go to Home
                </Link>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}
