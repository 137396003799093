import { MdCloudUpload } from "react-icons/md";
import { useState } from "react";
import { toast } from "react-toastify";
import SteamCvcandRes from "./StreamCand/StreamCvcandRes";
import { FaLinkedin, FaTimes } from "react-icons/fa";
import endpoint from "../Helpers/endpoint";
// import { useDocumentUploadJob } from "../hooks/apihooks/useParseJob";
import { useCandidateProfileLink } from "../hooks/apihooks/useCandidate";
import Token from "../Helpers/Token";
import { Spinner } from "../Components/Loader/Spinner";
// import Anthropic from '@anthropic-ai/sdk';
export default function ImportCandidate({ open, setOpen, setManualCand }) {
  const {mutateAsync, isPending} = useCandidateProfileLink();
  // const {mutateAsync : uploadDocument, isPending:loadFile} = useDocumentUploadResume();
  const [streamedData, setStreamedData] = useState('');
  const [typeofCand, setTypeofCand] = useState('');
  const [candtab, setCandTab] = useState('document');
  const [loading, setLoading] = useState(false);
  const [documentfile, setFile] = useState(null);
  const [profile, setProfile] = useState("");
  const [text, setText] = useState('');
  const [steamOpen, setStreamOpen] = useState(false);
  const [steamDataSet, setStreaDataSet] = useState({});

  const handleFileSelect = (e) => {
    setFile(e.target.files[0]); // Get the selected file
  }

  // function convertToBase64(file) {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onloadend = () => resolve(reader.result.split(',')[1]); // Extract base64 string
  //     reader.onerror = reject;
  //   });
  // }

  const handletoParser = async() => {
    if(candtab==='linkedin'){
      let data = {
        profile : profile
      }
      
      setTypeofCand("");
      mutateAsync({data, token: Token.getAccessToken()}).then((resp) => {
        console.log("Response", resp);
        setProfile("");
        setStreamOpen(true);
        setLoading(false);
        setTypeofCand("linkedin")
        setStreaDataSet(resp)
      });

    }else if(candtab==='document'){
      setStreamOpen(true);
      if(!documentfile){
        toast.warning("Pls copy and paste you remove content.");
        return;
      }
      setLoading(true);
      setStreamedData('');  // Reset the data when new stream starts
      const formData = new FormData();
      formData.append('file', documentfile); // Append file
      const BASE_URL = process.env.REACT_APP_APIENDPOINT;
      const response = await fetch(`${BASE_URL}/${endpoint.parser.documentParse}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${Token.getAccessToken()}`,
        },
        body: formData
      });
  
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let done = false;
      let data = '';
      const firstNameRegex = /"first_name"\s*[:\\-]?\s*"([^"]+)"/i;
      const middleNameRegex = /"middle_name"\s*[:\\-]?\s*"([^"]+)"/i;
      const lastNameRegex = /"last_name"\s*[:\\-]?\s*"([^"]+)"/i;
      const emailRegex = /"email"\s*[:\\-]?\s*"([^"]+)"/i;
      const alteremailRegex = /"alternate_email"\s*[:\\-]?\s*"([^"]+)"/i;
      const phoneRegex = /"phone"\s*[:\\-]?\s*"([^"]+)"/i;
      const alterphoneRegex = /"alternate_phone"\s*[:\\-]?\s*"([^"]+)"/i;
      const phoneCodeRegex = /"phone_code"\s*[:\\-]?\s*"([^"]+)"/i;
      const addressCodeRegex = /"address"\s*[:\\-]?\s*"([^"]+)"/i;
      const domainRegex = /"domain"\s*[:\\-]?\s*"([^"]+)"/i;
      const linkedinRegex = /"linkedin"\s*[:\\-]?\s*"([^"]+)"/i;
      const websiteRegex = /"website"\s*[:\\-]?\s*"([^"]+)"/i;
      // const dobthRegex = /"date_of_birth"\s*[:\-]?\s*"([^"]+)"/i; // OLD
      const dobthRegex =  /"date_of_birth"\s*[:-]?\s*"([^"]+)"/i; // NEW
      const nationalityRegex = /"nationality"\s*[:\\-]?\s*"([^"]+)"/i;
      const countryRegex = /"country"\s*[:\\-]?\s*"([^"]+)"/i;
      const stateRegex = /"state"\s*[:\\-]?\s*"([^"]+)"/i;
      const cityRegex = /"city"\s*[:\\-]?\s*"([^"]+)"/i;
      const diversityRegex = /"diversity"\s*[:\\-]?\s*"([^"]+)"/i;
      const synopsisRegex = /"synopsis"\s*[:\\-]?\s*"([^"]+)"/i;
      const workExperRegex = /"work_experience"\s*:\s*\[(.*?)\]/gs;
      // const workExperRegex = /"work_experience"{(?:[^{}]|{(?:[^{}]|{[^{}]*})*})*}/g;
      const educationRegex = /"education"\s*:\s*\[(.*?)\]/gs;
      // const educationRegex = /"education"{(?:[^{}]|{(?:[^{}]|{[^{}]*})*})*}/g;
      const projectRegex = /"projects"\s*:\s*\[(.*?)\]/gs;
      // const projectRegex = /"projects"{(?:[^{}]|{(?:[^{}]|{[^{}]*})*})*}/g;
      const certificationRegex = /"certifications"\s*:\s*\[(.*?)\]/gs;
      // const certificationRegex = /"certifications"{(?:[^{}]|{(?:[^{}]|{[^{}]*})*})*}/g;
      // const skillsRegex = /"skills"\s*:\s*\[(.*?)\]/gs;
      // Read stream data and update state as it arrives
      while (!done) {
        const { value, done: doneReading } = await reader.read();
        done = doneReading;
        data += decoder.decode(value, { stream: true });
        // Filter and extract only the text portion from the stream
        const textContent = extractTextFromStream(data)
        // const textContent = extractJSON(data);
        // Extract first name from the text using regex
        const firstNameMatch = firstNameRegex.exec(textContent);
        const middleNameMatch = middleNameRegex.exec(textContent);
        const lastNameMatch = lastNameRegex.exec(textContent);
        const emailMatch = emailRegex.exec(textContent);
        const alteremailMatch = alteremailRegex.exec(textContent);
        const phoneMatch = phoneRegex.exec(textContent);
        const dobMatch = dobthRegex.exec(textContent);
        const alterphoneMatch = alterphoneRegex.exec(textContent);
        const phonecodeMatch = phoneCodeRegex.exec(textContent);
        const addresscodeMatch = addressCodeRegex.exec(textContent);
        const domainMatch = domainRegex.exec(textContent);
        const linkedinMatch = linkedinRegex.exec(textContent);
        const websiteMatch = websiteRegex.exec(textContent);
        const nationlityMatch = nationalityRegex.exec(textContent);
        const countryMatch = countryRegex.exec(textContent);
        const stateMatch = stateRegex.exec(textContent);
        const cityMatch = cityRegex.exec(textContent);
        const diversityMatch = diversityRegex.exec(textContent);
        const synopsisMatch = synopsisRegex.exec(textContent);
        const educationMatch = educationRegex.exec(textContent);
        const projectMatch = projectRegex.exec(textContent);
        const workExperMatch = workExperRegex.exec(textContent);
        const certificationMatch = certificationRegex.exec(textContent);
        
        const firstName = (firstNameMatch)?firstNameMatch[1]:"";  // Extracted first name
        const lastName = (lastNameMatch)?lastNameMatch[1]:"";  // Extracted first name
        const middleName = (middleNameMatch)?middleNameMatch[1]:"";  
        const email = (emailMatch)?emailMatch[1]:"";  
        const alteremail = (alteremailMatch)?alteremailMatch[1]:"";  
        const dob = (dobMatch)?dobMatch[1]:"";  
        const phone = (phoneMatch)?phoneMatch[1]:"";  
        const alterphone = (alterphoneMatch)?alterphoneMatch[1]:"";  
        const phonecode = (phonecodeMatch)?phonecodeMatch[1]:"";  
        const addresscode = (addresscodeMatch)?addresscodeMatch[1]:"";  
        const domain = (domainMatch)?domainMatch[1]:"";  
        const linkedin = (linkedinMatch)?linkedinMatch[1]:"";  
        const website = (websiteMatch)?websiteMatch[1]:"";  
        const nationality = (nationlityMatch)?nationlityMatch[1]:"";  
        const country = (countryMatch)?countryMatch[1]:"";  
        const state = (stateMatch)?stateMatch[1]:"";  
        const city = (cityMatch)?cityMatch[1]:"";  
        const diversity = (diversityMatch)?diversityMatch[1]:"";  
        const synopsis = (synopsisMatch)?synopsisMatch[1]:"";  
        const workexper = (workExperMatch)?workExperMatch[1]:""; 
        const education = (educationMatch)?educationMatch[1]:""; 
        const project = (projectMatch)?projectMatch[1]:""; 
        const certification = (certificationMatch)?certificationMatch[1]:"";  // Extracted first name
        const skills  = extractSkillsFromStream(textContent);

        var workExperienceArray,educationDataArray,projectDataArray,certDataArray;

        if(workexper){
          try {
            workExperienceArray = JSON.parse(`[${workexper}]`);
          } catch (error) {
            console.error(error)
          }
        }
  
        if(education){
          try {
            educationDataArray = JSON.parse(`[${education}]`);
          } catch (error) {
            console.error(error)
          }
        }
  
        if(project){
          try {
            projectDataArray = JSON.parse(`[${project}]`);
          } catch (error) {
            console.error(error)
          }
        }
        
        if(certification){
          try {
            certDataArray = JSON.parse(`[${certification}]`);
          } catch (error) {
            console.error(error)
          }
        }

        if (textContent) {
          setStreamedData(textContent); // Update the state with the streamed text
          setStreaDataSet({
            first_name : firstName,
            last_name : lastName,
            middleName : middleName,
            email : email,
            alteremail : alteremail,
            dob : dob,
            phone : phone,
            alterphone :alterphone,
            phonecode :phonecode,
            addresscode :addresscode,
            domain : domain,
            linkedin :linkedin,
            website :website,
            nationality :nationality,
            country :country,
            state :state,
            city :city,
            diversity :diversity,
            synopsis :synopsis,
            education : educationDataArray,
            project : projectDataArray,
            workexper : workExperienceArray,
            certification: certDataArray,
            skills :skills,
          })
        }
      }
      setLoading(false);  // Stop loading when the stream is finished
      setText('')
    }else if(candtab==='copy-paste'){
      setStreamOpen(true);
      if(text==="" || text===''){
        toast.warning("Pls copy and paste you remove content.");
        return;
      }
      setLoading(true);
      setStreamedData('');  // Reset the data when new stream starts
      const BASE_URL = process.env.REACT_APP_APIENDPOINT;

      const response = await fetch(`${BASE_URL}/${endpoint.parser.parserCV}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(
          {
            "query" : text
          }
        ),
      });
  
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let done = false;
      let data = '';
      const firstNameRegex = /"first_name"\s*[:\\-]?\s*"([^"]+)"/i;
      const middleNameRegex = /"middle_name"\s*[:\\-]?\s*"([^"]+)"/i;
      const lastNameRegex = /"last_name"\s*[:\\-]?\s*"([^"]+)"/i;
      const emailRegex = /"email"\s*[:\\-]?\s*"([^"]+)"/i;
      const alteremailRegex = /"alternate_email"\s*[:\\-]?\s*"([^"]+)"/i;
      const phoneRegex = /"phone"\s*[:\\-]?\s*"([^"]+)"/i;
      const alterphoneRegex = /"alternate_phone"\s*[:\\-]?\s*"([^"]+)"/i;
      const phoneCodeRegex = /"phone_code"\s*[:\\-]?\s*"([^"]+)"/i;
      const addressCodeRegex = /"address"\s*[:\\-]?\s*"([^"]+)"/i;
      const domainRegex = /"domain"\s*[:\\-]?\s*"([^"]+)"/i;
      const linkedinRegex = /"linkedin"\s*[:\\-]?\s*"([^"]+)"/i;
      const websiteRegex = /"website"\s*[:\\-]?\s*"([^"]+)"/i;
      const dobthRegex = /"date_of_birth"\s*[:\\-]?\s*"([^"]+)"/i;
      const nationalityRegex = /"nationality"\s*[:\\-]?\s*"([^"]+)"/i;
      const countryRegex = /"country"\s*[:\\-]?\s*"([^"]+)"/i;
      const stateRegex = /"state"\s*[:\\-]?\s*"([^"]+)"/i;
      const cityRegex = /"city"\s*[:\\-]?\s*"([^"]+)"/i;
      const diversityRegex = /"diversity"\s*[:\\-]?\s*"([^"]+)"/i;
      const synopsisRegex = /"synopsis"\s*[:\\-]?\s*"([^"]+)"/i;
      const workExperRegex = /"work_experience"\s*:\s*\[(.*?)\]/gs;
      const educationRegex = /"education"\s*:\s*\[(.*?)\]/gs;
      const projectRegex = /"projects"\s*:\s*\[(.*?)\]/gs;
      const certificationRegex = /"certifications"\s*:\s*\[(.*?)\]/gs;
      // const skillsRegex = /"skills"\s*:\s*\[(.*?)\]/gs;
  
      // Read stream data and update state as it arrives
      while (!done) {
        const { value, done: doneReading } = await reader.read();
        done = doneReading;
        data += decoder.decode(value, { stream: true });
        // Filter and extract only the text portion from the stream
        const textContent = extractTextFromStream(data)
        // Extract first name from the text using regex
        const firstNameMatch = firstNameRegex.exec(textContent);
        const middleNameMatch = middleNameRegex.exec(textContent);
        const lastNameMatch = lastNameRegex.exec(textContent);
        const emailMatch = emailRegex.exec(textContent);
        const  alteremailMatch = alteremailRegex.exec(textContent);
        const  phoneMatch = phoneRegex.exec(textContent);
        const  dobMatch = dobthRegex.exec(textContent);
        const  alterphoneMatch = alterphoneRegex.exec(textContent);
        const  phonecodeMatch = phoneCodeRegex.exec(textContent);
        const  addresscodeMatch = addressCodeRegex.exec(textContent);
        const  domainMatch = domainRegex.exec(textContent);
        const  linkedinMatch = linkedinRegex.exec(textContent);
        const  websiteMatch = websiteRegex.exec(textContent);
        const  nationlityMatch = nationalityRegex.exec(textContent);
        const  countryMatch = countryRegex.exec(textContent);
        const  stateMatch = stateRegex.exec(textContent);
        const  cityMatch = cityRegex.exec(textContent);
        const  diversityMatch = diversityRegex.exec(textContent);
        const synopsisMatch = synopsisRegex.exec(textContent);
        const educationMatch = educationRegex.exec(textContent);
        const projectMatch = projectRegex.exec(textContent);
        const workExperMatch = workExperRegex.exec(textContent);
        const certificationMatch = certificationRegex.exec(textContent);
        
        const firstName = (firstNameMatch)?firstNameMatch[1]:"";  // Extracted first name
        const lastName = (lastNameMatch)?lastNameMatch[1]:"";  // Extracted first name
        const middleName = (middleNameMatch)?middleNameMatch[1]:"";  
        const email = (emailMatch)?emailMatch[1]:"";  
        const alteremail = (alteremailMatch)?alteremailMatch[1]:"";  
        const dob = (dobMatch)?dobMatch[1]:"";  
        const phone = (phoneMatch)?phoneMatch[1]:"";  
        const alterphone = (alterphoneMatch)?alterphoneMatch[1]:"";  
        const phonecode = (phonecodeMatch)?phonecodeMatch[1]:"";  
        const addresscode = (addresscodeMatch)?addresscodeMatch[1]:"";  
        const domain = (domainMatch)?domainMatch[1]:"";  
        const linkedin = (linkedinMatch)?linkedinMatch[1]:"";  
        const website = (websiteMatch)?websiteMatch[1]:"";  
        const nationality = (nationlityMatch)?nationlityMatch[1]:"";  
        const country = (countryMatch)?countryMatch[1]:"";  
        const state = (stateMatch)?stateMatch[1]:"";  
        const city = (cityMatch)?cityMatch[1]:"";  
        const diversity = (diversityMatch)?diversityMatch[1]:"";  
        const synopsis = (synopsisMatch)?synopsisMatch[1]:"";  
        const workexper = (workExperMatch)?workExperMatch[1]:"";  // Extracted first name
        const education = (educationMatch)?educationMatch[1]:"";  // Extracted first name
        const project = (projectMatch)?projectMatch[1]:"";  // Extracted first name
        const certification = (certificationMatch)?certificationMatch[1]:"";  // Extracted first name
        const skills  = extractSkillsFromStream(textContent);
  
        // var workExperienceArray,educationDataArray,projectDataArray,certDataArray;

        if(workexper){
          try {
            workExperienceArray = JSON.parse(`[${workexper}]`);
          } catch (error) {
            console.error(error)
          }
           
        }
  
        if(education){
          try {
            educationDataArray = JSON.parse(`[${education}]`);
          } catch (error) {
            console.error(error)
          }
        }
  
        if(project){
          try {
            projectDataArray = JSON.parse(`[${project}]`);
          } catch (error) {
            console.error(error)
          }
        }
        
        if(certification){
          try {
            certDataArray = JSON.parse(`[${certification}]`);
          } catch (error) {
            console.error(error)
          }
        }
  
        if (textContent) {
          setStreamedData(textContent); // Update the state with the streamed text
          setStreaDataSet({
            first_name : firstName,
            last_name : lastName,
            middleName : middleName,
            email : email,
            alteremail : alteremail,
            dob : dob,
            phone : phone,
            alterphone :alterphone,
            phonecode :phonecode,
            addresscode :addresscode,
            domain : domain,
            linkedin :linkedin,
            website :website,
            nationality :nationality,
            country :country,
            state :state,
            city :city,
            diversity :diversity,
            synopsis :synopsis,
            education :educationDataArray,
            project :projectDataArray,
            workexper :workExperienceArray,
            certification: certDataArray,
            skills :skills,
          })
        }
      }
      setLoading(false);  // Stop loading when the stream is finished
      setText('')
    }
  }

  const extractSkillsFromStream = (streamData) => {
    const skillsRegex = /"skills"\s*:\s*\[([^\]]+)\]/i;  // Regex to match the skills array
    const skillItemRegex = /"([^"]+)"/g;  // Regex to extract each skill item from the array
    const skillsMatch = skillsRegex.exec(streamData); // Match the whole array
    if (skillsMatch && skillsMatch[1]) {      const skillsString = skillsMatch[1]; // The matched skills part inside the array []
      // Now, extract individual skills
      const skillMatches = [];
      let skillMatch;
      while ((skillMatch = skillItemRegex.exec(skillsString)) !== null) {
        skillMatches.push(skillMatch[1]);  // Push each skill to the array
      }
      
      return skillMatches;  // Return the array of skills
    }
    return [];  // Return an empty array if no skills were found
  }

  // function extractJSON(text) {
  //   try {

  //       const jsonRegex = /{(?:[^{}]|{(?:[^{}]|{[^{}]*})*})*}/g;
  //       const matches = text.match(jsonRegex) || [];
        
  //       const jsonObjects = matches.length > 0 ? matches.map(match => JSON.parse(match)) : text;
  //       return jsonObjects;
  //   } catch (e) {
  //       console.error("Error extracting JSON:", e);
  //       return [];
  //   }
  // }

  // console.log("Steam Data", steamDataSet);

  const extractTextFromStream = (streamData) => {
    let text = '';
    try {
       let match;
        // const eventData = '{"type":"error","error":{"details":null,"type":"overloaded_error","message":"Overloaded"}}';
        // Regular expression to match the "overloaded_error"
        const regexError = /"type"\s*:\s*"error".*"error"\s*:\s*\{.*?"type"\s*:\s*"overloaded_error".*?\}/;
        // Check if the event data contains the "overloaded_error"
        const matchError = streamData.match(regexError);
        if (matchError) {
          console.log('Overloaded error detected:', match[0]);
          toast.warning('Parsing Overloading issues will take a time or try again.')
          return text=undefined;  
        }
        // Regex to capture everything inside the "text" field including escaped characters
        const regex = /"text"\s*:\s*"([^"]+)"/g;
       
        // While the regex finds a match, append the captured text
        while ((match = regex.exec(streamData)) !== null) {
          text += match[1] // Replace newlines with <br> tags
        }
    } catch (error) {
        console.error('Error processing stream data with regex:', error);
    }

    return text.replace(/\\n/g, '').replace(/'/g, '"').trim(); // Replace all single quotes with double quotes.trim(); // Trim any leading/trailing spaces
  };


  // Function to remove the hash fragment
const removeHashFragment = () => {
  // Update the URL without the hash
  // window.history.pushState({}, document.title, window.location.pathname);
  window.close();
};

  const handleSelectionValue = () => {
    setText("");
    setProfile("");
  }

  return (

    <div
      className={`relative z-10 ${open ? `` : `hidden tansition-all`}`}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      {isPending? <Spinner size="medium" /> :""}
      <SteamCvcandRes loading={loading} typeofCand={typeofCand} steamDataSet={steamDataSet} streamedData={streamedData}  steamOpen={steamOpen} setStreamOpen={setStreamOpen} />
      <div
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          
          <div className="relative transform rounded bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[800px]">
            <button
            className="absolute right-[-10px] bg-red-600 top-[-10px] w-[30px] h-[30px] rounded-full flex justify-center items-center text-white"
              onClick={() => {setOpen(false); removeHashFragment()}}
            >
              <FaTimes />
            </button>
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 w-full">
              <div className="sm:flex sm:items-start w-full">
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                  <h3
                    className="text-[18px] text-center font-semibold leading-6 text-gray-900"
                    id="modal-title"
                  > Add Another Candidate
                  </h3>
                  <div className="mt-3">
                    <ul className="flex border-b">
                      <li onClick={() => {setCandTab('document'); handleSelectionValue()}} className={`${candtab==="document" ? 'bg-bgbrown text-white':""}  border px-3 py-2 cursor-pointer rounded-t mr-1 font-semibold text-[13px]`}>Document</li>
                      <li onClick={() => {setCandTab('copy-paste'); handleSelectionValue()}} className={`${candtab==="copy-paste" ? 'bg-bgbrown text-white':""}  border px-3 py-2 cursor-pointer rounded-t mr-1 font-semibold text-[13px]`}>Copy & paste</li>
                      <li onClick={() => {setCandTab('linkedin'); handleSelectionValue()}} className={`${candtab==="linkedin" ? 'bg-bgbrown text-white':""}  border px-3 py-2 cursor-pointer rounded-t font-semibold text-[13px]`}>LinkedIn Link</li>
                    </ul>
                  </div>

                  {candtab==='document'?  
                  <div>
                    <div className="mt-2">
                      <p className="text-[12px] text-gray-500">
                        Upload a PDF/DOC to import candidates data in you
                        candidate bank
                      </p>
                    </div>
                    <div className="flex items-center justify-center w-full mt-3">
                      <label
                        htmlFor="dropzone-file"
                        className={`flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 hover:border-dashed rounded-lg cursor-pointer ${documentfile? 'bg-lightbrown hover:bg-gray text-white':'bg-gray-50 dark:bg-gray-200 dark:hover:bg-gray-300 hover:bg-gray-100 '}`}
                      >
                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                          <MdCloudUpload size={50} className="" />
                          <p className="mb-2 text-sm">
                            <span className="font-semibold">Click to upload</span>{" "}
                            or drag and drop
                          </p>
                          <p className="text-xs">
                            PDF, CSV or DOC (MAX 3 MB)
                          </p>
                          <p className="text-xs">{documentfile? documentfile?.name : ""}</p>
                        </div>
                        <input
                          id="dropzone-file"
                          type="file"
                          onChange={(e) => handleFileSelect(e)}
                          accept=".docx,.pdf"
                          className="hidden"
                        />
                      </label>
                    </div>
                  </div> : 
                  candtab==='copy-paste'? 
                  <div className="mt-5">
                    <textarea rows={5} className="border w-full p-2 rounded" placeholder="Copy paste here cv content" onChange={(e) => setText(e.target.value)}></textarea>
                  </div>
                  : candtab ==='linkedin'?
                    <div className="w-full mt-5 flex items-start justify-center flex-col gap-2">
                      <p className="text-[13px] font-medium">Copy & paste candidate profile link</p>
                      <div className="flex items-center border w-full rounded">
                        <FaLinkedin size={40} className="text-blue-500 pl-1"/>
                        <input
                          placeholder="https://www.linkedin.com/in/jelena-stanin/"
                          onChange={(e) => setProfile(e.target.value)}
                          value={profile}
                          required
                          className="w-full text-[12px] rounded-md p-2 outline-none"
                        />
                      </div>
                    </div>: ""
                  }
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 flex justify-between sm:flex-row-reverse sm:px-6">
              <div>
                {text!=="" || profile!=="" || documentfile?.name? 
                <button
                type="button"
                onClick={() => handletoParser()}
                className="inline-flex w-full justify-center rounded-md bg-green-700 mr-3 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-600 sm:ml-3 sm:w-auto"
                >
                Submit
                </button> :""
              }
              </div>
              <div>
                <button
                  type="button"
                  onClick={() => setManualCand(true)}
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                >
                  +Add Manully
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
