import React, { useContext, useEffect, useState } from "react";
import { CgProfile } from "react-icons/cg";
import { CiSearch } from "react-icons/ci";
import { GrConnect } from "react-icons/gr";
import { HiDocumentReport } from "react-icons/hi";
import { IoIosNotifications, IoIosRocket } from "react-icons/io";
import { IoSettingsOutline } from "react-icons/io5";
import { LiaUsersSolid } from "react-icons/lia";
import { MdArrowDropDown, MdLogout } from "react-icons/md";
import { RiLockPasswordLine, RiTeamLine } from "react-icons/ri";
import { AiOutlineDashboard } from "react-icons/ai";
import { MdOutlineContentPasteSearch } from "react-icons/md";
import { SlMenu } from "react-icons/sl";
import { TbAppsFilled, TbTransactionDollar } from "react-icons/tb";
import { WiStars } from "react-icons/wi";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Spinner } from "../../Components/Loader/Spinner";
import ContextProvider from "../../Context/ContextProvider";
import { useGlobalContext } from "../../Context/ContextWrapper";
import NotificationSound from "../../Helpers/NotificationSound";
import Role from "../../Helpers/Role";
import { remove } from "../../Helpers/Storage";
import Token from "../../Helpers/Token";
import { MdOutlinePayment } from "react-icons/md";
import { useLogout } from "../../hooks/apihooks/useProfile";
import { AMAZONS3 } from "../../http";
import { useCreateChat } from "../../hooks/useCreateChat";
import Loader from "../../Components/Loader/Loader";
import { MdOutlineMessage } from "react-icons/md";
import { FaCircleInfo } from "react-icons/fa6";
import { PiUserListFill } from "react-icons/pi";
import { RiExpandUpDownLine } from "react-icons/ri";

export default function SideBar({ open, setOpen, header = {} }) {
  const [handleDrowpDown, sethandleDrowpDown] = useState();
  const { toggleHam, settoogleHam, notification } = useContext(ContextProvider);
  const navigate = useNavigate();
  const { mutateAsync: logoutUser, isPending } = useLogout();

  const logout = () => {
    logoutUser({ token: Token.getAccessToken() }).then((resp) => {
      if (resp?.status === true) {
        remove("userAuth");
        // Token.clearToken();
        // Role.clearRole();
        localStorage.clear();
        navigate("/signin");
      }
    });
  };

  const { websocket } = useGlobalContext();
  useEffect(() => {
    if (websocket) {
      websocket.onmessage = (event) => {
        let datanew = event?.data ? JSON.parse(event?.data) : {};
        if (datanew?.typeofsmg === "notification") {
          toast.success(datanew?.data?.message);
          NotificationSound(`${AMAZONS3}/icons/hiringplug_logo.jpg`, datanew);
        }
      };
      // if (websocket.readyState === 3) {
      //   websocket.onopen = () => {
      //     console.log("Websocket Connection Established again:");
      //   };
      // }
      // console.log(websocket.readyState, "Websocket Ready State");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [websocket]);

  const { mutateAsync, isPending: status } = useCreateChat();

  const openChat = () => {
    mutateAsync({ chat_title: "" }).then((res) => {
      if (res?.status) {
        console.log(res?.data[0]);
      } else {
        navigate(`/plug`);
      }
    });
  };

  return (
    <>
      {isPending ? <Spinner size="medium" /> : ""}
      {status ? <Loader /> : ""}
      <div
        className={`${open ? "left-0" : "left-[-500px] sm:left-0"} ${
          toggleHam
            ? "transition-all sm:w-[70px] w-[70px] "
            : "transition-all sm:w-[20%] w-12/12 px-4"
        } sticky top-0 border-r h-[100vh]  shadow-gray-30 z-10 sm:z-[1] bg-white border-gray-200 gap-4`}
      >
        <div
          className={`w-full flex  px-2 items-center ${
            toggleHam ? `justify-center` : `justify-between`
          }  flex-wrap-reverse  h-[80px]`}
        >
          {!toggleHam ? (
            <p className={`text-[20px] font-bold font-robo  text-lightbrown`}>{process.env.REACT_APP_APP_NAME}</p>
          ) : (
            ""
          )}

          <SlMenu
            onClick={() => {
              setOpen((v) => !v);
              settoogleHam(!toggleHam);
            }}
            size={25}
            className="cursor-pointer"
          />
        </div>
        <div
          className={` ${
            toggleHam ? "hidden" : "flex "
          } w-full  items-center justify-center  mt-6`}
        >
          <div className="w-11/12 flex items-center justify-center border-b py-2 border-green-950">
            <CiSearch />
            <input
              className="outline-none w-full px-2 font-sans text-[14px]"
              placeholder="Search"
            />
          </div>
        </div>
        <div
          className={` ${
            toggleHam ? "" : ""
          } w-full flex items-center justify-center flex-wrap h-[650px] overflow-y-auto`}
        >
          <NavLink
            to="/dashboard"
            className={({ isActive }) =>
              isActive
                ? `bg-lightbrown  hover:bg-opacity-15 bg-opacity-15 border-lightbrown border-l-4  p-3 justify-start w-full hover:bg-lightbrown flex  cursor-pointer items-center gap-2 text-[16px] relative group`
                : "hover:border-l-4 border-l-4 border-white hover:border-lightbrown  p-3 justify-start w-full hover:bg-lightbrown flex  cursor-pointer items-center gap-2 text-[16px] relative group hover:bg-opacity-15 bg-opacity-15"
            }
          >
            <AiOutlineDashboard size={25} /> {toggleHam ? " " : <p>Dashboard</p>}

            {toggleHam ? (
              <div
                className={`absolute whitespace-no-wrap bg-gray-700 text-white text-sm p-2 rounded shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 left-full top-1/2 transform -translate-y-1/2 ml-2`}
              >
                Tasks
              </div>
            ) : (
              ""
            )}
          </NavLink>
          
          <NavLink
            to="/job-listing"
            className={({ isActive }) =>
              isActive
                ? `bg-lightbrown  hover:bg-opacity-15 bg-opacity-15 border-lightbrown border-l-4  p-3 justify-start w-full hover:bg-lightbrown flex  cursor-pointer items-center gap-2 text-[16px] relative group`
                : "hover:border-l-4 border-l-4 border-white hover:border-lightbrown  p-3 justify-start w-full hover:bg-lightbrown flex  cursor-pointer items-center gap-2 text-[16px] relative group hover:bg-opacity-15 bg-opacity-15"
            }
          >
            <MdOutlineContentPasteSearch size={25} /> {toggleHam ? " " : <p>Jobs</p>}
            {toggleHam ? (
              <div
                className={`absolute whitespace-no-wrap bg-gray-700 text-white text-sm p-2 rounded shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 left-full top-1/2 transform -translate-y-1/2 ml-2`}
              >
                Jobs
              </div>
            ) : (
              ""
            )}
          </NavLink>

          {header?.data?.chats?.chat_uid ? (
            <NavLink
              to={`/plug/${header?.data?.chats?.chat_uid}`}
              className={({ isActive }) =>
                isActive
                ? `bg-lightbrown  hover:bg-opacity-15 bg-opacity-15 border-lightbrown border-l-4  p-3 justify-start w-full hover:bg-lightbrown flex  cursor-pointer items-center gap-2 text-[16px] relative group`
                : "hover:border-l-4 border-l-4 border-white hover:border-lightbrown  p-3 justify-start w-full hover:bg-lightbrown flex  cursor-pointer items-center gap-2 text-[16px] relative group hover:bg-opacity-15 bg-opacity-15"
              }
            >
              <WiStars size={30} />{" "}
              {toggleHam ? (
                " "
              ) : (
                <div className="text-[15px] flex w-full items-center justify-between">
                 <p> Chat with <b>Ai Plug</b></p> <RiExpandUpDownLine />
                </div>
              )}
              {toggleHam ? (
                <div
                  className={`absolute whitespace-no-wrap bg-gray-700 text-white text-sm p-2 rounded shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 left-full top-1/2 transform -translate-y-1/2 ml-2`}
                >
                  Chat with Ai
                </div>
              ) : (
                ""
              )}
            </NavLink>
          ) : (
            <div
              onClick={openChat}
              className={
                "hover:border-l-4 border-l-4 border-white hover:border-lightbrown  p-3 justify-start w-full hover:bg-lightbrown flex  cursor-pointer items-center gap-2 text-[16px] relative group"
              }
            >
              <WiStars size={30} />{" "}
              {toggleHam ? (
                " "
              ) : (
                <p className="text-[15px]">
                  Chat with <b>Ai Plug</b>
                </p>
              )}
              {toggleHam ? (
                <div
                  className={`absolute whitespace-no-wrap bg-gray-700 text-white text-sm p-2 rounded shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 left-full top-1/2 transform -translate-y-1/2 ml-2`}
                >
                  Chat with Ai
                </div>
              ) : (
                ""
              )}
            </div>
          )}

          <NavLink
            to="/campaigns-list"
            className={({ isActive }) =>
              isActive
                ? `bg-lightbrown hover:bg-opacity-15  bg-opacity-15 border-lightbrown border-l-4  p-3 justify-start w-full hover:bg-lightbrown flex  cursor-pointer items-center gap-2 text-[16px] relative group`
                : "hover:border-l-4 border-l-4 border-white hover:border-lightbrown  p-3 justify-start w-full hover:bg-lightbrown flex  cursor-pointer items-center gap-2 text-[16px] relative group bg-opacity-15 hover:bg-opacity-15 "
            }
          >
            <IoIosRocket size={25} />{" "}
            {toggleHam ? " " : <p className="text-[15px]">Campaigns</p>}
            {toggleHam ? (
              <div
                className={`absolute whitespace-no-wrap bg-gray-700 text-white text-sm p-2 rounded shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 left-full top-1/2 transform -translate-y-1/2 ml-2`}
              >
                Campaigns
              </div>
            ) : (
              ""
            )}
          </NavLink>

          <NavLink
            to="/inbox"
            className={({ isActive }) =>
              isActive
                ? `bg-lightbrown  hover:bg-opacity-15 bg-opacity-15 border-lightbrown border-l-4  p-3 justify-start w-full hover:bg-lightbrown flex  cursor-pointer items-center gap-2 text-[16px] relative group`
                : "hover:border-l-4 border-l-4 border-white hover:border-lightbrown  p-3 justify-start w-full hover:bg-lightbrown flex   hover:bg-opacity-15 bg-opacity-15 cursor-pointer items-center gap-2 text-[16px] relative group"
            }
          >
            <MdOutlineMessage size={25} />{" "}
            {toggleHam ? " " : <p className="text-[15px]">Inbox</p>}
            {toggleHam ? (
              <div
                className={`absolute whitespace-no-wrap bg-gray-700 text-white text-sm p-2 rounded shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 left-full top-1/2 transform -translate-y-1/2 ml-2`}
              >
                Inbox
              </div>
            ) : (
              ""
            )}
          </NavLink>

          <NavLink
            to="/leads"
            className={({ isActive }) =>
              isActive
                ? `bg-lightbrown  hover:bg-opacity-15 bg-opacity-15 border-lightbrown border-l-4  p-3 justify-start w-full hover:bg-lightbrown flex  cursor-pointer items-center gap-2 text-[16px] relative group`
                : "hover:border-l-4 border-l-4 border-white hover:border-lightbrown  p-3 justify-start w-full hover:bg-lightbrown flex  hover:bg-opacity-15 bg-opacity-15  cursor-pointer items-center gap-2 text-[16px] relative group"
            }
          >
            <LiaUsersSolid size={25} />{" "}
            {toggleHam ? " " : <p className="text-[15px]">Leads </p>}
            {toggleHam ? (
              <div
                className={`absolute whitespace-no-wrap bg-gray-700 text-white text-sm p-2 rounded shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 left-full top-1/2 transform -translate-y-1/2 ml-2`}
              >
                Leads
              </div>
            ) : (
              ""
            )}
          </NavLink>

          <NavLink
            to="/candidates"
            className={({ isActive }) =>
              isActive
                ? `bg-lightbrown   hover:bg-opacity-15 bg-opacity-15 border-lightbrown border-l-4  p-3 justify-start w-full hover:bg-lightbrown flex  cursor-pointer items-center gap-2 text-[16px] relative group`
                : "hover:border-l-4 border-l-4 border-white hover:border-lightbrown  p-3 justify-start w-full hover:bg-lightbrown flex   hover:bg-opacity-15 bg-opacity-15 cursor-pointer items-center gap-2 text-[16px] relative group"
            }
          >
            <LiaUsersSolid size={25} />{" "}
            {toggleHam ? " " : <p className="text-[15px]">Candidates </p>}
            {toggleHam ? (
              <div
                className={`absolute whitespace-no-wrap bg-gray-700 text-white text-sm p-2 rounded shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 left-full top-1/2 transform -translate-y-1/2 ml-2`}
              >
                Candidates
              </div>
            ) : (
              ""
            )}
          </NavLink>

          <NavLink
            to="/team"
            className={({ isActive }) =>
              isActive
                ? `bg-lightbrown   hover:bg-opacity-15 bg-opacity-15 border-lightbrown border-l-4  p-3 justify-start w-full hover:bg-lightbrown flex  cursor-pointer items-center gap-2 text-[16px] relative group`
                : "hover:border-l-4 border-l-4 border-white hover:border-lightbrown  p-3 justify-start w-full hover:bg-lightbrown flex   hover:bg-opacity-15 bg-opacity-15 cursor-pointer items-center gap-2 text-[16px] relative group"
            }
          >
            <RiTeamLine size={25} />{" "}
            {toggleHam ? " " : <p className="text-[15px]">Member </p>}
            {toggleHam ? (
              <div
                className={`absolute whitespace-no-wrap bg-gray-700 text-white text-sm p-2 rounded shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 left-full top-1/2 transform -translate-y-1/2 ml-2`}
              >
                Member
              </div>
            ) : (
              ""
            )}
          </NavLink>

          <NavLink
            to="/reportdata"
            className={({ isActive }) =>
              isActive
                ? `bg-lightbrown   hover:bg-opacity-15 bg-opacity-15 border-lightbrown border-l-4  p-3 justify-start w-full hover:bg-lightbrown flex  cursor-pointer items-center gap-2 text-[16px] relative group`
                : "hover:border-l-4 border-l-4 border-white hover:border-lightbrown  p-3 justify-start w-full hover:bg-lightbrown flex   hover:bg-opacity-15 bg-opacity-15 cursor-pointer items-center gap-2 text-[16px] relative group"
            }
          >
            <HiDocumentReport size={25} />{" "}
            {toggleHam ? " " : <p className="text-[15px]">Report </p>}
            {toggleHam ? (
              <div
                className={`absolute whitespace-no-wrap bg-gray-700 text-white text-sm p-2 rounded shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 left-full top-1/2 transform -translate-y-1/2 ml-2`}
              >
                Report
              </div>
            ) : (
              ""
            )}
          </NavLink>

          <NavLink
            to="/notifications"
            className={({ isActive }) =>
              isActive
                ? `bg-lightbrown   hover:bg-opacity-15 bg-opacity-15 border-lightbrown border-l-4  p-3 justify-start w-full hover:bg-lightbrown flex  cursor-pointer items-center gap-2 text-[16px] relative group`
                : "hover:border-l-4 border-l-4 border-white hover:border-lightbrown  p-3 justify-start w-full hover:bg-lightbrown flex   hover:bg-opacity-15 bg-opacity-15 cursor-pointer items-center gap-2 text-[16px] relative group"
            }
          >
            <span className="relative">
              <IoIosNotifications size={25} />{" "}
              {notification?.notification ? (
                <span className="bg-red-600 text-white absolute top-[-10px] w-[20px] max-w-[40px] h-[20px] max-h-[40px] text-center rounded-full text-[12px]">
                  {notification?.notification}
                </span>
              ) : (
                ""
              )}{" "}
            </span>{" "}
            {toggleHam ? " " : <p>Notifications </p>}
            {toggleHam ? (
              <div
                className={`absolute whitespace-no-wrap bg-gray-700 text-white text-sm p-2 rounded shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 left-full top-1/2 transform -translate-y-1/2 ml-2`}
              >
                Notifications
              </div>
            ) : (
              ""
            )}
          </NavLink>

          <NavLink
            className={
              "hover:border-l-4 border-l-4  hover:bg-opacity-15 bg-opacity-15 border-white hover:border-lightbrown  flex-wrap p-3 justify-between w-full hover:bg-lightbrown flex cursor-pointer items-center gap-2 text-[16px] relative group"
            }
          >
            <div className={`flex justify-between w-full relative`}>
              <div className="flex">
                <IoSettingsOutline className="mr-2" size={25} />
                <p className={` text-[15px] ${toggleHam ? "hidden" : ""}`}>
                  Settings
                </p>
              </div>
              <span>
                <MdArrowDropDown
                  size={25}
                  onClick={() =>
                    handleDrowpDown === "settings"
                      ? sethandleDrowpDown("")
                      : sethandleDrowpDown("settings")
                  }
                  className={`${toggleHam ? "" : "mr-2"}`}
                />
              </span>
            </div>
          </NavLink>

          {/* Dropdown Links */}
          <div
            className={`dropdown w-full ml-0 md:ml-auto md:w-11/12 ${
              handleDrowpDown === "settings"
                ? "max-h-99 block"
                : "max-h-0 hidden"
            } transition-all duration-700 select-none`}
          >
            <NavLink
              to={"/profile"}
              className="flex py-3 px-3 hover:bg-lightbrown hover:bg-opacity-20"
            >
              <CgProfile size={20} className="mr-3" />
              <p className="text-[15px]">Profile</p>
            </NavLink>
            <NavLink
              to={"/accounts"}
              className="flex py-3 px-3 hover:bg-lightbrown hover:bg-opacity-20"
            >
              <TbAppsFilled size={20} className="mr-3" />
              <p className="text-[15px]">Accounts</p>
            </NavLink>

            <NavLink
              to={"/connect-ats"}
              className="flex py-3 px-3 hover:bg-lightbrown hover:bg-opacity-20"
            >
              <GrConnect size={20} className="mr-3" />
              <p className="text-[15px]">Link to ATS</p>
            </NavLink>
            <NavLink
              to={"/change-password"}
              className={`flex py-3 px-3 hover:bg-lightbrown hover:bg-opacity-20`}
            >
              <RiLockPasswordLine size={20} className="mr-3" />
              <p className="text-[15px]">Reset Password</p>
            </NavLink>
          </div>

          <NavLink
            className={
              "hover:border-l-4 border-l-4 border-white hover:border-lightbrown flex-wrap p-3 justify-between w-full hover:bg-lightbrown flex cursor-pointer items-center gap-2 text-[16px] relative group  hover:bg-opacity-15 bg-opacity-15"
            }
          >
            <div className={`flex justify-between w-full relative`}>
              <div className="flex">
                <MdOutlinePayment className="mr-2" size={25} />
                <p className={` text-[15px] ${toggleHam ? 'hidden' : ''}`}>Billing</p>
              </div>
              <span>
                <MdArrowDropDown
                  size={25}
                  onClick={() =>
                    handleDrowpDown === "billing"
                      ? sethandleDrowpDown("")
                      : sethandleDrowpDown("billing")
                  }
                  className={`${toggleHam ? "" : "mr-2"}`}
                />
              </span>
            </div>
          </NavLink>

          {/* Dropdown Links */}
          <div
            className={`dropdown w-full ml-0 md:ml-auto md:w-11/12 ${
              handleDrowpDown === "billing" ? "max-h-99 block" : "max-h-0 hidden"
            } transition-all duration-700 select-none`}
          >
            <NavLink to={"/billing-information"} className={`flex py-3 px-3 hover:bg-lightbrown hover:bg-opacity-20`}>
              <FaCircleInfo size={20} className="mr-3" />
              <p className="text-[15px]">Billing Information</p>
            </NavLink>
            <NavLink to={"/transection-history"} className={`flex py-3 px-3 hover:bg-lightbrown hover:bg-opacity-20`}>
              <TbTransactionDollar size={20} className="mr-3" />
              <p className="text-[15px]">Billing & Invoice</p>
            </NavLink>
            <NavLink to={"/subscription-plan"} className={`flex py-3 px-3 hover:bg-lightbrown hover:bg-opacity-20`}>
              <PiUserListFill size={20} className="mr-3" />
              <p className="text-[15px]">Subscription & Uses</p>
            </NavLink>
          </div>

          <NavLink
            className={
              "hover:border-l-4 border-l-4 border-white hover:border-lightbrown hover:bg-opacity-15 p-3 justify-start w-full hover:bg-lightbrown flex  cursor-pointer items-center gap-2 text-[16px] relative group"
            }
          >
            <MdLogout size={25} />{" "}
            {toggleHam ? (
              " "
            ) : (
              <p onClick={() => logout()} className="text-[15px]">
                Log Out
              </p>
            )}
            {toggleHam ? (
              <div
                className={`absolute whitespace-no-wrap bg-gray-700 text-white text-sm p-2 rounded shadow-lg opacity-0 group-hover:opacity-100 className="text-[15px]" transition-opacity duration-300 left-full top-1/2 transform -translate-y-1/2 ml-2`}
              >
                Logout
              </div>
            ) : (
              ""
            )}
          </NavLink>
        </div>
      </div>
    </>
  );
}
