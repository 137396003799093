import { useEffect, useState } from "react";
import AddManualCandidate from "../Candidate/AddManualCandidate";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function SteamCvcandRes({loading,steamDataSet,typeofCand, streamedData, steamOpen, setStreamOpen}){
    // const [tab, setTabl] = useState('save-candidate');
    const [data, setData] = useState({});
    const navigate = useNavigate();


    useEffect(() => {
        if(loading===false && steamDataSet){
            setData(steamDataSet);
        }
    }, [loading, steamDataSet]);

    // console.log("Parse Data", streamedData);

    if(data?.error){
        toast.warning(data?.error);
        navigate('/candidates');
    }

    return (
        <div
            className={`relative z-20 ${steamOpen ? `` : `hidden tansition-all`}`}
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
        >
            <div className="fixed inset-0 z-20  m-auto overflow-y-auto">
                <div className="flex min-h-full w-[95%] m-auto items-end justify-center text-center">
                    <AddManualCandidate loading={loading} typeofCand={typeofCand} setManualCand={setStreamOpen}  steamDataSet={steamDataSet} />
                </div>
            </div>
        </div>
    );
}