import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import endpoint from "../../../Helpers/endpoint";
import { useResetPass } from "../../../hooks/apihooks/useResetPass";
import Http from "../../../http";
import { Spinner } from "../../Loader/Spinner";
import { IoEye,IoEyeOff } from "react-icons/io5";

export default function ResetPassword() {
  const { token } = useParams();
  const [passToggle, setPassToggle] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { mutateAsync, isPending } = useResetPass();
  const checkTokenExpRestPass = () => {
    const URL = `${endpoint.user.checkresetpasstoken}/${token}`;
    return Http().get(URL);
  };

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["gettokenforgotpass", token],
    queryFn: checkTokenExpRestPass,
  });

  const onSubmit = (data) => {
    const newdata = { ...data, token: token };
    mutateAsync(newdata).then((resp) => {
      if (resp.status === true) {
        toast.success(resp?.message);
        navigate("/signin");
        refetch();
      }
      if (resp?.status === false && resp?.error) {
        toast.error(resp?.error?.password[0]);
      }
      if (resp?.status === false) {
        refetch();
        toast.error(resp?.message);
      }
    });
  };
  return (
    <>
      {isPending || isLoading ? <Spinner size="medium" /> : ""}
      <section className="bg-green-100 h-[100vh]">
        <div className="m-[auto] max-sm:px-3 relative">
          <div className="flex flex-wrap max-sm:flex-wrap-reverse items-center">
            <div className="w-[50%] px-10 max-sm:w-full">
              <div className="py-5 absolute top-0 left-[3%] max-sm:ml-5">
                <Link to="/" className="font-bold">
                  {process.env.REACT_APP_APP_NAME}
                </Link>
              </div>
              <h1 className="text-[40px] font-extrabold">
                The Hire talent Engagement Platform
              </h1>
              <img src="/images/loginpage.png" alt="loginpage" />
              <p>
                Accelerate hiring, strengthen your brand, and exceed recruiting
                targets - all while delivering a personalized candidate
                experience.
              </p>
            </div>
            <div className="w-[50%] bg-[white] max-sm:w-full h-[100vh]">
              <div className="px-10 max-sm:p-0 flex flex-wrap items-center h-[100vh]">
                <div className="px-5 py-5 max-sm:w-full w-[70%] m-[auto]">
                  <div className="w-full">
                    <h2 className="text-[25px] py-5 font-semibold">
                      Hi, welcome to {process.env.REACT_APP_APP_NAME}!
                    </h2>
                  </div>
                  {data?.status === true ? (
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="flex flex-wrap justify-between mb-3">
                        <div className="w-[100%] mb-5">
                          <label className="py-2 block">
                            New Password<sup className="text-[red]">*</sup>
                          </label>
                          <div className="relative">
                            <input
                              type={passToggle? "text": "password"}
                              name="password"
                              {...register("password", {
                                required: true,
                              })}
                              className="w-full px-2 py-2 border  rounded"
                              placeholder="Password"
                            />
                            <label className="absolute right-5 top-3 cursor-pointer">
                            {passToggle ?<IoEyeOff onClick={() => setPassToggle(false)} /> : <IoEye onClick={() => setPassToggle(true)} />   }
                            </label>
                          </div>

                          {errors.password && (
                            <span className="text-[red]">
                              Email-Id is required
                            </span>
                          )}
                        </div>
                        <div className="w-[100%] mb-5">
                          <label className="py-2 block">
                            Confirm Password<sup className="text-[red]">*</sup>
                          </label>
                          <div className="relative">
                            <input
                              type={passToggle? "text": "password"}
                              name="password_confirmation"
                              {...register("password_confirmation", {
                                required: true,
                              })}
                              className="w-full px-2 py-2 border  rounded"
                              placeholder="Confirm Password"
                            />
                            <label className="absolute right-5 top-3 cursor-pointer">
                            {passToggle ?<IoEyeOff onClick={() => setPassToggle(false)} /> : <IoEye onClick={() => setPassToggle(true)} />   }
                            </label>
                          </div>
                         
                          {errors.password_confirmation && (
                            <span className="text-[red]">
                              Email-Id is required
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="flex justify-between flex-wrap mb-3">
                        <div className="w-[100%]">
                          <button
                            type="submit"
                            className="w-full px-2 py-2 bg-green-700 text-white  rounded"
                          >
                            {isPending ? "Loading..." : "Submit"}
                          </button>
                        </div>
                      </div>
                    </form>
                  ) : (
                    <div>
                      {data?.status === false && data?.message ? (
                        <>
                          <p>{data?.message}</p>
                          <Link
                            to={"/forgot-password"}
                            className="mt-3 text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800 inline-block"
                          >
                            Forgot Password
                          </Link>
                        </>
                      ) : data?.status === true ? (
                        <>
                          <p>
                            Your password has been reset successfully. You can
                            now log in with your new password.
                          </p>
                          <Link
                            to={"/signin"}
                            className="mt-3 text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800 inline-block"
                          >
                            Login
                          </Link>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
