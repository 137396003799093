import { useState } from "react";
import { toast } from "react-toastify";
import Token from "../../../../Helpers/Token";
import { useParseProfile } from "../../../../hooks/apihooks/useParseProfile";
import { Spinner } from "../../../Loader/Spinner";

export function BooleanSearch({setOpen, campaign}){
    const [country, setCountry] = useState('');
    const [location,setLocation] = useState('');
    const [jobTitle, setJobTitle] = useState('');
    const [sourceType, setSourceType] = useState('');
    const [locationKeywords, setLocationKeywords] = useState('');
    const [excludeKeywords, setExcludeKeywords] = useState('');
    const [totalnorecord, setTotalNoOfRecord] = useState(20);
    const {isPending, mutateAsync} = useParseProfile();


    const handleSearch = (e) => {
        e.preventDefault();
        const searchquery = {
          jobtitle : jobTitle,
          country : country,
          location : location,
          locationskills : locationKeywords,
          sourcetype : sourceType,
        }
        mutateAsync({searchquery, totalnorecord, campaign:{campaign}, token: Token.getAccessToken()}).then((resp) => {
          if(resp?.status===true){
            toast.success(resp?.message);
            setOpen(false);
          }
        })
    };


    return (
        <>
        {isPending ? <Spinner size="medium" /> : "" }
        <form onSubmit={handleSearch} className="w-full">
          <div className="mt-3 text-center sm:text-left w-full">
            <div className="w-full flex items-start justify-center flex-col mb-3">
              <label className="">Source Type</label>
              <select className="w-full text-[14px] border border-gray-300 rounded-md p-2" required  value={sourceType}
                  onChange={(e) => setSourceType(e.target.value)}>
                  <option>--Select Source--</option>
                  <option selected>Linkedin</option>
                  <option disabled>Naukari</option>
                  <option disabled>Monstar</option>
                  <option disabled>Shine</option>
                  <option disabled>Indeed</option>
              </select>
            </div>
            <div className="w-full flex items-start justify-center flex-col mb-3">
              <label className="">Job Title</label>
              <input
                placeholder="E.g. Php Developer"
                value={jobTitle}
                required
                  onChange={(e) => setJobTitle(e.target.value)}
                className="w-full text-[12px] border border-gray-300 rounded-md p-2"
              />
            </div>
            <div className="flex justify-between">
              <div className="w-[49%] flex items-start justify-center flex-col mb-3">
                <label>Country</label>
                <input
                  placeholder="E.g India"
                  required
                  value={country}
                onChange={(e) => setCountry(e.target.value)}
                  className="w-full text-[12px] border border-gray-300 rounded-md p-2"
                />
              </div>
              <div className="w-[49%] flex items-start justify-center flex-col mb-3">
                <label>Location</label>
                <input
                  placeholder="E.g Varanasi"
                  required
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  className="w-full text-[12px] border border-gray-300 rounded-md p-2"
                />
              </div>
            </div>
            
            <div className="w-full flex items-start justify-center flex-col mb-3">
              <label className="">Skills or keywords to include</label>
              <input
                placeholder="E.g. Php,html,css, javascript"
                value={locationKeywords}
                  onChange={(e) => setLocationKeywords(e.target.value)}
                className="w-full text-[12px] border border-gray-300 rounded-md p-2"
              />
            </div>
            <div className="w-full flex items-start justify-center flex-col mb-3">
              <label className="">Skills or Keywords to exclude</label>
              <input
                placeholder="E.g. Assistant OR secretary"
                value={excludeKeywords}
                  onChange={(e) => setExcludeKeywords(e.target.value)}
                className="w-full text-[12px] border border-gray-300 rounded-md p-2"
              />
            </div>
            <div className="w-full flex items-start justify-center flex-col mb-3">
              <label className="">Total no of record</label>
              <input
                placeholder="Total No of record"
                value={totalnorecord}
                required
                onChange={(e) => setTotalNoOfRecord(e.target.value)}
                className="w-full text-[12px] border border-gray-300 rounded-md p-2"
              />
            </div>
          </div>
          <div className="py-2 sm:flex sm:flex-row-reverse">
            <button
                type="submit"
                className="inline-flex w-full justify-center rounded-md bg-bgbrown px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-darkbrown sm:ml-3 sm:w-auto"
            >
                Generate
            </button>
          </div>
        </form>
        </>
    )
}