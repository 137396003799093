import { useMutation, useQueryClient } from "@tanstack/react-query";
import endpoint from "../../Helpers/endpoint";
import Http from "../../http";

const addcandidate = ({data, token}) => {
    const URL = `${endpoint.candidate.candidate}`;
    const headers  = {
        "Content-Type": "multipart/form-data", 
        "Authorization": `Bearer ${token}` 
    }

    return Http().post(URL, data, headers);
}

const uploadFile = ({data, token}) => {
  const URL = `${endpoint.parser.documentParse}`;
  const headers  = {
      "Content-Type": "multipart/form-data", 
      "Authorization": `Bearer ${token}` 
  }

  return Http().post(URL, data, headers);
}

const canddeleteevent = ({data, token}) => {
  const URL = `${endpoint.candidate.candidate}/${data?.action}/${data?.actionid}`;
  const headers  = {
    "Authorization": `Bearer ${token}` 
  }
  return Http().delete(URL, '', headers);
}

const candidateProfile = ({data, token}) => {
  const URL = `${endpoint.parser.linkedinParser}`;
  const headers  = {
      "Authorization": `Bearer ${token}` 
    }
  return Http().post(URL, data, headers);
}

const updatecandidate = ({newdata, token}) => {
    const URL = `${endpoint.candidate.candidate}/${newdata?.candidateuid}`;
    const headers  = {
        "Content-Type": "multipart/form-data", 
        "Authorization": `Bearer ${token}` 
      }
    return Http().post(URL, newdata, headers);
}

export const useCandidate = () => {
  const queryClient = useQueryClient();
  return useMutation({
      mutationFn:addcandidate,
      onSuccess: (data, variables, context) => {
        queryClient.refetchQueries(['candidateitems']);
      },
      onError: (error, variables, context) => {
        // I will fire first
      },
      onSettled: (data, error, variables, context) => {
        // I will fire first
      },
  });
}


export const useCandidateUpdate = () => {
  return useMutation({
      mutationFn:updatecandidate,
      onSuccess: (data, variables, context) => {
        // I will fire first
      },
      onError: (error, variables, context) => {
        // I will fire first
      },
      onSettled: (data, error, variables, context) => {
        // I will fire first
      },
  });
}


export const useCandDeleteEvent= () => {
  return useMutation({
      mutationFn:canddeleteevent,
      onSuccess: (data, variables, context) => {
        // I will fire first
      },
      onError: (error, variables, context) => {
        // I will fire first
      },
      onSettled: (data, error, variables, context) => {
        // I will fire first
      },
  });
}

export const useCandidateProfileLink= () => {
  return useMutation({
      mutationFn:candidateProfile,
      onSuccess: (data, variables, context) => {
        // I will fire first
      },
      onError: (error, variables, context) => {
        // I will fire first
      },
      onSettled: (data, error, variables, context) => {
        // I will fire first
      },
  });
}

export const useDocumentUploadResume= () => {
  return useMutation({
      mutationFn:uploadFile,
      onSuccess: (data, variables, context) => {
        // I will fire first
      },
      onError: (error, variables, context) => {
        // I will fire first
      },
      onSettled: (data, error, variables, context) => {
        // I will fire first
      },
  });
}



